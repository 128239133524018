/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import Slider from 'react-slick';
import {BrProps} from '@bloomreach/react-sdk';
import {BlogComponent} from '../../blog/BlogComponent';

export const BlogSlider = (props: BrProps) => {
  const {
    page,
    component
  } = props;

  const {pageable} = component!.getModels();

  if (!pageable) {
    return null;
  }

  const params = component!.getParameters();
  const {title, subTitle} = params || {};

  const pageSize = params.pageSize && parseInt(params.pageSize) < 3 ? parseInt(params.pageSize) : 3;
  const settings: any = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: pageSize,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: 'js-pagination text-center u-slick__pagination mt-7 mb-0',
    customPaging: (i: any) => <span/>,
  };

  if (pageSize > 1) {
    settings.responsive = [
      {
        'breakpoint': 1200,
        'settings': {
          'slidesToShow': 3
        }
      }, {
        'breakpoint': 992,
        'settings': {
          'slidesToShow': 2
        }
      }, {
        'breakpoint': 768,
        'settings': {
          'slidesToShow': 2
        }
      }, {
        'breakpoint': 554,
        'settings': {
          'slidesToShow': 1
        }
      }
    ];
  }

  return (
    <div className='bg-off-white'>
      <div className='container space-2 space-md-3'>
        <div className='w-md-80 w-lg-50 text-center mx-md-auto mb-9'>
          {title && <h2 className='text-primary'>{title}</h2>}
          {subTitle && <p>{subTitle}</p>}
        </div>
        <Slider {...settings}
                className='js-slick-carousel u-slick u-slick--equal-height u-slick--gutters-2'>
          {pageable.items && pageable.items.map((reference: any, key: number) => {
            //const item = page.getContent(reference);
            return (
              <BlogComponent {...{
                page,
                component,
                documentRef: reference,
                type: 'card'
              }}/>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
