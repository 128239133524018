/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {DiscountType} from '../../../modules/commerce';
import {Badge} from 'react-bootstrap';

export interface Discount {
  code: string | null;
  targetIds: (string | null)[] | null;
  type: DiscountType | null;
}

export interface DiscountedProps {
  discounts?: Array<Discount | null> | null,
  itemId?: string
}

export const Discounted = (props: DiscountedProps) => {
  const {discounts, itemId} = props;

  if (!discounts || !itemId) {
    return null;
  }

  const isDiscounted = discounts.filter((discount) => discount?.targetIds?.includes(itemId));

  return isDiscounted && <Badge pill variant='primary'>Discounted</Badge>
}
