/*
 * Copyright 2019 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {ApolloProvider, useReactiveVar} from '@apollo/client';
import {commerceApiClient, connectorVar, ErrorResponse, errorResponseVar} from '../modules/commerce/api';
import {getDefaultConnector} from './utils';
import {BrPageProps} from './ApolloClient';

interface ContextProps {
  connector?: string;
  clearError?: () => void;
  errors?: ErrorResponse[];
}

const CommerceConnectorContext = React.createContext<ContextProps>({});
export const CommerceConnectorConsumer = CommerceConnectorContext.Consumer;

export function CommerceConnectorProvider<P extends BrPageProps>(props: React.PropsWithChildren<P>) {
  const errors = useReactiveVar(errorResponseVar);
  const defaultConnector = getDefaultConnector(props.page);
  const connector = sessionStorage.getItem('default-connector') ?? defaultConnector;
  connectorVar(connector);
  const clearError = () => {
    errorResponseVar(undefined);
  };

  return (
    <ApolloProvider client={commerceApiClient}>
      <CommerceConnectorContext.Provider
        value={{
          connector,
          errors,
          clearError,
        }}
      >
        {props.children}
      </CommerceConnectorContext.Provider>
    </ApolloProvider>
  );
}

export default CommerceConnectorContext;
