/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Content, Document, Page} from '@bloomreach/spa-sdk';
import {UserSegmentType} from '../../contexts';
import {AuthorByLineProps} from '../author/author-byline/AuthorByLineComponentTypes';
import {getAuthorByLineProps} from '../author/author-byline/AuthorByLineComponentUtils';
import {ArticleProps, CallToActionEntryProps} from './ArticleComponentTypes';
import {getImageVariants} from '../../utils/ImageUtil';
import {getPickedProducts} from '../product/ProductComponentUtils';

export const getArticleProps = (
  page: Page,
  content: Content | Document | undefined,
  userSegment: UserSegmentType
): ArticleProps | null => {
  if (!page) {
    return null;
  }

  if (!content) {
    return null;
  }

  const {
    authors: documentRefs,
    tags,
    entries,
    introduction,
    images: image,
    title,
    date,
    sidebar: sidebars
  } = content.getData();

  const articleProps: ArticleProps = {
    title,
    description: introduction?.value,
    date,
    tags,
    link: {
      url: content.getUrl()!
    },
    images: getImageVariants(page, image)
  };

  const authorByLineProps: AuthorByLineProps = getAuthorByLineProps({
    componentStyle: '',
    params: {},
    page, documentRefs, date
  }, userSegment);
  articleProps.authors = authorByLineProps?.authors;

  // Parse the entries
  articleProps.entries = entries?.map((entry: any) => {
    const entryType = entry.contentType.split(':')[1];
    switch (entryType) {
      case 'paragraph':
      case 'demoParagraph':
        const {title: entryTitle, description: entryDescription} = entry;
        return {
          type: entryType,
          title: entryTitle,
          description: entryDescription.value
        };
      case 'calltoaction':
      case 'demoCallToAction':
        const {name, info, image: entryImage, internallink: internalLinkRef, externallink: externalLink} = entry;
        const articleProps: CallToActionEntryProps = {
          type: entryType,
          title: name,
          description: info.value,
          images: getImageVariants(page, entryImage),
          externalLink
        };
        const internalLinkDocument = internalLinkRef && page.getContent(internalLinkRef);
        if (internalLinkDocument) {
          articleProps.internalLink = {
            url: internalLinkDocument.getUrl() || '',
            label: internalLinkDocument.getData().displayName
          }
        }
        return articleProps;
      default:
        return null;
    }
  });

  articleProps.sidebars = sidebars?.filter((sidebar: any) => sidebar.sidebarItem).map((sidebar: any) => {
    const sidebarItem = sidebar?.sidebarItem?.[0];
    if (sidebarItem) {
      if (sidebarItem?.bannerLink) {
        return {
          type: 'banner',
          banner: sidebarItem.bannerLink
        };
      } else if (sidebarItem.productPicker) {
        const products = getPickedProducts(page, sidebarItem.productPicker);
        return {
          type: 'product',
          products
        };
      }
    }
    return null;
  });

  return articleProps;
};
