/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {lazy, Suspense} from 'react';
import {Container} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {BrComponentContext, BrManageMenuButton, BrPageContext} from '@bloomreach/react-sdk';
import {ComponentWrapper} from '..';
import {MenuComponentArgs, MenuComponentType} from './MenuComponentTypes';
import {getMenuProps} from './MenuComponentUtils';
import {Loader} from '../utility';

const OneLevelHorizontalMenu = lazy(() => import('./templates/OneLevelHorizontalMenu')
  .then((module) => ({default: module.OneLevelHorizontalMenu}))
);

const TwoLevelMultiColumnMenu = lazy(() => import('./templates/TwoLevelMultiColumnMenu')
  .then((module) => ({default: module.TwoLevelMultiColumnMenu}))
);

const PrimaryNavMenu = lazy(() => import('./templates/PrimaryNavMenu')
  .then((module) => ({default: module.PrimaryNavMenu}))
);

export const MenuComponent = ({menu, params, template, nowrap = false}: MenuComponentArgs) => {
  const page = React.useContext(BrPageContext)!;
  const component = React.useContext(BrComponentContext)!;
  const preview = page.isPreview();
  const {menu: menuRef} = component.getModels()
  const {channelId} = useParams();

  menu = menu || page.getContent(menuRef);

  if (!menu) {
    return null;
  }

  params = params || component.getParameters();
  template = template || params['template'];

  const menuBanners: any = {};

  Object.keys(params)
    .filter(key => key.startsWith('menuItemBanner') && !!params[key])
    .forEach(key => menuBanners[page.getContent(params[key])?.getData().title] = params[key]);

  const banners = menuBanners || {};
  const props = getMenuProps(
    page,
    menu,
    params,
    template,
    banners,
    channelId
  ) as unknown as MenuComponentType;

  const render = (props: MenuComponentType) => {
    const {params} = props;

    switch (params.template) {
      case 'multi-column-vertical':
        return <TwoLevelMultiColumnMenu {...props}/>;
      case 'site-menu':
        return <PrimaryNavMenu {...props}/>;
      default:
        return <OneLevelHorizontalMenu {...props}/>;
    }
  };

  return (
    <Suspense fallback={<Loader/>}>
      <ComponentWrapper {...{type: 'Menu', nowrap: nowrap}}>
        <Container
          className={`menu-component menu-component--${template} font-size-sm font-weight-light px-0 py-2 py-md-0`}
          data-menu-name={menu.getName()}>
          {preview && menu && <BrManageMenuButton {...{menu}}/>}
          {props && render(props)}
        </Container>
      </ComponentWrapper>
    </Suspense>
  );
};

