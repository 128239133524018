/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {ImageSet, Page} from '@bloomreach/spa-sdk';
import {ImageUrl} from '../components/global/CommonTypes';
import {isSaas} from './UrlUtils';
import {brxmEndpoint} from '../contexts';
import {ImageSizeEnum} from '../components/global/CommonEnums';
import {ResponsiveQueryType} from '../demo-connector-components/responsive/ResponsiveQuery';

export const UNSPLASH_IMAGE_MAPPING: Partial<Record<string, ImageSizeEnum>> = {
  full: ImageSizeEnum.Large,
  raw: ImageSizeEnum.Original,
  regular: ImageSizeEnum.MediumSquare,
  small: ImageSizeEnum.Small,
  thumb: ImageSizeEnum.Thumbnail
}

export const BYNDER_IMAGE_MAPPING: Partial<Record<string, ImageSizeEnum>> = {
  online: ImageSizeEnum.Original,
  large: ImageSizeEnum.Large,
  webImage: ImageSizeEnum.MediumSquare,
  thumbnail: ImageSizeEnum.Small,
  mini: ImageSizeEnum.Thumbnail
}

export const CLOUDINARY_IMAGE_MAPPING: Partial<Record<string, ImageSizeEnum>> = {
  "c_fit,h_100,w_100": ImageSizeEnum.Thumbnail
}

const parseSearchImageUrl = (url: string): ImageUrl => {
  if (url.startsWith('/')) {
    const brBaseUrl: string = brxmEndpoint();
    url = brBaseUrl && (brBaseUrl.substring(0, brBaseUrl.indexOf('/site')) + (isSaas() ? '/resources' : '/site/binaries') + url);
  }

  return {
    type: 'internal',
    url
  } as ImageUrl;
};

const getImageVariantByResponsiveQuery = (
  imageVariants: Partial<Record<ImageSizeEnum, ImageUrl>>,
  responsiveQuery: ResponsiveQueryType
): string | undefined => {
  const {
    isExtraSmallOrGreater,
    isSmallOrGreater,
    isMediumOrGreater,
    isLargeOrGreater
  } = responsiveQuery;

  let url;

  if (isLargeOrGreater) {
    // Try to make the article image display better
    url = imageVariants?.[ImageSizeEnum.Original]?.url;
  }
  if (!url && isMediumOrGreater) {
    // Try to make the article image display better
    url = imageVariants?.[ImageSizeEnum.Large]?.url;
  }
  if (!url && isSmallOrGreater) {
    url = imageVariants?.[ImageSizeEnum.Small]?.url;
  }
  if (!url && isExtraSmallOrGreater) {
    url = imageVariants?.[ImageSizeEnum.Thumbnail]?.url;
  }
  if (!url) {
    url = imageVariants?.[ImageSizeEnum.Original]?.url;
  }
  return url;
}

const getImageVariantBySize = (imageVariants: Partial<Record<ImageSizeEnum, ImageUrl>>, imageSize: ImageSizeEnum): string | undefined => {
  return imageVariants?.[imageSize]?.url ?? imageVariants?.[ImageSizeEnum.Original]?.url;
}

const getImageVariants = (page: Page, imageObject: any): Partial<Record<ImageSizeEnum, ImageUrl>> => {
  const images: Partial<Record<ImageSizeEnum, ImageUrl>> = {};

  const image = imageObject?.image?.[0];

  if (image) {
    const imageType = image?.contentType?.split(':')?.[1];
    switch (imageType) {
      case 'unsplashImage':
      case 'demoUnsplashImage':
        try {
          const imageJson = JSON.parse(image?.unsplashImage);
          const {urls, width, height} = imageJson;
          Object.keys(UNSPLASH_IMAGE_MAPPING).forEach(key => {
            const url = urls?.[key];
            if (url) {
              const imageSize = UNSPLASH_IMAGE_MAPPING[key] as ImageSizeEnum;
              images[imageSize] = {
                type: 'unsplash',
                url,
                imageSize
              } as ImageUrl;
            }
          });
          const imageOriginal = images[ImageSizeEnum.Original];
          if (imageOriginal) {
            imageOriginal.width = width;
            imageOriginal.height = height;
          }
        } catch (e) {
        }
        break;
      case 'bynderImage':
      case 'demoBynderImage':
        try {
          const imageJson = JSON.parse(image?.bynderImage);
          Object.keys(BYNDER_IMAGE_MAPPING).forEach(key => {
            const file = imageJson?.[0]?.files?.[key];
            if (file) {
              const {fileSize, url, height, width} = file;
              const imageSize = BYNDER_IMAGE_MAPPING[key] as ImageSizeEnum;
              images[imageSize] = {
                type: 'bynder',
                imageSize,
                fileSize,
                url,
                height,
                width
              } as ImageUrl;
            }
          });
          return images;
        } catch (e) {
        }
        break;
      case 'demoCloudinaryImage':
        try {
          const imageJson = JSON.parse(image?.cloudinaryImage);
          const {secure_url: url, width, height, bytes: fileSize} = imageJson?.[0];
          images[ImageSizeEnum.Original] = {
            type: 'cloudinary',
            imageSize: ImageSizeEnum.Original,
            fileSize,
            url,
            height,
            width
          } as ImageUrl;
          const files = imageJson?.[0]?.derived;
          files?.forEach((file: any) => {
            if (file?.raw_transformation) {
              const imageSize = CLOUDINARY_IMAGE_MAPPING[file?.raw_transformation] as ImageSizeEnum;
              images[imageSize] = {
                type: 'cloudinary',
                imageSize,
                url: file.secure_url
              } as ImageUrl;
            }
          })
          return images;
        } catch (e) {
        }
        break;
      case 'externalLink':
      case 'demoExternalLink':
        let externalLink = image?.externalLink;
        if (externalLink) {
          return {
            [ImageSizeEnum.Original]: {
              type: 'external',
              url: externalLink,
              imageSize: ImageSizeEnum.Original
            }
          };
        }
        break;
      default:
        const imageDoc = page.getContent(image) as any;
        if (imageDoc) {
          Object.values(ImageSizeEnum)
            .forEach((value) => {
              const imageSize: ImageSizeEnum = value as ImageSizeEnum;
              const file = imageDoc?.model?.data?.[imageSize];
              const url = file?.links?.site?.href;
              if (url) {
                const {width, height, size: fileSize} = file;
                images[imageSize] = {
                  type: 'internal',
                  url,
                  imageSize,
                  width,
                  height,
                  fileSize
                } as ImageUrl;
              }
            });
          const imageSet = page.getContent<ImageSet>(image);
          if (imageSet && imageSet.getOriginal) {
            images[ImageSizeEnum.Original] = {
              type: 'internal',
              url: imageSet.getOriginal()?.getUrl(),
            } as ImageUrl;
          } else {
            images[ImageSizeEnum.Original] = {
              type: 'internal',
              url: imageDoc?.model?.links?.site?.href,
            } as ImageUrl;
          }
        }
    }
  }
  return images;
};

const getBackgroundImageLink = (url: string) => {
  // TODO: workaround
  url = url?.replaceAll('starterstoreboot', 'pacific-home')
  const hasSpecialCharacters = /\(|\)/.test(url);
  return hasSpecialCharacters ? 'url("' + url! + '")' : 'url(' + url! + ')';
}

export {
  parseSearchImageUrl,
  getBackgroundImageLink,
  getImageVariants,
  getImageVariantByResponsiveQuery,
  getImageVariantBySize
};
