/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap'
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {PageTitleComponent} from '..';
import emptyShoppingCart from '../../assets/img/empty_shopping_cart.svg'

function EmptyCartBase(props: RouteComponentProps) {
  const backgroundImgStyle = {
    backgroundImage: 'url(' + emptyShoppingCart + ')',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    opacity: '0.9',
    height: '60vh'
  };

  const handleGoBack = () => {
    // https://github.com/ReactTraining/history/issues/829
    window.history.back();
  };

  return (
    <Container className='d-flex' style={backgroundImgStyle}>
      <PageTitleComponent contentTitle={'Shopping cart is empty'}/>
      <Row className='align-self-center'>
        <Col>
          <h1 className='header'>Shopping cart is empty</h1>
          <p className='mb-0'>Before checking out, please add some products to your shopping cart</p>
          <Button variant={'secondary'} onClick={handleGoBack}>Go Back</Button>
        </Col>
      </Row>
    </Container>
  );
}

export const EmptyCart = withRouter(EmptyCartBase);
