/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {Col, Form} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {CartContext} from '../../../contexts';
import {CartStageEnum} from '../../global/CommonEnums';
import {PlaceOrder} from '../../order/action/PlaceOrder';
import {Shopping} from './Shopping';
import {EmptyCart} from '../EmptyCart';
import ReturnButton from '../action/ReturnButton';
import {readOrderFromSession} from '../../order/OrderComponentUtils';
import {StageInputProps} from '../CartComponentTypes';

export function Purchase(props: BrProps & StageInputProps) {
  const {
    page,
    setStage
  } = props;
  const {cartDetails}: any = useContext(CartContext);
  const {id, totalQuantity = 0} = cartDetails;
  const orderDetails = readOrderFromSession();

  if (!id || totalQuantity === 0) {
    return <EmptyCart/>;
  }

  return (
    <React.Fragment>
      <Form noValidate={true}>
        <h4>Review Your Order</h4>
        <Shopping {...{cartDetailsFetched: cartDetails}}/>
        <Form.Row>
          <Form.Group as={Col}>
            <ReturnButton stage={CartStageEnum.BILLING} setStage={setStage}/>
          </Form.Group>
          <Form.Group as={Col}>
            <PlaceOrder setStage={setStage} orderId={orderDetails?.id} page={page!}/>
          </Form.Group>
        </Form.Row>
      </Form>
    </React.Fragment>
  )
}
