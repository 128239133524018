/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {CartProps, GetErrorProps, withCart, withGetError} from '../../connector-components';
import {CartContext} from '../../contexts';
import {CartStageEnum} from '../global/CommonEnums';

import CartMenu from './CartMenu';
import {EmptyCart} from './EmptyCart';
import {ComponentHeader, Status} from '../utility';
import {Billing, Delivery, Purchase, Shipping, Shopping} from './stage';
import {getInitialStage, readOrderFromSession, saveOrderToSession} from '../order/OrderComponentUtils';
import {getUrl} from '../../utils/UrlUtils';

function CartDetailBase(props: BrProps & CartProps & GetErrorProps) {
  const {
    page,
    component,
    loading,
    cart,
    errors,
    clearError
  } = props;

  const history = useHistory();
  const {cartDetails, setCartDetails}: any = useContext(CartContext);
  const {id, totalQuantity = 0} = cartDetails;

  const orderDetails = readOrderFromSession();
  let initialStage = getInitialStage();

  const [stage, setStage] = useState<CartStageEnum>(initialStage);

  useEffect(() => {
    if (stage === CartStageEnum.FINISH) {
      setCartDetails(null);
      saveOrderToSession(undefined);
      history.push(getUrl('/orders/' + orderDetails?.id, page!));
    }
  }, [stage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cart) {
      setCartDetails(cart);
    }
  }, [cart]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <Status container loading status={'We are fetching cart for you.'}/>
    );
  }

  if (!id || totalQuantity === 0) {
    return <EmptyCart/>;
  }

  const renderStage = () => {
    const stageParams = {
      page: page!,
      component,
      setStage
    };
    switch (stage) {
      case CartStageEnum.SHOPPING:
        return <Shopping {...{...stageParams, ...{cartDetailsFetched: cart}}}/>;
      case CartStageEnum.SHIPPING:
        return <Shipping {...stageParams}/>;
      case CartStageEnum.DELIVERY:
        return <Delivery {...stageParams} orderId={orderDetails?.id}/>;
      case CartStageEnum.BILLING:
        return <Billing {...stageParams}/>;
      case CartStageEnum.PURCHASE:
        return <Purchase {...stageParams}/>;
      default:
        return null;
    }
  }

  return (
    <div className={'cart'}>
      <ComponentHeader>
        <span className={'float-right'}>{totalQuantity} items</span>
        <h2>Cart</h2>
      </ComponentHeader>
      {errors?.map((error, key: number) => {
        const skip = error.operation === 'OrderShipmentMethods' && error.message === 'Method not implemented.';
        return (!skip && <Status key={key} onClose={clearError} container status={error?.message}
                                 dismissible customClassName={'mt-3'} error/>)
      })}
      <Container className='space-1'>
        <Row>
          <Col xs={8}>
            {renderStage()}
          </Col>
          <Col xs={4}>
            <CartMenu/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export const CartDetail = withGetError(withCart(CartDetailBase));
