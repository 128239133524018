/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * BlogCard component
 */
import * as React from 'react';
import AuthorByLine from '../../author/author-byline/templates/AuthorByLine';
import {BlogProps} from '../BlogComponentTypes';
import {Link} from 'react-router-dom';
import {getDateString} from '../../../utils/DateUtils';

export const BlogCard = ({introduction, title, link, date, authors}: BlogProps) => {
  const trimmedDescription = introduction && introduction.length > 120 ? introduction.substring(0, 120) + '...' : introduction;
  return (
    <div className='card border-0 mb-3 h-100 blog-card'>
      <div className='card-body p-5 h-80'>
        <small className='d-block text-muted mb-2'>{getDateString(date)}</small>
        <h2 className='h5'>
          {link && link.url ? <Link className={'stretched-link'} to={link.url}>{title}</Link> : {title}}
        </h2>
        {trimmedDescription && <p className='mb-0'
           dangerouslySetInnerHTML={{__html: trimmedDescription}}/>}
      </div>

      {authors && <div className='card-footer pb-5 px-0 mx-5 h-20'>
        <div className='media align-items-center'>
          <AuthorByLine authors={authors} componentStyle={'Article'} date={date}/>
        </div>
      </div>}
    </div>
  )
};
