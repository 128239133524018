/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Card, CardGroup, Col, Image, Row} from 'react-bootstrap';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import {Icon} from '../../utility';
import {AuthorBioProps} from '../AuthorBioComponentTypes';
import {getMessage} from '../../../utils/MessageUtils';
import {getImageVariantBySize} from '../../../utils/ImageUtil';
import {ImageSizeEnum} from '../../global/CommonEnums';

const config = require('../AuthorConfig.json');
export default function AuthorBioSidebar({fullName, images, role, accounts}: AuthorBioProps) {
  const image = getImageVariantBySize(images, ImageSizeEnum.Thumbnail);
  return (
    <CardGroup className={'author-sidebar'}>
      <Row>
        <Col xs={12}>
          <Card className={'profile-author-sidebar d-flex align-items-center p-2 mb-2'}>
            {image && <Image roundedCircle src={image} className={'image-avatar my-3'} alt='Author'/>}
            <h4>{fullName}</h4>
            <p>{role}</p>
          </Card>
        </Col>
        <Col xs={12}>
          <Card className={'social-author-sidebar'}>
            <Card.Header>{getMessage(config, 'Social Profiles')}</Card.Header>
            <Card.Body>
              <ul className={'list-unstyled'}>
                {accounts.map((account, key: number) =>
                  <li key={key}>
                    <a href={account.link ? account.link.url : '#'}>
                      <Icon name={['fab', account.type as IconName]} size={'lg'}>
                        {account.type}
                      </Icon>
                    </a>
                  </li>
                )}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </CardGroup>
  );
}
