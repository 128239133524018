/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Document, Pagination, Reference} from '@bloomreach/spa-sdk';
import {BrProps} from '@bloomreach/react-sdk';
import {ArticleComponent} from '..';
import {ComponentHeader, ListPagination, Sorter, Status} from '../utility';
import {getListPaginationPropsFromBrxm} from '../utility/list-pagination/ListPaginationUtils';

const config = require('./ArticleListComponentConfig.json');

export const ArticleListComponent = (props: BrProps) => {
  const {
    page,
    component
  } = props;

  const pageable = page!.getContent<Pagination>(component!.getModels().pagination);
  if (!pageable) {
    return null;
  }

  const options = config.sorting;
  const {title, showPagination = true} = component!.getParameters();
  const queryType = 'content';

  const pagination = getListPaginationPropsFromBrxm(pageable);

  const itemsPerRowOptions = config?.itemsPerRowOptions?.map((value: number) => ({
    'key': 'size-' + value,
    'text': `${value}`,
    'value': `${value}`
  }));

  const items = pageable.getItems();

  // Check if all items have expected article type
  const findNonArticle = items?.some(item => {
    const content = page!.getContent<Document>(item);
    if (!content) {
      return true;
    }
    const {contentType} = content.getData();
    return contentType !== config.contentType;
  });

  if (findNonArticle) {
    return <Status container error status={`Invalid documentTypes parameter. It must be set as ${config.contentType}`}/>;
  }

  return (
    <Fragment>
      <ComponentHeader {...{showTitle: true, title}}/>
      <Container className='py-5'>
        <Row>
          <Col>
            <Sorter {...{
              options,
              itemsPerRow: 0,
              itemsPerRowOptions,
              numberOfItems: items.length,
              pagination,
              itemLabel: 'article',
              itemsLabel: 'articles',
              queryType,
              noSorter: true
            }}/>
          </Col>
        </Row>
        <Row>
          {items.map((reference: Reference, key: number) => {
            const itemStyle = key % 5 === 3 || key % 5 === 4 ? 6 : 4;
            return (
              <Col xs={12} lg={itemStyle} md={6} key={key} className={'mt-4'}>
                <ArticleComponent
                  {...props}
                  documentRef={reference}
                  type={'card'}
                  fluid={true}/>
              </Col>
            );
          })}
        </Row>
      </Container>
      {showPagination && pagination && <Container>
        <Col className={'text-center font-size-xs'}>
            <ListPagination {...pagination}/>
        </Col>
      </Container>}
    </Fragment>
  );
}
