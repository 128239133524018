/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {BrProps} from '@bloomreach/react-sdk';
import {UserContext} from '../../contexts';
import {ComponentWrapper} from '..';
import {BlogCard, BlogFull, BlogRow} from './templates';
import {GetBlogProps} from './BlogComponentUtils';
import {BlogComponentProps} from './BlogComponentTypes';

export const BlogComponent = (props: BrProps & BlogComponentProps) => {
  let {
    page,
    component,
    documentRef,
    type
  } = props;

  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  documentRef = documentRef || component!.getModels().document;

  const content = documentRef && page!.getContent(documentRef);

  if (!content) {
    return null;
  }

  const blogProps = GetBlogProps({
    page: page!,
    documentRef,
    type: type || 'full',
    params: {}
  }, userSegment);

  if (!blogProps) {
    return null;
  }

  const renderBlogTemplate = () => {
    const {
      type: blogTemplateType, title, introduction, body, date, authors, link, categories
    } = blogProps;

    switch (blogTemplateType) {
      case 'card':
        return <BlogCard {...{title, introduction, link}}/>;
      case 'row':
        return <BlogRow {...{introduction, title, link, categories, authors, date}}/>;
      case 'full':
        return <BlogFull {...{title, introduction, body, date, authors}}/>;
      default:
        return null;
    }
  }

  return (
    <ComponentWrapper {...{type: 'Blog', nowrap: false}}>
      {renderBlogTemplate()}
    </ComponentWrapper>
  );
};
