/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {Button, Card, Container, Jumbotron} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Document} from '@bloomreach/spa-sdk';
import {BrPageContext} from '@bloomreach/react-sdk';
import {UserContext} from '../../../contexts';
import {RenderInlineEditing} from '../../utility/component/RenderInlineEditing';
import {BannerMock} from '../../../mocks/ContentMocks';
import {CmsLink} from '../../global/CommonTypes';
import {BannerComponentArgs} from '../../banner/BannerComponentTypes';
import {getSlideContent} from '../CarouselComponentUtils';
import {ResponsiveQueryProps} from '../../../demo-connector-components/responsive/ResponsiveQuery';
import {getImageVariantBySize} from '../../../utils/ImageUtil';
import {ImageSizeEnum} from '../../global/CommonEnums';

const CarouselHeroSlide = ({documentRef, params}: BannerComponentArgs & ResponsiveQueryProps) => {
  const page = useContext(BrPageContext)!;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  const document = documentRef ? page.getContent<Document>(documentRef) : undefined;
  let content = document ? getSlideContent(page, document.getData(), userSegment) : page.isPreview() ? BannerMock : undefined;

  if (!content) {
    return null;
  }

  const textJustifyClass = `justify-content-${params.textAlignment === 'left' ? 'start' : params.textAlignment === 'right' ? 'end' : 'center'}`;
  const textAlignClass = params.textAlignment === 'center' ? 'text-center' : params.textAlignment === 'right' ? 'text-right' : '';
  const textVerticalAlignClass = `align-items-center`;
  const textColor = params.textColor === 'black' ? 'dark' : 'white';

  let jumbotronClass = `carousel-slide carousel-slide--hero mb-0 p-0 lazy`;
  jumbotronClass = textColor === 'white' ? `${jumbotronClass} bg-darken` : jumbotronClass;
  let containerClass = `d-flex h-100 px-0 px-md-4 px-lg-4 ${textJustifyClass} ${textAlignClass}`;
  let cardClass = `border-0 flex-row w-xs-100 w-md-65 w-lg-35 ${textVerticalAlignClass} `;
  cardClass = textColor === 'white' ? `${cardClass}bg-darken ` : cardClass;
  const cardTitleClass = `font-weight-semi-bold text-${textColor}`;
  const cardTextClass = `mb-4`;
  const buttonClass = 'transition-3d-hover mr-2 mb-2';

  const {images, title, links, text} = content;
  // TODO: better selection - banner too small???
  const image = getImageVariantBySize(images, ImageSizeEnum.Original);

  // https://github.com/verlok/vanilla-lazyload/issues/300
  // With dynamic background images such component with variants, the lazy load won't update bg images.
  const bgContainerStyle = {
    backgroundImage: `url(${image})`,
  } as React.CSSProperties;

  const docName = document ? document.getName() : 'undefined';

  return (
    <Jumbotron fluid className={jumbotronClass} style={bgContainerStyle} data-content-name={docName}>
      <RenderInlineEditing {...{
        page,
        content: document
      }}/>
      <Container className={containerClass}>
        <Card className={cardClass} text={textColor}>
          <Card.Body>
            <Card.Title as='h1' className={cardTitleClass}>{title}</Card.Title>
            {text && <Card.Text as='div' className={cardTextClass} dangerouslySetInnerHTML={{__html: text}}/>}
            {links?.map((link: CmsLink, key: number) => (
              link.url && <Link to={link.url} key={key}><Button
                className={key === 1 ? `${buttonClass} bg-white` : buttonClass}
                role='button'
                target={link.openInNewWindow ? '_blank' : '_self'}
                variant={key === 0 ? 'primary' : textColor === 'white' ? 'outline-white' : 'outline-dark'}
              >{link.label}</Button></Link>
            ))}
          </Card.Body>
        </Card>
      </Container>
    </Jumbotron>
  );
};

export default CarouselHeroSlide;
