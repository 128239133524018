/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ChannelPage, HomePage} from './pages';
import {channelIdFromPreview, PreferenceProvider} from './contexts';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from './ErrorFallback';

export default function App() {
  // Fix the issue with previewing with sitemap
  const channelId = channelIdFromPreview();
  if (channelId) {
    const urlParams = new URLSearchParams(window.location.search);
    if (!window.location.pathname.startsWith(`/${channelId}`)) {
      console.log('[DEBUG] Preview Mode', channelId, `${window.location.origin}/${channelId}${window.location.pathname}?${urlParams}`);
      window.location.href = `${window.location.origin}/${channelId}${window.location.pathname}?${urlParams}`;
    }
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
        window.location.href = `${window.location.origin}/?selector=true`;
      }}
    >
      <PreferenceProvider>
        <Switch>
          <Route exact path='/'>
            <HomePage/>
          </Route>
          <Route path="/:channelId" children={<ChannelPage/>}/>
        </Switch>
      </PreferenceProvider>
    </ErrorBoundary>
  );
}
