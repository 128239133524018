/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    id
    parentId
    displayName
    path
  }
`;

export const FIND_CATEGORIES = gql`
  query Categories($queryHint: QueryHintInput) {
    findCategories(queryHint: $queryHint) {
      ...CategoryFragment
    }
  }

  ${CATEGORY_FRAGMENT}
`;

export const GET_CATEGORY_BY_ID = gql`
  query Category($id: String!, $queryHint: QueryHintInput) {
    findCategoryById(id: $id, queryHint: $queryHint) {
      ...CategoryFragment
    }
  }

  ${CATEGORY_FRAGMENT}
`;
