/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  CartFragment_entries,
  UPDATE_CART,
  UpdateCart,
  UpdateCart_updateCart,
  UpdateCartVariables,
} from '../../modules/commerce';
import {withApolloClient} from '../ApolloClient';
import {addError, sessionService} from '../../modules/commerce/api';

interface InputProps {
  entry: CartFragment_entries;
}

export interface UpdateCartItemProps {
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  updateCart: () => Promise<FetchResult<UpdateCart>>;
  loading: boolean;
  result?: UpdateCart_updateCart;
}

function withUpdateCartItemBase<P>(Component: React.ComponentType<P & UpdateCartItemProps>) {
  return (props: P & InputProps) => {
    const {entry} = props;
    const [quantity, setQuantity] = useState<number>(entry.quantity);

    const [mutate, {data, loading}] = useMutation<UpdateCart, UpdateCartVariables>(UPDATE_CART);

    if (data?.updateCart.success) {
      sessionService.setCartIdInSession(data.updateCart.cart?.id);
    }

    const updateCart = () => {
      const cartId = sessionService.getCartIdFromSession();
      if (!cartId) {
        addError({
          code: 'VALIDATION_ERROR',
          message: 'Cart does not exist',
          operation: 'updateCard',
        });

        return Promise.resolve({});
      }
      return mutate({
        variables: {
          input: {
            cartId,
            quantity,
            entryId: props.entry.id,
          },
        },
      });
    };

    return (
      <Component
        quantity={quantity}
        setQuantity={setQuantity}
        updateCart={updateCart}
        loading={loading}
        result={data?.updateCart}
        {...props}
      />
    );
  };
}

export function withUpdateCartItem<P>(Component: React.ComponentType<P & UpdateCartItemProps>) {
  return withApolloClient(withUpdateCartItemBase(Component));
}
