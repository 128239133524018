/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {MouseEvent} from 'react';
import {Button} from 'react-bootstrap';
import {Icon} from '../../icon/Icon';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FacetListState} from '../FacetListTypes';
import {getMessage, getMessageTemplate} from '../../../../utils/MessageUtils';
import {FacetField} from '../../../../demo-connector-components/DemoBrsmUtils';

export interface FacetValuesExpandCollapseButtonProps {
  facet: FacetField,
  iconName: string,
  showLess : boolean,
  numOfDisplayedFacetFields: number,
  facetsState: FacetListState,
  setFacetsState: React.Dispatch<React.SetStateAction<FacetListState>>
}

const config = require('../FacetListConfig.json');
export const FacetValuesExpandCollapseButton = (props: FacetValuesExpandCollapseButtonProps) => {
  const {
    facet,
    iconName,
    showLess,
    numOfDisplayedFacetFields,
    facetsState,
    setFacetsState
  } = props;

  const handleMoreOrLessFacetValues = (event: MouseEvent, facetName: string, less: boolean) => {
    const newState = {...facetsState};
    const facetField = newState[facetName];
    let newValueDisplayCount;
    if (less) {
      newValueDisplayCount =
        facetField.shown - numOfDisplayedFacetFields < numOfDisplayedFacetFields
          ? numOfDisplayedFacetFields
          : facetField.shown - numOfDisplayedFacetFields;
    } else {
      newValueDisplayCount =
        facetField.shown + numOfDisplayedFacetFields > facetField.max
          ? facetField.max
          : facetField.shown + numOfDisplayedFacetFields;
    }
    newState[facetName].shown = newValueDisplayCount;
    setFacetsState(newState);
  };

  //const moreOrLess = showLess ? 'fewer' : 'more';
  const moreOrLessMessage = getMessageTemplate(
    config,
    showLess ? 'show-fewer-options' : 'show-more-options',
    getMessage(config, facet.name)
  );
  return (
    <Button
      aria-expanded='false'
      block
      className='ml-1 mt-0 px-0 text-left text-decoration-none font-size-xs'
      onClick={(e) => handleMoreOrLessFacetValues(e, facet.name, showLess)}
      size={'sm'}
      variant={'link'}>
      <Icon
        name={iconName as IconProp}
        content={moreOrLessMessage}/>
    </Button>
  );
};
