/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  PARAM_NUMBER_OF_COLUMNS,
  PARAM_SHOW_FACETS,
  PARAM_SHOW_PAGINATION,
  QUERY_PARAM_CONTENT_PAGE_NUM,
  QUERY_PARAM_CONTENT_PAGE_SIZE,
  QUERY_PARAM_QUERY
} from '../../utils/SearchUtil';
import {ArticleProps} from '../article/ArticleComponentTypes';
import {parseSearchImageUrl} from '../../utils/ImageUtil';
import {getUrl} from '../../utils/UrlUtils';
import {brxmEndpoint} from '../../contexts';
import {getListPaginationProps} from '../utility/list-pagination/ListPaginationUtils';
import {Page} from '@bloomreach/spa-sdk';

export const getArticleUrl = (page: Page, url: string, channelId: string, urlPrefix: string) => {
  if (Array.isArray(url)) {
    url = url[0];
  }
  if (url.indexOf('/articles') !== -1) {
    url = url.substring(url.indexOf('/articles'));
  }
  if (url.indexOf('/artikel') !== -1) {
    url = url.substring(url.indexOf('/artikel'));
  }

  if (url?.endsWith('.pdf')) {
    const urlSegments = url.split('/');
    url = `${urlPrefix}/resources/content/assets/${channelId}/pdfs/${urlSegments[urlSegments.length - 1]}`;
  } else {
    url = getUrl(url, page);
  }

  return url;
}


export const getContentSearchResults = (page: Page, channelId: string, params: Record<string, any>, autoCorrectQuery: string, contentSearchResult: any) => {

  const queryParameters = new URLSearchParams(window.location.search);
  const queryKeyword = autoCorrectQuery || queryParameters.get(QUERY_PARAM_QUERY);
  const contentPageSizeParam = queryParameters.get(QUERY_PARAM_CONTENT_PAGE_SIZE);
  const contentPageSize = contentPageSizeParam ? parseInt(contentPageSizeParam) : 12;
  const contentCurrentPageParam = queryParameters.get(QUERY_PARAM_CONTENT_PAGE_NUM);
  const contentOffset = contentCurrentPageParam ? (parseInt(contentCurrentPageParam) - 1) * contentPageSize : 0;

  const {
    docs,
    numFound,
    facet_fields
  } = contentSearchResult;

  const pagination = getListPaginationProps({
    offset: contentOffset,
    limit: contentPageSize,
    total: numFound ?? docs.length,
    params: {
      showPagination: true,
      queryType: 'content'
    },
  });

  const endpointSegments = brxmEndpoint().split('/');
  const siteIndex = endpointSegments.findIndex((segment) => segment === 'site');
  const urlPrefix = endpointSegments.slice(0, siteIndex).join('/');

  const {
    showTitle,
    showCaption,
    facetsLocation,
  } = params;

  return {
    showPagination: params[PARAM_SHOW_PAGINATION],
    showFacets: params[PARAM_SHOW_FACETS],
    showTitle: showTitle && showCaption,
    facetsLocation,
    itemsPerRow: params[PARAM_NUMBER_OF_COLUMNS] || 2,
    pagination: pagination!,
    articles: docs?.map((item: any) => {
      const article: ArticleProps = {
        type: '',
        title: item.title,
        description: item.description || item.introduction,
        image: parseSearchImageUrl(item.image),
        images: {}
      };

      let url = item.url;
      if (url) {
        article.link = {
          url: getArticleUrl(page, url, channelId, urlPrefix)
        };
      }

      return article;
    }),
    facets: {
      'facetFields': Object.keys(facet_fields)?.map((key: string) => {
        const facetField = facet_fields[key];
        facetField.forEach((facet: any) => {
          if (!facet.id) {
            facet.id = facet.name;
          }
        });
        return {
          'id': key,
          'name': key,
          'values': facetField
        }
      })
    },
    keyword: queryKeyword
  }
};
