/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {CmsLink, ImageUrl} from '../components/global/CommonTypes';

export const BannerMock = {
  imageUrl : '',
  title : 'For Placement Only',
  titleLink : undefined,
  subtitle : '',
  text : '<p>This content is for placement only. Click to edit the component.</p>',
  links : [ {
    url : 'https://www.bloomreach.com',
    label : 'Read More',
    isExternal : true,
    openInNewWindow : true
  } as CmsLink],
  images: {}
};

export const SimpleContentMock = {
  title : 'For Placement Only',
  introduction : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua.`,
  text : `Ac ut consequat semper viverra nam libero justo laoreet sit. Donec massa sapien faucibus
  et molestie. Dignissim suspendisse in est ante in nibh mauris cursus mattis. In arcu cursus
  euismod quis viverra nibh cras pulvinar mattis. Adipiscing bibendum est ultricies integer quis
  auctor elit. Interdum posuere lorem ipsum dolor sit amet.`
};

export const VideoMock = {
  title: 'Mock Video',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  videoItem: {
    videoLink: 'https://www.youtube.com/embed/7OMxBlK46wY',
    videoType: 'youtube'
  }
}

export const ArticleMock = {
  title: 'Mock Article',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  image: {
    type: 'unsplash',
    url: 'https://images.unsplash.com/photo-1586769852836-bc069f19e1b6?ixid=Mnw4ODk1MXwwfDF8c2VhcmNofDJ8fHNlYXJjaHxlbnwwfHx8fDE2Mjg2NDYxNDA&ixlib=rb-1.2.1',
  } as ImageUrl,
  images: {}
};
