/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Dropdown} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {channelsUrl} from '../../../contexts';
import {Channel, useChannels} from '../../../hooks';
import {Icon} from '../../utility';
import {FlagIcon, FlagIconCode} from 'react-flag-kit';

export function PreferenceWidget() {
  const {channelId: currentChannel} = useParams();

  const updateChannel = (newChannel: string) => {
    if (currentChannel !== newChannel) {
      window.location.href = `${window.location.origin}/${newChannel}`;
    }
  }

  const locale = sessionStorage.getItem('locale');
  let countryCode = 'US';
  if (locale && locale.indexOf('_') !== -1) {
    countryCode = locale.substring(locale.indexOf('_') + 1);
  }

  const [results, loading] = useChannels(channelsUrl());

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-primary" size={'sm'} id="dropdown-channel">
        <FlagIcon code={countryCode as FlagIconCode} size={20}/>
        <span className={'d-none d-md-inline'}> {currentChannel}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => window.location.href = `${window.location.origin}/?selector=true`}>
          <Icon name={'bars'} content={'Channel Selector'}/>
        </Dropdown.Item>
        <Dropdown.Divider/>
        {!loading && results?.map((channel: Channel, key: number) => {
          return (
            <Dropdown.Item key={key} onClick={() => updateChannel(channel.name)}
                           disabled={channel.name === currentChannel}>
              <Icon name={'desktop'} content={channel.name}/>
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

