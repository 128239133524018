/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Page} from '@bloomreach/spa-sdk';
import {getLinksArrayDynamic, getResourceLink} from '../global/ComponentUtils';
import {
  BannerComponentArgs,
  BannerComponentMockArgs,
  BannerComponentProps,
  BannerComponentTemplateType,
  BannerContent,
  BannerParams
} from './BannerComponentTypes';
import {UserSegmentType} from '../../contexts';
import {getImageVariants} from '../../utils/ImageUtil';

const config = require('./BannerComponentConfig.json');
export const getMockProps = (props: BannerComponentMockArgs): BannerComponentProps => {
  const {
    params,
    template,
    fluid = false
  } = props;

  const content: BannerContent = config.mock.content;
  return {
    type: 'Banner',
    document: undefined,
    documentRef: '',
    content,
    params: getParams(params, template),
    fluid
  };
};

export const getProps = (page: Page, args: BannerComponentArgs, userSegment: UserSegmentType): BannerComponentProps | null => {
  const {documentRef, params, template, fluid} = args;
  const document = documentRef && page.getContent(documentRef);
  if (!document) {
    return null;
  }

  return {
    type: 'Banner',
    document,
    documentRef,
    content: getContent(page, document.getData(), userSegment),
    params: getParams(params, template),
    fluid: fluid || params['template'] === 'full-width'
  };
};

export const getContent = (page: Page, data: any, userSegment: UserSegmentType): BannerContent => {
  const {content, images: image, links: linkRefs, resource, title} = data;

  const linksArray = getLinksArrayDynamic(page, linkRefs, userSegment);

  let links = (linksArray && linksArray.filter((linkRef: any) => linkRef.label)) || [];
  const titleLink = linksArray && linksArray.find((linkRef: any) => (!linkRef.label || 0 === linkRef.label.length));
  const resourceLink = getResourceLink(resource);
  if (resourceLink) {
    links.push(resourceLink);
  }

  return {
    title,
    titleLink,
    subtitle: '',
    text: page.rewriteLinks(content?.value ?? content),
    links,
    images: getImageVariants(page, image)
  };
};

const getParams = (params: any, template: string): BannerParams => {
  template = template ? template : params['template'] ? params['template'] : 'default';
  const textAlignment = params['textAlignment'] || params['textAlignmentBanner'] || 'left';
  const textColor = params['textColor'] || params['textColorBanner'] || 'black';
  const imageAlignment = params['imageAlignment'] || params['imageAlignmentBanner'] || 'left';

  return {
    template: template as BannerComponentTemplateType,
    textAlignment,
    textColor,
    imageAlignment
  };
};

