/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect} from 'react';
import {Button, Col, Form} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {Icon, Status} from '../../utility';
import {
  GetAddressGroupProps,
  SetDefaultShippingAddressProps,
  withSetDefaultShippingAddress
} from '../../../connector-components';
import {GetErrorProps, withGetError} from '../../../connector-components';

const config = require('../UserProfileComponentConfig.json');
function SetUserDefaultShippingAddressBase(props: BrProps & GetAddressGroupProps & SetDefaultShippingAddressProps & GetErrorProps) {
  const {
    addressGroup,
    addressId,
    setAddressId,
    setDefaultShippingAddress,
    loading,
    result,
    errors,
    clearError
  } = props
  const {addresses, defaultShippingAddress} = addressGroup!;

  const DEFAULT_MESSAGE = config.address.setUserDefaultShippingAddress.defaultMessage;
  const SUCCESS_MESSAGE = config.address.setUserDefaultShippingAddress.defaultSuccessMessage;

  const shippingAddressOptions = addresses?.filter((address) => address?.shippingAddress).map((address) => {
    const {id, streetAddress, additionalStreetInfo, city, state, country, postalCode} = address!;
    return {
      key: id,
      value: id,
      text: `${streetAddress} ${additionalStreetInfo}, ${city}, ${state} ${country} ${postalCode}`
    };
  });

  useEffect(() => {
    let initialAddressId = defaultShippingAddress?.id;
    if (!initialAddressId && addresses && addresses[0]?.id) {
      initialAddressId = addresses[0]?.id;
    }
    setAddressId(initialAddressId!);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage = errors?.find((error) => error.operation === 'setDefaultShippingAddress')?.message;

  return (
    <Col>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label className={'text-uppercase'}>Default Shipping Address</Form.Label>
          <Form.Control
            as={'select'}
            onChange={(e) => {
              setAddressId(e.target.value);
            }}
            defaultValue={defaultShippingAddress?.id}
            placeholder='Select Default Shipping Address'
          >
            {shippingAddressOptions && shippingAddressOptions.map((option) => {
              return <option key={option.key}
                             value={option.value}>{`${option.text}${option.key === defaultShippingAddress?.id ? ' (default)' : ''}`}</option>;
            })}
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Button variant='primary' onClick={() => {
            clearError();
            setDefaultShippingAddress();
          }}
                  disabled={!addressId}>
            {loading ? <Icon name={'spinner'} size={'lg'} spin content={DEFAULT_MESSAGE}/>
              : <Icon name={'save'} content={DEFAULT_MESSAGE}/>
            }
          </Button>
          {errorMessage && <Status error status={errorMessage} customClassName={'mt-3'} dismissible/>}
          {result?.success && <Status status={SUCCESS_MESSAGE} customClassName={'mt-3'} dismissible/>}
        </Form.Group>
      </Form.Row>
    </Col>
  );
}

export const SetUserDefaultShippingAddress = withGetError(withSetDefaultShippingAddress(SetUserDefaultShippingAddressBase));
