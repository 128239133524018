/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useReducer, useState} from 'react';
import {useCookies} from 'react-cookie';

const RECENT_SEARCH_COOKIE = 'recent_keyword_search';

type UserType = {
  email: string,
  firstName: string | null,
  id: string,
  lastName: string | null,
  middleName: string | null,
  username?: string,
  segment?: string,
  altSegment?: string,
  __typename: string,
}

type UserSegmentType = {
  primary?: string | null,
  secondary?: string | null,
  affinity?: string | null,
  campaign?: string | null
}

type UserContextType = {
  userState: {
    user: Partial<UserType>,
    setUser: React.Dispatch<UserType | null>
  },
  gtmEventState: {
    gtmEvent: any,
    setGtmEvent: React.Dispatch<any>
  },
  userSegmentState: {
    userSegment: Partial<UserSegmentType>,
    setUserSegment: React.Dispatch<UserSegmentType | null>
  },
  recentSearchesState: {
    recentSearches: Partial<any>,
    setRecentSearches: React.Dispatch<any | null>
  },
  headerState: {
    showVerticalMenu: boolean,
    setShowVerticalMenu: React.Dispatch<boolean>
  }
}

export type {
  UserType,
  UserSegmentType,
  UserContextType
}

export const USER_SEGMENT_PREFIX = 'customer_profile:';

let reducer = (user: Partial<UserType>, newUser: UserType | null) => {
  if (newUser === null) {
    sessionStorage.removeItem('user');
    return initialState;
  }
  const updatedUser = {...user, ...newUser};
  if (updatedUser.email) {
    updatedUser.username = updatedUser.email;
  }
  return updatedUser;
};

let gtmReducer = (event: any, newEvent: any) => {
  let updatedEvent: any = {};
  if (newEvent.cleanup) {
    const componentIds = newEvent.componentIds;
    Object.keys(event).forEach((key) => {
      if (componentIds && componentIds.includes(key)) {
        updatedEvent[key] = {...event[key]};
      }
    });
  } else {
    updatedEvent = {...event, ...newEvent};
  }
  updatedEvent.url = window.location.href;
  return updatedEvent;
};

let segmentReducer = (userSegment: Partial<UserSegmentType>, newUserSegment: UserSegmentType | null) => {
  if (newUserSegment === null) {
    sessionStorage.removeItem('userSegment');
    return {};
  }
  return {...userSegment, ...newUserSegment};
};

export const getSegmentWithPrefix = (segment: string | null | undefined) => {
  if (segment && !segment.startsWith(USER_SEGMENT_PREFIX)) {
    return USER_SEGMENT_PREFIX + segment;
  } else {
    return segment;
  }
}

export const brxmEndpointFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('endpoint');
}

export const channelIdFromPreview = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const endpoint = urlParams.get('endpoint');

  if (endpoint) {
    const segments = endpoint.split('/');
    return segments[segments.length - 2];
  }
}

export const defaultBrxmEndpoint = () => {
  return brxmEndpointFromUrl() ?? process.env.REACT_APP_BRXM_ENDPOINT!;
}

export const channelsUrl = () => {
  const segments = defaultBrxmEndpoint().split('/');
  if (segments[segments.length - 1] === 'pages') {
    segments.splice(segments.length - 2, 2);
  }
  return segments.join('/');
}

export const brxmEndpoint = (channelId ?: string | null) => {
  const endpoint = brxmEndpointFromUrl();

  if (endpoint) {
    return endpoint;
  } else {
    let endpointUrl = defaultBrxmEndpoint();
    if (channelId) {
      const index = endpointUrl.indexOf('?');
      let paramsStr = '';
      if (index !== -1) {
        paramsStr = endpointUrl.substring(index);
        endpointUrl = endpointUrl.substring(0, index)
      }
      const segments = endpointUrl.split('/');
      if (segments[segments.length - 1] === 'pages') {
        segments[segments.length - 2] = channelId;
      } else {
        segments.push(channelId, 'pages');
      }
      return segments.join('/') + paramsStr;
    } else {
      return endpointUrl;
    }
  }
}

const initialState = {};
const initialSegmentState = {};

const localState = JSON.parse(sessionStorage.getItem('user') as string) as UserType;
const localSegmentState = JSON.parse(sessionStorage.getItem('userSegment') as string) as UserSegmentType;

const UserContext = React.createContext<UserContextType | undefined>(undefined);

function UserProvider(props: any) {
  const [cookies, setCookies, remoteCookie] = useCookies();
  const [recentSearches, setRecentSearches] = useReducer((state: Array<string>, newState: any) => {
    if (newState === null) {
      return [];
    }
    newState = Array.isArray(newState) ? newState : [newState];
    const updatedState = state.filter(item => !newState.includes(item)).concat(newState);
    while (updatedState?.length > 5) {
      updatedState.shift();
    }
    return updatedState;
  }, cookies?.[RECENT_SEARCH_COOKIE]?.length > 0 ? cookies?.[RECENT_SEARCH_COOKIE].split('|') : []);

  const [user, setUser] = useReducer(reducer, localState || initialState);
  const [gtmEvent, setGtmEvent] = useReducer(gtmReducer, {
    url: window.location.href
  });

  const [userSegment, setUserSegment] = useReducer(segmentReducer, localSegmentState || initialSegmentState);

  const [showVerticalMenu, setShowVerticalMenu] = useState(false);

  useEffect(() => {
    sessionStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    sessionStorage.setItem('userSegment', JSON.stringify(userSegment));
  }, [userSegment]);

  useEffect(() => {
    if (recentSearches?.length === 0) {
      remoteCookie(RECENT_SEARCH_COOKIE);
    } else {
      setCookies(RECENT_SEARCH_COOKIE, recentSearches.join('|'), {
        path: '/',
        maxAge: 3600, // just keep for one hour
      });
    }
  }, [recentSearches]);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    <UserContext.Provider value={
      {
        userState: {user, setUser},
        gtmEventState: {gtmEvent, setGtmEvent},
        userSegmentState: {userSegment, setUserSegment},
        recentSearchesState: {recentSearches, setRecentSearches},
        headerState: {showVerticalMenu, setShowVerticalMenu}
      }
    }>
      {props.children}
    </UserContext.Provider>
  );
}

export {UserContext, UserProvider};

