/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';
import { MONEY_AMOUNT_FRAGMENT } from '../queries/items';
import { ORDER_FRAGMENT } from '../queries/order';
import { ADDRESS_FRAGMENT } from '../queries/address';

export const ORDER_DRAFT_FRAGMENT = gql`
  fragment OrderDraftFragment on Order {
    id
    status
    draft
    creationDate
    shipMethod
    shippingAddress {
      ...AddressFragment
    }
    billingAddress {
      ...AddressFragment
    }
    totalAmount {
      ...MoneyAmountFragment
    }
  }

  ${ADDRESS_FRAGMENT}
  ${MONEY_AMOUNT_FRAGMENT}
`;

export const SET_ORDER_DETAILS = gql`
  mutation SetOrderDetails($input: SetOrderDetailsInput!) {
    setOrderDetails(setOrderDetailsInput: $input) {
      code
      success
      message
      order {
        ...OrderDraftFragment
      }
    }
  }

  ${ORDER_DRAFT_FRAGMENT}
`;

export const CHECK_OUT = gql`
  mutation CheckOut($cartId: String!) {
    checkOut(cartId: $cartId) {
      code
      success
      message
      order {
        ...OrderDraftFragment
      }
    }
  }

  ${ORDER_DRAFT_FRAGMENT}
`;

export const FEDERATED_CHECK_OUT = gql`
  mutation FederatedCheckOut($cartId: String!) {
    checkOut(cartId: $cartId) {
      code
      success
      message
      order {
        id
        federatedCheckoutUrl
      }
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation PlaceOrder($id: String!) {
    placeOrder(id: $id) {
      code
      success
      message
      order {
        ...OrderFragment
      }
    }
  }

  ${ORDER_FRAGMENT}
`;
