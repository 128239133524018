/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {ChangeEvent, Fragment, useContext, useRef, useState} from 'react';
import {Button, ButtonGroup, Form, Overlay, Popover, Row} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  CAMPAIGN_PARAM_NAME,
  isSaas,
  SEGMENT_PARAM_NAME,
  SEGMENT_SAAS_PARAM_NAME,
  updateUrlParameter
} from '../../../utils/UrlUtils';
import {Icon} from '../../utility';
import {UserContext} from '../../../contexts';

const segments = require('./segments.json');

export function EngagementWidget() {
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;
  const history = useHistory();

  // react-cookie doesn't detect cookie value changes pushing from server.
  // So we have to use manual refresh.

  // Given a cookie key `name`, returns the value of
  // the cookie or `null`, if the key is not found.
  const getCookie = (name: string): string | null => {
    const nameLenPlus = (name.length + 1);
    return document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null;
  }

  /*
  const deleteAllCookies = () => {
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split(".");
      while (d.length > 0) {
        let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        let p = window.location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
  }
   */

  const parseSegmentIds = () => {
    const cookieValue = getCookie('__br__segment_ids');
    return cookieValue ? cookieValue.split(',') : null;
  }

  const parseUserId = () => {
    return getCookie('__exponea_etc__');
  }

  const [segmentIds, setSegmentIds] = useState<null | string[]>(parseSegmentIds());
  const [userId, setUserId] = useState<null | string>(parseUserId());

  const segment = userSegment.secondary || userSegment.primary;
  const campaign = userSegment.campaign;

  const target = useRef(null);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handlePopupClose = () => {
    setPopupOpen(!isPopupOpen);
  };

  const updateSegmentHandler = (e: ChangeEvent<HTMLInputElement>) => {
    updateSegment(e.target.value);
  }

  const updateSegment = (segment: string) => {
    const paramName = isSaas() ? SEGMENT_SAAS_PARAM_NAME : SEGMENT_PARAM_NAME;
    const updatedUrl = updateUrlParameter(segment, paramName);
    history.push(updatedUrl);
  }

  const updateCampaignHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const campaign = e.target.value;
    updateCampaign(campaign);
  }

  const updateCampaign = (campaign: string) => {
    history.push(updateUrlParameter(campaign, CAMPAIGN_PARAM_NAME));
  }

  const reset = () => {
    handlePopupClose();
    const searchParams = new URLSearchParams(window.location.search);
    const paramName = isSaas() ? SEGMENT_SAAS_PARAM_NAME : SEGMENT_PARAM_NAME;
    searchParams.delete(paramName)
    searchParams.delete(CAMPAIGN_PARAM_NAME);
    history.push(`${window.location.pathname}?${searchParams}`);
  }

  /*
  const reloadPage = () => {
    window.location.reload();
  }
   */

  return (
    <Fragment>
      <div className='d-block' ref={target} onClick={handlePopupClose}>
        <FontAwesomeIcon className='d-inline d-md-none mr-0 mr-md-1' size='lg'
                         icon={[`fas`, `users`]}/>
        <FontAwesomeIcon className='d-none d-md-inline mr-0 mr-md-1' size='sm'
                         icon={[`fas`, `users`]}/>
        <span className='d-none d-md-inline font-size-sm'>Engagement</span>
      </div>
      <Overlay
        target={target.current}
        show={isPopupOpen}
        placement='bottom'
        rootClose
        onHide={() => setPopupOpen(false)}
      >
        <Popover id={'campaign-popup'} className='no-arrow' show={false}>
          <Popover.Title>Engagement
            <Button size={'sm'} variant={'light'} className='close ml-auto' onClick={handlePopupClose}>
              <Icon name={'times'}/>
            </Button>
          </Popover.Title>
          <Popover.Content>
            <Form className={'mx-4 my-2'}>
              {userId && <Form.Group as={Row}>
                <Form.Label htmlFor='colFormLabelUserId'
                            className='col-form-label font-weight-bold'>User Id</Form.Label>
                <Form.Control type='text'
                              id='colFormLabelUserId'
                              placeholder='UserId'
                              defaultValue={userId}
                              disabled
                />
              </Form.Group>}
              {segmentIds && <Form.Group as={Row}>
                <Form.Label htmlFor='colFormLabelSegmentIds'
                            className='col-form-label font-weight-bold'>Segment IDs</Form.Label>
                {segmentIds?.map((segmentId, key) =>
                  <Form.Control type='text'
                                id={`colFormLabelUserId${key}`}
                                placeholder='UserId'
                                defaultValue={`${segmentId} (${segments?.[segmentId]?.join(' -> ')})`}
                                disabled
                                key={key}
                                className={'mb-1'}
                  />)}
              </Form.Group>}
              <Form.Group as={Row}>
                <ButtonGroup>
                  <Button size={'sm'} variant={'primary'} onClick={() => {
                    setSegmentIds(parseSegmentIds());
                    setUserId(parseUserId());
                  }}>
                    <Icon name={'redo'} size={'sm'} content={'Refresh'}/>
                  </Button>
                  {/*<Button size={'sm'} variant={'primary'} onClick={() => {
                    deleteAllCookies();
                    reloadPage();
                  }}>
                    Clear All Cookies
                  </Button>*/}
                </ButtonGroup>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label htmlFor='colFormLabelWeblayer'
                            className='col-form-label font-weight-bold'>Weblayer</Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <ButtonGroup id={'colFormLabelWeblayer'}>
                  <Button size={'sm'} variant={'primary'} onClick={() => {
                    (window as any)?.exponea?.showWebLayer('5fb2301d0d68192e9f778b3d');
                  }}>
                    <Icon name={'eye'} size={'sm'} content={'Show WebLayer'}/>
                  </Button>
                </ButtonGroup>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label htmlFor='colFormLabelCampaign'
                            className='col-form-label font-weight-bold'>Campaign</Form.Label>
                <Form.Control type='text'
                              id='colFormLabelCampaign'
                              placeholder='Campaign'
                              defaultValue={campaign ?? ''}
                              onBlur={updateCampaignHandler}
                              onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                const target = event.currentTarget;
                                if (event.key === 'Enter' || event.key === 'NumpadEnter') {
                                  updateCampaign(target.value);
                                }
                              }}
                />
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label htmlFor='colFormLabelSegment'
                            className='col-form-label font-weight-bold'>Segment</Form.Label>
                <Form.Control type='text'
                              id='colFormLabelSegment'
                              placeholder='Segment'
                              defaultValue={segment ?? ''}
                              onBlur={updateSegmentHandler}
                              onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                const target = event.currentTarget;
                                if (event.key === 'Enter' || event.key === 'NumpadEnter') {
                                  updateSegment(target.value);
                                }
                              }}
                />
              </Form.Group>
              <Form.Group as={Row}>
                <ButtonGroup>
                  <Button size={'sm'} variant={'danger'} onClick={() => {
                    handlePopupClose();
                  }}><Icon name={'pencil-alt'} size={'sm'} content={'Update'}/></Button>
                  <Button size={'sm'} variant={'danger'} onClick={() => {
                    reset();
                  }}><Icon name={'history'} size={'sm'} content={'Reset'}/></Button>
                </ButtonGroup>
              </Form.Group>
            </Form>
          </Popover.Content>
        </Popover>
      </Overlay>
    </Fragment>
  );
}

