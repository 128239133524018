/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect} from 'react';
import {Button, Card, Col, Container, Image, Row, Table} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {Icon, SecuredComponentWrapper, Status} from '../utility';
import {Link, useHistory} from 'react-router-dom';
import Address from '../cart/util/Address';
import {ProductPrice} from '../product/templates';
import {DemoOrderProps, GtmEventProps, withDemoOrder, withGtmEvent} from '../../demo-connector-components';
import {getProfileTabUrl} from '../user-profile/UserProfileComponentUtils';
import {getProductUrl} from '../product/ProductComponentUtils';
import {DemoOrder_getOrderById_orderItems_item_ItemVariant} from '../../modules/commerce';
import {Discounted} from '../utility/discounted/Discounted';
import {UserContext} from '../../contexts';
import {MoneyAmount} from '../../demo-connector-components/DemoBrsmUtils';

function OrderBase(props: BrProps & DemoOrderProps & GtmEventProps) {
  const {
    page,
    loading,
    order,
    setGtmEventOrder
  } = props;
  const history = useHistory();
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  useEffect(() => {
    setGtmEventOrder(order);
  }, [order]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Status container loading status={'We are loading order for you.'}/>
  }

  if (!order) {
    return <Status container error status={'Order not found!'}/>
  }

  const {
    id,
    status,
    paymentState,
    shipmentState,
    creationDate,
    totalAmount,
    shippingAddress,
    billingAddress,
    taxAmount,
    orderItems,
    discounts
  } = order;

  const orderListUrl = getProfileTabUrl(page!, 'my-orders', userSegment);

  return (
    <Container className={'py-5'}>
      <Row>
        <Col xs={8}>
          <h2>{'Order Details'}</h2>
        </Col>
        <Col xs={4} className={'text-right'}>
          <Button variant={'primary'} onClick={() => history.push(orderListUrl)}>
            <Icon name='list' content={'Order List'}/>
          </Button>
        </Col>
      </Row>
      <Table striped bordered hover className={'mt-3'}>
        <tbody>
        <tr>
          <td>ID</td>
          <td>{id}</td>
        </tr>
        <tr>
          <td>Creation Date</td>
          <td>{new Date(creationDate).toLocaleString()}</td>
        </tr>
        <tr>
          <td>Shipping Address</td>
          <td>
            <Address address={shippingAddress}/>
          </td>
        </tr>
        <tr>
          <td>Payment State</td>
          <td>{paymentState}</td>
        </tr>
        <tr>
          <td>Shipment State</td>
          <td>{shipmentState}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{status}</td>
        </tr>
        <tr>
          <td>Total Amount</td>
          <td><ProductPrice moneyAmount={totalAmount[0] as MoneyAmount}/></td>
        </tr>
        <tr>
          <td>Billing Address</td>
          <td>
            <Address address={billingAddress}/>
          </td>
        </tr>
        <tr>
          <td>Tax Amount</td>
          <td><ProductPrice moneyAmount={taxAmount as MoneyAmount}/></td>
        </tr>
        </tbody>
      </Table>
      <h3>Order Items</h3>
      {orderItems && orderItems.map((orderItem, key) => {
        const {item, unitPrice, totalPrice, quantity} = orderItem!;
        const {itemId, displayName, imageSet, description} = item!;
        const imageLink = imageSet?.original?.link?.href;
        // for ct item only
        let productId = itemId.id;
        if (item) {
          const itemVariant = item as DemoOrder_getOrderById_orderItems_item_ItemVariant;
          const itemProductId = itemVariant.varAttrs?.find((varAttr: any) => varAttr?.name === 'mpn')?.values?.[0];
          if (itemProductId) {
            productId = itemProductId;
          }
        }
        return (
          <Card key={key} className={'mb-3'}>
            <Card.Body>
              <Row>
                <Col xs={5}>
                  {imageLink && <Image src={imageLink} rounded className={'w-50'}/>}
                </Col>
                <Col xs={7}>
                  <h4><Link to={getProductUrl(page!, productId, itemId.code, userSegment)}>{displayName} <Discounted discounts={discounts} itemId={orderItem?.id}/></Link></h4>
                  {itemId && itemId.code && <dl>
                    <dt>SKU</dt>
                    <dd>{itemId.code}</dd>
                  </dl>}
                  {description && <dl>
                    <dt>Description</dt>
                    <dd>{description}</dd>
                  </dl>}
                  <dl>
                    <dt>Quantity</dt>
                    <dd>{quantity}</dd>
                  </dl>
                  <dl>
                    <dt>Unit Price</dt>
                    <dd>{unitPrice?.moneyAmounts && <ProductPrice
                      moneyAmount={unitPrice.moneyAmounts[0] as MoneyAmount}/>}</dd>
                  </dl>
                  <dl>
                    <dt>Total Price</dt>
                    <dd>{totalPrice?.moneyAmounts && <ProductPrice
                      moneyAmount={totalPrice.moneyAmounts[0] as MoneyAmount}/>}</dd>
                  </dl>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )
      })}
    </Container>
  )
}

export const Order = withGtmEvent(withDemoOrder(OrderBase));

export const OrderComponent = (props: BrProps) => {
  const {page, component} = props;
  let pathArray: Array<string> = window.location.pathname.split('/');
  const orderID = pathArray[pathArray.length - 1];

  return (
    <SecuredComponentWrapper>
      <Order orderID={orderID} page={page!} component={component!}/>
    </SecuredComponentWrapper>
  )
}
