/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from './address';
import { MONEY_AMOUNT_FRAGMENT, ITEM_LIKE_FRAGMENT } from './items';

export const ITEM_VARIANT_FRAGMENT = gql`
  fragment ItemVariantFragment on ItemVariant {
    varAttrs{
      name
      values
    }
  }
`;

export const DEMO_ORDER_FRAGMENT = gql`
  fragment DemoOrderFragment on Order {
    id
    draft
    status
    paymentState
    shipmentState
    creationDate
    shipMethod
    paymentMethodId
    shippingAddress {
      ...AddressFragment
    }
    billingAddress {
      ...AddressFragment
    }
    totalAmount {
      ...MoneyAmountFragment
    }
    shipAmount {
      ...MoneyAmountFragment
    }
    taxAmount {
      ...MoneyAmountFragment
    }
    orderItems {
      id
      type
      status
      quantity
      unitPrice {
        moneyAmounts {
          ...MoneyAmountFragment
        }
      }
      totalPrice {
        moneyAmounts {
          ...MoneyAmountFragment
        }
      }
      item {
        ...ItemLikeFragment
        ...ItemVariantFragment
      }
    }
    discounts {
      code
      targetIds
      type
    }
  }

  ${ADDRESS_FRAGMENT}
  ${MONEY_AMOUNT_FRAGMENT}
  ${ITEM_LIKE_FRAGMENT}
  ${ITEM_VARIANT_FRAGMENT}
`;

export const DEMO_GET_ORDER_BY_ID = gql`
  query DemoOrder($id: String!) {
    getOrderById(id: $id) {
      ...DemoOrderFragment
    }
  }

  ${DEMO_ORDER_FRAGMENT}
`;

