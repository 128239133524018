/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  CurrentCustomerAddressGroup,
  CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup_addresses,
  CurrentCustomerDefaultShippingAddress,
  CurrentCustomerDefaultShippingAddress_setCurrentCustomerDefaultShippingAddress,
  CurrentCustomerDefaultShippingAddressVariables,
  GET_CURRENT_CUSTOMER_ADDRESS_GROUP,
  SET_CURRENT_CUSTOMER_DEFAULT_SHIPPING_ADDRESS,
} from '../../modules/commerce';
import {withApolloClient} from '../ApolloClient';

interface InputProps {
  addresses: (CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup_addresses | null)[];
}

export interface SetDefaultShippingAddressProps extends InputProps {
  addressId: string;
  setAddressId: React.Dispatch<React.SetStateAction<string>>;
  setDefaultShippingAddress: () => Promise<FetchResult<CurrentCustomerDefaultShippingAddress>>;
  loading: boolean;
  result?: CurrentCustomerDefaultShippingAddress_setCurrentCustomerDefaultShippingAddress;
}

function withSetDefaultShippingAddressBase<P>(Component: React.ComponentType<P & SetDefaultShippingAddressProps>) {
  return (props: P & InputProps) => {
    const [addressId, setAddressId] = useState<string>('');

    const [setDefaultShippingAddress, {data, loading}] = useMutation<CurrentCustomerDefaultShippingAddress,
      CurrentCustomerDefaultShippingAddressVariables>(SET_CURRENT_CUSTOMER_DEFAULT_SHIPPING_ADDRESS, {
      variables: {
        addressId,
      },
      // eslint-disable-next-line no-shadow
      update(cache, {data}) {
        if (data?.setCurrentCustomerDefaultShippingAddress.address) {
          const results = cache.readQuery<CurrentCustomerAddressGroup>({query: GET_CURRENT_CUSTOMER_ADDRESS_GROUP});
          if (results?.getCurrentCustomerAddressGroup) {
            cache.writeQuery<CurrentCustomerAddressGroup>({
              query: GET_CURRENT_CUSTOMER_ADDRESS_GROUP,
              data: {
                getCurrentCustomerAddressGroup: {
                  __typename: 'AddressGroup',
                  id: results.getCurrentCustomerAddressGroup.id,
                  addresses: results.getCurrentCustomerAddressGroup.addresses,
                  defaultShippingAddress: data.setCurrentCustomerDefaultShippingAddress.address,
                  defaultBillingAddress: results.getCurrentCustomerAddressGroup.defaultBillingAddress,
                },
              },
            });
          }
        }
      },
    });

    return (
      <Component
        addressId={addressId}
        setAddressId={setAddressId}
        setDefaultShippingAddress={setDefaultShippingAddress}
        loading={loading}
        result={data?.setCurrentCustomerDefaultShippingAddress}
        {...props}
      />
    );
  };
}

export function withSetDefaultShippingAddress<P>(Component: React.ComponentType<P & SetDefaultShippingAddressProps>) {
  return withApolloClient(withSetDefaultShippingAddressBase(Component));
}
