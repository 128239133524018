/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useContext} from 'react';
import {Container, Image, Media, NavLink} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import {pushGtmEventWidgetClick} from 'src/components/gtm/GTMComponentUtils';
import {ProductProps} from '../ProductComponentTypes';
import {ProductCardProps} from './ProductCard';
import {ProductPrice} from './ProductPrice';
import {getImageSetUrl} from '../../../demo-connector-components/DemoBrsmUtils';
import {UserContext} from '../../../contexts';
import {getProductUrl} from '../ProductComponentUtils';

export const ProductMedia = (props: ProductProps & ProductCardProps) => {
  const {
    itemId,
    displayName,
    description,
    listPrice,
    purchasePrice,
    imageSet,
    widgetProps
  } = props;

  const page = useContext(BrPageContext)!;
  const component = useContext(BrComponentContext);
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  const history = useHistory();

  const documentRef = component?.getModels().document;
  const document = documentRef && page?.getContent(documentRef)?.getData();

  const productUrl = getProductUrl(page, itemId.id, itemId.code, userSegment)
  return (
    <Fragment>
      {document && (
        <Container>
          {document.title && <div className='h5'>{document.title}</div>}
          {document.content && (
            <div dangerouslySetInnerHTML={{__html: document.content?.value ?? document.content}}/>
          )}
        </Container>
      )}
      <Container className='mb-4'>
        <Media className='py-3'>
          {imageSet && <Image
            fluid
            rounded
            thumbnail
            className='mr-3'
            src={getImageSetUrl(imageSet)}
            alt={displayName}
          />}
          <Media.Body>
            <div className='h5'>
              <NavLink className='d-inline p-0'
                       onClick={() => {
                         pushGtmEventWidgetClick(widgetProps, itemId.id);
                         history.push(productUrl);
                       }}>
                {displayName}
              </NavLink>
            </div>
            <div className={'font-size-1'}>SKU: {itemId.code}</div>
            <div className='font-weight-bold font-size-1'>
              <ProductPrice price={purchasePrice!} listPrice={listPrice!}/>
            </div>
            <p className='mt-1 font-size-1'>{description}</p>
          </Media.Body>
        </Media>
      </Container>
    </Fragment>
  );
};
