/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useMutation} from '@apollo/client';
import {sessionService} from '../../modules/commerce/api';
import {
  ADD_TO_CART,
  AddToCart,
  AddToCartVariables,
  Cart,
  CartVariables,
  GET_CART,
  ItemFragment_itemId,
} from '../../modules/commerce';
import {AddToCartProps, withApolloClient} from '../../connector-components';

interface InputProps {
  itemId: ItemFragment_itemId;
  quantity?: number;
}

function withAddToCartBase<P>(Component: React.ComponentType<P & AddToCartProps>) {
  return (props: P & InputProps) => {
    const {quantity, itemId} = props;

    const [mutate, {data, loading}] = useMutation<AddToCart, AddToCartVariables>(ADD_TO_CART);

    const addToCart = () => {
      const cartId = sessionService.getCartIdFromSession();
      return mutate({
        variables: {
          input: {
            cartId,
            quantity,
            itemId: `${itemId.id ?? ''}___${itemId.code ?? ''}`,
          },
        },
        // eslint-disable-next-line no-shadow
        update(cache, {data}) {
          if (data?.addToCart?.cart) {
            cache.writeQuery<Cart, CartVariables>({
              query: GET_CART,
              variables: {
                id: data.addToCart.cart.id,
              },
              data: {
                getCart: {
                  __typename: 'Cart',
                  id: data.addToCart.cart.id,
                  revision: data.addToCart.cart.revision,
                  totalQuantity: data.addToCart.cart.totalQuantity,
                  entries: data.addToCart.cart.entries,
                  totalListPrice: data.addToCart.cart.totalListPrice,
                  totalPurchasePrice: data.addToCart.cart.totalPurchasePrice,
                  active: data.addToCart.cart.active,
                  state: data.addToCart.cart.state,
                  discounts: data.addToCart.cart.discounts
                },
              },
            });
          }
          if (data?.addToCart.success && cartId !== data.addToCart.cart?.id) {
            sessionService.setCartIdInSession(data.addToCart.cart?.id);
          }
        },
      });
    };

    return <Component addToCart={addToCart} loading={loading} result={data?.addToCart} {...props} />;
  };
}

export function withDemoAddToCart<P>(Component: React.ComponentType<P & AddToCartProps>) {
  return withApolloClient(withAddToCartBase(Component));
}
