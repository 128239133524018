/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {SIGN_UP, SignUp, SignUp_signUp, SignUpVariables} from '../../modules/commerce';
import {withApolloClient} from '../ApolloClient';

export interface SignupFormProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  middleName: string;
  setMiddleName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
}

export interface SignupProps {
  signupForm: SignupFormProps;
  signup: () => Promise<FetchResult<SignUp>>;
  loading: boolean;
  result?: SignUp_signUp;
}

function withSignupBase<P>(Component: React.ComponentType<P & SignupProps>) {
  return (props: P) => {
    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [signup, {data, loading}] = useMutation<SignUp, SignUpVariables>(SIGN_UP, {
      variables: {
        input: {
          email,
          password,
          firstName,
          middleName,
          lastName,
        },
      },
    });

    return (
      <Component
        signupForm={{
          email,
          setEmail,
          password,
          setPassword,
          firstName,
          setFirstName,
          middleName,
          setMiddleName,
          lastName,
          setLastName,
        }}
        signup={signup}
        loading={loading}
        result={data?.signUp}
        {...props}
      />
    );
  };
}

export function withSignup<P>(Component: React.ComponentType<P & SignupProps>) {
  return withApolloClient(withSignupBase(Component));
}
