/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {ChangeEvent, useContext, useState} from 'react';
import {Accordion, Badge, Card, Form, ListGroup} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {BrPageContext} from '@bloomreach/react-sdk';
import {filterFacets, getFacetsState, getSelectedFilters, updateSearchUrl} from './FacetListUtils';
import {QUERY_PARAM_CONTENT_FACET, QUERY_PARAM_FACET} from '../../../utils/SearchUtil';
import {FacetToggle, FacetValuesExpandCollapseButton, SelectedFacets} from './templates';
import {FacetListState} from './FacetListTypes';
import {FacetField} from '../../../demo-connector-components/DemoBrsmUtils';

export interface FacetListComponentProps {
  facetFields?: Array<FacetField | null>,
  isContent?: boolean,
  config: {
    excluded: Array<string>,
    numOfDisplayedFacetItems: number,
    numOfOpenedFacets: number,
  }
}

export const FacetList = (props: FacetListComponentProps) => {
  const {
    facetFields,
    isContent = false,
    config: {
      excluded = [],
      numOfDisplayedFacetItems = 5,
      numOfOpenedFacets = 1,
    }
  } = props;

  const page = useContext(BrPageContext)!;
  const history = useHistory();

  const facetsList = filterFacets(facetFields, excluded);
  const selectedFilters: Array<FacetField> = getSelectedFilters(isContent);

  const [facetsState, setFacetsState] = useState<FacetListState>(
    getFacetsState(facetsList, selectedFilters, numOfDisplayedFacetItems, numOfOpenedFacets)
  );

  const handleFacetValueClick = (event: ChangeEvent<HTMLInputElement>, facet: string, value: string) => {
    const facetFilterName = isContent ? QUERY_PARAM_CONTENT_FACET : QUERY_PARAM_FACET;
    const updatedFilters: Array<FacetField> = JSON.parse(JSON.stringify(selectedFilters));
    const facetField = updatedFilters.find((item) => item.id === facet);
    const newState = {...facetsState};
    const filterValue = {
      id: value,
      name: '',
      count: 0
    }
    if (event.target.checked) {
      //add
      if (!facetField) {
        updatedFilters.push({id: facet, name: '', values: [filterValue]});
      } else {
        facetField.values.push(filterValue);
      }
      newState[facet].values[value] = true;
    } else {
      //remove
      if (facetField) {
        const newVals = facetField.values.filter(
          (entry) => entry?.id !== value
        );
        if (newVals.length > 0) {
          facetField.values = newVals;
        } else {
          const idx = updatedFilters.findIndex((item) => item.id === facet);
          if (idx > -1) {
            updatedFilters.splice(idx, 1);
          }
        }
      }
      newState[facet].values[value] = false;
    }
    setFacetsState(newState);

    const queryParameters = new URLSearchParams(window.location.search);
    const categoryElement = `${facet}:'${value}'`;

    queryParameters.forEach((value, key)=> {
      if (key === 'page' || key.endsWith(':page')) {
        queryParameters.set(key, '1');
      }
    });

    let categoryParameters = queryParameters.getAll(facetFilterName);
    if (event.target.checked) {
      // add
      if (queryParameters.has(facetFilterName)) {
        if (!categoryParameters.includes(categoryElement)) {
          queryParameters.append(facetFilterName, categoryElement);
        }
      } else {
        queryParameters.set(facetFilterName, categoryElement);
      }
    } else {
      // remove
      if (queryParameters.has(facetFilterName)) {
        const index = categoryParameters.indexOf(categoryElement);
        if (index > -1) {
          categoryParameters.splice(index, 1);
          queryParameters.delete(facetFilterName);
          categoryParameters.forEach(categoryParameter => {
            queryParameters.append(facetFilterName, categoryParameter);
          })
        }
      }
    }

    updateSearchUrl(queryParameters, page, history);
  };

  return (
    <div className='border-bottom'>
      <SelectedFacets {...{
        selectedFilters,
        facetsList,
        handleFacetValueClick,
        isContent
      }}/>
      {facetsList?.map((facet, key: number) => {
        if (!facet) {
          return null;
        }
        const eventKey = key.toString();
        const facetName = facet.name;
        return (
          <Accordion
            key={key}
            className='border border-bottom-0'
            defaultActiveKey={facetsState[facetName].open ? eventKey : ''}>
            <Card className='border-0 p-0 m-0' style={{height: 'auto'}}>
              <FacetToggle {...{
                eventKey,
                facetName,
                facetsState,
                setFacetsState
              }}/>
              <Accordion.Collapse eventKey={eventKey} className='py-1'>
                <Card.Body className='p-0'>
                  <ListGroup as={'ul'} variant='flush'>
                    {facet.values?.map((facetValue, valueKey: number) =>
                        facetsState[facetName].shown > valueKey && (
                          <ListGroup.Item
                            as={'li'}
                            key={valueKey}
                            className='d-flex border-0 justify-content-between m-1 p-0 font-size-sm'>
                            <Form.Check
                              id={facetValue.id}
                              className='ml-2'
                              checked={!!facetsState?.[facetName]?.values[facetValue.id]}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => handleFacetValueClick(
                                e,
                                facetName,
                                facetValue.id
                              )}
                              label={facetValue.name}/>
                            <Badge
                              pill
                              variant='info'
                              className='py-0'
                              style={{
                                lineHeight: '2',
                                minWidth: '2rem',
                                maxHeight: '1.2rem',
                              }}>
                              {facetValue.count}
                            </Badge>
                          </ListGroup.Item>
                        )
                    )}
                  </ListGroup>
                  {facet.values.length > numOfDisplayedFacetItems && (
                    <div className='m-1 ml-2 pt-1'>
                      {facetsState[facetName].shown > numOfDisplayedFacetItems && (
                        <FacetValuesExpandCollapseButton
                          iconName='arrow-up'
                          showLess={true}
                          facet={facet}
                          facetsState={facetsState}
                          setFacetsState={setFacetsState}
                          numOfDisplayedFacetFields={numOfDisplayedFacetItems}
                        />
                      )}
                      {facetsState[facetName].shown < facetsState[facetName].max && (
                        <FacetValuesExpandCollapseButton
                          iconName='arrow-down'
                          showLess={false}
                          facet={facet}
                          facetsState={facetsState}
                          setFacetsState={setFacetsState}
                          numOfDisplayedFacetFields={numOfDisplayedFacetItems}
                        />
                      )}
                    </div>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        );
      })}
    </div>
  );
};
