/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Card, Image} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {AuthorByLineProps} from '../AuthorByLineComponentTypes';
import {getImageVariantBySize} from '../../../../utils/ImageUtil';
import {ImageSizeEnum} from '../../../global/CommonEnums';

const AuthorByLineCard = ({authors}: AuthorByLineProps) => {
  return (
    <React.Fragment>
      {authors?.map((author, key) => {
        const {name, link, images} = author;
        const image = getImageVariantBySize(images, ImageSizeEnum.Thumbnail);
        return (
            <Card key={key}>
              {image && <Image src={image} alt={name}/>}
              <Card.Header>
                <Link to={link} className={'stretched-link'}>{name}</Link>
              </Card.Header>
            </Card>
          )
        }
      )}
    </React.Fragment>
  )
};

export default AuthorByLineCard;
