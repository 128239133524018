/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Container} from 'react-bootstrap';
import {CarouselProvider, DotGroup, Slide, Slider} from 'pure-react-carousel';
import {BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {BannerComponentArgs} from '../banner/BannerComponentTypes';
import {ComponentWrapper} from '..';
import {CarouselComponentArgs} from './CarouselComponentTypes';
import {getAllItemsProps} from './CarouselComponentUtils';
import CarouselHeroSlide from './templates/CarouselHeroSlide';
import {ResponsiveQueryProps, withResponsiveQuery} from '../../demo-connector-components/responsive/ResponsiveQuery';

function CarouselComponentBase({pageable, params, template, responsiveQuery}: CarouselComponentArgs & ResponsiveQueryProps){
  const page = React.useContext(BrPageContext)!;
  const component = React.useContext(BrComponentContext)!;

  pageable = pageable || component.getModels();
  params = params || component.getParameters();
  template = template || 'slide';

  const slides = getAllItemsProps(pageable, params, template);

  const carouselSettings = {
    infinite: true,
    interval: 6000,
    isIntrinsicHeight: true,
    isPlaying: true,
    naturalSlideHeight: 4,
    naturalSlideWidth: 12,
    totalSlides: slides.length,
  };

  return (
    (slides || page.isPreview()) &&
    <ComponentWrapper {...{type: 'Carousel', nowrap: false}}>
      <CarouselProvider {...carouselSettings}>
        <Slider>
          {slides?.map((args: BannerComponentArgs, key) => (
            <Slide key={key} index={key}>
              <CarouselHeroSlide {...args} responsiveQuery={responsiveQuery}/>
            </Slide>
          ))}
        </Slider>
        <Container
          className='carousel-controls d-flex justify-content-center mt-n5 mb-5 position-relative z-index-4'>
          <DotGroup/>
        </Container>
      </CarouselProvider>
    </ComponentWrapper>
  );
};

export const CarouselComponent = withResponsiveQuery(CarouselComponentBase);

