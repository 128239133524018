/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {Icon, Status} from '../../utility';
import {CartStageEnum} from '../../global/CommonEnums';
import {CheckOutProps, GetErrorProps, withCheckOut, withGetError} from '../../../connector-components';
import {saveOrderToSession} from '../OrderComponentUtils';
import {StageInputProps} from '../../cart/CartComponentTypes';

function CheckOutBase(props: StageInputProps & GetErrorProps & CheckOutProps) {
  const {
    setStage,
    checkOut,
    loading,
    result,
    errors,
    clearError
  } = props;
  const DEFAULT_MESSAGE = 'Checkout';

  useEffect(() => {
    if (result) {
      saveOrderToSession(result?.order);
      setStage && setStage(CartStageEnum.SHIPPING);
    }
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {errors?.map((error, key: number) => <Status key={key} onClose={clearError} container status={error?.message}
                                                        dismissible customClassName={'mt-3'} error/>)}
      <Button className={'float-right'} variant={'primary'} onClick={() => {
        clearError();
        checkOut();
      }}>
        {loading ? <Icon name={'spinner'} size={'lg'} spin content={DEFAULT_MESSAGE}/>
          : <Icon name={'cart-plus'} content={DEFAULT_MESSAGE}/>}
      </Button>
    </Fragment>
  )
}

export const CheckOut = withGetError(withCheckOut(CheckOutBase));
