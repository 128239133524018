/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect} from 'react';
import {Reference} from '@bloomreach/spa-sdk';
import {BrProps} from '@bloomreach/react-sdk';
import {UserContext} from '../../contexts';
import {ComponentWrapper} from '..';
import {Article, ArticleCard} from './templates';
import {ArticleMock} from '../../mocks/ContentMocks';
import {GtmEventProps, withGtmEvent} from '../../demo-connector-components';
import {getArticleProps} from './ArticleComponentUtils';
import {getDocument} from '../../utils/DocumentUtils';

export interface ArticleComponentProps {
  type: string,
  documentRef: Reference,
  fluid: boolean
}

function ArticleComponentBase(props: BrProps & ArticleComponentProps & GtmEventProps) {
  const {
    page,
    component,
    type = 'details',
    documentRef,
    setGtmEventContent
  } = props;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;
  const preview = page!.isPreview();
  const {article} = component!.getParameters();

  const content = getDocument(page!, 'brxsaas:demoArticle', documentRef ?? article);

  useEffect(() => {
    setGtmEventContent(content);
  }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!content && !preview) {
    return null;
  }

  let articleProps = getArticleProps(page!, content, userSegment);

  if (!articleProps && preview) {
    articleProps = ArticleMock;
  }

  if (!articleProps) {
    return null;
  }

  const componentWrapperProps = {
    type: 'Article',
    inlineEditingProps: {
      page,
      content,
      documentTemplateQuery: 'new-demoarticle-document',
      parameter: 'article',
      path: 'articles',
      pickerSelectableNodeTypes: 'brxsaas:demoArticle',
      root: 'articles',
      editOnly: false
    },
    // Make sure the component wrapper doesn't break card group same height.
    wrap: preview
  };

  switch (type) {
    case 'details':
      return (
        <ComponentWrapper {...componentWrapperProps}>
          <Article {...articleProps}/>
        </ComponentWrapper>
      );
    case 'card':
      return (
        <ComponentWrapper {...componentWrapperProps}>
          <ArticleCard {...articleProps}/>
        </ComponentWrapper>
      );
    default:
      return null;
  }
}

export const ArticleComponent = withGtmEvent(ArticleComponentBase);
