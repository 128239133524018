/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Card, Table} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {AddUserAddress} from './AddUserAddress';
import {RemoveUserAddress} from './RemoveUserAddress';
import {UpdateUserAddress} from './UpdateUserAddress';
import {GetAddressGroupProps} from '../../../connector-components';

export default function ListUserAddress(props: BrProps & GetAddressGroupProps) {
  const {
    page,
    component,
    addressGroup
  } = props;

  const addresses = addressGroup?.addresses;
  const tdDefaultClassName = {
    className: 'align-middle'
  }
  return (
    <Card>
      <Card.Body>
        <Card.Title className={'clearfix'}><AddUserAddress page={page!} component={component!}/> Addresses</Card.Title>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Street Address</th>
            <th>Apt,Suite,Etc.</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <th>Postal Code</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {addresses!.map((address, key) => {
            const {streetAddress, additionalStreetInfo, city, state, country, postalCode} = address!;
            return (
              <tr key={key}>
                <td {...tdDefaultClassName}>{streetAddress}</td>
                <td {...tdDefaultClassName}>{additionalStreetInfo}</td>
                <td {...tdDefaultClassName}>{city}</td>
                <td {...tdDefaultClassName}>{state}</td>
                <td {...tdDefaultClassName}>{country}</td>
                <td {...tdDefaultClassName}>{postalCode}</td>
                <td {...tdDefaultClassName}>
                  <UpdateUserAddress address={address!} page={page!} component={component!}/>
                  {' '}
                  <RemoveUserAddress address={address!} addressId={address!.id} page={page!} component={component!}/>
                </td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
