/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {BrProps} from '@bloomreach/react-sdk';
import {FIND_ORDERS, Orders, Orders_findOrders, OrdersVariables} from '../../modules/commerce';
import {withApolloClient} from '../../connector-components';

export interface OrdersProps {
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  sortFields: string;
  setSortFields: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  ordersPageResult?: Orders_findOrders;
  handleLoadMore?: () => void;
}

export interface OrdersInputProps {
  pageSize?: number;
  pageNumber?: number;
}

function withOrdersBase<P extends BrProps>(Component: React.ComponentType<P & OrdersProps>) {
  return (props: P & OrdersInputProps) => {
    const {pageSize: defaultPageSize = 10, pageNumber: defaultPageNumber = 1} = props;
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [pageNumber, setPageNumber] = useState(defaultPageNumber);
    const [sortFields, setSortFields] = useState('-creationDate');

    // This makes sure the state gets updated when props has different pageNumber value aka
    // when pagination bar link gets clicked
    useEffect(() => {
      setPageNumber(defaultPageNumber);
    }, [defaultPageNumber]);

    const {loading, data} = useQuery<Orders, OrdersVariables>(FIND_ORDERS, {
      fetchPolicy: 'cache-and-network',
      variables: {
        sortFields,
        offset: (pageNumber - 1) * pageSize,
        limit: pageSize,
      },
    });

    if (!data?.findOrders) {
      return (
        <Component
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          sortFields={sortFields}
          setSortFields={setSortFields}
          loading={loading}
          {...props}
        />
      );
    }

    const {findOrders} = data;

    return (
      <Component
        pageSize={pageSize}
        setPageSize={setPageSize}
        sortFields={sortFields}
        setSortFields={setSortFields}
        loading={loading}
        ordersPageResult={findOrders}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        {...props}
      />
    );
  };
}

export function withDemoOrders<P extends BrProps>(Component: React.ComponentType<P & OrdersProps>) {
  return withApolloClient(withOrdersBase(Component));
}
