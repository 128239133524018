/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {Col, Container, Image, Media, NavLink, Row} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {BrPageContext} from '@bloomreach/react-sdk';
import {CartContext} from '../../../contexts';
import {CartStageEnum} from '../../global/CommonEnums';
import {CheckOut} from '../../order/action/CheckOut';
import {ProductPrice} from '../../product/templates';
import {RemoveCartItem} from '../action/RemoveCartItem';
import ReturnButton from '../action/ReturnButton';

import {UpdateCartForm} from '../action/UpdateCart';
import {Discounted} from '../../utility/discounted/Discounted';
import {getUrl} from '../../../utils/UrlUtils';

const routeConfig = require('../../../utils/RouteConfig.json');

export function Shopping({cartDetailsFetched, setStage}: any) {
  const {cartDetails}: any = useContext(CartContext);
  const page = useContext(BrPageContext)!;

  const history = useHistory();
  const {stage} = cartDetails;

  const {discounts, entries} = cartDetailsFetched;
  const readOnly = stage === CartStageEnum.PURCHASE;
  const cartReadyOnly = false;

  const getProductUrl = function (item: any) {
    let productId = item.itemId?.id;
    const code = item.itemId?.code;

    // for CT only
    const itemProductId = item?.varAttrs?.find((varAttr: any) => varAttr?.name === 'mpn')?.values?.[0];
    if (itemProductId) {
      productId = itemProductId;
    }

    const productsPageUrl = `${routeConfig.product}/${productId}___${code}`;

    /*
    const index = productsPageUrl.indexOf('?');
    let prefix = '/' + productId;
    if (index === -1) {
      return productsPageUrl + prefix + '___' + code || '';
    } else {
      return productsPageUrl.substring(0, index) + prefix + productsPageUrl.substring(index);
    }
     */
    return getUrl(productsPageUrl, page);
  };

  return (
    <Container>
      {entries?.map((entry: any, key: number) => (
        <Row className={'py-4'} key={key}>
          <Col xs={9}>
            <>
              {entry.items?.map((item: any, key2: number) => (
                <Media as={NavLink} onClick={() => {
                  history.push(getProductUrl(item));
                }} key={key2} className={'nav-link'}>
                  <Image rounded src={item.imageSet?.original?.link?.href}
                         className={'w-25 align-self-start mr-3'}/>
                  <Media.Body>
                    <h6>
                      {item.displayName}
                    </h6>
                    <Discounted discounts={discounts} itemId={entry.id}/>
                    <ul className='list-unstyled'>
                      <li><small>SKU: {item.itemId?.code}</small></li>
                      <li>
                        <small>Price: <ProductPrice
                          price={item?.purchasePrice}
                          listPrice={item?.listPrice}
                        />
                        </small>
                      </li>
                    </ul>
                  </Media.Body>
                </Media>
              ))}
            </>
          </Col>
          <Col xs={3}>
            <ul className='list-unstyled'>
              {cartReadyOnly ?
                <li>Quantity: {entry.quantity}</li>
                :
                <React.Fragment>
                  <li>
                    <UpdateCartForm entry={entry} page={page}/>
                  </li>
                  <li>
                    <RemoveCartItem entry={entry} page={page}/>
                  </li>
                </React.Fragment>
              }
            </ul>
          </Col>
        </Row>
      ))}
      {setStage && <Col>
        {readOnly ?
          <ReturnButton direction={'right'} stage={CartStageEnum.SHIPPING}
                        setStage={setStage}/>
          :
          <CheckOut setStage={setStage} page={page}/>}
      </Col>}
    </Container>
  );
}
