/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect} from 'react';
import {Button, Col, Form} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {BrProps} from '@bloomreach/react-sdk';
import {Icon, Status} from '../../utility';
import {
  ChangePasswordProps,
  GetCurrentCustomerProps,
  LogoutProps,
  withLogout
} from '../../../connector-components';
import {GetErrorProps, withGetError} from '../../../connector-components';
import {withDemoChangePassword} from '../../../demo-connector-components';
import {saveOrderToSession} from '../../order/OrderComponentUtils';
import {CartContext, UserContext} from '../../../contexts';
import {getUrl} from '../../../utils/UrlUtils';

function ChangeUserPasswordBase(props: BrProps & GetCurrentCustomerProps & ChangePasswordProps & GetErrorProps & LogoutProps) {
  const {
    page,
    logout,
    changePasswordForm,
    changePassword,
    loading,
    result,
    errors,
    clearError
  } = props;

  const {userState: {setUser}} = useContext(UserContext)!;
  const {setCartDetails}: any = useContext(CartContext);
  const history = useHistory();
  const {setPassword, setNewPassword} = changePasswordForm;

  const DEFAULT_MESSAGE = 'Reset Password';
  const SUCCESS_MESSAGE = 'User password updated successfully!';

  const errorMessage = errors?.find((error) => error.operation === 'changePassword')?.message;

  useEffect(() => {
    if (!loading && result?.success) {
      logout().then(() => {
        saveOrderToSession(null);
        setCartDetails(null);
        setUser(null);
        history.push(getUrl('/', page!));
      });
    }
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form noValidate={true}>
      <Form.Row>
        <Form.Group as={Col} xs={6}>
          <Form.Label className={'text-uppercase'}>Current Password</Form.Label>
          <Form.Control type='password' name='currentPassword' required
                        onChange={(e) => setPassword(e.currentTarget.value
                        )}/>
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label className={'text-uppercase'}>New Password</Form.Label>
          <Form.Control type='password' name='newPassword' required
                        onChange={(e) => setNewPassword(
                          e.currentTarget.value
                        )}/>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Button variant='primary' onClick={() => {
            clearError();
            changePassword();
          }}>
            {loading ? <Icon name={'spinner'} size={'lg'} spin content={DEFAULT_MESSAGE}/>
              : <Icon name={'key'} content={DEFAULT_MESSAGE}/>
            }
          </Button>
          {errorMessage && <Status error status={errorMessage} customClassName={'mt-3'} dismissible={true}/>}
          {result?.success && <Status status={SUCCESS_MESSAGE} customClassName={'mt-3'} dismissible={true}/>}
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

export const ChangeUserPassword = withGetError(withLogout(withDemoChangePassword(ChangeUserPasswordBase)));
