import React, {useContext} from 'react';
import {UserContext} from '../../contexts';
import {RecentSearchInline, RecentSearchList} from './templates';

export const RecentSearch = ({template = 'inline', showClearButton}: any) => {
  const {recentSearchesState: {recentSearches, setRecentSearches}} = useContext(UserContext)!;

  if (recentSearches?.length === 0) {
    return null;
  }

  const params = {
    recentSearches,
    setRecentSearches,
    showClearButton
  }

  switch (template) {
    case 'list':
      return <RecentSearchList {...params}/>
    case 'inline':
    default:
      return <RecentSearchInline {...params}/>
  }
}
