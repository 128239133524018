/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {BrProps} from '@bloomreach/react-sdk';
import {DemoOrder, DemoOrder_getOrderById, DemoOrderVariables} from '../../modules/commerce';
import {withApolloClient} from '../../connector-components';
import {DEMO_GET_ORDER_BY_ID} from '../../modules/commerce/queries/demoorder';

export interface DemoOrderProps {
  loading: boolean;
  order?: DemoOrder_getOrderById;
  orderId?: string;
  setOrderId: React.Dispatch<React.SetStateAction<string>>;
}

interface InputProps {
  orderID: string;
}

function withDemoOrderBase<P extends BrProps>(Component: React.ComponentType<P & DemoOrderProps>) {
  return (props: P & InputProps) => {
    const {orderID = ''} = props; //props.component.getParameters();
    const [orderId, setOrderId] = useState<string>(orderID);

    useEffect(() => {
      setOrderId(orderID);
    }, [orderID]); // eslint-disable-line react-hooks/exhaustive-deps

    const {loading, data} = useQuery<DemoOrder, DemoOrderVariables>(DEMO_GET_ORDER_BY_ID, {
      variables: {
        id: orderId,
      },
    });

    return <Component
      orderId={orderId}
      setOrderId={setOrderId}
      loading={loading}
      order={data?.getOrderById ?? undefined}
      {...props}
    />;
  };
}

export function withDemoOrder<P extends BrProps>(Component: React.ComponentType<P & DemoOrderProps>) {
  return withApolloClient(withDemoOrderBase(Component));
}
