/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {lazy, Suspense} from 'react';
import {BrProps} from '@bloomreach/react-sdk';
import {Loader} from '../utility';
import {BrsmProductDetail} from './ProductDetail';
import {getProductIdFromUrl} from './ProductComponentUtils';

const ProductDetail = lazy(() => import('./ProductDetail')
  .then((module) => ({default: module.ProductDetail}))
);

export const PARAM_PRODUCT_ID = 'productId';

export const ProductComponent = (props: BrProps) => {
  const {page, component} = props;
  const params = component!.getParameters();
  let productID = params[PARAM_PRODUCT_ID];

  const defaultConnector = 'brsm';

  if (!productID) {
    const queryParameters = new URLSearchParams(window.location.search);
    const pid = queryParameters.get('pid');
    const sku = queryParameters.get('sku') ?? pid;
    if (pid && sku) {
      productID = `${pid}___${sku}`;
    }
  }

  if (!productID) {
    productID = getProductIdFromUrl();
  }

  const RenderProduct = () => {
    switch (defaultConnector) {
      case 'brsm':
        return <BrsmProductDetail productID={productID} page={page!} component={component!}/>;
      default:
        return <ProductDetail productID={productID} page={page!} component={component!}/>;
    }
  }

  return (
    <Suspense fallback={<Loader/>}>
      <RenderProduct/>
    </Suspense>
  );
};
