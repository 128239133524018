/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useMemo} from 'react';
import {useCookies} from 'react-cookie';
import {
  DemoItems_findItemsByKeyword,
  DemoItems_findItemsByKeyword_facetResult_fields,
  DemoItems_findItemsByKeyword_items,
  DemoItems_findItemsByKeyword_queryHint,
  QueryHintInput,
} from '../../modules/commerce';
import {BrProps} from '@bloomreach/react-sdk';
import {getSmViewId} from '../../connector-components/utils';
import {addError} from '../../modules/commerce/api';
import {ProductGridKeywordProps, ProductsKeywordInputProps} from './DemoProductGridKeyword';
import {useSearch} from '../../hooks';
import {
  BrProduct,
  createFacetResult,
  DEFAULT_BRSM_FIELDS,
  DEFAULT_UID_COOKIE,
  getItem,
  sortFieldsMapping,
  statsField
} from '../DemoBrsmUtils';
import {ProductGroupsType} from '../../components/product/ProductComponentTypes';
import {GtmEventProps} from '../gtm/DemoGtmEvent';
import {ProductGridTemplateProps} from '../../components/product-grid-by-search/ProductGridBySearch';
import {Status} from '../../components/utility';

export interface ProductGroupsKeywordProps {
  productGroups?: ProductGroupsType;
}

function withDemoBrsmProductGridKeywordBase<P extends BrProps>(Component: React.ComponentType<P & ProductGridKeywordProps> & ProductGroupsKeywordProps) {
  return (props: P & ProductsKeywordInputProps) => {
    const {
      page,
      query,
      groupBy,
      groupLimit,
      viewId = '',
      segment,
      widgetId,
      pageSize = 12,
      pageNumber = 1,
      facetFieldFilters = [],
      sortFields = '',
    } = props;

    const {
      smEndpoint,
      discoveryRealm,
      discoveryAuthKey,
      discoveryDomainKey,
      discoveryAccountId,
      discoveryFields,
      smAuthKey,
      smDomainKey,
      smAccountId
    } = page!.getChannelParameters();

    const endpoint = smEndpoint || `https://${discoveryRealm === 'STAGING' ? 'staging-': ''}core.dxpapi.com/api/v1/core/`;
    const authKey = smAuthKey || discoveryAuthKey;
    const domainKey = smDomainKey || discoveryDomainKey;
    const accountId = smAccountId || discoveryAccountId;
    const fields = discoveryFields || DEFAULT_BRSM_FIELDS;

    const smViewId = viewId || getSmViewId(page!) || '';
    const [cookies] = useCookies(['_br_uid_2']);

    if (!query) {
      addError({
        code: 'VALIDATION_ERROR',
        message: 'query not provided',
        operation: 'fetchProductByKeyword',
      });
    }

    const queryHint: QueryHintInput = {
      facetFieldFilters,
      viewId: smViewId,
      brUid2: cookies._br_uid_2,
    }

    queryHint.params = [{
      name: 'url',
      values: [window.location.href]
    }];

    let ref = '';
    if (segment) {
      const prefix = 'customer_profile:'
      ref = segment.startsWith(prefix) ? segment.substring(prefix.length) : segment;
      queryHint.params.push({
        name: 'segment',
        values: [segment]
      }, {
        name: 'ref',
        values: [ref]
      })
    }

    if (widgetId) {
      queryHint.params.push({
        name: 'widget_id',
        values: [widgetId]
      })
    }

    queryHint.customAttrFields = ['skuid'];
    queryHint.customVariantAttrFields = ['skuid'];

    const facetFieldFiltersJson = JSON.stringify(facetFieldFilters);

    const urlParams = new URLSearchParams(window.location.search);

    const brUid2Cookie = urlParams.get('_br_uid_2') || cookies._br_uid_2 || DEFAULT_UID_COOKIE;

    console.log('COOKIE', 'search', brUid2Cookie);

    const params = useMemo(
      () => {
        return {
          view_id: smViewId,
          widget_id: widgetId,
          account_id: accountId,
          auth_key: authKey,
          domain_key: domainKey,
          segment,
          ref: ref ?? '',
          search_type: 'keyword',
          request_type: 'search',
          url: window.location.href,
          ref_url: window.location.href,
          request_id: new Date().getTime(),
          //widget_id: widgetId || widget?.[searchType],
          //user_id: userId,
          q: query,
          groupby: groupBy,
          group_limit: groupBy ? groupLimit ?? 0 : '',
          fl: fields.split(','),
          fq: JSON.parse(facetFieldFiltersJson),
          rows: pageSize,
          sort: sortFieldsMapping[sortFields]!,
          start: pageSize * (pageNumber - 1),
          _br_uid_2: brUid2Cookie,
          'stats.field': statsField,
        }
      },
      [
        brUid2Cookie,
        accountId,
        authKey,
        domainKey,
        smViewId,
        widgetId,
        query,
        pageNumber,
        pageSize,
        facetFieldFiltersJson,
        sortFields,
        groupBy,
        groupLimit,
        ref,
        segment,
        fields
      ],
    );

    // Perform actual search query
    const [results, loading, error] = useSearch(endpoint, params);
    if (loading) {
      return null;
    }

    if (!loading && error) {
      return <Status status={error.message} container error/>
    }

    const {
      response,
      facet_counts,
      group_response,
      autoCorrectQuery,
      did_you_mean
    } = results;

    const {numFound, start, docs} = response ?? {};

    const itemsPageResult: DemoItems_findItemsByKeyword = response && {
      queryHint: {
        __typename: "QueryHint",
        autoCorrectQuery,
        autoCorrectQuerySet: did_you_mean
      } as DemoItems_findItemsByKeyword_queryHint,
      __typename: 'ItemsPageResult',
      count: docs.length,
      facetResult: {
        __typename: "FacetResult",
        fields: createFacetResult(facet_counts) as (DemoItems_findItemsByKeyword_facetResult_fields | null)[]
      },
      items: (docs as (BrProduct)[]).map(item => {
        return (getItem(item) as DemoItems_findItemsByKeyword_items);
      }),
      limit: pageSize,
      offset: start ?? 0,
      total: numFound,
    }

    const productGroups = groupBy && group_response && {
      groupBy,
      groups: group_response?.[groupBy]?.groups?.map(({groupValue, doclist}: any) => ({
        groupValue: groupValue,
        products: doclist?.docs?.map((doc: BrProduct) => (getItem(doc) as DemoItems_findItemsByKeyword_items))
      })),
      facetResult: {
        __typename: "FacetResult",
        fields: createFacetResult(facet_counts) as (DemoItems_findItemsByKeyword_facetResult_fields | null)[]
      },
      matches: group_response?.[groupBy]?.matches,
      queryHint: {
        autoCorrectQuery,
        autoCorrectQuerySet: did_you_mean
      }
    }

    return (
      <Component
        pageSize={pageSize}
        pageNumber={pageNumber}
        sortFields={sortFields}
        viewId={viewId}
        segment={segment}
        widgetId={widgetId}
        itemsPageResult={itemsPageResult}
        loading={loading}
        facetFieldFilters={facetFieldFilters}
        productGroups={productGroups}
        {...props}
      />
    );
  };
}

export function withDemoBrsmProductGridKeyword<P extends BrProps>(
  Component: (props: (P & GtmEventProps & ProductGridKeywordProps & ProductGridTemplateProps & ProductGroupsKeywordProps)) => (JSX.Element),
) {
  return withDemoBrsmProductGridKeywordBase(Component);
}
