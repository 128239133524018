/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Col, Container, Row, Table} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {BrProps} from '@bloomreach/react-sdk';
import {ListPagination, SecuredComponentWrapper, Status} from '../utility';
import {ProductPrice} from '../product/templates';
import {OrdersProps, withDemoOrders} from '../../demo-connector-components';
import {MoneyAmount} from '../../demo-connector-components/DemoBrsmUtils';
import {getListPaginationProps} from '../utility/list-pagination/ListPaginationUtils';
import {
  PARAM_CURRENT_PAGE,
  PARAM_ORDER_CURRENT_PAGE,
  PARAM_PAGE_SIZE,
  PARAM_SHOW_PAGINATION,
  QUERY_PARAM_PAGE_SIZE
} from '../../utils/SearchUtil';
import {getUrl} from '../../utils/UrlUtils';

const routeConfig = require('../../utils/RouteConfig.json');

function OrderListBase(props: BrProps & OrdersProps) {
  const {
    page,
    component,
    loading,
    ordersPageResult,
  } = props;

  const params = component!.getParameters();

  if (loading) {
    return (
      <Status container loading status={'We are fetching order history for you'}/>
    )
  }

  const orders = ordersPageResult?.orders;

  if (!orders || orders.length === 0) {
    return <Status container warning status={'No order'} customClassName={'pt-5'} dismissible={true}/>
  }

  // Pagination
  const pagination = getListPaginationProps({
    offset: ordersPageResult?.offset ?? 0,
    limit: ordersPageResult?.limit ?? 0,
    total: ordersPageResult?.total ?? 0,
    params: {
      showPagination: params[PARAM_SHOW_PAGINATION] ?? true
    }
  });

  return (
    <SecuredComponentWrapper>
      <Container className={'pt-5'}>
        <h2>{params.title || 'Order History'}</h2>
        <Table striped bordered hover className={'mt-3'}>
          <thead>
          <tr>
            <th>Id</th>
            <th>Status</th>
            <th>Total Price</th>
            <th>Creation</th>
          </tr>
          </thead>
          <tbody>
          {orders?.map((order, key: number) => {
            const {id, status, totalAmount, creationDate} = order!;
            const orderUrl = getUrl(`${routeConfig.order}/${id}`, page!);
            return (
              <tr key={key}>
                <td><Link to={orderUrl}>{id}</Link></td>
                <td>{status}</td>
                <td><ProductPrice moneyAmount={totalAmount[0] as MoneyAmount}/></td>
                <td>{new Date(creationDate).toLocaleString()}</td>
              </tr>
            )
          })}
          </tbody>
        </Table>
        <Row>
          <Col className={'text-center py-2 font-size-xs'}>
            {pagination && <ListPagination {...pagination}/>}
          </Col>
        </Row>
      </Container>
    </SecuredComponentWrapper>
  )
}

export const OrderList = withDemoOrders(OrderListBase);

const config = require('./OrderHistoryComponentConfig.json');

export const OrderHistoryComponent = (props: BrProps) => {
  const {page, component} = props;
  const params = component!.getParameters();
  const queryParameters = new URLSearchParams(window.location.search);
  let pageSize: number = params[PARAM_PAGE_SIZE] ?? config.defaultPageSize;
  if (queryParameters.get(QUERY_PARAM_PAGE_SIZE)) {
    pageSize = parseInt(queryParameters.get(QUERY_PARAM_PAGE_SIZE)!);
  }
  let pageNumber: number = params[PARAM_CURRENT_PAGE] ?? config.defaultPage;
  if (queryParameters.get(PARAM_ORDER_CURRENT_PAGE)) {
    pageNumber = parseInt(queryParameters.get(PARAM_ORDER_CURRENT_PAGE)!);
  }
  return (
    <OrderList pageSize={pageSize} pageNumber={pageNumber} page={page!} component={component!}/>
  )
}
