/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useMediaQuery} from 'react-responsive';

export interface ResponsiveQueryType {
  isExtraSmall: boolean,
  isSmall: boolean,
  isMedium: boolean,
  isLarge: boolean,
  isExtraLarge: boolean,
  isExtraSmallOrGreater: boolean,
  isSmallOrGreater: boolean,
  isMediumOrGreater: boolean,
  isLargeOrGreater: boolean,
}

export interface ResponsiveQueryProps {
  responsiveQuery: ResponsiveQueryType,
}

const displayConfig = require('../../utils/DisplayConfig.json');

function withResponsiveQueryBase<P>(Component: React.ComponentType<P & ResponsiveQueryProps>) {
  return (props: P) => {
    const isExtraSmall = useMediaQuery(displayConfig.xs);
    const isSmall = useMediaQuery(displayConfig.sm);
    const isMedium = useMediaQuery(displayConfig.md);
    const isLarge = useMediaQuery(displayConfig.lg);
    const isExtraLarge = useMediaQuery(displayConfig.xl);

    const isExtraSmallOrGreater = isExtraSmall || isSmall || isMedium || isLarge || isExtraLarge;
    const isSmallOrGreater = isSmall || isMedium || isLarge || isExtraLarge;
    const isMediumOrGreater = isMedium || isLarge || isExtraLarge;
    const isLargeOrGreater = isLarge || isExtraLarge;

    return (
      <Component
        responsiveQuery={{
          isExtraSmall,
          isSmall,
          isMedium,
          isLarge,
          isExtraLarge,
          isExtraSmallOrGreater,
          isSmallOrGreater,
          isMediumOrGreater,
          isLargeOrGreater
        }}
        {...props}
      />
    );
  };
}

export function withResponsiveQuery<P>(Component: React.ComponentType<P & ResponsiveQueryProps>) {
  return withResponsiveQueryBase(Component);
}
