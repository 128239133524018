import React from 'react';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Icon} from '../../utility';
import {getMessage} from '../../../utils/MessageUtils';
import {getSearchUrl} from '../../auto-suggestion/AutoSuggestionUtils';

const config = require('../RecentSearchConfig.json');
export const RecentSearchList = (props: any) => {
  const {recentSearches, setRecentSearches, showClearButton = true} = props;
  return (
    <ul className='list-unstyled'>
      <li className={'list-item h6 font-weight-bold'}>{getMessage(config,'recent searches')}</li>
      {recentSearches.slice(0).reverse().map((recentSearch: string, index: number) => (
        <li key={index} className={'list-item h6'}>
          <Link to={getSearchUrl(recentSearch)}>{recentSearch}</Link>
        </li>
      ))}
      {recentSearches?.length > 0 && showClearButton &&
      <li className={'list-item h6'}>
        <Button size={'sm'} variant={'link'} onClick={() => setRecentSearches(null)}>
          <Icon name={'trash-alt'}/>
        </Button>
      </li>}
    </ul>
  )
}
