/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {PageTitleComponent} from '..';
import default404Img from '../../assets/img/404.svg'

export const PageNotFoundComponent = () => {
  const handleGoBack = () => {
    // https://github.com/ReactTraining/history/issues/829
    window.history.back();
  };

  const backgroundImgStyle = {
    backgroundImage: 'url(' + default404Img + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center'
  };

  return (
    <Fragment>
      <PageTitleComponent contentTitle={'Page Not Found'}/>
      <div style={backgroundImgStyle}>
        <Container className='d-flex height-60vh'>
          <Row className='align-self-center'>
            <Col>
              <h1 className='header'>Page not found</h1>
              <p className='mb-0'>Oops! Looks like you followed a bad link.</p>
              <p>If you think this is a problem with us, please <a href='mailto:sales@bloomreach.com'>tell us</a>.</p>
              <Button className={'primary'} onClick={handleGoBack}>Go Back</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
