/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Page} from '@bloomreach/spa-sdk';
import {
  GuidedSellingFlowComponentArgs,
  GuidedSellingFlowComponentProps,
  GuidedSellingFlowContent,
  GuidedSellingStepContent
} from './GuidedSellingFlowComponentTypes';
import {getImageVariantBySize, getImageVariants} from '../../utils/ImageUtil';
import {ImageSizeEnum} from '../global/CommonEnums';

export const getProps = (page: Page, {documentRef}: GuidedSellingFlowComponentArgs): GuidedSellingFlowComponentProps => {
  const document = documentRef && page.getContent(documentRef);
  const content = document && getContent(page, document.getData());

  return {
    type: 'Guided Selling Flow',
    documentRef: documentRef,
    document: document,
    content: content
  } as GuidedSellingFlowComponentProps;
};

const getContent = (page: Page, {footer, header, image, steps}: any): GuidedSellingFlowContent => {
  const images = getImageVariants(page, image);
  const imageUrl = getImageVariantBySize(images, ImageSizeEnum.Large);
  const stepsContent = steps.map((ref: any, key: number) => {
    const document = ref && page.getContent(ref);
    return document && getStepContent(page, document.getData());
  });

  return {
    footer: footer && page.rewriteLinks(footer?.value),
    header: header && page.rewriteLinks(header?.value),
    imageUrl,
    steps: stepsContent
  } as GuidedSellingFlowContent;
};

const getStepContent = (page: Page, {key, options, text, title}: any): GuidedSellingStepContent => {
  const optionsContent = options.map(({icon, label, value}: any) => {
    const images = getImageVariants(page, icon);
    const iconUrl = getImageVariantBySize(images, ImageSizeEnum.Small);
    return {
      iconUrl,
      label,
      value
    };
  });

  return {
    paramName: key,
    options: optionsContent,
    text: text && page.rewriteLinks(text ?? text?.value),
    title
  } as GuidedSellingStepContent;
};
