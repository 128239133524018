/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {FacetFieldFilterInput} from '../modules/commerce';

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 12;

export const PARAM_PAGE_SIZE = 'pageSize';
export const PARAM_CURRENT_PAGE = 'currentPage';
export const PARAM_SHOW_CAPTION = 'showCaption';
export const PARAM_SHOW_CONTENT = 'showContent';
export const PARAM_SHOW_SWITCHBOARD = 'showSwitchboard';
export const PARAM_SHOW_PAGINATION = 'showPagination';
export const PARAM_SHOW_FACETS = 'showFacets';
export const PARAM_NUMBER_OF_COLUMNS = 'numberOfColumns';
export const PARAM_FACETS_LOCATION = 'facetsLocation';
export const PARAM_WIDGET_ID = 'widgetId';

export const QUERY_PARAM_QUERY = '_sq';

export const QUERY_PARAM_GROUP_BY = 'groupby';

export const QUERY_PARAM_FACET = '_sfq';
export const QUERY_PARAM_CONTENT_FACET = '_csfq';

export const QUERY_PARAM_PAGE_SIZE = '_sps';
export const QUERY_PARAM_CONTENT_PAGE_SIZE = '_csps';

export const QUERY_PARAM_SORT = 'sort';
export const QUERY_PARAM_CONTENT_SORT = 'csort';

export const QUERY_PARAM_PAGE_NUM = 'page';
export const QUERY_PARAM_CONTENT_PAGE_NUM = '_cspage';

export const QUERY_PARAM_ACTIVE_TAB = 'active';

export const QUERY_PARAM_PAGE_PARAM_NAME = 'pageParameterName';
export const QUERY_PARAM_SORT_TARGET = 'sortTarget';

export const QUERY_PARAM_SEGMENT = 'segment';
export const QUERY_PARAM_VIEW_ID = 'view_id';

export const PARAM_ORDER_PAGE_SIZE = 'pageSize';
export const PARAM_ORDER_CURRENT_PAGE = "page";

export interface SearchParam {
  pageNumber: number,
  pageSize: number,
  sort: string,
  segment?: string | null,
  viewId?: string,
  query?: string,
  groupBy?: string,
  widgetId?: string
}

const config = require('./SearchConfig.json');

export const getSearchParam = (params: Partial<Record<string, string>>, defaultSearchParam?: SearchParam, queryType?: string): SearchParam => {
  const queryParameters = new URLSearchParams(window.location.search);
  const queryParameterNames = queryType ? config.queryParameters[queryType] : config.queryParameters.default;

  let pageSize: number = defaultSearchParam?.pageSize ?? DEFAULT_PAGE_SIZE;
  if (params[PARAM_PAGE_SIZE]) {
    pageSize = parseInt(params[PARAM_PAGE_SIZE]!);
  }
  if (queryParameters.get(queryParameterNames.pageSize)) {
    pageSize = parseInt(queryParameters.get(queryParameterNames.pageSize)!);
  }

  let pageNumber: number = defaultSearchParam?.pageNumber ?? DEFAULT_PAGE_NUMBER;
  if (params[PARAM_CURRENT_PAGE]) {
    pageNumber = parseInt(params[PARAM_CURRENT_PAGE]!);
  }
  if (queryParameters.get(queryParameterNames.pageNumber)) {
    pageNumber = parseInt(queryParameters.get(queryParameterNames.pageNumber)!);
  }

  const sort = queryParameters.get(queryParameterNames.sort) || defaultSearchParam?.sort || '';

  const searchParam: SearchParam = {
    pageNumber,
    pageSize,
    sort
  }

  const segment = queryParameters.get(QUERY_PARAM_SEGMENT) || defaultSearchParam?.segment;
  if (segment) {
    searchParam.segment = segment;
  }

  const viewId = queryParameters.get(QUERY_PARAM_VIEW_ID) || defaultSearchParam?.viewId;
  if (segment) {
    searchParam.viewId = viewId;
  }

  const query = queryParameters.get(QUERY_PARAM_QUERY) || defaultSearchParam?.query;
  if (query) {
    searchParam.query = query;
  }

  const groupBy = queryParameters.get(QUERY_PARAM_GROUP_BY) || defaultSearchParam?.groupBy;
  if (groupBy) {
    searchParam.groupBy = groupBy;
  }

  if (params[PARAM_WIDGET_ID]) {
    searchParam.widgetId = params[PARAM_WIDGET_ID];
  }

  return searchParam;
}

export const getFacetFieldFilters = (paramName: string = QUERY_PARAM_FACET): FacetFieldFilterInput[] => {
  const queryParameters = new URLSearchParams(window.location.search);
  const facetFieldFilters: FacetFieldFilterInput[] = [];
  let facetFieldFiltersInput = queryParameters.getAll(paramName);
  if (facetFieldFiltersInput.length > 0) {
      facetFieldFiltersInput.forEach(facetItem => {
        prepareFilterInputs(facetItem, facetFieldFilters);
     });
  }
  return facetFieldFilters;
}

export const getFacetFilterFromString = (filterValues: string): FacetFieldFilterInput[] => {
  const facetFieldFilters: FacetFieldFilterInput[] = [];
  filterValues.split(',')
    .forEach((filterValue) => {
      prepareFilterInputs(filterValue, facetFieldFilters);
    });
  return facetFieldFilters;
}

const prepareFilterInputs = (filterValue: string, facetFieldFilters: FacetFieldFilterInput[]) => {
  const facet = getFacetFilterFilter(filterValue);
  if (facet) {
    const {facetItemId, facetItemValue} = facet;
    const matchedFacetFieldFilter = facetFieldFilters.find((facetFieldFilter: any) => facetFieldFilter?.id === facetItemId);
    if (matchedFacetFieldFilter) {
      matchedFacetFieldFilter?.values?.push(facetItemValue);
    } else {
      facetFieldFilters.push({
        id: facetItemId,
        values: [facetItemValue]
      });
    }
  }
}

export const getFacetFilterFilter = (filterValue: string) => {
  const facetItemSegments = filterValue.split(":");
  if (facetItemSegments.length === 2) {
    const facetItemId = facetItemSegments[0];
    const facetItemValue = facetItemSegments[1].substring(1, facetItemSegments[1].length - 1);
    return {
      facetItemId,
      facetItemValue
    }
  }
  return null;
}

export const getFirstQueryParameter = (array: string | string[] | null | undefined) : string | undefined => {
  if (!array) {
    return undefined;
  }

  if (!Array.isArray(array)) {
    return array;
  }

  return array && array.length > 0 ? array[0] : undefined;
};
