/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  RECOVER_CUSTOMER,
  RecoverCustomer,
  RecoverCustomer_recoverCustomer,
  RecoverCustomerVariables,
} from '../../modules/commerce';
import {withApolloClient} from '../ApolloClient';

export interface ForgotPasswordProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  recoverCustomer: () => Promise<FetchResult<RecoverCustomer>>;
  loading: boolean;
  result?: RecoverCustomer_recoverCustomer;
}

function withForgotPasswordBase<P>(Component: React.ComponentType<P & ForgotPasswordProps>) {
  return (props: P) => {
    const [email, setEmail] = useState<string>('');

    const [recoverCustomer, {data, loading}] = useMutation<RecoverCustomer, RecoverCustomerVariables>(
      RECOVER_CUSTOMER,
      {
        variables: {
          email,
        },
      },
    );

    return (
      <Component
        email={email}
        setEmail={setEmail}
        recoverCustomer={recoverCustomer}
        loading={loading}
        result={data?.recoverCustomer}
        {...props}
      />
    );
  };
}

export function withForgotPassword<P>(Component: React.ComponentType<P & ForgotPasswordProps>) {
  return withApolloClient(withForgotPasswordBase(Component));
}
