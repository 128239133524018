/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment} from 'react';
import {BrComponentContext} from '@bloomreach/react-sdk';
import {ComponentWrapperProps} from './ComponentWrapperTypes';
import {RenderInlineEditing} from '../utility/component/RenderInlineEditing';

const config = require('./ComponentWrapperConfig.json');
export const ComponentWrapper = (props: ComponentWrapperProps) => {
  const componentFromContext = React.useContext(BrComponentContext);
  const {
    component = componentFromContext,
    type,
    wrap = true,
    inlineEditing = true,
    children,
    inlineEditingProps
  } = props;

  if (!component) {
    return children;
  }

  if (!wrap) {
    return (
      <Fragment>
        {inlineEditing && inlineEditingProps && <RenderInlineEditing {...inlineEditingProps}/>}
        {children}
      </Fragment>
    );
  }

  let dataAttrs: any = {};
  dataAttrs[config.dataAttributes.componentId] = component.getId();
  dataAttrs[config.dataAttributes.componentName] = component.getName();
  dataAttrs[config.dataAttributes.componentType] = type || 'undefined';

  return (
    <section className='br-component' {...dataAttrs}>
      {inlineEditing && inlineEditingProps && <RenderInlineEditing {...inlineEditingProps}/>}
      {children}
    </section>
  );
};
