/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {useCookies} from 'react-cookie';

export const CommerceCookieComponent = () => {
  const [, setCookie] = useCookies();
  const queryParameters = new URLSearchParams(window.location.search);

  const pattern = '_ext_';
  queryParameters.forEach((value: string, queryParameterName: string) => {
    if (queryParameterName.startsWith(pattern)) {
      const cookieName = queryParameterName.substring(pattern.length);
      if (cookieName && cookieName !== '') {
        setCookie('commerce_' + cookieName, value, {path: '/'});
      }
    }
  });

  return null;
}
