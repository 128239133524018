/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The Discount Types
 */
export enum DiscountType {
  Cart = "Cart",
  Product = "Product",
  Promotion = "Promotion",
}

/**
 * The input data to add a product item to the cart
 */
export interface AddToCartInput {
  cartId?: string | null;
  itemId: string;
  quantity?: number | null;
  customAttrs?: AttributeInput[] | null;
}

/**
 * The address input data used in mutations
 */
export interface AddressInput {
  id?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  streetAddress?: string | null;
  additionalStreetInfo?: string | null;
  company?: string | null;
  postalCode?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  shippingAddress?: boolean | null;
  billingAddress?: boolean | null;
  readOnly?: boolean | null;
}

/**
 * Generic attribute input type.
 * e.g, {name:'color', values:['orange']}, {name:'size', values:['large']}, ..."
 */
export interface AttributeInput {
  name: string;
  values?: string[] | null;
}

/**
 * The customer credentials input data used when updating the credentials
 */
export interface CustomerCredsInput {
  password: string;
  newPassword?: string | null;
}

/**
 * The customer detail input data used when updating the customer's detail information
 */
export interface CustomerDetailInput {
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  title?: string | null;
  salutation?: string | null;
  dateOfBirth?: string | null;
  companyName?: string | null;
}

/**
 * The input data used when resetting password for a customer
 */
export interface CustomerResetInput {
  passwordResetToken: string;
  newPassword: string;
}

/**
 * The customer input data used when registering a new customer
 */
export interface CustomerSignUpInput {
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  password: string;
  title?: string | null;
  salutation?: string | null;
  dateOfBirth?: string | null;
  companyName?: string | null;
}

/**
 * The expiration date input. e.g, credit cart expiration date input.
 */
export interface ExpirationDateInput {
  day?: number | null;
  month: number;
  year: number;
}

/**
 * Facet field filter
 */
export interface FacetFieldFilterInput {
  id: string;
  values: (string | null)[];
}

export interface ParamInput {
  name: string;
  values: (string | null)[];
}

/**
 * The payment method detail
 */
export interface PaymentMethodInput {
  accountIssuer: string;
  accountNumber: string;
  accountHolderName: string;
  accountExpirationDate?: ExpirationDateInput | null;
  additionalCode?: string | null;
}

/**
 * The optional input data for the brSM or the specific Commerce Backend Platform in product or category searches.
 * For example, if 'viewId' is included, then the value is passed to the search API calls against the brSM.
 */
export interface QueryHintInput {
  brUid2?: string | null;
  domainKey?: string | null;
  viewId?: string | null;
  catalogViews?: string | null;
  facetFieldFilters?: (FacetFieldFilterInput | null)[] | null;
  cursor?: string | null;
  params?: (ParamInput | null)[] | null;
  customAttrFields?: (string | null)[] | null;
  customVariantAttrFields?: (string | null)[] | null;
  widgetType?: string | null;
  widgetId?: string | null;
}

/**
 * The input data to remove an existing cart entry
 */
export interface RemoveFromCartInput {
  cartId: string;
  entryId?: string | null;
  discountCodes?: (string | null)[] | null;
}

/**
 * The input to set a shipping or billing address to an order
 */
export interface SetOrderAddressInput {
  addressId?: string | null;
  address?: AddressInput | null;
}

/**
 * The input to update the order details such as shipping/billing addresses, payment method, etc.
 */
export interface SetOrderDetailsInput {
  id: string;
  shipmentAddress?: SetOrderAddressInput | null;
  shipmentMethod?: SetOrderShipmentMethodInput | null;
  billingAddress?: SetOrderAddressInput | null;
  paymentMethod?: SetOrderPaymentMethodInput | null;
}

/**
 * The input to set the payment method to an order
 */
export interface SetOrderPaymentMethodInput {
  paymentMethodId?: string | null;
  paymentData?: PaymentMethodInput | null;
}

/**
 * The input to set the shipping method to an order
 */
export interface SetOrderShipmentMethodInput {
  shipmentMethodId?: string | null;
}

/**
 * The input data to update an existing cart entry
 */
export interface UpdateCartInput {
  cartId: string;
  entryId?: string | null;
  quantity?: number | null;
  discountCodes?: (string | null)[] | null;
  customAttrs?: AttributeInput[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
