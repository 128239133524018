/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment} from 'react';
import {Media} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {getBackgroundImageLink} from '../../../utils/ImageUtil';
import {ArticleProps} from '../../article/ArticleComponentTypes';
import {getMessage} from '../../../utils/MessageUtils';
import {brxmEndpoint} from '../../../contexts';

export interface SuggestedArticlesProps {
  articles: Array<ArticleProps> | undefined
}

const config = require('../AutoSuggestionConfig.json');
export const SuggestedArticles = (props: SuggestedArticlesProps) => {
  const {
    articles
  } = props;

  const SuggestContentLink = (params: any) => {
// For testing
// params.link.url = "/site/binaries/content/assets/pacific-supply/pdfs/circuit_breaker_course.pdf";
    let targetUrl = params.link.url;

    if (targetUrl?.indexOf('.pdf') > 0) {
      const url = new URL(brxmEndpoint());
      targetUrl = url.origin + params.link.url;
      // Note: The 'noopener noreferrer' is included to avoid security warnings
      return (
        <a
          href={targetUrl}>
          <Media className={'mb-3'}>
            {params.image.url &&
            <div className={'image-thumbnail image-background mr-2'}
                 style={{backgroundImage: getBackgroundImageLink(params.image.url)}}/>
            }
            <Media.Body>
              <strong className={'font-weight-normal'}>
                {params.title}
              </strong>
            </Media.Body>
          </Media>
        </a>
      )
    }
    // regular (non-pdf) article link
    return (
      <Link to={targetUrl}>
        <Media className={'mb-3'}>
          {params?.image?.url &&
          <div className={'image-thumbnail image-background mr-2'}
               style={{backgroundImage: getBackgroundImageLink(params.image.url)}}/>
          }
          <Media.Body>
            <strong className={'font-weight-normal'}>
              {params.title}
            </strong>
          </Media.Body>
        </Media>
      </Link>
    )
  }

  return (
    <Fragment>
      <h5>{getMessage(config, 'content')}</h5>
      {articles?.map((article, key: number) => (
        <div className={'h6'} key={key}>
          <SuggestContentLink {...article} />
        </div>
      ))}
    </Fragment>
  )
}
