/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Document, Page} from '@bloomreach/spa-sdk';
import {parseSearchImageUrl} from '../../utils/ImageUtil';
import {UserSegmentType} from '../../contexts';
import {getUrl} from '../../utils/UrlUtils';
import {CmsLink} from './CommonTypes';

export const getImageUrl = (image: any): string => {
  const imageUrl = parseSearchImageUrl(image.imageUrl);
  return imageUrl.url;
};

export const getLinksArray = (page: Page, linkRefs: Array<any>, userSegment: UserSegmentType): Array<CmsLink> => {
  // TODO: fix this link issue
  const mount = '/site/supply';
  return linkRefs && linkRefs.map((linkRef: any) => {
    let url = linkRef.url;
    let isExternal = false;
    if (url) {
      switch (linkRef.type) {
        case 'INTERNAL':
          url = getUrl(url, page)!;
          if (mount) {
            if (url.startsWith(mount)) {
              url = url.substring(mount.length);
            }
          }
          break;
        case 'EXTERNAL':
          if (!url.startsWith('/') && !url.startsWith('http')) {
            url = 'https://' + url;
          }
          if (url.startsWith('/')) {
            url = getUrl(url, page)!;
          }
          isExternal = true;
          break;
      }
    }
    return {
      url: url,
      label: linkRef.label,
      openInNewWindow: linkRef.openInNewWindow,
      isExternal
    };
  });
};

export const getLinksArrayDynamic = (page: Page, linkRefs: Array<any>, userSegment: UserSegmentType): Array<CmsLink> => {
  return linkRefs && linkRefs.map((linkRef: any) => {
    const link = linkRef?.link?.[0] ?? linkRef?.primaryLink?.[0];
    let url = link?.externalLink;

    const {label, openInNewWindow} = linkRef;
    if (url) {
      if (!url.startsWith('/') && !url.startsWith('http')) {
        url = 'https://' + url;
      }
      if (url.startsWith('/')) {
        url = getUrl(url, page)!;
      }
    } else {
      const linkContent = page.getContent<Document>(link) as any;
      //url = linkContent?.links?.site?.href;
      url = linkContent?.getUrl();
    }
    return {
      url,
      label,
      openInNewWindow,
      isExternal: !!url
    };
  });
};

export const getResourceLink = (resource: any): CmsLink => {
  return resource && resource.url && {
    label: 'Download resource',
    url: resource.url,
    openInNewWindow: true
  };
};

export const formatMessage = (message: string, args: string[]): string => {
  args.forEach((value: string, index: number) => {
    const pattern = `{${index}}`;
    message = message.replace(pattern, value);
  });
  return message;
};

export const trimToWhitespace = (theString: string, maxLength: number) => {
  if (theString.length < maxLength) {
    return theString;
  }
  let trimmedString = theString.substring(0, 120);
  const lastWS = trimmedString.lastIndexOf(' ');
  trimmedString = trimmedString.substring(0, lastWS);
  return `${trimmedString} ...`;
};
