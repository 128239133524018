/*
 * Copyright 2019 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {ApolloProvider} from '@apollo/client';
import {Page} from '@bloomreach/spa-sdk';
import {commerceApiClient, connectorVar} from '../modules/commerce/api';
import {getDefaultConnector} from './utils';

export interface BrPageProps {
  page: Page;
}

export function withApolloClient<P>(Component: React.ComponentType<P>) {
  return (props: P & BrPageProps) => {
    const defaultConnector = getDefaultConnector(props.page);
    const connector = sessionStorage.getItem('default-connector') ?? defaultConnector;
    connectorVar(connector);
    return (
      <ApolloProvider client={commerceApiClient}>
        <Component {...props} />
      </ApolloProvider>
    );
  };
}
