/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useHistory} from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {INSPECTOR_PARAM_NAME} from '../../utility';
import {updateUrlParameter} from '../../../utils/UrlUtils';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export const AppInspectorWidget = () => {
  const history = useHistory();

  const toggleInspector = () => {
    history.push(updateUrlParameter( 'true', INSPECTOR_PARAM_NAME, true));
  }

  const icon = [`fas`, `lightbulb`] as IconProp;
  return (
    <div onClick={toggleInspector}>
      <FontAwesomeIcon className='d-inline d-md-none mr-0 mr-md-1' size='lg'
                       icon={icon}/>
      <FontAwesomeIcon className='d-none d-md-inline mr-0 mr-md-1' size='sm'
                       icon={icon}/>
      <span className='d-none d-md-inline font-size-sm'>Inspector</span>
    </div>
  )
}
