/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {ArticleCard} from '../../article/templates';
import {ComponentHeader, FacetList, ListPagination, Sorter} from '../../utility';
import {FacetHider} from '../../utility/facet/templates';
import {ArticleGridProps} from '../../article/ArticleComponentTypes';

const config = require('../ArticleGridSearchComponentConfig.json');
const ArticleGrid = (props: ArticleGridProps) => {
  const {
    title,
    articles,
    pagination,
    facets,
    showPagination,
    showFacets,
    showTitle,
    facetsLocation,
  } = props;

  const facetsOnRight = facetsLocation && facetsLocation === 'right';

  const options = config.sorting;
  const itemsPerRow = config.itemsPerRow;

  const [showFacetList, setShowFacetList] = useState(showFacets);

  return (
    <Fragment>
      <ComponentHeader {...{showTitle, title}}/>
      <Container className='py-5 article-grid'>
        <Row>
          <FacetHider showFacetList={showFacetList} setShowFacetList={setShowFacetList}/>
          <Col sm md xl={showFacetList ? 9 : 12}>
            {showPagination && pagination && pagination.endOffset &&
            <Sorter {...{
              options,
              itemsPerRow,
              numberOfItems: articles.length,
              pagination,
              itemLabel: 'article',
              itemsLabel: 'articles',
              queryType: 'content',
              showFacetList,
              setShowFacetList
            }}/>}
          </Col>
        </Row>
        <Row>
          {showFacetList && !facetsOnRight && <Col xs={12} md={3}>
            <FacetList {...{
              ...facets,
              isContent: true,
              config: config.facet
            }}/>
          </Col>}
          <Col xs={12} md={showFacetList ? 9 : 12}>
            <Container className={'py-5'}>
              <Row>
                {articles?.map((article, key: number) => (
                  <Col xs={12} lg={6} className={'mb-2'} key={key}>
                    <ArticleCard {...article}/>
                  </Col>
                ))}
              </Row>
            </Container>
            <Container>
              <Col className={'text-center font-size-xs'}>
                {showPagination && <ListPagination {...pagination}/>}
              </Col>
            </Container>
          </Col>

          {showFacetList && facetsOnRight && <Col xs={12} md={3}>
            <FacetList {...{
              ...facets,
              isContent: true,
              config: config.facet
            }}/>
          </Col>}
        </Row>
      </Container>
    </Fragment>
  );
};

export default ArticleGrid;
