/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, ButtonGroup, Dropdown, DropdownButton} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import {Icon} from '../icon/Icon';
import {SorterOption} from './SorterTypes';
import {ListPaginationProps} from '../list-pagination/ListPaginationTypes';
import {getMessage, getMessageTemplate} from '../../../utils/MessageUtils';
import {BrPageContext} from '@bloomreach/react-sdk';
import {Document} from '@bloomreach/spa-sdk';

const config = require('../../../utils/SearchConfig.json');

export interface SorterProps {
  options: Array<SorterOption>,
  itemsPerRow: number,
  itemsPerRowOptions?: Array<SorterOption>,
  numberOfItems: number,
  pagination?: ListPaginationProps | null,
  itemsLabel: string,
  queryType?: string,
  showFacetList?: boolean,
  setShowFacetList?: React.Dispatch<React.SetStateAction<boolean>>,
  noSorter?: boolean,
  showGroupBy?: boolean,
  groupBy?: string
}

const sorterConfig = require('./SorterConfig.json');
export const Sorter = (props: SorterProps) => {
  let {
    options,
    itemsPerRow,
    itemsPerRowOptions,
    numberOfItems,
    pagination,
    itemsLabel,
    queryType = 'default',
    showFacetList,
    setShowFacetList,
    noSorter = false,
    showGroupBy = false,
    groupBy: groupByParam
  } = props;

  const page = useContext(BrPageContext)!;
  const queryParameterNames = config.queryParameters[queryType];

  const history = useHistory();
  const queryParameters = new URLSearchParams(window.location.search);

  const matchedSortBy = options.find((element: SorterOption) => element.value === queryParameters.get(queryParameterNames.sort));

  const handleShowHideFacetsClick = () => {
    setShowFacetList && setShowFacetList(!showFacetList);
  };

  const handleSorterUpdates = () => {
    const updatedLocationSearch = `?${queryParameters}`;
    let updatedUrl = window.location.pathname + updatedLocationSearch!;
    // TODO: for empty location search, without ? doesn't seem to update the history???
    if (!updatedLocationSearch) {
      updatedUrl += '?';
    }
    history.push(updatedUrl);
  }

  const handleSortChange = (item: SorterOption) => {
    const matchedSortBy = options.find((element: SorterOption) => element.value === item.value);
    if (matchedSortBy?.value) {
      queryParameters.set(queryParameterNames.sort, matchedSortBy?.value!);
    }
    handleSorterUpdates();
  }

  if (itemsPerRowOptions) {
    itemsPerRow = 1;
  }

  const pageSizeOptions: Array<SorterOption> = itemsPerRowOptions || [1, 2, 3, 4, 5, 6].map((value: number) => ({
    'key': 'size-' + value,
    'text': (value * itemsPerRow).toString(),
    'value': (value * itemsPerRow).toString()
  }));

  const handlePageSizeChange = (item: SorterOption) => {
    const parameterName = queryParameterNames.pageSize;
    if (item.value === '0') {
      queryParameters.delete(parameterName);
    } else {
      queryParameters.set(parameterName, item.value);
    }
    // Set pageNumber to 1
    queryParameters.set(queryParameterNames.pageNumber, '1');

    handleSorterUpdates();
  }

  const document = page.getDocument<Document>();
  const {reference}: any = document?.getData() ?? {};

  const resourceBundle = page.getContent(reference);

  let groupByOptions: Array<SorterOption> = sorterConfig.groupByOptions;

  if (resourceBundle) {
    const resourceBundleData = resourceBundle.getData();
    groupByOptions = resourceBundleData?.keys?.map((key: any, index: number) => ({
      key,
      text: resourceBundleData?.messages?.[index],
      value: key
    }));
  }

  const handleGroupByChange = (item: SorterOption) => {
    const parameterName = queryParameterNames.groupBy;
    if (!item.value) {
      queryParameters.delete(parameterName);
    } else {
      queryParameters.set(parameterName, item.value);
    }

    handleSorterUpdates();
  }

  const groupBy = groupByParam ?? queryParameters.get(queryParameterNames.groupBy);
  const groupByLabel = groupBy && groupByOptions.find(groupByOption => groupByOption?.value === groupBy)?.text;

  return (
    <h4 className='header'>
      {pagination && <span>{getMessageTemplate(
        sorterConfig,
        'show-x-y-of-z',
        pagination.endOffset - numberOfItems + 1,
        pagination.endOffset,
        pagination.total
      )} {getMessage(sorterConfig, itemsLabel)}</span>}

      {!noSorter && <ButtonGroup className='float-right sm mr-2'>
        <DropdownButton as={ButtonGroup}
                        title={getMessage(sorterConfig, (matchedSortBy && matchedSortBy.text) || 'Sort By')}
                        id='bg-sort'
                        className='sm pull-right' variant='light'>
          {options.map((item, key: number) => (
            <Dropdown.Item
              key={key}
              name={item.text}
              value={item.text}
              active={queryParameters.get(queryParameterNames.sort) === item.value}
              onClick={(e) => handleSortChange(item)}
              trigger={<React.Fragment/>}
              className='pl-2 pr-0'>
              <FontAwesomeIcon className='ml-0 mr-2 pl-0' icon={[`fas`, item.icon as IconName]}/>
              {getMessage(sorterConfig, item.text)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </ButtonGroup>}

      {!noSorter && pagination && <ButtonGroup className='float-right sm mr-2 d-none d-lg-block'>
        <DropdownButton
          title={`${getMessage(sorterConfig, 'Size')}: ${pagination?.pageSize}` || getMessage(sorterConfig, 'Page Size')}
          id='bg-pagination'
          className='sm pull-right' variant='light'>
          {pageSizeOptions.map((item, key: number) => (
            <Dropdown.Item
              key={key}
              name={item.text}
              value={item.text}
              defaultValue={pagination && pagination.pageSize}
              active={pagination?.pageSize === parseInt(item.value)}
              onClick={(e) => handlePageSizeChange(item)}
              trigger={<React.Fragment/>}>
              {item.text}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </ButtonGroup>}

      {!noSorter && showGroupBy && <ButtonGroup className='float-right sm mr-2 d-none d-lg-block'>
        <DropdownButton
          title={`${getMessage(sorterConfig, 'Group By')}${groupByLabel ? ' : ' + groupByLabel : ''}`}
          id='bg-groupby'
          className='sm pull-right' variant='light'>
          {groupByOptions.map((item, key: number) => (
            <Dropdown.Item
              key={key}
              name={item.text}
              value={item.text}
              defaultValue={queryParameters.get(queryParameterNames.groupBy) ?? ''}
              active={queryParameters.get(queryParameterNames.groupBy) === item.value}
              onClick={(e) => handleGroupByChange(item)}
              trigger={<React.Fragment/>}>
              {item.text}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </ButtonGroup>}

      {!noSorter && !showFacetList && <ButtonGroup className='float-right sm mr-2'>
        <Button className='sm pull-right' variant='light' onClick={handleShowHideFacetsClick}>
          <Fragment>
            {getMessage(sorterConfig, 'show')} <Icon name={'chevron-down'}/>
          </Fragment>
        </Button>
      </ButtonGroup>}
    </h4>
  )
}
