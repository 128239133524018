// Model for Brsm API response
export interface BrProduct {
  sale_price: number;
  price: number;
  sale_price_range: number[];
  price_range: number[];
  description: string;
  title: string;
  pid: string;
  thumb_image: string;
  variants: BrProductVariant[];
  sku_color?: string;
  sku_price?: string[];
  sku_sale_price?: string[];
  sku_size?: string;
}

export interface BrProductVariant {
  skuid?: string[] | string;
  sku_swatch_images?: string[];
  sku_thumb_images?: string[];
  mainItem: BrProduct;
  sku_color?: string;
  sku_price?: string[];
  sku_sale_price?: string[];
  sku_size?: string;
}

export interface FacetFieldValueNode {
  count: number;
  name?: string;
  cat_id?: string;
  cat_name?: string;
  parent?: string;
  crumb?: string;
}

export interface FacetFieldNode {
  key: string;
  value: FacetFieldValueNode[];
}

export interface FacetFieldsNode {
  [key: string]: FacetFieldValueNode[];
}

export interface FacetCountsNode {
  facet_fields?: FacetFieldsNode;
}

export interface FacetsNode {
  fields?: FacetFieldNode[];
}

export interface FacetFieldValue {
  id: string;
  parentId?: string;
  name: string;
  count: number;
}

export interface FacetField {
  id: string;
  name: string;
  values: FacetFieldValue[];
}

export interface FacetResult {
  fields: FacetField[];
}

// Model for rendition
export interface Dimension {
  w: number;
  h: number;
}

export interface BaseType {
  __typename: string;
}

export interface ItemId extends BaseType {
  id: string;
  code?: string;
}

const ITEM_ID = /([\w\d._=-]+[\w\d=]?)?___([\w\d._=/-]+[\w\d=]?)?/i;

export class ItemId implements ItemId {
  constructor(value: string) {
    [, this.id, this.code] = ITEM_ID.exec(value) ?? ['', value];
    this.id = this.id?.trim();
    this.code = this.code?.trim();
  }
}

export interface Link extends BaseType {
  href?: string;
  ref?: string;
}

export interface Image extends BaseType {
  dimension?: Dimension;
  link: Link;
}

export class Image implements Image {
  constructor(imageUrl?: string) {
    const linkModel = {href: imageUrl, __typename: 'Link'};
    this.link = linkModel;
  }
}

export interface ImageSet extends BaseType {
  original?: Image;
}

export class ImageSet implements ImageSet {
  constructor(imageUrl?: string) {
    const imageModel = new Image(imageUrl);
    this.original = imageModel;
  }
}

export interface MoneyAmount extends BaseType {
  currency?: string;
  amount: number;
}

export class MoneyAmount implements MoneyAmount {
  constructor(public amount = 0) {
    this.__typename = 'MoneyAmount';
  }

  get displayValue(): string {
    return `${this.amount}`;
  }
}

export interface Price extends BaseType {
  moneyAmounts?: MoneyAmount[];
}

export interface AttributeType {
  name: string;
}

export interface Attribute extends BaseType {
  name: string;
  values?: string[];
}

export interface ItemLike {
  itemId: ItemId;
  displayName: string;
  description: string;
  imageSet: ImageSet;
  listPrice: Price;
  purchasePrice: Price;
  color?: string;
  size?: string;
  customAttrs?: Attribute[];
  varAttrs?: Attribute[];
}

export interface ItemVariant extends ItemLike, BaseType {
  master?: boolean;
  variants?: ItemVariant[];
}

export interface Item extends ItemLike, BaseType {
  varAttrTypes: [AttributeType] | null;
  variants: ItemVariant[];
  salePriceRange?: number[];
  priceRange?: number[];
}

export interface RedirectHint {
  url?: string;
  query?: string;
  newQuery?: string;
}

export interface QueryHint {
  cursor?: string;
  autoCorrectQuery?: string;
  autoCorrectQuerySet?: string[];
  redirectHint?: RedirectHint;
}

export interface TermSuggestionV2 {
  query: string;
}

export interface SuggestionGroupsV2 {
  querySuggestions?: TermSuggestionV2[];
  searchSuggestions?: BrProduct[];
}

export const sortFieldsMapping: Record<string, string> = {
  '-purchasePrice': 'price desc',
  'purchasePrice': 'price asc',
  '-displayName': 'title desc',
  'displayName': 'title asc',
}

const customVariantPurchasePriceField = 'sku_sale_price';
const customVariantListPriceField = 'sku_price';

export const statsField = 'sale_price';

export const getVariantProductCode = (variant: BrProductVariant): string | undefined => {
  const variantId = Array.isArray(variant?.skuid) ? variant?.skuid[0] : variant?.skuid;
  return variantId || variant?.sku_swatch_images?.[0];
}

export const isItem = (parent: BrProduct | BrProductVariant): parent is BrProduct => {
  return 'pid' in parent;
}

export const getItemColor = (
  parent: BrProduct | BrProductVariant,
): string | undefined => {
  return parent?.sku_color ?? getVariants(parent as BrProduct)?.[0]?.sku_color ?? '';
};

export const getItemSize = (
  parent: BrProduct | BrProductVariant,
): string | undefined => {
  return parent?.sku_size ?? getVariants(parent as BrProduct)?.[0]?.sku_size ?? '';
};

export const getItemId = (parent: BrProduct | BrProductVariant): ItemId => {
  if (isItem(parent)) {
    const id = parent.pid;
    const code = getVariantProductCode(parent.variants?.[0]) ?? id;
    return {
      __typename: 'ItemId',
      id,
      code,
    };
  }

  return {
    __typename: 'ItemId',
    id: parent.mainItem.pid,
    code: getVariantProductCode(parent),
  };
}

export const getImageSet = (parent: BrProduct | BrProductVariant): ImageSet => {
  return isItem(parent) ? new ImageSet(parent.thumb_image) : new ImageSet(parent.sku_thumb_images?.[0] ?? parent.sku_swatch_images?.[0]);
}

export const getDisplayName = (parent: BrProduct | BrProductVariant): string => {
  return isItem(parent) ? parent.title : parent.mainItem.title;
}

export const getDescription = (parent: BrProduct | BrProductVariant): string => {
  return isItem(parent) ? parent.description : parent.mainItem.description;
}

export const getListPrice = (parent: BrProduct | BrProductVariant): Price => {
  const moneyAmounts: MoneyAmount[] = [];

  if (isItem(parent)) {
    const {price} = parent;
    moneyAmounts.push(new MoneyAmount(price));
  } else if (customVariantListPriceField) {
    const amount = getCustomVariantPriceFieldValue(parent, customVariantListPriceField);
    if (amount) {
      moneyAmounts.push(new MoneyAmount(amount));
    }
  }

  return {
    __typename: 'Price',
    moneyAmounts,
  };
}

export const getVariants = (parent: BrProduct): BrProductVariant[] => {
  return (parent?.variants ?? []).map((variant) => ({
    ...variant,
    mainItem: parent,
  }));
}

export const getPurchasePrice = (parent: BrProduct | BrProductVariant): Price => {
  const moneyAmounts: MoneyAmount[] = [];

  if (isItem(parent)) {
    const {sale_price} = parent;
    moneyAmounts.push(new MoneyAmount(sale_price));
  } else if (customVariantPurchasePriceField) {
    const amount = getCustomVariantPriceFieldValue(parent, customVariantPurchasePriceField);
    if (amount) {
      moneyAmounts.push(new MoneyAmount(amount));
    }
  }

  return {
    __typename: 'Price',
    moneyAmounts,
  };
}

export const getCustomVariantPriceFieldValue = (variant: any, fieldName: string): number | undefined => {
  const fieldData = variant[fieldName];
  const fieldValue = Array.isArray(fieldData) && fieldData.length ? fieldData[0] : fieldData;
  const amount = parseFloat(`${fieldValue}`);
  return !Number.isNaN(amount) ? amount : undefined;
}

export const getItemLike = (item: BrProduct | BrProductVariant): ItemLike => ({
  itemId: getItemId(item),
  displayName: getDisplayName(item),
  description: getDescription(item),
  imageSet: getImageSet(item),
  listPrice: getListPrice(item),
  purchasePrice: getPurchasePrice(item),
  varAttrs: [
    {name: 'sku_color', values: getItemColor(item)} as Attribute,
    {name: 'sku_size', values: getItemSize(item)} as Attribute
  ],
})

export const getItemVariant = (item: BrProductVariant): ItemVariant => ({
  __typename: 'ItemVariant',
  ...getItemLike(item),
})

export const getItem = (item: BrProduct): Item => ({
  __typename: 'Item',
  ...getItemLike(item),
  variants: (getVariants(item) ?? []).map((variant) => getItemVariant(variant)),
  varAttrTypes: null,
  salePriceRange: [],
  customAttrs: []
})

export const createFacetResult = ({facet_fields}: FacetCountsNode/*, { fields }: FacetsNode*/): FacetField[] => {
  const facetFields: FacetField[] = [];

  if (facet_fields) {
    Object.keys(facet_fields).forEach((id) => {
      const facetFieldValueNode = facet_fields[id];
      if (facetFieldValueNode.length > 0) {
        const values = facetFieldValueNode.map((node) => createFacetFieldValue(node));
        facetFields.push({id, name: id, values});
      }
    });
  } /*else if (fields) {
    fields.forEach((field) => {
      const fieldName = field.key;
      const fieldId = fieldName;
      if (field.value.length > 0) {
        const values = field.value.map((node) => createFacetFieldValue(node));
        facetFields.push({ id: fieldId, name: fieldName, values });
      }
    });
  }*/

  return facetFields;
}

export const createFacetFieldValue = (node: FacetFieldValueNode): FacetFieldValue => {
  const {count, cat_id, cat_name, parent, name} = node;
  return {
    id: cat_id ?? name ?? '',
    parentId: parent,
    name: cat_name ?? name ?? '',
    count,
  };
}

export const getImageSetUrl = (imageSet: ImageSet | undefined): string | undefined => {
  return imageSet?.original?.link?.href;
}

export const DEFAULT_UID_COOKIE = '7797686432023:v=11.5:ts=1428617911187:hc=55';

export const DEFAULT_BRSM_FIELDS = process.env.REACT_APP_BRSM_FIELDS || 'pid,title,brand,price,sale_price,promotions,thumb_image,sku_thumb_images,sku_swatch_images,sku_color_group,url,price_range,sale_price_range,description,is_live,score,skuid'

