/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from './address';
import { MONEY_AMOUNT_FRAGMENT, ITEM_LIKE_FRAGMENT } from './items';

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    draft
    status
    paymentState
    shipmentState
    creationDate
    shipMethod
    paymentMethodId
    shippingAddress {
      ...AddressFragment
    }
    billingAddress {
      ...AddressFragment
    }
    totalAmount {
      ...MoneyAmountFragment
    }
    shipAmount {
      ...MoneyAmountFragment
    }
    taxAmount {
      ...MoneyAmountFragment
    }
    orderItems {
      id
      type
      status
      quantity
      unitPrice {
        moneyAmounts {
          ...MoneyAmountFragment
        }
      }
      totalPrice {
        moneyAmounts {
          ...MoneyAmountFragment
        }
      }
      item {
        ...ItemLikeFragment
      }
    }
  }

  ${ADDRESS_FRAGMENT}
  ${MONEY_AMOUNT_FRAGMENT}
  ${ITEM_LIKE_FRAGMENT}
`;

export const FIND_ORDERS = gql`
  query Orders($offset: Int, $limit: Int, $sortFields: String) {
    findOrders(offset: $offset, limit: $limit, sortFields: $sortFields) {
      offset
      limit
      count
      total
      orders {
        ...OrderFragment
      }
    }
  }

  ${ORDER_FRAGMENT}
`;

export const GET_ORDER_BY_ID = gql`
  query Order($id: String!) {
    getOrderById(id: $id) {
      ...OrderFragment
    }
  }

  ${ORDER_FRAGMENT}
`;

export const GET_ORDER_SHIPMENT_METHODS = gql`
  query OrderShipmentMethods($id: String!) {
    getOrderShipmentMethods(id: $id) {
      id
      name
      description
      cost {
        moneyAmounts {
          ...MoneyAmountFragment
        }
      }
    }
  }

  ${MONEY_AMOUNT_FRAGMENT}
`;
