/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment} from 'react';
import {AuthorByLineProps} from '../AuthorByLineComponentTypes';
import {Link} from 'react-router-dom';
import {getDateString} from '../../../../utils/DateUtils';
import {getImageVariantBySize} from '../../../../utils/ImageUtil';
import {ImageSizeEnum} from '../../../global/CommonEnums';

const AuthorByLineList = ({authors, date}: AuthorByLineProps) => {
  return (
    <Fragment>
      <div className='media-body d-flex justify-content-start authorbyline-list'>
        {authors?.map((author, key) => {
            const {name, link, images} = author;
            const image = getImageVariantBySize(images, ImageSizeEnum.Thumbnail);
            return image ? <div className='u-sm-avatar u-sm-avatar--bordered rounded-circle ml-n2'
                                data-toggle='tooltip' data-placement='top' title={name}
                                key={'author-' + key}>
                <Link to={link}><img className='img-fluid rounded-circle' src={image}
                                     alt={`${name}`}/></Link>
              </div>
              :
              <Fragment key={'author-' + key}>
                <small className='d-block text-muted text-uppercase'>Written by</small>
                <small className='text-muted ml-2'>
                  <Link to={link}>{name}</Link>
                </small>
              </Fragment>
          }
        )}
      </div>
      <div className='media-body d-flex justify-content-end text-muted font-size-1 ml-2 authorbyline-list'>
        {getDateString(date)}
      </div>
    </Fragment>
  )
};

export default AuthorByLineList;
