/*
 * Copyright 2020 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect, useState} from 'react';
import {Card, Col, Collapse, Dropdown, NavLink, Row} from 'react-bootstrap';
import {BrPageContext} from '@bloomreach/react-sdk';
import {useHistory} from 'react-router';
import {AddToCartForm} from '../../cart/action/AddToCart';
import {ProductPrice} from './ProductPrice';
import {pushGtmEventWidgetClick} from '../../gtm/GTMComponentUtils';
import {SecuredComponentWrapper} from '../../utility';
import {ItemFragment_itemId} from '../../../modules/commerce';
import {ProductProps} from '../ProductComponentTypes';
import {UserContext} from '../../../contexts';
import {PromotionProps} from '../../promotion/PromotionComponentTypes';
import {PromotionComponent} from '../../promotion/PromotionComponent';
import {getProductUrl} from '../ProductComponentUtils';
import {ProductCardProps} from './ProductCard';
import listNumber1 from '../../../assets/img/list-number-1.svg';
import listNumber2 from '../../../assets/img/list-number-2.svg';
import listNumber3 from '../../../assets/img/list-number-3.svg';
import listNumber4 from '../../../assets/img/list-number-4.svg';
import listNumber5 from '../../../assets/img/list-number-5.svg';
import {getImageSetUrl, ItemVariant} from '../../../demo-connector-components/DemoBrsmUtils';

interface ProductListItemProps {
  index: number
}

export const ProductListItem = (props: ProductProps & ProductCardProps & ProductListItemProps) => {
  const page = useContext(BrPageContext)!;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  const ListNumbers = [
    listNumber1,
    listNumber2,
    listNumber3,
    listNumber4,
    listNumber5
  ]

  const {
    index,
    itemId,
    displayName,
    listPrice,
    purchasePrice,
    imageSet,
    variants,
    widgetProps
  } = props;

  const [visible, setVisible] = useState(false);
  const [variant, setVariant] = useState<ItemVariant>({
    __typename: 'ItemVariant',
    customAttrs: [],
    master: false,
    varAttrs: undefined,
    itemId,
    displayName,
    listPrice,
    purchasePrice,
    imageSet,
    description: ''
  });

  const [promotionProps, ] = useState<PromotionProps | null>(null);

  const history = useHistory();

  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);

  useEffect(() => {
    setVariant({
      __typename: 'ItemVariant',
      customAttrs: [],
      master: false,
      varAttrs: undefined,
      itemId,
      displayName,
      listPrice,
      purchasePrice,
      imageSet,
      description: ''
    });
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
  useEffect(() => {
    console.log('Fetching decorator document', itemId.id, itemId.code);
    getProductPromotions(page, setPromotionProps, itemId.id, itemId.code);
  }, [itemId.id, itemId.code]); // eslint-disable-line react-hooks/exhaustive-deps
  */

  const {
    itemId: variantItemId,
    displayName: variantDisplayName,
    listPrice: variantListPrice,
    //purchasePrice: variantPurchasePrice,
    imageSet: variantImageSet
  } = variant;

  const addToCartParams = {
    itemName: variantDisplayName,
    itemId: {
      id: variantItemId.id,
      code: variantItemId.code ?? variantItemId.id
    } as ItemFragment_itemId,
    itemKey: '',
    quantity: 1,
    mode: 'button',
    itemPrice: listPrice, // added to params; need to send to Exponea
    itemTitle: displayName, // added to params; need to send to Exponea
    widgetProps
  };

  const variantCount = variants?.filter((item) => item.itemId.code !== itemId.code).length || 0;

  let hasPromotion: boolean = false;
  if (promotionProps) {
    const {images, videos} = promotionProps;
    if (images?.length && videos?.length) {
      hasPromotion = true;
      promotionProps.template = 'CARD';
      const imageUrl = getImageSetUrl(variantImageSet);
      if (imageUrl) {
        if (!images.some(image => image.media === imageUrl)) {
          images.push({
            type: 'IMAGE',
            media: imageUrl
          });
        }
      }
    }
  }

  const backgroundImgStyle = {
    backgroundImage: 'url(' + ListNumbers?.[index] + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center'
  };

  const productUrl = getProductUrl(page, variantItemId.id, variantItemId.code, userSegment)
  return (
    <Row className={'my-2'}>
      <Col sm={12} md={3}>
        <div style={backgroundImgStyle} className={'h-80'}/>
      </Col>
      <Col sm={12} md={6}>
        <Card className='h-100 product-card border-0' onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
          <Card.Body className={`text-left`}>
            <Card.Text>
              <NavLink className='d-inline p-0 h4' onClick={() => {
                pushGtmEventWidgetClick(widgetProps, variantItemId.id);
                history.push(productUrl);
              }}>{variantDisplayName}</NavLink>
            </Card.Text>
            {variantListPrice &&
            <Card.Subtitle>
              <ProductPrice
                price={(Object.keys(variantListPrice ?? {}).length === 0 ? listPrice : variantListPrice)!}/>
            </Card.Subtitle>}
            {variantCount === 0 && variantItemId.code &&
            <Card.Text><small>SKU: {variantItemId.code}</small></Card.Text>}
            {variantCount > 0 && <Dropdown>
              <Dropdown.Toggle variant='link' className={'pl-0'}>
                <small>SKU: {variantItemId.code}</small>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {variants?.map((item, key: number) => (
                  <Dropdown.Item
                    key={key}
                    active={item.itemId === variant.itemId && item.itemId.code === variant.itemId.code}
                    onClick={() => {
                      setVariant(item);
                    }}>
                    <i className={'icon'}
                       style={{backgroundImage: 'url(' + getImageSetUrl(item.imageSet) + ')',}}/> {item.itemId.code}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>}
          </Card.Body>
          <SecuredComponentWrapper showMessage={false}>
            <Collapse in={visible}>
              <Card.Footer className={'text-center'}>
                <AddToCartForm {...addToCartParams} page={page}/>
              </Card.Footer>
            </Collapse>
          </SecuredComponentWrapper>
        </Card>
      </Col>
      <Col sm={12} md={3}>
        <Card className='shadow h-100 product-card'>
          {hasPromotion ?
            <div className={'card-img-top'}>
              <PromotionComponent {...{...promotionProps}}/>
            </div>
            : variantImageSet &&
            <div className={'card-img-top img-fluid'} style={{
              backgroundImage: 'url(' + getImageSetUrl(variantImageSet) + ')',
            }}/>
          }
        </Card>
      </Col>
    </Row>
  );
};
