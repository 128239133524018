/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useEffect} from 'react';
import {CartStageEnum} from '../../global/CommonEnums';
import {Button} from 'react-bootstrap';
import {Icon, Status} from '../../utility';
import {GetErrorProps, PlaceOrderProps, withGetError} from '../../../connector-components';
import {withDemoPlaceOrder} from '../../../demo-connector-components';
import {saveOrderToSession} from '../OrderComponentUtils';
import {StageInputProps} from '../../cart/CartComponentTypes';

function PlaceOrderBase(props: GetErrorProps & PlaceOrderProps & StageInputProps) {
  const {
    setStage,
    loading,
    placeOrder,
    result,
    errors,
    clearError,
  } = props;
  const DEFAULT_MESSAGE = 'Place order';

  useEffect(() => {
    if (result) {
      saveOrderToSession(result?.order);
      setStage(CartStageEnum.FINISH);
    }
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {errors?.map((error, key: number) => <Status key={key} onClose={clearError} container status={error?.message}
                                                   dismissible customClassName={'mt-3'} error/>)}
      <Button className={'float-right'} variant={'primary'}
              onClick={() => {
                clearError();
                placeOrder();
              }}>
        {loading ? <Icon name={'spinner'} size={'lg'} spin content={DEFAULT_MESSAGE}/>
          : <Icon name={'shopping-bag'} content={DEFAULT_MESSAGE}/>}
      </Button>
    </Fragment>
  )
}

export const PlaceOrder = withGetError(withDemoPlaceOrder(PlaceOrderBase));
