/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  CHANGE_PASSWORD,
  ChangeCurrentCustomerPassword,
  ChangeCurrentCustomerPassword_changeCurrentCustomerPassword,
  ChangeCurrentCustomerPasswordVariables,
} from '../../modules/commerce';
import {withApolloClient} from '../../connector-components';

export interface ChangePasswordFormProps {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  newPassword: string;
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
}

export interface ChangePasswordProps {
  changePasswordForm: ChangePasswordFormProps;
  changePassword: () => Promise<FetchResult<ChangeCurrentCustomerPassword>>;
  loading: boolean;
  result?: ChangeCurrentCustomerPassword_changeCurrentCustomerPassword;
}

function withChangePasswordBase<P>(Component: React.ComponentType<P & ChangePasswordProps>) {
  return (props: P) => {
    const [password, setPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');

    const [changePassword, {data, loading}] = useMutation<ChangeCurrentCustomerPassword,
      ChangeCurrentCustomerPasswordVariables>(CHANGE_PASSWORD, {
      // TODO: This option is needs to make sure result can be passed down to child component
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          password,
          newPassword,
        },
      },
    });

    return (
      <Component
        changePasswordForm={{
          password,
          setPassword,
          newPassword,
          setNewPassword,
        }}
        changePassword={changePassword}
        loading={loading}
        result={data?.changeCurrentCustomerPassword}
        {...props}
      />
    );
  };
}

export function withDemoChangePassword<P>(Component: React.ComponentType<P & ChangePasswordProps>) {
  return withApolloClient(withChangePasswordBase(Component));
}
