/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect, useState} from 'react';
import {Card, Collapse, Dropdown, NavLink} from 'react-bootstrap';
import {BrPageContext} from '@bloomreach/react-sdk';
import {useHistory} from 'react-router';
import {AddToCartForm} from '../../cart/action/AddToCart';
import {ProductPrice} from './ProductPrice';
import {pushGtmEventWidgetClick} from '../../gtm/GTMComponentUtils';
import {SecuredComponentWrapper} from '../../utility';
import {ItemFragment_itemId} from '../../../modules/commerce';
import {ProductProps, WidgetProps} from '../ProductComponentTypes';
import {PreferenceContext, UserContext} from '../../../contexts';
import {PromotionProps} from '../../promotion/PromotionComponentTypes';
import {PromotionComponent} from '../../promotion/PromotionComponent';
import {getProductUrl, getSku} from '../ProductComponentUtils';
import {getImageSetUrl, ItemVariant} from '../../../demo-connector-components/DemoBrsmUtils';

export interface ProductCardProps {
  widgetProps?: WidgetProps,
  hideSku?: boolean
}

export const ProductCard = (props: ProductProps & ProductCardProps) => {
  const page = useContext(BrPageContext)!;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;
  const {preference} = useContext(PreferenceContext)!;

  const {
    itemId,
    displayName,
    listPrice,
    purchasePrice,
    imageSet,
    variants,
    widgetProps,
    hideSku
  } = props;

  const initVariant = () => variants?.[0] ?? {
    __typename: 'ItemVariant',
    customAttrs: [],
    imageSet,
    master: false,
    varAttrs: undefined,
    itemId,
    displayName,
    listPrice,
    purchasePrice,
    description: ''
  };

  const [visible, setVisible] = useState(false);
  const [variant, setVariant] = useState<ItemVariant>(initVariant);

  const [promotionProps,] = useState<PromotionProps | null>(null);

  const history = useHistory();

  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);

  useEffect(() => {
    setVariant(initVariant());
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
  useEffect(() => {
    console.log('Fetching decorator document', itemId.id, itemId.code);
    getProductPromotions(page, setPromotionProps, itemId.id, itemId.code);
  }, [itemId.id, itemId.code]); // eslint-disable-line react-hooks/exhaustive-deps
  */

  const {
    itemId: variantItemId,
    displayName: variantDisplayName,
    listPrice: variantListPrice,
    //purchasePrice: variantPurchasePrice,
    imageSet: variantImageSet,
  } = variant;

  const addToCartParams = {
    itemName: variantDisplayName,
    itemId: {
      id: variantItemId.id,
      code: variantItemId.code ?? variantItemId.id
    } as ItemFragment_itemId,
    itemKey: '',
    quantity: 1,
    itemPrice: listPrice, // added to params; need to send to Exponea
    itemTitle: displayName, // added to params; need to send to Exponea
    mode: 'button',
    widgetProps
  };

  const variantCount = variants?.filter((item) => item.itemId.code !== itemId.code).length || 0;

  let hasPromotion: boolean = false;
  if (promotionProps) {
    const {images, videos} = promotionProps;
    if (images?.length && videos?.length) {
      hasPromotion = true;
      promotionProps.template = 'CARD';
      const imageUrl = getImageSetUrl(variantImageSet);
      if (imageUrl) {
        if (!images.some(image => image.media === imageUrl)) {
          images.push({
            type: 'IMAGE',
            media: imageUrl
          });
        }
      }
    }
  }

  const productUrl = getProductUrl(page, variantItemId.id, variantItemId.code, userSegment)
  const linkOption = preference.enableHeatmap ? {href: `https://pacifichome.bloomreach.com${productUrl}`} : {};

  return (
    <Card className='shadow h-100 product-card' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {hasPromotion ?
        <div className={'card-img-top'}>
          <PromotionComponent {...{...promotionProps}}/>
        </div>
        :
        <div className={'card-img-top img-fluid'} style={{
          backgroundImage: `url(${getImageSetUrl(variantImageSet)})`,
        }}/>
      }
      <Card.Body className={`text-center d-flex flex-column`}>
        <Card.Text>
          <NavLink onClick={() => {
            pushGtmEventWidgetClick(widgetProps, variantItemId.id);
            history.push(productUrl);
          }} className={'product-pdp-link'} {...linkOption}>
            {variantDisplayName}
          </NavLink>
        </Card.Text>
        {(variantListPrice || listPrice) &&
          <Card.Subtitle className={'mt-auto'}>
            <ProductPrice
              price={(Object.keys(variantListPrice ?? {}).length === 0 ? listPrice : variantListPrice)!}
              /*price={(Object.keys(variantPurchasePrice ?? {}).length === 0 ? purchasePrice : variantPurchasePrice)!}*/
              /*listPrice={Object.keys(variantListPrice).length == 0 ? listPrice : variantListPrice}*//>
          </Card.Subtitle>}
        {!hideSku && variantCount === 0 && variantItemId.code && <Dropdown>
          <Dropdown.Toggle variant='link' disabled={true}>
            <small>SKU: {getSku(variantItemId)}</small>
          </Dropdown.Toggle>
        </Dropdown>}
        {!hideSku && variantCount > 0 && <Dropdown>
          <Dropdown.Toggle variant='link'>
            <small>SKU: {getSku(variantItemId)}</small>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {variants?.map((item, key) => (
              <Dropdown.Item
                key={key}
                active={item.itemId === variant.itemId && item.itemId.code === variant.itemId.code}
                onClick={() => {
                  setVariant(item);
                }}>
                <i className={'icon'}
                   style={{backgroundImage: `url(${getImageSetUrl(item?.imageSet)})`}}/> {getSku(item.itemId)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>}
      </Card.Body>
      <SecuredComponentWrapper showMessage={false}>
        <Collapse in={visible}>
          <Card.Footer className={'text-center'}>
            <AddToCartForm {...addToCartParams} page={page}/>
          </Card.Footer>
        </Collapse>
      </SecuredComponentWrapper>
    </Card>
  );
};
