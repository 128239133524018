/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {Icon, Status} from './components/utility';

export const ErrorFallback = ({error, resetErrorBoundary}: any) => {

  return (
    <div className='vh-100 d-flex justify-content-md-center align-items-center'>
      <Container>
        <Row>
          <Col sm={{span: 12}} lg={{span: 6, offset: 3}}>
            <Status error status={error?.message}/>
            <Form.Group>
              <Button onClick={() => resetErrorBoundary()}>
                <Icon name={'arrow-alt-circle-left'} content={'Back'}/>
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
