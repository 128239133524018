/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '../queries/address';

export const ADD_CURRENT_CUSTOMER_ADDRESS = gql`
  mutation AddCurrentCustomerAddress($addressInput: AddressInput!) {
    addCurrentCustomerAddress(addressInput: $addressInput) {
      success
      message
      address {
        ...AddressFragment
      }
    }
  }

  ${ADDRESS_FRAGMENT}
`;

export const UPDATE_CURRENT_CUSTOMER_ADDRESS = gql`
  mutation UpdateCurrentCustomerAddress($addressInput: AddressInput!) {
    updateCurrentCustomerAddress(addressInput: $addressInput) {
      success
      message
      address {
        ...AddressFragment
      }
    }
  }

  ${ADDRESS_FRAGMENT}
`;

export const REMOVE_CURRENT_CUSTOMER_ADDRESS = gql`
  mutation RemoveCurrentCustomerAddress($addressId: String!) {
    removeCurrentCustomerAddress(addressId: $addressId) {
      success
      message
      address {
        ...AddressFragment
      }
    }
  }

  ${ADDRESS_FRAGMENT}
`;

export const SET_CURRENT_CUSTOMER_DEFAULT_SHIPPING_ADDRESS = gql`
  mutation CurrentCustomerDefaultShippingAddress($addressId: String!) {
    setCurrentCustomerDefaultShippingAddress(addressId: $addressId) {
      success
      message
      address {
        ...AddressFragment
      }
    }
  }

  ${ADDRESS_FRAGMENT}
`;

export const SET_CURRENT_CUSTOMER_DEFAULT_BILLING_ADDRESS = gql`
  mutation CurrentCustomerDefaultBillingAddress($addressId: String!) {
    setCurrentCustomerDefaultBillingAddress(addressId: $addressId) {
      success
      message
      address {
        ...AddressFragment
      }
    }
  }

  ${ADDRESS_FRAGMENT}
`;
