/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment} from 'react';
import {AuthorByLineProps} from '../AuthorByLineComponentTypes';
import {Link} from 'react-router-dom';
import {getMessage} from '../../../../utils/MessageUtils';
import {getDateString} from '../../../../utils/DateUtils';
import {getImageVariantBySize} from '../../../../utils/ImageUtil';
import {ImageSizeEnum} from '../../../global/CommonEnums';

const config = require('../../AuthorConfig.json');
const AuthorByLineContent = ({authors, date}: AuthorByLineProps) => {
  if (authors.length === 0) {
    return null;
  }
  return (
    <Fragment>
      {authors.map((author, key) => {
        const {name, link, images} = author;
        const image = getImageVariantBySize(images, ImageSizeEnum.Thumbnail);
        return (
          <div className='border-top border-bottom py-4 mb-5 authorbyline-content' key={'author-' + key}>
            <div className='row align-items-md-center'>
              <div className='col-md-7 mb-5 mb-md-0'>
                <div className='media align-items-center'>
                  <div className='u-avatar'>
                    {image &&
                    <img className='img-fluid rounded-circle' src={image}
                         alt='Author'/>}
                  </div>
                  <div className='media-body font-size-1 ml-3'>
                    {name &&
                    <span className='h6 font-weight-medium'>
                      <Link to={link} className=''>{name}</Link>
                      <button
                        type='button'
                        className='btn btn-xs btn-medium-primary font-weight-medium transition-3d-hover py-1 px-2 ml-1'>{getMessage(config, 'Follow')}</button></span>}
                    {date &&
                    <span
                      className='d-block text-muted'>{getDateString(date)}</span>}
                  </div>
                </div>
              </div>
              <div className='col-md-5'>
                <div className='d-md-flex justify-content-md-end align-items-md-center'>
                  <h2
                    className='text-secondary font-size-1 font-weight-medium text-uppercase mb-0'>{getMessage(config, 'Share')}:</h2>

                  <button
                    className='btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle ml-2'>
                    <span className='fab fa-facebook-f btn-icon__inner'/>
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle ml-2'>
                    <span className='fab fa-twitter btn-icon__inner'/>
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle ml-2'>
                    <span className='fab fa-instagram btn-icon__inner'/>
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle ml-2'>
                    <span className='fab fa-telegram btn-icon__inner'/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </Fragment>
  );
};

export default AuthorByLineContent;
