/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Row} from 'react-bootstrap';
import {ContentComponentProps} from '../ContentComponentTypes';

export const ContentRow = ( props: ContentComponentProps ) => {
  const { content } = props;
  return (
    <Row className='mx-lg-auto'>
      { content.title && <h2 className='font-weight-medium'>{ content.title }</h2> }
      { content.introduction && (
        <p className='border-bottom py-1'
           dangerouslySetInnerHTML={ { __html : content.introduction } }/>
      ) }
      { content.text && (
        <div dangerouslySetInnerHTML={ { __html : content.text } }/>
      ) }
    </Row>
  );
};
