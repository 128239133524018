/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Badge, Col, Container, Row} from 'react-bootstrap';

export const CampaignP18NComponent = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const firstName = queryParameters.get('fn') || '';
  const loyalty = queryParameters.get('lvl') || 'basic';

  return (
    <Container className='my-3'>
      <Row>
        <Col className='h2'>Hello{firstName ? ` ${firstName}` : ``}!</Col>
        <Col className='h2 d-flex justify-content-end'>
          {loyalty && (
            <Badge pill className={loyalty?.toLowerCase()}>
              {loyalty} Member
            </Badge>
          )}
        </Col>
      </Row>
    </Container>
  );
};
