/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {lazy, Suspense} from 'react';
import {BrProps} from '@bloomreach/react-sdk';
import {Loader} from '../utility';
import {getProductIdFromUrl} from '../product/ProductComponentUtils';

const ProductRecommendations = lazy(() => import('./ProductRecommendations')
  .then((module) => ({default: module.ProductRecommendations}))
);

export const ProductRecommendationsComponent = (props: BrProps) => {
  const {component} = props;
  const {widgetId, productFromUrl, cols = 6, rows = 1, pids} = component!.getParameters();

  const productID = getProductIdFromUrl(true);

  const queryParameters = new URLSearchParams(window.location.search);
  const qsPid = queryParameters.get('pid') ?? '';
  const itemIds = productFromUrl ? (qsPid || productID) : pids;
  const start = 0;
  const limit = cols * rows

  return (
    <Suspense fallback={<Loader/>}>
      <ProductRecommendations
        family={'item'}
        widgetId={widgetId}
        itemIds={itemIds}
        start={start}
        limit={limit}
        {...props}
      />
    </Suspense>
  )
}
