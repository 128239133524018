/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Container} from 'react-bootstrap';
import {Pagination} from '@bloomreach/spa-sdk';
import {BrProps} from '@bloomreach/react-sdk';
import {BlogComponent} from '../..';
import {RenderInlineEditing} from '../../utility/component/RenderInlineEditing';
import {ListPagination} from '../../utility';
import {getListPaginationPropsFromBrxm} from '../../utility/list-pagination/ListPaginationUtils';

export const BlogList = (props: BrProps) => {
  const {
    page,
    component
  } = props;

  const pageable = page!.getContent<Pagination>(component!.getModels().pagination);
  if (!pageable) {
    return null;
  }

  const pagination = getListPaginationPropsFromBrxm(pageable);

  const params = component!.getParameters();
  //TODO: parameter for card style list
  const {title, subTitle} = params ?? {};

  return (
    <React.Fragment>
      {(title || subTitle) && <Container className='space-2'>
        <div className='w-md-80 w-lg-75 text-center mx-md-auto'>
          <div className='mb-5'>
            {title && <h1 className='display-4 font-size-md-down-5 font-weight-semi-bold'>{title}</h1>}
            {subTitle && <p className='lead font-weight-normal'>{subTitle}</p>}
          </div>
        </div>
      </Container>}
      <Container className='space-2 space-bottom-lg-3'>
        <div className='row'>
          {pageable.getItems().map((reference: any, key: number) => {
            const itemStyle = 'col-12 col-lg-12';
            const item = page!.getContent(reference);
            return (
              item && <div className={`${itemStyle} mb-4`} key={key}>
                <RenderInlineEditing {...{
                  page,
                  content: item
                }}/>
                <BlogComponent {...{
                  page,
                  component,
                  documentRef: reference,
                  type: 'row'
                }}/>
              </div>
            );
          })}
        </div>
        <div className='space-bottom-2 font-size-xs'/>
        {pagination && <ListPagination {...pagination}/>}
      </Container>
    </React.Fragment>
  );
}
