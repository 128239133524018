/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useQuery} from '@apollo/client';
import {
  GET_ORDER_SHIPMENT_METHODS,
  OrderShipmentMethods,
  OrderShipmentMethods_getOrderShipmentMethods,
  OrderShipmentMethodsVariables,
} from '../../modules/commerce';
import {withApolloClient} from '../ApolloClient';

interface InputProps {
  orderId: string;
}

export interface GetShipmentMethodsProps {
  loading: boolean;
  shipmentMethods?: (OrderShipmentMethods_getOrderShipmentMethods | null)[];
}

function withGetShipmentMethodsBase<P>(Component: React.ComponentType<P & GetShipmentMethodsProps>) {
  return (props: P & InputProps) => {
    const {loading, data} = useQuery<OrderShipmentMethods, OrderShipmentMethodsVariables>(
      GET_ORDER_SHIPMENT_METHODS,
      {
        fetchPolicy: 'cache-and-network',
        variables: {
          id: props.orderId,
        },
      },
    );

    return <Component loading={loading} shipmentMethods={data?.getOrderShipmentMethods ?? undefined} {...props} />;
  };
}

export function withGetShipmentMethods<P>(Component: React.ComponentType<P & GetShipmentMethodsProps>) {
  return withApolloClient(withGetShipmentMethodsBase(Component));
}
