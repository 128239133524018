/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import AuthorByLineList from './AuthorByLineList';
import AuthorByLineCard from './AuthorByLineCard';
import AuthorByLineContent from './AuthorByLineContent';
import AuthorByLineArticle from './AuthorByLineArticle';
import {AuthorByLineProps} from '../AuthorByLineComponentTypes';

const AuthorByLine = ({componentStyle, authors, date}: AuthorByLineProps) => {
  switch (componentStyle) {
    case 'List':
      return (
        <AuthorByLineList {...{componentStyle, authors, date}}/>
      );
    case 'Card':
      return (
        <AuthorByLineCard {...{componentStyle, authors, date}}/>
      );
    case 'Content':
      return (
        <AuthorByLineContent {...{componentStyle, authors, date}}/>
      );
    case 'Article':
      return (
        <AuthorByLineArticle {...{componentStyle, authors, date}}/>
      );
    default:
      return null;
  }
};

AuthorByLine.defaultProps = {
  componentStyle: 'list'
};

export default AuthorByLine;
