/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { AxiosResponse } from 'axios';
import { auth } from './auth';
import {
  addError,
  addLoginError,
  addRefreshError,
  clearError,
  connectorVar,
  extractErrorMessage,
  loggedInVar,
} from './variables';

import possibleTypes from '../possibleTypes.json';

const connectorLink = setContext((_, { headers }) => {
  const connector = connectorVar();
  return {
    connector,
    headers: {
      connector,
      ...headers, // Allow custom connector override
    },
  };
});

const sessionTokenLink = setContext((_, { connector }) => {
  // if you have a cached value, return it immediately
  const token = sessionStorage.getItem('token');
  if (token) {
    return { token };
  }

  return auth
    .login({ scope: 'public' }, connector)
    .then((res) => {
      // eslint-disable-next-line no-shadow
      let token;
      if ('token' in res) {
        token = res.token;
      } else {
        token = (res as AxiosResponse).data?.authorization;
      }
      return { token };
    })
    .catch((err) => {
      addLoginError(err);
    });
});

const authLink = setContext((_, { headers, token }) => ({
  headers: {
    ...headers,
    authorization: token ? `Bearer ${token}` : '',
  },
}));

const authFlowLink = connectorLink.concat(sessionTokenLink).concat(authLink);

const customFetch = async (uri: RequestInfo, options: RequestInit) => {
  if (options?.headers && 'forceRefresh' in options?.headers) {
    const { forceRefresh } = options.headers;
    if (!forceRefresh) {
      return fetch(uri, options);
    }

    const previousToken = options.headers.authorization;
    const includeAuthorization = options.headers.includeAuthorization ?? true;
    const { connector } = options.headers;
    let res;
    if (includeAuthorization) {
      try {
        res = await auth.refresh(previousToken, connector);
      } catch (error) {
        if (error.response?.data?.errors?.[0]?.extensions?.code !== 'FORBIDDEN') {
          addRefreshError(error);
          return fetch(uri, options);
        }
      }
    }

    if (!res) {
      // token refresh disabled
      delete options.headers.authorization;
      try {
        res = await auth.login({ scope: 'public' }, connector, previousToken);
      } catch (error) {
        addLoginError(error);
        return fetch(uri, options);
      }
    }

    let token;
    if ('token' in res) {
      token = res.token;
    } else {
      token = (res as AxiosResponse).data?.authorization;
    }

    if (!token) {
      return fetch(uri, options);
    }
    options.headers = {
      ...options.headers,
      authorization: `Bearer ${token}`,
    };
  }

  return fetch(uri, options);
};

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_APOLLO_SERVER_URI}/graphql`,
  fetch: customFetch,
});

// eslint-disable-next-line consistent-return
const errorHandlerLink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
  if (graphQLErrors) {
    // console.log(graphQLErrors);
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      const oldHeaders = operation.getContext().headers;
      // TODO: [DEMO PATCH]
      let code = err.extensions?.code;
      if (err.message === 'invalid_token') {
        code = 'TOKEN_INVALID';
      }
      switch (code) {
        case 'UNAUTHENTICATED':
          /*
          if (operation.operationName === 'CurrentCustomer' && response) {
            // ignore this error
            response.errors = undefined;
            return;
          }
          operation.setContext({
            headers: {
              ...oldHeaders,
              forceRefresh: true,
            },
          });

          // eslint-disable-next-line consistent-return
          return forward(operation);
           */
          //TODO: [DEMO PATCH]
          const user = sessionStorage.getItem('user');
          if (!(user && JSON.parse(user)?.username)) {
            if (operation.operationName === 'CurrentCustomer' && response) {
              // ignore this error
              response.errors = undefined;
              return;
            }
            operation.setContext({
              headers: {
                ...oldHeaders,
                forceRefresh: true,
              },
            });
            // eslint-disable-next-line consistent-return
            return forward(operation);
          } else {
            addError({
              code: err.extensions?.code,
              message: extractErrorMessage(err),
              operation: operation.operationName,
            });
            auth.logout();
            if (!loggedInVar()) {
              // in case of anonymous users, establish automatically a new session
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  forceRefresh: true,
                  includeAuthorization: false,
                },
              });
              // eslint-disable-next-line consistent-return
              return forward(operation);
            }
            break;
          }
        case 'TOKEN_INVALID':
          auth.logout();
          if (!loggedInVar()) {
            // in case of anonymous users, establish automatically a new session
            operation.setContext({
              headers: {
                ...oldHeaders,
                forceRefresh: true,
                includeAuthorization: false,
              },
            });
            // eslint-disable-next-line consistent-return
            return forward(operation);
          }
        // eslint-disable-next-line no-fallthrough
        default:
          addError({
            code: err.extensions?.code,
            message: extractErrorMessage(err),
            operation: operation.operationName,
          });
      }
    }
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
    addError({
      code: 'NETWORK_ERROR',
      message: networkError.message,
    });
  }
});

const cleanupLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data.data) {
      clearError({ operation: operation.operationName });
    }
    return data;
  });
});

const tokenAwareHttpLink = cleanupLink.concat(errorHandlerLink).concat(httpLink);

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    ItemId: {
      keyFields: ['id', 'code'],
    },
    Item: {
      keyFields: ['itemId', ['id', 'code']],
    },
    ItemVariant: {
      keyFields: ['itemId', ['id', 'code']],
    },
  },
});

export const commerceApiClient = new ApolloClient({
  cache,
  link: authFlowLink.concat(tokenAwareHttpLink),
  defaultOptions: {
    query: {
      errorPolicy: 'ignore',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    mutate: {
      errorPolicy: 'ignore',
    },
  },
});
