/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { makeVar } from '@apollo/client';
import { AxiosError } from 'axios';
import { GraphQLError } from 'graphql';

export interface ErrorResponse {
  code: string;
  message: string;
  operation?: string;
}

// export const cartIdVar = makeVar<string | undefined>(undefined);
export const connectorVar = makeVar<string>('');
export const loggedInVar = makeVar<boolean>(false);
export const errorResponseVar = makeVar<ErrorResponse[] | undefined>(undefined);

export function extractErrorMessage(error: GraphQLError): string {
  return error.message;
}

export function addError(error: ErrorResponse) {
  const currentErrors = errorResponseVar() ?? [];
  const index = currentErrors.findIndex((err) => err.code === error.code && err.operation === error.operation);
  if (index >= 0) {
    currentErrors[index] = error;
  } else {
    currentErrors.push(error);
  }
  errorResponseVar(currentErrors);
}

export function addLoginError(error: AxiosError) {
  addError({
    code: error.response?.data?.errors?.[0]?.extensions?.code ?? 'AUTHENTICATION_ERROR',
    message: error.response?.data?.errors?.[0]?.message ?? error.message,
    operation: 'login',
  });
}

export function addRefreshError(error: AxiosError) {
  addError({
    code: error.response?.data?.errors?.[0]?.extensions?.code ?? 'AUTHENTICATION_ERROR',
    message: error.response?.data?.errors?.[0]?.message ?? error.message,
    operation: 'refresh',
  });
}

export function clearError({ code, operation }: { code?: string; operation?: string }) {
  let currentErrors = errorResponseVar();
  if (!currentErrors) {
    return;
  }

  if (!code && !operation) {
    errorResponseVar(undefined);
    return;
  }

  currentErrors = currentErrors.filter((error) => {
    if (code && operation) {
      return error.code !== code && error.operation !== operation;
    }
    return code ? error.code !== code : error.operation !== operation;
  });

  if (!currentErrors.length) {
    currentErrors = undefined;
  }
  errorResponseVar(currentErrors);
}
