/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import {BrProps} from '@bloomreach/react-sdk';
import {
  DemoBrsmContentSearchProps,
  SuggestionsProps,
  withBrsmContentSearch,
  withDemoBrsmSuggestions
} from '../../demo-connector-components';
import {getProductUrl, getSearchUrl, getSuggestedArticles, highLightSearchTerm} from './AutoSuggestionUtils';
import {SuggestedArticles, SuggestedProducts, Suggestions} from './templates';
import {PopoverState} from '..';
import {SuggestionItem, SuggestionProduct} from './AutoSuggestionTypes';
import {ArticleProps} from '../article/ArticleComponentTypes';
import {UserContext} from '../../contexts';
import {RecentSearch} from '../recent-search/RecentSearch';
import {getMessage} from '../../utils/MessageUtils';

export interface AutoSuggestionProps {
  input: string,
  setPopoverState: React.Dispatch<React.SetStateAction<PopoverState>>,
  showRecentSearch: boolean,
  showPredefinedSearches: boolean,
  predefinedSearches: string
}

const config = require('./AutoSuggestionConfig.json');

function AutoSuggestionBase(props: BrProps & AutoSuggestionProps & DemoBrsmContentSearchProps & SuggestionsProps) {
  const {
    page,
    input,
    setPopoverState,
    loading,
    suggestions,
    contentSearchLoading,
    contentSearchResult,
    setKeyword,
    showRecentSearch,
    showPredefinedSearches,
    predefinedSearches
  } = props;
  const {recentSearchesState: {setRecentSearches}} = useContext(UserContext)!;

  const [suggestedArticles, setSuggestedArticles] = useState<Array<ArticleProps> | undefined>(undefined);

  const {channelId} = useParams();

  useEffect(() => {
    const firstTerm = suggestions?.terms?.[0];
    if (firstTerm) {
      setKeyword(suggestions?.terms?.includes(input) ? input : firstTerm);
    }
  }, [suggestions?.terms?.[0]]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!contentSearchLoading && contentSearchResult) {
      const {
        docs
      } = contentSearchResult;
      setSuggestedArticles(getSuggestedArticles(docs, page!, channelId));
    }
  }, [contentSearchResult, contentSearchLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return null;
  }

  if (!suggestions) {
    return null;
  }

  const {terms, items} = suggestions;
  const suggestionItems: Array<SuggestionItem> = terms?.filter((term) => term != null).slice(0, config.maxSuggestions)
    .map((term) => {
      return {
        term: term!,
        url: getSearchUrl(term!),
        highlightedTerm: highLightSearchTerm(input, term!)
      };
    });

  const products: Array<SuggestionProduct> | undefined = items?.slice(0, config.maxProducts).filter((item) => item != null).map((item) => {
    const url = getProductUrl(item!.itemId?.id);
    return {...{...item!, url}};
  });

  const hasSuggestions = suggestionItems && suggestionItems.length > 0;
  if (!hasSuggestions) {
    setPopoverState({
      input,
      showAutosuggestion: false
    })
    return null;
  }

  const suggestedSearches = predefinedSearches?.split(',');
  const handleSearch = (keyword: string) => {
    setRecentSearches(keyword);
  }

  return (
    <Container fluid className={'auto-suggestion p-2'}>
      <Row>
        <Col md={4}>
          {showRecentSearch && <RecentSearch
            template='list'
            showClearButton={false}
          />}
          {showPredefinedSearches && suggestedSearches?.length > 0 &&
          <ul className='list-unstyled'>
            <li className={'h6 font-weight-bold'}>{getMessage(config, 'we may suggest')}</li>
            {suggestedSearches?.map((suggestion: string, key: number) => (
              <li key={key} className={'h6'}>
                <Link to={getSearchUrl(suggestion)}
                      onClick={() => handleSearch(suggestion)}
                      dangerouslySetInnerHTML={{__html: highLightSearchTerm(input, suggestion)}}
                />
              </li>
            ))}
          </ul>
          }
          <Suggestions suggestionItems={suggestionItems} input={input}/>
        </Col>
        <Col md={4}>
          <SuggestedProducts products={products}/>
        </Col>
        <Col md={4}>
          <SuggestedArticles articles={suggestedArticles}/>
        </Col>
      </Row>
    </Container>
  )
}

export const AutoSuggestionComponent = withBrsmContentSearch(withDemoBrsmSuggestions(AutoSuggestionBase));
