/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {BrComponentContext} from '@bloomreach/react-sdk';
import React, {lazy, Suspense} from 'react';
import {CardDeck, Col, Container, Row} from 'react-bootstrap';
import {BannerComponentArgs} from '../banner/BannerComponentTypes';
import {ComponentWrapper} from '..';
import {BannerCollectionComponentArgs} from './BannerCollectionComponentTypes';
import {Loader} from '../utility';
import {
  ResponsiveQueryProps,
  ResponsiveQueryType,
  withResponsiveQuery
} from '../../demo-connector-components/responsive/ResponsiveQuery';

const BannerCollectionCard = lazy(() => import('./templates/BannerCollectionCard'));
const BannerCollectionCardLarge = lazy(() => import('./templates/BannerCollectionCardLarge'));
const BannerCollectionImgOverlayCard = lazy(() => import('./templates/BannerCollectionImgOverlayCard'));
const BannerCollectionMediaItem = lazy(() => import('./templates/BannerCollectionMediaItem'));
const BannerCollectionMediaNavItem = lazy(() => import('./templates/BannerCollectionMediaNavItem'));

function BannerCollectionComponentBase({
                                         pageable,
                                         params,
                                         template,
                                         fluid = false,
                                         nowrap = false,
                                         overlap = false,
                                         responsiveQuery
                                       }: BannerCollectionComponentArgs & ResponsiveQueryProps) {
  const component = React.useContext(BrComponentContext)!;

  pageable = pageable || component.getModels();

  params = params || component.getParameters();
  template = template || params['template'] || 'textoverimage';
  if (template === 'textbelowimage') {
    template = 'card';
  }

  const bannerCount = Object.keys(params).filter(key => key.startsWith('banner') && !!params[key]).length;
  const itemsPerRow = template === 'usp' || template === 'threebythree' || bannerCount - 1 > 4 ? 3 : bannerCount;
  const showRow2 = bannerCount - 1 > itemsPerRow;

  let containerClassName = `banner-collection banner-collection--${template} pt-4`;
  containerClassName = overlap ? `${containerClassName} overlap-top` : containerClassName;
  const titleClassName = `banner-collection-title text-center mb-4`;

  const getAllItemsProps = (pageable: any, params: any, template: string): BannerComponentArgs[] => {
    return Object.keys(pageable).filter(key => !!pageable[key] && key.startsWith('banner')).map((key: any) => {
      const idx = key.replace('banner', 'Banner');
      const itemParams = {
        textAlignment: params[`textAlignment${idx}`] || 'left',
        textColor: params[`textColor${idx}`] || 'black',
        template
      };
      return {
        documentRef: pageable[key],
        params: itemParams,
        template,
        fluid: false,
        nowrap: true,
        idx
      };
    });
  };

  const renderCardDeck = (items: BannerComponentArgs[], template: string, responsiveQuery: ResponsiveQueryType) => (
    <CardDeck className='pb-0 pb-md-4'>
      {items.map((props, key) => {
          switch (template) {
            case "card":
              return <BannerCollectionCard key={key} {...props} responsiveQuery={responsiveQuery}/>;
            case "cardlarge":
              return <BannerCollectionCardLarge key={key} {...props} responsiveQuery={responsiveQuery}/>;
            default:
              return <BannerCollectionImgOverlayCard key={key} {...props} responsiveQuery={responsiveQuery}/>;
          }
        }
      )}
    </CardDeck>
  );

  const renderRow = (items: BannerComponentArgs[], colClassName: string, template: string, responsiveQuery: ResponsiveQueryType) => {
    const RenderTemplate = (item: BannerComponentArgs) => {
      const props = {...item, responsiveQuery};
      switch (template) {
        case 'navItem':
          return <BannerCollectionMediaNavItem {...props}/>;
        default:
          return <BannerCollectionMediaItem {...props}/>;
      }
    }
    return (
      <Row className='pb-0 pb-md-4'>
        {items.map((item, key) => (
          <Col key={key} className={colClassName}>
            <RenderTemplate {...item}/>
          </Col>
        ))}
      </Row>
    )
  };

  const itemsProps = getAllItemsProps(pageable, params, template).sort((a: any, b: any) => {
    if (a.idx < b.idx) {
      return -1;
    }
    if (a.idx > b.idx) {
      return 1;
    }
    return 0;
  });
  const itemsRow1 = itemsProps.length > itemsPerRow ? itemsProps.slice(0, itemsPerRow) : itemsProps;
  const itemsRow2 = showRow2 && itemsProps.length > itemsPerRow ? itemsProps.slice(itemsPerRow, itemsProps.length) : [];

  const row1ColSizeLg = itemsPerRow === 4 ? 3 : itemsPerRow === 3 ? 4 : 12 / itemsPerRow;
  const row1ColSizeMd = itemsPerRow === 4 ? 6 : 12;
  const row1ColClassName = `col-12 col-md-${row1ColSizeMd} col-lg-${row1ColSizeLg}`;

  const row2ColSizeLg = 12 / itemsRow2.length;
  const row2ColClassName = `col-12 col-md-${row2ColSizeLg} col-lg-${row2ColSizeLg}`;

  const isCardDeck = (template: string) => ['card', 'cardlarge', 'textoverimage', 'threebythree'].includes(template);
  return (
    <Suspense fallback={<Loader/>}>
      <ComponentWrapper {...{type: 'Banner Collection', wrap: !nowrap}}>
        <Container className={containerClassName} fluid={fluid}>
          {params.title && <div className={titleClassName}>{params.title}</div>}
          {itemsRow1 && isCardDeck(template) ?
            renderCardDeck(itemsRow1, template, responsiveQuery)
            :
            renderRow(itemsRow1, row1ColClassName, template, responsiveQuery)}
          {showRow2 && itemsRow2.length > 0 &&
          isCardDeck(template) ?
            renderCardDeck(itemsRow2, template, responsiveQuery)
            :
            renderRow(itemsRow2, row2ColClassName, template, responsiveQuery)
          }
        </Container>
      </ComponentWrapper>
    </Suspense>
  );
}

export const BannerCollectionComponent = withResponsiveQuery(BannerCollectionComponentBase);
