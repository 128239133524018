/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * BlogFull component
 */
import React from 'react';
import AuthorByLine from '../../author/author-byline/templates/AuthorByLine';
import {BlogProps} from '../BlogComponentTypes';

export const BlogFull = ({title, introduction, body, date, authors}: BlogProps) => {
  return (
    <div className='w-lg-60 mx-lg-auto blog-full'>
      <div className='mb-4'>
        {title && <h1 className='h2 font-weight-medium'>{title}</h1>}
      </div>
      {authors && <AuthorByLine authors={authors} componentStyle={'Article'} date={date}/>}
      {introduction && <p className='border-bottom space-bottom-1'
                          dangerouslySetInnerHTML={{__html: introduction}}/>}
      {body && <div className='space-bottom-1 space-top-1 blog-content'
                    dangerouslySetInnerHTML={{__html: body}}/>}
    </div>
  )
}
