/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useState} from 'react';
import {Col, Form} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {CartStageEnum} from '../../global/CommonEnums';
import {Status} from '../../utility';
import ReturnButton from '../action/ReturnButton';
import {SetOrderDelivery} from '../../order/action/SetOrderDelivery';
import {GetShipmentMethodsProps, withGetError, withGetShipmentMethods} from '../../../connector-components';
import {getDefaultConnector} from '../../../connector-components/utils';
import {readOrderFromSession} from '../../order/OrderComponentUtils';
import {StageInputProps} from '../CartComponentTypes';

function DeliveryBase(props: BrProps & GetShipmentMethodsProps & StageInputProps) {
  const {
    page,
    setStage,
    loading,
    shipmentMethods
  } = props;

  const defaultConnector = getDefaultConnector(page!);
  const orderDetails = readOrderFromSession();
  const {shipMethod} = orderDetails;

  const [shippingOptions, setShippingOptions] = useState({
    deliveryMethod: shipMethod
  });

  const {deliveryMethod} = shippingOptions;

  //TODO: Find better way since saas won't have defaultConnector as part of channel
  if (defaultConnector === 'commercetools' || process.env.REACT_APP_PACIFIC_THEME === 'home') {
    setStage(CartStageEnum.BILLING);
  }

  const setOrderShippingAddressParams = {
    deliveryMethod,
    setStage
  }

  if (loading) {
    return (
      <Status container loading status={'We are fetching shipment methods for you'}/>
    )
  }

  return (
    <React.Fragment>
      <Form noValidate={true}>
        <h4>Delivery</h4>
        <Form.Group>
          <Form.Label className={'text-uppercase'}>Select Delivery Method</Form.Label>
          {shipmentMethods?.map((shipmentMethod, key: number) => {
            if (!shipmentMethod) {
              return null;
            }
            const {id, name, description} = shipmentMethod;
            return (
              <Fragment key={key}>
                <Form.Check
                  type='radio'
                  label={name}
                  name='deliveryMethodGroup'
                  id={id}
                  checked={deliveryMethod === id}
                  onChange={() => {
                    setShippingOptions({...shippingOptions, deliveryMethod: id})
                  }}
                />
                <div className={'form-check'}>
                  <label className={'form-check-label text-muted'}>{description}</label>
                </div>
              </Fragment>
            )
          })}
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <ReturnButton stage={CartStageEnum.SHIPPING} setStage={setStage}/>
          </Form.Group>
          <Form.Group as={Col}>
            <SetOrderDelivery {...setOrderShippingAddressParams} orderId={orderDetails?.id} page={page!}/>
          </Form.Group>
        </Form.Row>
      </Form>
    </React.Fragment>
  )
}

export const Delivery = withGetError(withGetShipmentMethods(DeliveryBase));
