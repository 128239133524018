/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Card, Image, OverlayTrigger, Popover} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {ArticleProps} from '../ArticleComponentTypes';
import {getBackgroundImageLink, getImageVariantBySize} from '../../../utils/ImageUtil';
import {ImageSizeEnum} from '../../global/CommonEnums';

function ArticleLink(params: any) {
  let targetUrl = params.url;

  if (targetUrl?.endsWith('.pdf') > 0) {
    return (
      <a
        className={'stretched-link'}
        href={targetUrl}
        target={'_blank'}
        rel={'noopener noreferrer'}>
        {params.title}
      </a>
    )
  }

  return (
    <Link
      className={'stretched-link'}
      to={targetUrl}>
      {params.title}
    </Link>
  )
}

export const ArticleCard = ({description, image, images, title, link}: ArticleProps) => {
  const backgroundImage = getBackgroundImageLink(getImageVariantBySize(images, ImageSizeEnum.Large) ?? image?.url!);
  return (
    <Card className='shadow h-100 article-card'>
      <Image fluid style={{
        backgroundImage
      }}/>
      <Card.Body className={'py-4 text-center'}>
        <OverlayTrigger trigger={['hover', 'focus']} placement='bottom' overlay={
          <Popover id='popover-basic' className={'w-25'}>
            <div dangerouslySetInnerHTML={{__html: description}} className={'p-2'}/>
          </Popover>
        }>
          <Card.Text>
            <ArticleLink {...{...link, title}}/>
          </Card.Text>
        </OverlayTrigger>
      </Card.Body>
    </Card>
  )
};
