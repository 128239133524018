/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Document, Page} from '@bloomreach/spa-sdk';
import {ProductProps} from './ProductComponentTypes';
import {getUrl} from '../../utils/UrlUtils';
import {brxmEndpoint, UserSegmentType} from '../../contexts';
import {getVideoContent, getVideoProps} from '../video/VideoComponentUtils';
import {getImageVariantBySize, getImageVariants} from '../../utils/ImageUtil';
import {PromotionProps} from '../promotion/PromotionComponentTypes';
import {getPromotionImages} from '../promotion/PromotionComponentUtils';
import {
  AttributeType,
  ImageSet,
  Item,
  ItemId,
  ItemLike,
  ItemVariant
} from '../../demo-connector-components/DemoBrsmUtils';
import {ImageSizeEnum} from '../global/CommonEnums';

const routeConfig = require('../../utils/RouteConfig.json');

export const getProductUrl = (page: Page, productId: string, productCode: string | null | undefined, userSegment: UserSegmentType) => {
  const route = routeConfig.product;
  // Work around for bad productCode
  if (productCode && productCode.startsWith('http')) {
    productCode = productId;
  }
  const productPageUrl = `${route}/${productId}___${productCode || ''}`;
  return getUrl(productPageUrl, page);
};

export const getProductProps = (page: Page, item: Item, contentEnrich: boolean = false): ProductProps | null => {
  let productProps: ProductProps = {
    __typename: "Item",
    ...getBaseProductProps(page, item),
    varAttrTypes: item.varAttrTypes as ([AttributeType] | null),
    variants: item?.variants?.map((variant: ItemVariant) => {
      return getVariantProps(page, variant);
    })
  };

  // For commercetools only
  productProps.itemKey = item?.variants
    ?.find((variant) => variant?.master === true)
    ?.varAttrs?.find((attribute) => attribute?.name === 'mpn')
    ?.values?.[0];

  if (contentEnrich) {
    const document = page.getDocument<Document>();
    if (document) {
      // Decoration document
      const contentType = document.getData()?.contentType;
      if (contentType === 'brxdemopaas:productdecorator' || contentType === 'brxsaas:demoProductDecorator') {
        const {title, description, videoLinks, images} = document.getData();
        productProps.videos = videoLinks?.map((videoLink: any) => {
          return getVideoProps(page, {
            documentRef: videoLink,
            params: {},
            template: ''
          })
        });

        productProps.images = images?.map((image: any) => {
          const imageSet = getImageVariants(page, image);
          return getImageVariantBySize(imageSet, ImageSizeEnum.Small);
        });

        const updateProductProps = (productProps: ProductProps) => {
          title && (productProps.displayName = title);
          description && (productProps.description = description.value);
        };

        updateProductProps(productProps);
      }
    }
  }

  return productProps;
}

export const getBaseProductProps = (page: Page, item: ItemLike): ItemLike => {
  const {
    itemId,
    displayName = '',
    description = '',
    imageSet,
    listPrice,
    purchasePrice,
    customAttrs
  } = item;

  let effectiveCode = customAttrs?.find((customAttr) => customAttr?.name === 'skuid')?.values?.[0];
  if (!effectiveCode || effectiveCode === 'undefined') {
    effectiveCode = itemId?.code;
  }

  return {
    description,
    displayName,
    imageSet,
    customAttrs,
    listPrice,
    purchasePrice,
    itemId: {
      __typename: 'ItemId',
      id: itemId.id,
      code: effectiveCode
    }
  };
}

export const getVariantProps = (page: Page, item: ItemVariant): ItemVariant => {
  const {
    master,
    varAttrs
  } = item;

  return {
    __typename: "ItemVariant",
    ...getBaseProductProps(page, item),
    varAttrs,
    master
  };
}

export const getFacetFilterFilter = (filterValue: any) => {
  const facetItemSegments = filterValue.split(':');
  if (facetItemSegments.length === 2) {
    const facetItemId = facetItemSegments[0];
    const facetItemValue = facetItemSegments[1].substring(1, facetItemSegments[1].length - 1);
    return {
      facetItemId,
      facetItemValue
    }
  }
  return null;
}

export const getPickedProducts = (page: Page, productPicker: any) => {
  const pickedProducts = JSON.parse(productPicker);

  return pickedProducts.map((pickedProduct: any) => {
    const {itemId, displayName, description, imageSet, listPrice, purchasePrice} = pickedProduct;
    const product: ProductProps = {
      __typename: '',
      purchasePrice,
      varAttrTypes: null,
      variants: [],
      itemId: {
        __typename: 'ItemId',
        id: itemId?.id,
        code: itemId?.code ?? itemId?.id
      },
      displayName,
      description,
      imageSet: new ImageSet(imageSet?.thumbnail?.link?.href),
      listPrice
    };
    return product;
  });
};

export const getProductIdFromUrl = (onlyId: boolean = false) => {
  // Take the last segment of
  const pathSegments = window.location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];
  if (lastSegment) {
    const matches = (/\d+___([\d_]+)?$/).exec(lastSegment);
    if (matches && matches.length > 0) {
      if (onlyId) {
        return lastSegment.split('__')[0];
      } else {
        return lastSegment;
      }
    } else {
      return lastSegment.indexOf('___') > -1
        ? lastSegment.split('___')[0]
        : lastSegment;
    }
  }
}

export const getProductPromotions = (page: Page, setPromotionProps: React.Dispatch<React.SetStateAction<PromotionProps | null>>, itemId?: string, code?: string) => {
  if (!itemId || !code) {
    setPromotionProps(null);
  } else {
    let endpoint = brxmEndpoint();
    const index = endpoint.indexOf('?');
    const productSegment = '/products/' + itemId + '___' + code;
    if (index !== -1) {
      endpoint = endpoint.substring(0, index) + productSegment + endpoint.substring(index);
    } else {
      endpoint += productSegment;
    }
    fetch(endpoint, {}).then(res => res.json())
      .then(data => {
        const content: any = Object.values(data?.page).find((page: any) => page?.data?.contentType === 'brxdemopaas:productdecorator');
        if (content) {
          const {images, videoLinks} = content?.data;
          const imageMedias = getPromotionImages(images?.map((image: any) => {
            const imageSet = getImageVariants(page, image);
            return getImageVariantBySize(imageSet, ImageSizeEnum.Small);
            /*
            let url = parseImageUrlDynamic(page, image)?.url;
            if (url) {
              return url;
            } else {
              // Bloomreach image
              const imagePageId = image?.image?.[0]?.$ref?.replace('/page/', '');
              return data?.page?.[imagePageId]?.links?.site?.href;
            }
             */
          }));
          const videos = videoLinks?.map((videoLink: any) => {
            const videoPageId = videoLink?.$ref?.replace('/page/', '');
            const pageData = data?.page?.[videoPageId]?.data;
            const link = getVideoContent(page, pageData)?.videoItem?.videoLink;
            if (link) {
              return {
                type: 'VIDEO',
                media: link,
                options: {
                  height: '200px'
                }
              }
            } else {
              const linkMediaPageId = pageData?.videoitem?.[0]?.hstLink?.$ref?.replace('/page/', '');
              const hostedVideoLink = data?.page?.[linkMediaPageId]?.data?.asset?.links?.site?.href;
              return {
                type: 'VIDEO',
                media: hostedVideoLink,
                options: {
                  type: 'HOSTED',
                  height: '200px'
                }
              }
            }
          });
          if (imageMedias || videos) {
            setPromotionProps({
              images: imageMedias,
              videos: videos
            })
          }
        }
      });
  }
}

export const getSku = (variantItemId: ItemId) => {
  // Work around for bad productCode
  let code = variantItemId.code;
  if (code && code.startsWith('http')) {
    code = variantItemId.id;
  }
  return `${variantItemId.id}${code !== variantItemId.id ? ' - ' + code : ''}`;
}
