/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import {Icon} from '../utility';
import {
  get2DigitUidFromBinary,
  get2DigitUidFromUID,
  getCookie,
  getReplacedCookie,
  getUidFromCookie,
  setCookie
} from './IntelligenceSwitchboardComponentUtils';
import {getMessage} from '../../utils/MessageUtils';

const config = require('./IntelligenceSwitchboardComponentConfig.json');

export const IntelligenceSwitchboardComponent = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const uidCookieName = config.uidCookieName;
  const switchBoardCookieName = config.switchBoardCookieName;
  let initState = config.panel;
  initState.panel = !!urlParams.get(uidCookieName);

  const [state, setState] = useState(initState);

  const loadToggles = () => {
    let switchboardSession = getCookie(switchBoardCookieName);
    if (switchboardSession !== '') {
      const urlParams = new URLSearchParams(window.location.search);
      let originalUID = getUidFromCookie(urlParams.get(uidCookieName) || getCookie(uidCookieName));
      const twoDigitNumber = get2DigitUidFromUID(originalUID);

      if (twoDigitNumber != null) {
        const updatedState = {...state};
        const binaryNumber = twoDigitNumber.toString(2);
        Object.keys(updatedState.items).forEach((key) => {
          updatedState.items[key].status = false;
        });

        // 1111, 1100, 1000, 0
        const binaryNumberVal = parseInt(binaryNumber);
        if (binaryNumberVal === 1100011) {
          updatedState.items['full'].status = true;
        } else if (binaryNumberVal === 10) {
          updatedState.items['performanceRanking'].status = true;
        } else if (binaryNumberVal === 1) {
          updatedState.items['semanticSearch'].status = true;
        }
        if (binaryNumberVal === 0) {
          updatedState.items['none'].status = true;
        }

        setState(updatedState);
      }
    } else {
      setCookie(switchBoardCookieName, 'true', 0.02);
    }
  }

  const onClickSwitch = (item: any) => {
    let updatedState = JSON.parse(JSON.stringify(state));
    Object.keys(updatedState.items).forEach((key) => {
      updatedState.items[key].status = false;
    });

    updatedState.items[item].status = true;

    let searchBinNumber = 0;

    if (updatedState.items.performanceRanking.status) {
      searchBinNumber = 10;
    }
    if (updatedState.items.semanticSearch.status) {
      searchBinNumber = 1;
    }
    if (updatedState.items.none.status) {
      searchBinNumber = 0;
    }
    if (updatedState.items.full.status) {
      searchBinNumber = 1100011;
    }

    let digitUID = get2DigitUidFromBinary(searchBinNumber.toString());
    let originalUID = getUidFromCookie(getCookie(uidCookieName));
    let newUID = getReplacedCookie(getUidFromCookie(getCookie(uidCookieName)), digitUID)!;
    let originalCookieValue = getCookie(uidCookieName);
    let updatedCookieValue = originalCookieValue.replace(originalUID, newUID);

    // Per Albert's Note
    if (updatedState.items.performanceRanking.status) {
      updatedCookieValue = 'uid=3887804411002:v=12.0:ts=1559140374393:hc=263';
    }
    if (updatedState.items.semanticSearch.status) {
      updatedCookieValue = 'uid=3887804411001:v=12.0:ts=1559140374393:hc=263';
    }
    if (updatedState.items.none.status) {
      updatedCookieValue = 'uid=3887804411000:v=12.0:ts=1559140374393:hc=263';
    }
    if (updatedState.items.full.status) {
      updatedCookieValue = 'uid=3887804411099:v=12.0:ts=1559140374393:hc=263';
    }
    setCookie(uidCookieName, updatedCookieValue, 100 * 365);     // update the cookie

    setState(updatedState);

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(uidCookieName, updatedCookieValue);
    window.location.href = `${window.location.origin}${window.location.pathname}?${urlParams}`;
  }

  const onClickPanel = (toggle = false) => {
    let updatedState = {...state};
    updatedState.panel = !updatedState.panel;
    setState(updatedState);
    const bodyElement = document.querySelector('body');
    if (toggle) {
      bodyElement?.classList.toggle('collapsed');
    } else {
      bodyElement?.classList.remove('collapsed');
    }
  }

  useEffect(() => {
    loadToggles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => () => {
    const bodyElement = document.querySelector('body');
    bodyElement?.classList.remove('collapsed');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {panel, items} = state;
  return (
    <Fragment>
      <div id='intelligence-switchboard' className={`p-2 card ${panel ? 'active' : 'd-none'}`}>
        <Button size={'sm'} variant={'light'} className='close ml-auto' onClick={() => onClickPanel()}>
          <Icon name={'times'}/>
        </Button>
        <h4 className={'title text-center mb-4'}>
          {getMessage(config, 'Intelligence Switchboard')}
        </h4>
        <div>
          {Object.keys(items).map((key, index) => <Card key={index}>
            <Card.Header>
              <span className={'float-right'}>
                {items[key].status ?
                  <Icon name={'check-circle'} color={'green'}/>
                  :
                  <Icon name={'times-circle'} color={'red'}/>
                }
                </span>
              {getMessage(config, items[key].label)}
            </Card.Header>
            <Card.Body className={'d-flex'}>
              <Button variant={'primary'} className={'mx-auto my-auto'}
                      onClick={() => {
                        onClickSwitch(key);
                      }} disabled={items[key].status}>
                {getMessage(config, 'Switch On')}
              </Button>
            </Card.Body>
          </Card>)}
        </div>
      </div>

      <div className='intelligence-switchboard-button'>
        <Button color={'red'} onClick={() => onClickPanel(true)}>
          {getMessage(config, 'Intelligence Switchboard')}
        </Button>
      </div>
    </Fragment>
  );
}
