/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment} from 'react';
import {BrManageContentButton, BrManageMenuButton} from '@bloomreach/react-sdk';
import {ManageContentButton, Menu, Page} from '@bloomreach/spa-sdk';

export type RenderInlineEditingProps = {
  page?: Page;
  menu?: Menu;
  editOnly?: boolean;
} & ManageContentButton;

export const RenderInlineEditing = (props: RenderInlineEditingProps) => {
  const {
    page,
    menu,
    content,
    documentTemplateQuery,
    parameter = 'document',
    path,
    root,
    pickerSelectableNodeTypes,
    relative = true,
    editOnly = true
  } = props;

  if (!page?.isPreview()) {
    return <Fragment/>;
  }

  if (menu) {
    return (
      <div className={'position-relative'}>
        <BrManageMenuButton menu={menu}/>
      </div>
    )
  } else {
    if (content) {
      return (
        <div className={'position-relative'}>
          <BrManageContentButton content={content}/>
        </div>
      )
    } else if (!editOnly) {
      return (
        <div className={'position-relative'}>
          <BrManageContentButton
            {...{
              documentTemplateQuery,
              parameter,
              path,
              relative,
              root,
              pickerSelectableNodeTypes
            }}
          />
        </div>
      )
    } else {
      return <Fragment/>;
    }
  }
}
