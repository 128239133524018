/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {AuthorBioContentProps, AuthorBioProps, AuthorBioType} from './AuthorBioComponentTypes';
import {getImageVariants} from '../../utils/ImageUtil';

export const GetAuthorBioProps = ({
                                    page,
                                    documentRef,
                                    content: pageDocument,
                                    params = {}
                                  }: AuthorBioContentProps): AuthorBioProps | null => {
  const document = pageDocument ?? (documentRef && page.getContent(documentRef));

  if (!document) {
    return null;
  }

  const {fullName, role, content, accounts, picture} = document.getData();

  return {
    type: params.type as AuthorBioType,
    fullName,
    role,
    accounts: accounts && accounts.map((account: any) => {
      return {
        type: account.type?.selectionValues?.[0]?.key ?? account.type,
        link: {
          url: account.link
        }
      }
    }),
    info: page.rewriteLinks(content?.value ?? content),
    images: getImageVariants(page, picture)
  }
};
