/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';
import {ITEM_FRAGMENT} from './items';

// TODO: [DEMO PATCH] Add facet back
export const FACET_RESULT_FRAGMENT = gql`
  fragment FacetResultFragment on FacetResult {
    fields {
      id,
      name,
      values {
        id,
        parentId,
        name,
        count
      }
    }
  }
`;


// TODO: [DEMO PATCH] Adds sortFields parameter
export const DEMO_FIND_ITEMS_BY_KEYWORD = gql`
  query DemoItems($query: String!, $offset: Int, $limit: Int, $queryHint: QueryHintInput, $sortFields: String) {
    findItemsByKeyword(text: $query, offset: $offset, limit: $limit, queryHint: $queryHint, sortFields: $sortFields) {
      offset
      limit
      count
      total
      items {
        ...ItemFragment
      }
      facetResult {
        ...FacetResultFragment
      }
      queryHint {
        autoCorrectQuery,
        autoCorrectQuerySet
      }
    }
  }

  ${ITEM_FRAGMENT}
  ${FACET_RESULT_FRAGMENT}
`;

// TODO: [DEMO PATCH] Adds sortFields parameter
// TODO: [DEMO PATCH] Add facet result back
export const DEMO_FIND_ITEMS_BY_CATEGORY = gql`
  query DemoItemsByCategory($categoryId: String!, $offset: Int, $limit: Int, $queryHint: QueryHintInput, $sortFields: String) {
    findItemsByCategory(categoryId: $categoryId, offset: $offset, limit: $limit, queryHint: $queryHint, sortFields: $sortFields) {
      offset
      limit
      count
      total
      items {
        ...ItemFragment
      }
      facetResult {
        ...FacetResultFragment
      }
    }
  }

  ${ITEM_FRAGMENT}
  ${FACET_RESULT_FRAGMENT}
`;
