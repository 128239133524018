/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Button} from 'react-bootstrap';
import {Icon} from '../../utility';
import {CartStageEnum} from '../../global/CommonEnums';

interface ReturnButtonProps {
  direction?: 'left' | 'right',
  setStage: Function,
  stage: CartStageEnum
}

export default function ReturnButton (props: ReturnButtonProps) {
  const {direction = 'left', stage, setStage} = props;
  const iconName = direction === 'left' ? 'arrow-left' : 'arrow-right';
  const message = direction === 'left' ? 'Return to' : 'Next to';
  return (
    <Button
      variant={'primary'}
      className={direction === 'left' ? 'float-left' : 'float-right'}
      onClick={() => setStage(stage)}>
      <Icon name={iconName}>{`${message} ${stage}`}</Icon>
    </Button>
  )
}
