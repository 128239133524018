/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useQuery} from '@apollo/client';
import {CurrentCustomer, CurrentCustomer_getCurrentCustomer, GET_CURRENT_CUSTOMER} from '../../modules/commerce';
import {withApolloClient} from '../ApolloClient';
import {loggedInVar} from '../../modules/commerce/api';

export interface GetCurrentCustomerResult {
  anonymous: boolean;
  currentCustomer?: CurrentCustomer_getCurrentCustomer;
}

export interface GetCurrentCustomerProps {
  loading: boolean;
  currentCustomer?: GetCurrentCustomerResult;
}

function withGetCurrentCustomerBase<P>(Component: React.ComponentType<P & GetCurrentCustomerProps>) {
  return (props: P) => {
    let currentCustomer: GetCurrentCustomerResult = {anonymous: true};
    const {loading, data} = useQuery<CurrentCustomer>(GET_CURRENT_CUSTOMER);

    if (data && data.getCurrentCustomer) {
      currentCustomer = {
        anonymous: false,
        currentCustomer: data.getCurrentCustomer,
      };
      loggedInVar(true);
    }

    return <Component loading={loading} currentCustomer={currentCustomer} {...props} />;
  };
}

export function withGetCurrentCustomer<P>(Component: React.ComponentType<P & GetCurrentCustomerProps>) {
  return withApolloClient(withGetCurrentCustomerBase(Component));
}
