/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Document} from '@bloomreach/spa-sdk';
import {BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import {BannerCollectionComponent, BannerComponent, ComponentWrapper} from '..';
import {RenderInlineEditing} from '../utility/component/RenderInlineEditing';

export const CategoryShowcaseComponent = () => {
  const page = React.useContext(BrPageContext)!;
  const component = React.useContext(BrComponentContext)!;

  const documentRef = component.getModels().document;
  const document = documentRef ? page.getContent<Document>(documentRef) : undefined;
  if (!document) {
    return null;
  }

  const heroProps = {
    documentRef: documentRef,
    params: component.getParameters(),
    template: 'full-width',
    fluid: true,
    nowrap: true
  };

  const collectionProps = {
    pageable: component.getModels(),
    params: component.getParameters(),
    template: 'card',
    fluid: false,
    nowrap: true,
    overlap: true
  };

  const show = heroProps || collectionProps || page.isPreview();

  return (
    show && <ComponentWrapper {...{type: 'CategoryShowcase', nowrap: false}}>
      <div className={'category-showcase'}>
        <BannerComponent {...heroProps}/>
        <BannerCollectionComponent {...collectionProps} />
        <RenderInlineEditing {...{
          page,
          content: document
        }}/>
      </div>
    </ComponentWrapper>
  );
};
