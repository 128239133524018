/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../queries/customer';

export const SIGN_UP = gql`
  mutation SignUp($input: CustomerSignUpInput!) {
    signUp(customerSignUpInput: $input) {
      code
      success
      message
      customer {
        ...CustomerFragment
      }
    }
  }

  ${CUSTOMER_FRAGMENT}
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCurrentCustomerDetail($input: CustomerDetailInput!) {
    updateCurrentCustomerDetail(customerDetailInput: $input) {
      code
      success
      message
      customer {
        ...CustomerFragment
      }
    }
  }

  ${CUSTOMER_FRAGMENT}
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangeCurrentCustomerPassword($input: CustomerCredsInput!) {
    changeCurrentCustomerPassword(customerCredsInput: $input) {
      code
      success
      message
      customer {
        ...CustomerFragment
      }
    }
  }

  ${CUSTOMER_FRAGMENT}
`;

export const RECOVER_CUSTOMER = gql`
  mutation RecoverCustomer($email: String!) {
    recoverCustomer(email: $email) {
      code
      success
      message
    }
  }
`;

export const RESET_CUSTOMER_PASSWORD = gql`
  mutation ResetCustomerPassword($input: CustomerResetInput!) {
    resetCustomerPassword(customerResetInput: $input) {
      code
      success
      message
      customer {
        ...CustomerFragment
      }
    }
  }

  ${CUSTOMER_FRAGMENT}
`;
