/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {createContext, useEffect, useState} from 'react';

type GlobalSearchContextType = {
  globalSearchParams: any,
  setGlobalSearchParams: (params: any) => void
};

const initialState = {
  view_id: process.env.REACT_APP_DEFAULT_VIEW_ID,
  segment_name: '',
  segment: ''
};

const GlobalSearchContext = createContext<GlobalSearchContextType | undefined>(undefined);

const GlobalSearchProvider = (props: any) => {
  const [globalSearchParams, setGlobalSearchParams] = useState(initialState);

  useEffect(() => {
    sessionStorage.setItem('searchContext', JSON.stringify(globalSearchParams));
    //DataLayerUtils.push( 'brx.search', globalSearchParams );
  }, [globalSearchParams]);

  return (
    <GlobalSearchContext.Provider
      value={{globalSearchParams, setGlobalSearchParams}}>
      {props.children}
    </GlobalSearchContext.Provider>
  );
};

export {GlobalSearchContext, GlobalSearchProvider};
