/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {lazy, Suspense, useContext} from 'react';
import {BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import {ComponentWrapper} from '..';
import {BannerComponentArgs, BannerComponentProps,} from './BannerComponentTypes';
import {getMockProps, getProps} from './BannerComponentUtils';
import {Loader} from '../utility';
import {UserContext} from '../../contexts';
import BannerSplitPanel from './templates/BannerSplitPanel';
import {getDocument} from '../../utils/DocumentUtils';
import {ResponsiveQueryProps, withResponsiveQuery} from '../../demo-connector-components/responsive/ResponsiveQuery';

const BannerCard = lazy(() => import('./templates/BannerCard'));
const BannerItem = lazy(() => import('./templates/BannerItem'));
const BannerJumbotron = lazy(() => import('./templates/BannerJumbotron'));
const BannerJumbotronSplitText = lazy(() => import('./templates/BannerJumbotronSplitText'));
const BannerProductShowcase = lazy(() => import('./templates/BannerProductShowcase'));

const config = require('./BannerComponentConfig.json');

function BannerComponentBase({
                               documentRef,
                               params,
                               template,
                               fluid = false,
                               nowrap = false,
                               responsiveQuery
                             }: BannerComponentArgs & ResponsiveQueryProps) {
  const page = React.useContext(BrPageContext)!;
  const component = React.useContext(BrComponentContext)!;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  documentRef = documentRef || component.getModels().banner;
  params = params || component.getParameters();
  template = template || (params && params['template']);
  fluid = fluid || template === 'full-width' || template === 'slide' || template === 'split-text';
  nowrap = nowrap || (template !== 'full-width' && template !== 'default');

  const props = documentRef
    ? getProps(page, {
      documentRef,
      params,
      template,
      fluid,
      nowrap,
    }, userSegment)
    :
    page.isPreview()
      ? getMockProps({
        params,
        template,
        fluid,
        nowrap,
      })
      : undefined;

  const componentWrapperProps = {
    type: config.componentName,
    wrap: true,
    inlineEditingProps: {
      page,
      content: getDocument(page, 'brxsaas:demoBanner', documentRef),
      documentTemplateQuery: 'new-demobanner-document',
      path: 'banners',
      pickerSelectableNodeTypes: 'brxsaas:demoBanner',
      parameter: 'banner',
      root: 'banners',
      editOnly: false
    }
  };

  const renderTemplate = (props: BannerComponentProps) => {
    const {params} = props;
    const templateProps = {...props, responsiveQuery};
    switch (params.template) {
      case 'card':
      case 'menu':
      case 'slot':
        return <BannerCard {...templateProps}/>;
      case 'usp':
        return <BannerItem {...templateProps}/>;
      case 'product-showcase':
        return <BannerProductShowcase {...templateProps}/>;
      case 'split-text':
        return <BannerJumbotronSplitText {...templateProps}/>;
      case 'split-panel':
        return <BannerSplitPanel {...templateProps}/>;
      default:
        return <BannerJumbotron {...templateProps}/>;
    }
  };

  return (
    <Suspense fallback={<Loader/>}>
      <ComponentWrapper {...componentWrapperProps}>
        {props && renderTemplate(props)}
      </ComponentWrapper>
    </Suspense>
  );
}

export const BannerComponent = withResponsiveQuery(BannerComponentBase);

