/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup_addresses} from '../../../modules/commerce';

interface AddressProps {
  address?: CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup_addresses | null,
  header?: string | null
}

export default function Address({address, header}: AddressProps) {
  if (!address) {
    return null;
  }
  const {firstName, lastName, middleName, streetAddress, additionalStreetInfo, city, state, country, postalCode} = address;
  const fullName = `${firstName ? firstName : ''}${middleName ? ' ' + middleName : ''}${lastName ? ' ' + lastName : ''}`;
  return (
    <span className={'d-block text-muted'}>
      {header && <strong>{header}</strong>}
      <span className={'d-block'}>{fullName}</span>
      {streetAddress && <span className={'d-block'}>{streetAddress}</span>}
      {additionalStreetInfo && <span className={'d-block'}>{additionalStreetInfo}</span>}
      <span className={'d-block'}>{`${city ? city + ', ' : ''}${state ? state + ' ' : ''}${country ? country + ' ' : ''}${postalCode || ''}`}</span>
    </span>
  )
}
