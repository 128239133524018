/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {Container} from 'react-bootstrap';
import {UserContext} from '../../../contexts';
import {Status} from '../../utility';
import ListUserAddress from '../address/ListUserAddress';
import SetUserDefaultAddress from '../address/SetUserDefaultAddress';
import {GetAddressGroupProps, withGetAddressGroup} from '../../../connector-components';
import {BrProps} from '@bloomreach/react-sdk';

const config = require('../UserProfileComponentConfig.json');
function ProfileAddressesBase(props: BrProps & GetAddressGroupProps) {
  const {
    loading,
    addressGroup,
  } = props;

  const {userState: {user}} = useContext(UserContext)!;

  if (!user.username) {
    return null;
  }

  if (loading) {
    return (
      <Status container loading status={config.profile.getAddressGroup.defaultLoadingMessage}/>
    );
  }

  if (!addressGroup) {
    return null;
  }

  return (
    <Container className={'pt-5'}>
      <SetUserDefaultAddress {...props}/>
      <br/>
      <ListUserAddress {...props}/>
    </Container>
  );
}

export const ProfileAddresses = withGetAddressGroup(ProfileAddressesBase);
