/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';

export const MONEY_AMOUNT_FRAGMENT = gql`
  fragment MoneyAmountFragment on MoneyAmount {
    amount
    currency
    displayValue
  }
`;

export const ITEM_LIKE_FRAGMENT = gql`
  fragment ItemLikeFragment on ItemLike {
    itemId {
      id
      code
    }
    displayName
    description
    imageSet {
      original {
        link {
          href
        }
      }
    }
    listPrice {
      moneyAmounts {
        ...MoneyAmountFragment
      }
    }
    purchasePrice {
      moneyAmounts {
        ...MoneyAmountFragment
      }
    }
  }

  ${MONEY_AMOUNT_FRAGMENT}
`;

export const ITEM_VARIANT_FRAGMENT = gql`
  fragment ItemVariantFragment on ItemVariant {
    ...ItemLikeFragment
    master
    varAttrs {
      name
      values
    }
    customAttrs {
      name
      values
    }
  }

  ${ITEM_LIKE_FRAGMENT}
`;

export const ITEM_FRAGMENT = gql`
  fragment ItemFragment on Item {
    ...ItemLikeFragment
    varAttrTypes {
      name
    }
    salePriceRange
    priceRange
    customAttrs {
      name
      values
    }
    variants {
      ...ItemVariantFragment
    }
  }

  ${ITEM_LIKE_FRAGMENT}
  ${ITEM_VARIANT_FRAGMENT}
`;

export const FACET_RESULT_FRAGMENT = gql`
  fragment FacetResultFragment on FacetResult {
    fields {
      id
      name
      values {
        id
        parentId
        name
        count
      }
    }
  }
`;

export const FIND_ITEMS_BY_KEYWORD = gql`
  query Items($query: String!, $offset: Int, $limit: Int, $queryHint: QueryHintInput, $sortFields: String) {
    findItemsByKeyword(text: $query, offset: $offset, limit: $limit, queryHint: $queryHint, sortFields: $sortFields) {
      offset
      limit
      count
      total
      items {
        ...ItemFragment
      }
      facetResult {
        ...FacetResultFragment
      }
      queryHint {
        cursor
      }
    }
  }

  ${ITEM_FRAGMENT}
  ${FACET_RESULT_FRAGMENT}
`;

export const GET_ITEM_BY_ID = gql`
  query Item($id: String!, $queryHint: QueryHintInput) {
    findItemById(id: $id, queryHint: $queryHint) {
      ...ItemFragment
    }
  }

  ${ITEM_FRAGMENT}
`;

export const FIND_ITEMS_BY_CATEGORY = gql`
  query ItemsByCategory(
    $categoryId: String!
    $offset: Int
    $limit: Int
    $queryHint: QueryHintInput
    $sortFields: String
  ) {
    findItemsByCategory(
      categoryId: $categoryId
      offset: $offset
      limit: $limit
      queryHint: $queryHint
      sortFields: $sortFields
    ) {
      offset
      limit
      count
      total
      items {
        ...ItemFragment
      }
      facetResult {
        ...FacetResultFragment
      }
      queryHint {
        cursor
      }
    }
  }

  ${ITEM_FRAGMENT}
  ${FACET_RESULT_FRAGMENT}
`;
