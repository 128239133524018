/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Col, Container, Row, Table} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {Link} from 'react-router-dom';
import {ListPagination, Status} from '../../utility';
import {ProductPrice} from '../../product/templates';
import {OrdersProps, withDemoOrders} from '../../../demo-connector-components';
import {Orders_findOrders_orders} from '../../../modules/commerce';
import {getListPaginationProps} from '../../utility/list-pagination/ListPaginationUtils';
import {getUrl} from '../../../utils/UrlUtils';
import {MoneyAmount} from '../../../demo-connector-components/DemoBrsmUtils';

const config = require('../UserProfileComponentConfig.json');

function ProfileMyOrdersBase(props: BrProps & OrdersProps) {
  const {
    page,
    loading,
    ordersPageResult
  } = props;

  if (loading) {
    return (
      <Status container loading status={config.profile.getOrders.defaultLoadingMessage}/>
    )
  }

  const orders = ordersPageResult?.orders;

  if (!orders || orders.length === 0) {
    return <Status container warning status={config.profile.getOrders.defaultNoOrderMessage} customClassName={'pt-5'}
                   dismissible={true}/>
  }

  // Pagination
  const pagination = getListPaginationProps({
    offset: ordersPageResult?.offset ?? 0,
    limit: ordersPageResult?.limit ?? 0,
    total: ordersPageResult?.total ?? 0,
    params: {showPagination: true}
  });

  return (
    <Container className={'pt-5'}>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Id</th>
          <th>Status</th>
          <th>Total Price</th>
          <th>Creation</th>
        </tr>
        </thead>
        <tbody>
        {orders?.map((order: Orders_findOrders_orders | null, key: number) => {
          const {id, status, totalAmount, creationDate} = order!;
          const orderUrl = getUrl('/orders/' + id, page!);
          return (
            <tr key={key}>
              <td><Link to={orderUrl}>{id}</Link></td>
              <td>{status}</td>
              <td><ProductPrice moneyAmount={totalAmount[0] as MoneyAmount}/></td>
              <td>{new Date(creationDate).toLocaleString()}</td>
            </tr>
          )
        })}
        </tbody>
      </Table>
      <Row>
        <Col className={'text-center py-2 font-size-xs'}>
          {pagination && <ListPagination {...pagination}/>}
        </Col>
      </Row>
    </Container>
  )
}

export const ProfileMyOrders = withDemoOrders(ProfileMyOrdersBase);
