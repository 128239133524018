/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Page} from '@bloomreach/spa-sdk';
import {parseSearchImageUrl} from '../../utils/ImageUtil';
import {ArticleProps} from '../article/ArticleComponentTypes';
import {brxmEndpoint, UserSegmentType} from '../../contexts';
import {getContent} from '../banner/BannerComponentUtils';
import {BannerContent} from '../banner/BannerComponentTypes';
import {getArticleUrl} from '../article-grid-by-search/ArticleGridSearchComponentUtils';

const routeConfig = require('../../utils/RouteConfig.json');

export const getSearchUrl = (keyword: string) => {
  const searchPageUrl = routeConfig.search;
  const encodedKeyword = encodeURIComponent(keyword);
  return `${searchPageUrl}${searchPageUrl.includes('?') ? '&' : '?'}_sq=${encodedKeyword}`;
}

export const getProductUrl = (productId: string) => {
  const productsPageUrl = routeConfig.product;
  const index = productsPageUrl.indexOf('?');
  let prefix = '/' + productId + '___' + productId;
  if (index === -1) {
    return productsPageUrl + prefix;
  } else {
    return productsPageUrl.substring(0, index) + prefix + productsPageUrl.substring(index);
  }
}

export const highLightSearchTerm = (query: string, suggest: string) => {
  const index = suggest.indexOf(query);
  if (index !== -1) {
    return `<span>${suggest.substring(0, index)}<b>${query}</b>${suggest.substring(index + query.length)}</span>`;
  } else {
    return suggest;
  }
}

export const getSuggestedArticles = (docs: any, page: Page, channelId: string): Array<ArticleProps> | undefined => {
  const endpointSegments = brxmEndpoint().split('/');
  const siteIndex = endpointSegments.findIndex((segment) => segment === 'site');
  const urlPrefix = endpointSegments.slice(0, siteIndex).join('/');

  return docs?.map((item: any) => {
    let image = item.image || item.images;
    if (Array.isArray(image)) {
      image = image?.[0];
    }

    const article: ArticleProps = {
      type: '',
      title: item.title,
      description: item.description || item.introduction,
      image: image && parseSearchImageUrl(image),
      images: {}
    };

    let url = item.url;
    if (url) {
      article.link = {
        url: getArticleUrl(page, url, channelId, urlPrefix)
      };
    }

    return article;
  })
}

export const getSuggestedBanner = (page: Page, banner: any, userSegment: UserSegmentType): BannerContent | null => {
  if (!banner) {
    return null;
  }

  const bannerContent = getContent(page, banner, userSegment);
  const link = bannerContent.links?.[0];
  if (link && !link.url) {
    const contentObject: any = Object.values(banner?.raw?.page).find((item : any) => item?.data?.name === 'brxdemopaas:link');
    if (contentObject) {
      link.url = contentObject?.links?.site?.href;
    }
  }

  return bannerContent;
}

