/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment} from 'react';
import NumberFormat from 'react-number-format';
import {MoneyAmount, Price} from '../../../demo-connector-components/DemoBrsmUtils';

interface ProductPriceProps {
  price?: Price,
  listPrice?: Price,
  moneyAmount?: MoneyAmount,
  listMoneyAmount?: MoneyAmount
}

const config = require('./ProductPriceConfig.json');
export const ProductPrice = ({price, listPrice, moneyAmount, listMoneyAmount}: ProductPriceProps) => {
  if (!price && !moneyAmount) {
    return null;
  }

  const priceMoneyAmount = moneyAmount ?? price?.moneyAmounts?.[0];
  const listPriceMoneyAmount = listMoneyAmount ?? listPrice?.moneyAmounts?.[0];

  const isListPriceDifferent = listPriceMoneyAmount && listPriceMoneyAmount?.amount !== priceMoneyAmount?.amount;

  let prefix = '$';
  switch (priceMoneyAmount?.currency) {
    case 'USD':
      prefix = '$';
      break;
    case 'EUR':
      prefix = '€';
      break;
  }

  let numberFormat = {
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: true,
    prefix
  }

  const locale = sessionStorage.getItem('locale');
  if (locale && config[locale]) {
    numberFormat = {...numberFormat, ...config[locale]};
  }

  return (
    <Fragment>
      <NumberFormat value={priceMoneyAmount?.amount}
                    displayType={'text'}
                    {...numberFormat}/>
      {isListPriceDifferent && <em className={'ml-2'}>
        <del>
          <NumberFormat value={listPriceMoneyAmount?.amount}
                        displayType={'text'}
                        {...numberFormat}/>
        </del>
      </em>}
    </Fragment>
  )
}
