/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {useApolloClient} from '@apollo/client';
import {addLoginError, auth, connectorVar, Credentials, sessionService} from '../../modules/commerce/api';
import {Cart_getCart, CurrentCustomer_getCurrentCustomer} from '../../modules/commerce';
import {withApolloClient} from '../../connector-components';
import {refreshCart, refreshCustomer} from '../../connector-components/utils';

export interface LoginProps {
  username?: string;
  setUsername: React.Dispatch<React.SetStateAction<string | undefined>>;
  password?: string;
  setPassword: React.Dispatch<React.SetStateAction<string | undefined>>;
  login: () => Promise<CurrentCustomer_getCurrentCustomer | undefined>;
  loading: boolean;
  customer?: CurrentCustomer_getCurrentCustomer;
  cart?: Cart_getCart;
}

function withLoginBase<P>(Component: React.ComponentType<P & LoginProps>) {
  return (props: P) => {
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [loading, setLoading] = useState(false);

    // TODO: [DEMO PATCH]
    const [customer, setCustomer] = useState<CurrentCustomer_getCurrentCustomer>();
    const [cart, setCart] = useState<Cart_getCart>();

    const connector = connectorVar();
    const client = useApolloClient();

    const login = async () => {
      setLoading(true);
      try {
        const oldCartId = sessionService.getCartIdFromSession();
        const credentials: Credentials = {username, password};
        if (oldCartId) {
          credentials.authHint = {oldCartId};
        }
        await auth.login(credentials, connector);
        const result = await refreshCustomer(client);
        const cartResult = await refreshCart(client);
        // TODO: [DEMO PATCH]
        setCustomer(result ?? undefined);
        setCart(cartResult ?? undefined);

        return result ?? undefined;
      } catch (error) {
        addLoginError(error);
        return undefined;
      } finally {
        setLoading(false);
      }
    };

    return (
      <Component
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        login={login}
        loading={loading}
        // TODO: [DEMO PATCH]
        customer={customer}
        cart={cart}
        {...props}
      />
    );
  };
}

export function withDemoLogin<P>(Component: React.ComponentType<P & LoginProps>) {
  return withApolloClient(withLoginBase(Component));
}
