/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Suspense, useEffect} from 'react';
import {BrProps} from '@bloomreach/react-sdk';
import {ComponentWrapper} from '..';
import {GetErrorProps, withGetError} from '../../connector-components';
import {
  GtmEventProps,
  ProductDetailProps,
  withDemoBrsmProductDetail,
  withDemoProductDetail,
  withGtmEvent
} from '../../demo-connector-components';
import {Loader, Status} from '../utility';
import {getProductProps} from './ProductComponentUtils';
import {Item} from '../../demo-connector-components/DemoBrsmUtils';
import {Product, ProductMedia} from './templates'

function ProductDetailBase(
  props: BrProps & GetErrorProps & GtmEventProps & ProductDetailProps
) {
  const {
    productId,
    page,
    component,
    item,
    loading,
    errors,
    clearError,
    setGtmEventProduct
  } = props;

  const {template, hideSku} = component!.getParameters();

  const fetchedProduct = item && getProductProps(page!, item as Item, true);

  useEffect(() => {
    setGtmEventProduct(fetchedProduct);
  }, [productId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <Status container loading status={'We are fetching product for you.'}/>
    );
  }

  const RenderProduct = () => {
    switch (template) {
      case 'mediaitem':
        return <ProductMedia {...fetchedProduct!} />;
      default:
        return <Product productId={productId} hideSku={hideSku} {...fetchedProduct!} />
    }
  }

  return (
    <Suspense fallback={<Loader/>}>
      <ComponentWrapper {...{type: 'Product', nowrap: false}}>
        {errors?.map((error, key: number) => (
          <Status
            key={key}
            onClose={clearError}
            container
            status={error?.message}
            dismissible
            customClassName={'mt-3'}
            error
          />
        ))}
        {fetchedProduct && <RenderProduct/>}
      </ComponentWrapper>
    </Suspense>
  );
}

export const BrsmProductDetail = withGetError(
  withGtmEvent(withDemoBrsmProductDetail(ProductDetailBase))
);

export const ProductDetail = withGetError(
  withGtmEvent(withDemoProductDetail(ProductDetailBase))
);
