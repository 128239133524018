/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import {PromotionMedia, PromotionTemplate} from '../PromotionComponentTypes';

export const PromotionVideo = (props: PromotionMedia & PromotionTemplate) => {
  const {template, media, options} = props;
  const isHostedVideo = options?.type === 'HOSTED';
  const height = template === 'CARD' ? '100%' : options?.height || '600px';
  return (
    <div className={`${template === 'CARD' ? 'card-img-top' : ''} video-player-wrapper`}>
      {isHostedVideo ? <video width={'100%'} height={height} controls autoPlay muted>
        <source src={media}/>
      </video> : <iframe
        width={'100%'}
        height={height}
        frameBorder={'0'}
        src={`${media}?autoplay=1&mute=1`}
        title={media}
      />}
    </div>
  )
}
