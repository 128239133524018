/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  GET_ORDER_SHIPMENT_METHODS,
  OrderShipmentMethods,
  OrderShipmentMethodsVariables,
  PaymentMethodInput,
  SET_ORDER_DETAILS,
  SetOrderDetails,
  SetOrderDetails_setOrderDetails,
  SetOrderDetailsVariables,
} from '../../modules/commerce';
import {withApolloClient} from '../ApolloClient';

interface InputProps {
  orderId: string;
}

export interface OrderDetailsForm {
  shippingAddressId: string;
  setShippingAddressId: React.Dispatch<React.SetStateAction<string>>;
  billingAddressId: string;
  setBillingAddressId: React.Dispatch<React.SetStateAction<string>>;
  shipmentMethodId?: string;
  setShipmentMethodId: React.Dispatch<React.SetStateAction<string | undefined>>;
  accountIssuer?: string;
  setAccountIssuer: React.Dispatch<React.SetStateAction<string | undefined>>;
  accountNumber?: string;
  setAccountNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  accountHolderName?: string;
  setAccountHolderName: React.Dispatch<React.SetStateAction<string | undefined>>;
  additionalCode?: string;
  setAdditionalCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  expirationMonth?: number;
  setExpirationMonth: React.Dispatch<React.SetStateAction<number | undefined>>;
  expirationYear?: number;
  setExpirationYear: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export interface SetOrderDetailsProps {
  orderDetailsForm: OrderDetailsForm;
  setOrderDetails: () => Promise<FetchResult<SetOrderDetails>>;
  loading: boolean;
  result?: SetOrderDetails_setOrderDetails;
}

function withSetOrderDetailsBase<P>(Component: React.ComponentType<P & SetOrderDetailsProps>) {
  return (props: P & InputProps) => {
    const [shippingAddressId, setShippingAddressId] = useState('');
    const [billingAddressId, setBillingAddressId] = useState('');
    const [shipmentMethodId, setShipmentMethodId] = useState<string>();
    const [accountIssuer, setAccountIssuer] = useState<string>();
    const [accountNumber, setAccountNumber] = useState<string>();
    const [accountHolderName, setAccountHolderName] = useState<string>();
    const [additionalCode, setAdditionalCode] = useState<string>();
    const [expirationMonth, setExpirationMonth] = useState<number>();
    const [expirationYear, setExpirationYear] = useState<number>();

    const setOrderDetails = (): Promise<FetchResult<SetOrderDetails>> => {
      let paymentData: PaymentMethodInput | undefined;
      if (accountIssuer && accountNumber && accountHolderName) {
        paymentData = {
          accountIssuer,
          accountNumber,
          accountHolderName,
          additionalCode,
        };
        if (expirationMonth && expirationYear) {
          paymentData.accountExpirationDate = {month: expirationMonth, year: expirationYear};
        }
      }

      return update({
        variables: {
          input: {
            id: props.orderId,
            billingAddress: billingAddressId ? {addressId: billingAddressId} : undefined,
            shipmentAddress: shippingAddressId ? {addressId: shippingAddressId} : undefined,
            shipmentMethod: shipmentMethodId ? {shipmentMethodId} : undefined,
            paymentMethod: paymentData ? {paymentData} : undefined,
          },
        },
      });
    };
    const [update, {loading, data}] = useMutation<SetOrderDetails, SetOrderDetailsVariables>(SET_ORDER_DETAILS, {
      fetchPolicy: 'no-cache',
      update(cache) {
        cache.writeQuery<OrderShipmentMethods, OrderShipmentMethodsVariables>({
          query: GET_ORDER_SHIPMENT_METHODS,
          variables: {
            id: props.orderId,
          },
          data: {
            getOrderShipmentMethods: null,
          },
        });
      },
    });

    return (
      <Component
        orderDetailsForm={{
          shippingAddressId,
          setShippingAddressId,
          billingAddressId,
          setBillingAddressId,
          shipmentMethodId,
          setShipmentMethodId,
          accountIssuer,
          setAccountIssuer,
          accountNumber,
          setAccountNumber,
          accountHolderName,
          setAccountHolderName,
          additionalCode,
          setAdditionalCode,
          expirationMonth,
          setExpirationMonth,
          expirationYear,
          setExpirationYear,
        }}
        setOrderDetails={setOrderDetails}
        loading={loading}
        result={data?.setOrderDetails}
        {...props}
      />
    );
  };
}

export function withSetOrderDetails<P>(Component: React.ComponentType<P & SetOrderDetailsProps>) {
  return withApolloClient(withSetOrderDetailsBase(Component));
}
