/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {BrPageContext} from '@bloomreach/react-sdk';
import {pushGtmEventSuggestion} from '../../gtm/GTMComponentUtils';
import {SuggestionItem} from '../AutoSuggestionTypes';
import {getMessage} from '../../../utils/MessageUtils';
import {UserContext} from '../../../contexts';
import {getUrl} from '../../../utils/UrlUtils';

export interface SuggestionsProps {
  suggestionItems: Array<SuggestionItem>,
  input: string
}

const config = require('../AutoSuggestionConfig.json');
export const Suggestions = (props: SuggestionsProps) => {
  const {
    suggestionItems,
    input
  } = props;
  const page = useContext(BrPageContext)!;
  const {recentSearchesState: {setRecentSearches}} = useContext(UserContext)!;

  const hasSuggestions = suggestionItems && suggestionItems.length > 0;
  if (!hasSuggestions) {
    return null;
  }
  return (
    <ul className='list-unstyled'>
      <li className={'list-item h5'}>{getMessage(config, 'suggestions')}</li>
      {suggestionItems?.map((suggestion, key: number) => (
        <li key={`suggest-${key}`} className={'list-item h6'}>
          <Link to={getUrl(suggestion.url, page)}
                onClick={() => {
                  setRecentSearches(suggestion.term!);
                  pushGtmEventSuggestion(suggestion.term!, input);
                }}
                dangerouslySetInnerHTML={{__html: suggestion.highlightedTerm}}
          />
        </li>
      ))}
    </ul>
  )
}
