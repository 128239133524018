/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * BlogRow component
 */
import * as React from 'react';
import AuthorByLine from '../../author/author-byline/templates/AuthorByLine';
import {BlogProps} from '../BlogComponentTypes';
import {Link} from 'react-router-dom';

export const BlogRow = ({introduction, title, link, categories, authors, date}: BlogProps) => {
  const trimmedDescription = introduction && introduction.length > 120 ? introduction.substring(0, 120) + '...' : introduction;
  return (
    <article className='card shadow mb-5 blog-row'>
      <div className='card-body p-5'>
        {categories && categories.map((category, key) => <a
            className='d-inline-block btn btn-sm btn-soft-secondary font-weight-medium text-uppercase small mr-1 mb-2'
            href='/' key={'category-' + key}>{category}</a>)}

        {link && <h2 className='h5 font-weight-medium'>
          <Link to={link.url}>{title}</Link>
        </h2>}

        {trimmedDescription && <p dangerouslySetInnerHTML={{__html: trimmedDescription}}/>}

        {authors && <div className='media align-items-center pt-5'>
          <AuthorByLine authors={authors} componentStyle={'List'} date={date}/>
        </div>}
      </div>
    </article>
  )
};
