/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Container} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {Pagination, Reference} from '@bloomreach/spa-sdk';
import {ContentComponent} from './ContentComponent';
import {ListPagination} from '../utility';
import {getListPaginationPropsFromBrxm} from '../utility/list-pagination/ListPaginationUtils';

export const ContentListComponent = (props: BrProps) => {
  const {
    page,
    component
  } = props;

  const pageable = page!.getContent<Pagination>(component!.getModels().pagination);
  if (!pageable) {
    return null;
  }

  const pagination = getListPaginationPropsFromBrxm(pageable);

  return (
    <Container className='space-2 space-bottom-lg-3 content-list'>
      {pageable.getItems().map((reference: Reference, key: number) => {
        return (
          <div className='mb-4' key={key}>
            <ContentComponent
              {...{
                documentRef: reference,
                params: {template: 'row'},
                template: 'row',
                nowrap: true,
              }}
            />
          </div>
        );
      })}
      <div className='space-bottom-2 font-size-xs'/>
      {pagination && <ListPagination {...pagination}/>}
    </Container>
  );
};
