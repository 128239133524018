/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react"
import {Container} from 'react-bootstrap';
import {CarouselProvider, DotGroup, Slide, Slider} from 'pure-react-carousel';
import {PromotionMedia, PromotionProps} from './PromotionComponentTypes';
import {PromotionImage, PromotionText, PromotionVideo} from './templates';

export const PromotionComponent = (props: PromotionProps) => {
  const {template = 'DEFAULT', videos, images} = props;

  const medias: Array<PromotionMedia> = (videos ?? []).concat(images ?? []);

  const carouselSettings = {
    infinite: true,
    interval: 6000,
    isIntrinsicHeight: true,
    isPlaying: true,
    naturalSlideHeight: 4,
    naturalSlideWidth: 12,
    totalSlides: medias.length,
  };

  const Promotion = (media: PromotionMedia) => {
    switch (media.type) {
      case 'TEXT':
        return <PromotionText {...{...media}}/>;
      case 'VIDEO':
        return <PromotionVideo {...{...media, template}}/>;
      case 'IMAGE':
        return <PromotionImage {...{...media, template}}/>;
      default:
        return null;
    }
  }

  return (
    <CarouselProvider {...carouselSettings}>
      <Slider>
        {medias?.map((media: PromotionMedia, key: number) => (
          <Slide key={key} index={key}>
            <Promotion {...{...media}}/>
          </Slide>
        ))}
      </Slider>
      {carouselSettings.totalSlides > 1 && <Container
        className='carousel-controls d-flex justify-content-center mt-n5 mb-5 position-relative z-index-4'>
        <DotGroup/>
      </Container>}
    </CarouselProvider>
  )
}
