/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {ChangeEvent, useContext, useRef, useState} from 'react';
import {Container, FormControl, InputGroup, OverlayTrigger, Popover} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BrProps} from '@bloomreach/react-sdk';
import {GlobalSearchContext, UserContext} from '../../contexts';
import {AutoSuggestionComponent} from '..';
import {QUERY_PARAM_FACET, QUERY_PARAM_QUERY} from '../../utils/SearchUtil';
import {pushGtmEventSearch} from '../gtm/GTMComponentUtils';
import {getMessage} from '../../utils/MessageUtils';
import {isSaas, SEGMENT_PARAM_NAME, SEGMENT_SAAS_PARAM_NAME} from '../../utils/UrlUtils';

export interface PopoverState {
  showAutosuggestion: boolean,
  input: string
}

const config = require('./SearchBoxConfig.json');
const routeConfig = require('../../utils/RouteConfig.json');

export const SearchBoxComponent = (props: BrProps) => {
  const {
    page,
    component
  } = props;
  const useSearchContext = () => useContext(GlobalSearchContext);
  const {globalSearchParams} = useSearchContext()!;
  const {userSegmentState: {userSegment}, recentSearchesState: {setRecentSearches}} = useContext(UserContext)!;

  const {showRecentSearch, showPredefinedSearches, predefinedSearches} = component!.getParameters();

  const history = useHistory();

  const [popoverState, setPopoverState] = useState<PopoverState>({
    showAutosuggestion: false,
    input: ''
  });

  const keywordInput = useRef<HTMLInputElement>(null);

  const getSearchUrl = (keyword: string) => {
    const searchPageUrl = page!.getUrl(routeConfig.search)!;
    // Fixes preview case
    let parsedQS = new URLSearchParams(searchPageUrl.indexOf('?') !== -1 ? searchPageUrl.substring(searchPageUrl.indexOf('?') + 1) : '');
    if (keyword) {
      parsedQS.set(QUERY_PARAM_QUERY, keyword);
    }
    if (globalSearchParams.filter) {
      parsedQS.set(QUERY_PARAM_FACET, globalSearchParams.filter);
    }

    const segment = userSegment?.secondary || userSegment?.primary;
    const paramName = isSaas() ? SEGMENT_SAAS_PARAM_NAME : SEGMENT_PARAM_NAME;
    if (segment) {
      parsedQS.set(paramName, segment);
    } else {
      if (parsedQS.has(paramName)) {
        parsedQS.delete(paramName);
      }
    }

    return {
      pathname: searchPageUrl.indexOf('?') !== -1 ? searchPageUrl.substring(0, searchPageUrl.indexOf('?')) : searchPageUrl,
      search: `?${parsedQS}`,
      state: {from: history.location}
    };
  };

  const handleSearchInputEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      //globalSearchParams.segment = getCurrentSegment();
      setRecentSearches(target.value);
      pushGtmEventSearch(target.value);
      history.push(getSearchUrl(target.value));
    } else {
      //event.persist();
      const input = target.value;
      setTimeout(() => {
        if (input === keywordInput.current?.value) {
          if (input === '') {
            setPopoverState({
              showAutosuggestion: false,
              input
            });
          } else {
            if (popoverState.input !== input) {
              setPopoverState({
                showAutosuggestion: true,
                input
              });
            }
          }
        }
      }, 100);
    }
  };

  const handleSearchInputFocus = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const input = event.target.value;
    if (input === keywordInput.current?.value) {
      if (input === '') {
        popoverState.showAutosuggestion && setPopoverState({
          showAutosuggestion: false,
          input
        });
      } else {
        setPopoverState({
          showAutosuggestion: true,
          input
        });
      }
    }
  };

  const handleSearchButtonClick = () => {
    setRecentSearches(keywordInput.current?.value);
    pushGtmEventSearch(keywordInput.current?.value);
    history.push(getSearchUrl(keywordInput.current?.value!));
  };

  const {showAutosuggestion, input} = popoverState;

  const popoverStyles: React.CSSProperties = {};
  if (!showAutosuggestion) {
    popoverStyles.border = 'none';
  }

  return (
    <OverlayTrigger
      trigger={['focus']}
      placement='bottom'
      delay={{show: 250, hide: 400}}
      overlay={
        <Popover id='auto-suggestion-popover' className='no-arrow' style={{...popoverStyles}}>
          {showAutosuggestion &&
          <Container><AutoSuggestionComponent {...{
            input,
            keyword: input,
            offset: 0,
            limit: 3,
            setPopoverState,
            showRecentSearch,
            showPredefinedSearches,
            predefinedSearches
          }} {...props}/></Container>}
        </Popover>
      }>
      <InputGroup className='mx-auto'>
        <FormControl
          type='text'
          id='searcKInput' //This is not a typo. It is for completely disabling autofill
          placeholder={getMessage(config, 'search')}
          autoComplete='off'
          onKeyUp={handleSearchInputEnter}
          onFocus={handleSearchInputFocus}
          defaultValue={input}
          ref={keywordInput}
          aria-describedby='search-icon'/>
        <InputGroup.Append>
          <InputGroup.Text>
            <FontAwesomeIcon id='search-icon' icon={'search'} onClick={handleSearchButtonClick}/>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </OverlayTrigger>
  );
}
