/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {Button, Card, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BrProps} from '@bloomreach/react-sdk';
import {UserContext} from '../../../contexts';
import {Status} from '../../utility';
import {ProductPrice} from '../../product/templates';
import {OrdersProps, withDemoOrders} from '../../../demo-connector-components';
import {getProfileTabUrl} from '../UserProfileComponentUtils';
import {Link, useHistory} from 'react-router-dom';
import {getUrl} from '../../../utils/UrlUtils';
import {MoneyAmount} from '../../../demo-connector-components/DemoBrsmUtils';

export interface ProfileOverviewProps {
  setSelected: React.Dispatch<React.SetStateAction<string>>
}

const config = require('../UserProfileComponentConfig.json');

function ProfileOverviewBase(props: ProfileOverviewProps & BrProps & OrdersProps) {
  const {
    page,
    loading,
    ordersPageResult,
  } = props;
  const {userState: {user}, userSegmentState: {userSegment}} = useContext(UserContext)!;

  const history = useHistory();
  if (loading) {
    return (
      <Status container loading status={config.profile.getOrders.defaultLoadingMessage}/>
    )
  }

  const orders = ordersPageResult?.orders;

  const LastOrder = () => {
    if (!orders || orders.length === 0) {
      return null;
    }
    const {id, status, totalAmount, creationDate} = orders[0]!;
    const orderUrl = getUrl('/orders/' + id, page!)!;
    return (
      <Row as={'dl'} className={'font-size-1 mt-2'}>
        <Col as={'dd'} sm={12}><Link to={orderUrl}>Last Order</Link></Col>
        <Col as={'dt'} sm={5}>Status</Col>
        <Col as={'dd'} sm={7}>{status}</Col>
        <Col as={'dt'} sm={5}>Total Amount</Col>
        <Col as={'dd'} sm={7}><ProductPrice moneyAmount={totalAmount[0] as MoneyAmount}/></Col>
        <Col as={'dt'} sm={5}>Order Date</Col>
        <Col as={'dd'} sm={7}>{new Date(creationDate).toLocaleString()}</Col>
      </Row>
    )
  }

  return (
    <div className={'mt-4'}>
      <h4 className={'mb-4'}>{`Welcome back, ${user?.firstName}`}</h4>
      <Row>
        <Col xs={4}>
          <Card>
            <Card.Body>
              <FontAwesomeIcon icon={'shopping-bag'} size={'4x'} className={'float-right'}/>
              <h5>My Orders</h5>
              <div className={'text-muted font-size-1 clearfix'}>Track,return,or buy things again</div>
              <LastOrder/>
            </Card.Body>
            <Card.Footer>
              <Button variant={'link'} onClick={() => {
                history.push(getProfileTabUrl(page!, 'my-orders', userSegment));
              }}>See Previous Orders</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export const ProfileOverview = withDemoOrders(ProfileOverviewBase);
