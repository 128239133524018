/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {Alert, Col, Container, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getMessage} from '../../../utils/MessageUtils';

export interface StatusProps {
  customClassName?: string,
  customMessage?: string,
  container?: boolean,
  status: string | null,
  dismissible?: boolean,
  warning?: boolean,
  error?: boolean,
  loading?: boolean,
  onClose?: Function
}

const config = require('./StatusConfig.json');
export const Status = (props: StatusProps) => {
  const [show, setShow] = useState(true);

  let {customClassName = '', customMessage, container, status, dismissible, warning, error, loading, onClose} = props;

  if (!status) {
    return null;
  }

  const WithContainer = ({container, children}: any) => (container ?
      <Container className={'mt-5'}>
        {children}
      </Container>
      : children
  );

  const dismiss = !!dismissible ? {onClose: () => {onClose && onClose(); setShow(false)}, dismissible: true} : {};

  if (!show) {
    return null;
  }

  // Figure out variant for alert
  let displayMessage = status;
  let variant = 'success';
  if (warning) {
    variant = 'warning';
  }
  if (error) {
    variant = 'danger';
  }

  if (loading) {
    return (
      <WithContainer container={container}>
        <Alert className={customClassName} variant='success' {...dismiss}>
          <Row>
            <Col xs={1}>
              <FontAwesomeIcon icon='spinner' size={'4x'} spin/>
            </Col>
            <Col>
              <Alert.Heading>{getMessage(config, 'Just one second')}</Alert.Heading>
              <p>{getMessage(config, displayMessage || customMessage || "")}</p>
            </Col>
          </Row>
        </Alert>
      </WithContainer>
    )
  } else {
    return (
      <WithContainer container={container}>
        <Alert variant={variant} className={customClassName} {...dismiss}>
          <p>{displayMessage}</p>
        </Alert>
      </WithContainer>
    )
  }
}
