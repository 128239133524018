/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Page} from '@bloomreach/spa-sdk';
import {parseSearchImageUrl} from '../../utils/ImageUtil';
import {MenuComponentItem, MenuComponentParams, MenuComponentType} from './MenuComponentTypes';

// const config = require( './MenuComponentConfig.json' );
export const getMenuProps = (page: Page, menu: object, params: object, template: string, banners: any, channelId: string): MenuComponentType => {
  const navItems = getItems({page, menu, banners, channelId});
  const navParams = getParams(params, template);

  return {
    type: 'Menu',
    items: navItems,
    params: navParams,
  };
};

const getItems = ({page, menu, banners, channelId}: any): Array<MenuComponentItem> => {
  return menu.getItems().map((siteMenuItem: any) => {
    const bannerTitle = siteMenuItem.getParameters() && siteMenuItem.getParameters()['Related Banner (Title of desired Banner document)'];
    let bannerElem = null;
    if (bannerTitle && banners[bannerTitle]) {
      bannerElem = {
        documentRef: banners[bannerTitle],
        params: {},
        template: 'menu'
      };
      // TODO: disable the search call for now
      //getPersonalizedBanner(siteMenuItem.name);
    }
    return getItem({page, siteMenuItem, bannerElem, channelId});
  });
};

const getParams = (params: any, template: string): MenuComponentParams => {
  return {
    siteMenu: params['siteMenu'],
    template: template || params['template'] || 'default'
  };
};

const getItem = ({page, siteMenuItem, bannerElem = null, channelId}: any): MenuComponentItem => {
  const itemParams = siteMenuItem.getParameters() || {};
  const imageUrl = itemParams.image && parseSearchImageUrl(itemParams.image).url;
  let url = siteMenuItem.getUrl();
  if (siteMenuItem.getLink()?.type === 'external' && url?.startsWith('/')) {
    url = `/${channelId}${url}`;
  }
  let item: MenuComponentItem = {
    name: siteMenuItem.getName(),
    url,
    selected: siteMenuItem.isSelected(),
    display: itemParams.display,
    icon: itemParams.icon,
    image: imageUrl,
    userType: itemParams.usertype,
  };

  if (bannerElem) {
    item.banner = bannerElem;
  }

  const children = siteMenuItem.getChildren();
  if (children && children.length > 0) {
    item.childNavItems = children.map((childMenuItem: any) => {
      return getItem({page: page, siteMenuItem: childMenuItem, channelId});
    });
  }

  return item;
};
