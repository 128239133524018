/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BrProps} from '@bloomreach/react-sdk';
import {Icon, Status} from '../../utility';
import {RemoveAddressProps, withRemoveAddress} from '../../../connector-components';
import {CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup_addresses} from '../../../modules/commerce';
import {GetErrorProps, withGetError} from '../../../connector-components';

export interface RemoveAddressExtraProp {
  address: CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup_addresses
}

const config = require('../UserProfileComponentConfig.json');
function RemoveUserAddressBase(props: BrProps & RemoveAddressProps & RemoveAddressExtraProp & GetErrorProps) {
  const {
    //addressId,
    address,
    removeAddress,
    loading,
    result,
    errors,
    clearError
  } = props;

  const [show, setShow] = useState(false);

  const {
    id,
    additionalStreetInfo,
    city,
    postalCode,
    state,
    streetAddress,
    country
  } = address;

  const addressText = `${streetAddress} ${additionalStreetInfo}, ${city}, ${state} ${country} ${postalCode}`;
  const DEFAULT_MESSAGE = config.address.removeUserAddress.defaultMessage;

  useEffect(() => {
    if (result?.success) {
      show && setShow(false);
    }
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage = errors?.find((error) => error.operation === 'addAddress')?.message;
  return (
    <Fragment>
      <Button variant={'danger'} size={'sm'} onClick={(event) => {
        setShow(true);
      }}><FontAwesomeIcon icon='window-close'/></Button>
      <Modal show={show} onHide={() => setShow(false)} size='lg'>
        <Modal.Header closeButton>
          <span className='align-middle'>
            <Icon name={'minus-circle'}>
              Remove Address {id}
            </Icon>
          </span>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure to remove this address?</h3>
          <p>{addressText}</p>
          {errorMessage && <Status error status={errorMessage} customClassName={'mt-3'} dismissible={true}/>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'danger'} onClick={() => {
            clearError();
            removeAddress();
          }}>
            {loading ? <Icon name={'spinner'} size={'lg'} spin content={DEFAULT_MESSAGE}/>
              : <Icon name={'window-close'} content={DEFAULT_MESSAGE}/>}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export const RemoveUserAddress = withGetError(withRemoveAddress(RemoveUserAddressBase));
