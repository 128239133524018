/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Container} from 'react-bootstrap';
import {BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import {ComponentWrapper} from '..';
import {ContentCard, ContentItem, ContentRow} from './templates';
import {ContentComponentArgs, ContentComponentProps} from './ContentComponentTypes';
import {getMockProps} from './ComponentContentUtils';
import {getDocument} from '../../utils/DocumentUtils';

// in case of a content list, documentRef will be defined
export const ContentComponent = ({
                                   documentRef,
                                   params,
                                   template,
                                   nowrap = false,
                                 }: ContentComponentArgs) => {
  const page = React.useContext(BrPageContext)!;
  const component = React.useContext(BrComponentContext)!;

  // if this is an old-style non-dynamic component, we should get a non-empty document component property value
  documentRef = documentRef || component.getModels().document;

  const document = getDocument(page, 'brxsaas:demoContent', documentRef);

  const docName = document ? document.getName() : 'undefined';
  params = params || component.getParameters();

  const regexp = new RegExp(``, 'gi');
  const data = document?.getData();

  const text = data?.content?.value ?? data?.content;

  const props = data
    ? (({
      content: {
        ...data,
        text: text && page.rewriteLinks(text.replace(regexp, '')),
      }, // /\/site\/supply/ig
      params: {template: template || params['template'] || 'full-width'},
    } as unknown) as ContentComponentProps)
    : page.isPreview() ? getMockProps(params) : undefined;

  if (!props) {
    return null;
  }

  const componentWrapperProps = {
    type: 'Simple Content',
    wrap: !nowrap,
    inlineEditingProps: {
      page,
      content: document,
      documentTemplateQuery: 'new-democontent-document',
      path: 'content',
      pickerSelectableNodeTypes: 'brxsaas:demoContent',
      root: 'content',
      editOnly: false
    }
  };

  return (
    <ComponentWrapper {...componentWrapperProps}>
      <Container data-content-name={docName} className={`content--${props.params.template}`}>
        {props && renderTemplate(props)}
      </Container>
    </ComponentWrapper>
  );
};

const renderTemplate = (props: ContentComponentProps) => {
  const template = props.params.template;
  switch (template) {
    case 'card':
      return <ContentCard {...props} />;
    case 'inline':
      return <ContentItem {...props} />;
    default:
      return <ContentRow {...props} />;
  }
};
