/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect, useState} from 'react';
import {Container, Nav} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {BrProps} from '@bloomreach/react-sdk';
import {ComponentHeader, SecuredComponentWrapper} from '../utility';
import {ProfileAddresses, ProfileMyOrders, ProfileOverview, ProfileSecurity} from './profile';
import {getProfileTabUrl} from './UserProfileComponentUtils';
import {UserContext} from '../../contexts';

export interface ProfileMenuItem {
  key: string,
  name: string
}

const config = require('./UserProfileComponentConfig.json');

export const UserProfileComponent = (props: BrProps) => {
  const {
    page,
    component
  } = props;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  const history = useHistory();
  const {menu, myOrders} = config;
  const queryParameters = new URLSearchParams(window.location.search);
  const activeTabKey = queryParameters.get('active');
  const [selected, setSelected] = useState<string>(activeTabKey || menu.defaultItem);

  useEffect(() => {
    if (activeTabKey) {
      setSelected(activeTabKey);
    }
  }, [activeTabKey]);

  const renderProfileComponent = () => {
    switch (selected) {
      case 'overview' :
        return <ProfileOverview page={page!} component={component!} pageSize={1} pageNumber={1} setSelected={setSelected}/>;
      case 'my-orders' :
        let pageSize: number = myOrders.defaultPageSize;
        if (queryParameters.get(myOrders.queryParamPageSize)) {
          pageSize = parseInt(queryParameters.get(myOrders.queryParamPageSize)!);
        }
        let pageNumber: number = myOrders.defaultPage;
        if (queryParameters.get(myOrders.paramCurrentPage)) {
          pageNumber = parseInt(queryParameters.get(myOrders.paramCurrentPage)!);
        }
        return <ProfileMyOrders page={page!} component={component!} pageSize={pageSize} pageNumber={pageNumber}/>;
      case 'addresses':
        return <ProfileAddresses page={page!} component={component!}/>
      case 'security':
        return <ProfileSecurity page={page!} component={component!}/>
    }
  }

  return (
    <SecuredComponentWrapper>
      <div className={'profile'}>
        <ComponentHeader title={'My Account'} showTitle={true}/>
        <Container className={'mt-3'}>
          <Nav
            className={'border-bottom border-secondary'}
            activeKey={selected}
            onSelect={(selectedKey: string | null) => {
              history.push(getProfileTabUrl(page!, selectedKey, userSegment));
            }}
          >{menu.items?.map((item: ProfileMenuItem, menuKey: number) => {
            const {key, name} = item;
            return (
              <Nav.Item key={menuKey}>
                <Nav.Link eventKey={key}>{name}</Nav.Link>
              </Nav.Item>
            )
          })}
          </Nav>
          {renderProfileComponent()}
        </Container>
      </div>
    </SecuredComponentWrapper>
  );
}
