/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useContext} from 'react';
import {Link} from 'react-router-dom';
import {Media} from 'react-bootstrap';
import {BrPageContext} from '@bloomreach/react-sdk';
import {SuggestionProduct} from '../AutoSuggestionTypes';
import {getMessage} from '../../../utils/MessageUtils';
import {ProductPrice} from '../../product/templates';
import {getUrl} from '../../../utils/UrlUtils';
import {Price} from '../../../demo-connector-components/DemoBrsmUtils';

export interface SuggestedProductsProps {
  products: Array<SuggestionProduct> | undefined
}

const config = require('../AutoSuggestionConfig.json');
export const SuggestedProducts = (props: SuggestedProductsProps) => {
  const {
    products
  } = props;

  const page = useContext(BrPageContext)!;

  const hasProducts = products && products.length > 0;
  if (!hasProducts) {
    return null;
  }
  return <Fragment>
    <h5>{getMessage(config, 'products')}</h5>
    {products?.map((product, key: number) => {
      const purchasePrice = product.purchasePrice as Price;
      return (
        <div className={'h6'} key={key}>
          <Link to={getUrl(product.url, page)!}>
            <Media className={'mb-3'}>
              <div className={'image-thumbnail image-background mr-2'} style={{
                backgroundImage: 'url(' + product.imageSet?.original?.link?.href + ')'
              }}/>
              <Media.Body>
                <strong className={'font-weight-normal'}>
                  {product.displayName}
                </strong>
                <div>{purchasePrice && <ProductPrice price={purchasePrice}/>}</div>
              </Media.Body>
            </Media>
          </Link>
        </div>
      )
    })}
  </Fragment>
}
