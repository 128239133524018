/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {FormControl, InputGroup, Pagination} from 'react-bootstrap';
import {ListPaginationProps} from '../ListPaginationTypes';

export const ListPaginationList = (props: ListPaginationProps) => {
  const {
    currentPage,
    previous,
    previousPageUrl,
    next,
    nextPageUrl,
    pageNumbers,
    totalPages,
    previousBatch,
    previousBatchLastPage,
    nextBatch,
    nextBatchFirstPage,
    getUrl,
  } = props;

  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState<string | number>(currentPage);
  if (!totalPages) {
    return null;
  }

  const gotoPage = (pageUrl: string) => {
    history.push(pageUrl);
  }

  return (
    <Pagination className={'my-5 justify-content-center'}>
      <Pagination.Item className={'d-none'}/>
      <Pagination.Prev disabled={!previous} href={previousPageUrl}/>
      {previousBatch && <Pagination.Item href={getUrl && getUrl(previousBatchLastPage)}>...</Pagination.Item>}
      {pageNumbers && pageNumbers.map((page, key) => (
        <Pagination.Item
          active={page.page === currentPage}
          key={key}
          className={'d-none d-lg-block'}
          onClick={() => {
            gotoPage(page.pageUrl);
          }}>
          {page.page}
        </Pagination.Item>
      ))}
      <Pagination.Item className={'d-lg-none'}>
        <select value={selectedPage}
                onChange={(e) => {
                  setSelectedPage(e.target.value);
                  const pageUrl = e.target[e.target.selectedIndex].getAttribute('data-url');
                  gotoPage(pageUrl!);
                }}>
          {pageNumbers && pageNumbers.map((page, key) => (
            <option key={key} value={page.page} data-url={page.pageUrl}>
              {page.page}
            </option>
          ))}
        </select>
      </Pagination.Item>
      {nextBatch &&
      <Pagination.Item href={getUrl && getUrl(nextBatchFirstPage)}>...</Pagination.Item>}
      <Pagination.Next disabled={!next} href={next ? nextPageUrl : '#'}/>
      <li className={'page-item'}>
        <InputGroup size={'sm'}>
          <FormControl
            defaultValue={currentPage}
            style={{
              width: '4em'
            }}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                if (getUrl) {
                  gotoPage(getUrl(parseInt(e.currentTarget.value)));
                }
              }
            }}
            aria-describedby='pagination-page-input'
          />
          <InputGroup.Append>
            <InputGroup.Text id='pagination-page-input'>of {totalPages}</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </li>
    </Pagination>
  )
};
