/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {BrComponentContext, BrPageContext,} from '@bloomreach/react-sdk';
import {ComponentWrapper} from '..';
import {VideoComponentArgs, VideoComponentProps} from './VideoComponentTypes';
import {getVideoProps} from './VideoComponentUtils';
import {getDocument} from '../../utils/DocumentUtils';
import {VideoMock} from '../../mocks/ContentMocks';

export const VideoComponent = (props: VideoComponentArgs) => {
  const {
    documentRef: documentRefInput,
    params: paramsInput,
    template: templateInput,
    nowrap = false,
  } = props;
  const page = React.useContext(BrPageContext)!;
  const component = React.useContext(BrComponentContext)!;

  const documentRef = documentRefInput || component.getModels().document;

  const params = paramsInput || component.getParameters();
  const template = templateInput || (params?.template);

  let videoProps = documentRef && getVideoProps(page, {
    documentRef,
    params,
    template,
    nowrap,
  });

  if (!videoProps && page.isPreview()) {
    videoProps = {
      type: 'Video',
      documentRef: '',
      params: {
        template: 'right',
        height: 300,
        width: 400
      },
      content: VideoMock
    }
  }

  if (!videoProps) {
    return null;
  }

  const RenderVideo = () => {
    switch (template) {
      case 'right':
        return <Row>
          {renderContent(videoProps!, 1)}
          {renderVideo(videoProps!, 1)}
        </Row>;
      case 'left':
      default:
        return <Row>
          {renderVideo(videoProps!, 1)}
          {renderContent(videoProps!, 1)}
        </Row>;
    }
  }

  return (
    <ComponentWrapper {...{
      type: 'Video',
      wrap: true,
      inlineEditingProps: {
        page,
        content: getDocument(page, 'brxsaas:demoVideo', documentRef),
        documentTemplateQuery: 'new-demovideo-document',
        path: 'videos',
        pickerSelectableNodeTypes: 'brxsaas:demoVideo',
        root: 'videos',
        editOnly: false
      }
    }}>
      <Container className={'video-component'}>
        <RenderVideo/>
      </Container>
    </ComponentWrapper>
  );
};

const renderContent = (props: VideoComponentProps, order: number) => {
  const {content} = props;
  return (
    <Col
      className={`video-content-col d-flex col-12 col-md-5 order-1 align-items-center justify-content-center order-md-${order}`}
    >
      <div className='video-content-wrapper text-center'>
        {content.title && <div className='video-content-title h2 mb-2'>{content.title}</div>}
        {content.description && <div
          className={'video-content-text'}
          dangerouslySetInnerHTML={{__html: content.description}}
        />}
      </div>
    </Col>
  );
};

const renderVideo = (props: VideoComponentProps, order: number) => {
  const {content, params} = props;
  const {width, height} = params;
  return (
    <Col
      className={`video-player-col d-flex align-items-center justify-content-center col-12 col-md-7 order-12 order-md-${order}`}
    >
      <div className={'video-player-wrapper'}>
        {content.videoItem.videoType === 'hosted' ? (
          <video width={width} height={height} controls>
            <source src={content.videoItem.videoLink}/>
          </video>
        ) : (
          <iframe
            width={width}
            height={height}
            frameBorder={'0'}
            title={content.title}
            src={content.videoItem.videoLink}
          ></iframe>
        )}
      </div>
    </Col>
  );
};
