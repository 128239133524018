/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Card, useAccordionToggle} from 'react-bootstrap';
import {Icon} from '../../icon/Icon';
import {FacetListState} from '../FacetListTypes';
import {getMessage} from '../../../../utils/MessageUtils';

export interface FacetToggleProps {
  eventKey: string,
  facetName: string,
  facetsState: FacetListState,
  setFacetsState: React.Dispatch<React.SetStateAction<FacetListState>>
}

const config = require('../FacetListConfig.json');
export const FacetToggle = (props: FacetToggleProps) => {
  const {
    eventKey,
    facetName,
    facetsState,
    setFacetsState
  } = props;

  const toggleOnClick = useAccordionToggle(eventKey, () => {
    const newState = {...facetsState};
    newState[facetName].open = !newState[facetName].open;
    setFacetsState(newState);
  });

  return (
    <Card.Header
      className='cursor-pointer border-0 bg-transparent d-flex font-weight-bold justify-content-between p-2'
      onClick={toggleOnClick}>
      <span className='text-uppercase my-auto'>{getMessage(config, facetName)}</span>
      {facetsState && facetsState[facetName]?.open ? (
        <Icon name={'minus'}/>
      ) : (
        <Icon name={'plus'}/>
      )}
    </Card.Header>
  );
};
