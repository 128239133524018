/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {sessionService} from '../../modules/commerce/api';
import {
  FIND_ORDERS,
  PLACE_ORDER,
  PlaceOrder,
  PlaceOrder_placeOrder,
  PlaceOrderVariables,
} from '../../modules/commerce';
import {withApolloClient} from '../../connector-components';

export interface InputProps {
  orderId: string;
}

export interface PlaceOrderProps {
  placeOrder: () => Promise<FetchResult<PlaceOrder>>;
  loading: boolean;
  result?: PlaceOrder_placeOrder;
}

function withPlaceOrderBase<P>(Component: React.ComponentType<P & PlaceOrderProps>) {
  return (props: P & InputProps) => {
    const [placeOrder, {loading, data}] = useMutation<PlaceOrder, PlaceOrderVariables>(PLACE_ORDER, {
      variables: {
        id: props.orderId,
      },
      onCompleted: () => {
        sessionService.setCartIdInSession(undefined);
      },
      refetchQueries: [
        {
          query: FIND_ORDERS,
        },/*
        {
          query: GET_CART,
        },
        */
      ],
      awaitRefetchQueries: true,
    });

    return <Component placeOrder={placeOrder} loading={loading} result={data?.placeOrder} {...props} />;
  };
}

export function withDemoPlaceOrder<P>(Component: React.ComponentType<P & PlaceOrderProps>) {
  return withApolloClient(withPlaceOrderBase(Component));
}
