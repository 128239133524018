/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useMemo, useState} from 'react';
import {BrProps} from '@bloomreach/react-sdk';
import {ItemFragment} from '../../modules/commerce';
import {getSmViewId} from '../../connector-components/utils';
import {ProductDetailInputProps, ProductDetailProps} from './DemoProductDetail';
import {BrProduct, DEFAULT_BRSM_FIELDS, getItem, ItemId} from '../DemoBrsmUtils';
import {useSearch} from '../../hooks';
import {Status} from '../../components/utility';

function withDemoBrsmProductDetailBase<P extends BrProps>(Component: React.ComponentType<P & ProductDetailProps>) {
  return (props: P & ProductDetailInputProps) => {
    const page = props.page;
    const {productID} = props;
    const [productId, setProductId] = useState<string>(productID);
    const smViewId = getSmViewId(page);

    const {
      smEndpoint,
      discoveryRealm,
      discoveryAuthKey,
      discoveryDomainKey,
      discoveryAccountId,
      discoveryFields,
      smAuthKey,
      smDomainKey,
      smAccountId
    } = page!.getChannelParameters();

    const endpoint = smEndpoint || `https://${discoveryRealm === 'STAGING' ? 'staging-': ''}core.dxpapi.com/api/v1/core/`;
    const authKey = smAuthKey || discoveryAuthKey;
    const domainKey = smDomainKey || discoveryDomainKey;
    const accountId = smAccountId || discoveryAccountId;
    const fields = discoveryFields || DEFAULT_BRSM_FIELDS;

    useEffect(() => {
      setProductId(productID);
    }, [productID]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!productId) {
      return null;
      /*
      addError({
        code: 'VALIDATION_ERROR',
        message: 'Product id or code not provided',
        operation: 'fetchProductDetail',
      });
       */
    }

    const itemId: ItemId = new ItemId(productId);

    const pid = itemId.id ?? '';

    const params = useMemo(
      () => {
        return {
          view_id: smViewId,
          account_id: accountId,
          auth_key: authKey,
          domain_key: domainKey,
          search_type: 'keyword',
          request_type: 'search',
          url: window.location.href,
          ref_url: window.location.href,
          request_id: new Date().getTime(),
          //widget_id: widgetId || widget?.[searchType],
          //user_id: userId,
          fl: fields.split(','),
          rows: 10,
          q: !pid && itemId.code ? `sku_ids:${itemId.code}` : (pid ?? ''),
          fq: {
            id: 'pid',
            values: [pid]
          },
          facet: 'false',
        }
      },
      [
        accountId,
        authKey,
        domainKey,
        smViewId,
        itemId.code,
        pid,
        fields
      ],
    );

    // Perform actual search query
    const [results, loading, error] = useSearch(endpoint, params);
    if (loading) {
      return null;
    }

    if (!loading && error) {
      return <Status status={error.message} container error/>
    }

    const {
      response
    } = results;

    const {docs} = response ?? {};

    if (!docs?.[0]) {
      return <Status container error status={`Product with id ${productId} not found!`}/>
    }

    const item = getItem((docs as (BrProduct)[])?.[0]) as ItemFragment;

    return <Component
      productId={productId}
      setProductId={setProductId}
      item={item}
      loading={loading}
      {...props}
    />;
  };
}

export function withDemoBrsmProductDetail<P extends BrProps>(Component: React.ComponentType<P & ProductDetailProps>) {
  return withDemoBrsmProductDetailBase(Component);
}
