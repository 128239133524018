/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useQuery} from '@apollo/client';
import {BrProps} from '@bloomreach/react-sdk';
import {getDefaultConnector, getSmViewId} from '../../connector-components/utils';
import {Page} from '@bloomreach/spa-sdk';
import {GET_ITEM_BY_ID, Item, ItemFragment, ItemVariables} from '../../modules/commerce';
import {withApolloClient} from '../../connector-components';
import {addError} from '../../modules/commerce/api';

export interface ProductDetailProps {
  loading: boolean;
  item?: ItemFragment;
  productId?: string;
  setProductId: React.Dispatch<React.SetStateAction<string>>;
}

export interface ProductDetailInputProps {
  productID: string;
  page: Page;
  connector?: string;
  clearError?: Function
}

function withProductDetailBase<P extends BrProps>(Component: React.ComponentType<P & ProductDetailProps>) {
  return (props: P & ProductDetailInputProps) => {
    const page = props.page;
    const {productID, connector = getDefaultConnector(page) || 'brsm'} = props;
    const [productId, setProductId] = useState<string>(productID);
    const smViewId = getSmViewId(page);
    const [cookies] = useCookies(['_br_uid_2']);

    useEffect(() => {
      setProductId(productID);
    }, [productID]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!productId) {
      addError({
        code: 'VALIDATION_ERROR',
        message: 'Product id or code not provided',
        operation: 'fetchProductDetail',
      });
    }

    const {loading, data} = useQuery<Item, ItemVariables>(GET_ITEM_BY_ID, {
      variables: {
        id: productId,
        queryHint: {
          brUid2: cookies._br_uid_2,
          viewId: smViewId,
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      context: {
        headers: {
          connector,
        },
      },
    });

    return <Component
      productId={productId}
      setProductId={setProductId}
      item={data?.findItemById ?? undefined}
      loading={loading}
      {...props}
    />;
  };
}

export function withDemoProductDetail<P extends BrProps>(Component: React.ComponentType<P & ProductDetailProps>) {
  return withApolloClient(withProductDetailBase(Component));
}
