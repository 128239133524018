/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Badge, Image, Media} from 'react-bootstrap';
import {AuthorByLineProps} from '../AuthorByLineComponentTypes';
import {Link} from 'react-router-dom';
import {getMessage} from '../../../../utils/MessageUtils';
import {getDateString} from '../../../../utils/DateUtils';
import {getImageVariantBySize} from '../../../../utils/ImageUtil';
import {ImageSizeEnum} from '../../../global/CommonEnums';

const config = require('../../AuthorConfig.json');
const AuthorByLineArticle = ({authors, date}: AuthorByLineProps) => {
  if (authors.length === 0) {
    return null;
  }
  return (
    <ul className={'list-inline author-byline-article'}>
      {authors.map((author, key) => {
        const {name, link, images} = author;
        // TODO: saas instance
        const image = getImageVariantBySize(images, ImageSizeEnum.Thumbnail);
        return (
            <li className={'list-inline-item'} key={key}>
              <Media>
                {image && <Image roundedCircle src={image} className={'image-avatar mr-3'}/>}
                <Media.Body>
                  <h5>
                    <Link to={link}>
                      {name}
                      <Badge pill variant={'primary'} className={'ml-2'}>
                        <small>{getMessage(config, 'Follow')}</small>
                      </Badge>
                    </Link>
                  </h5>
                  <p>
                    <small>{getDateString(date)}</small>
                  </p>
                </Media.Body>
              </Media>
            </li>
          )
        }
      )}
    </ul>
  );
};

export default AuthorByLineArticle;
