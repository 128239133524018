/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useState} from 'react';
import {Button, Col, Container, Dropdown, Form, Row} from 'react-bootstrap';
import {Channel, useChannels} from '../hooks';
import {channelsUrl, PreferenceContext} from '../contexts';
import {Icon, Status} from '../components/utility';

export const HomePage = () => {

  const [results, loading] = useChannels(channelsUrl());
  const {preference, setPreference} = useContext(PreferenceContext)!;

  const [selectedChannel, setSelectedChannel] = useState<string | null | undefined>(preference?.channel);
  const [rememberAsDefault, setRememberAsDefault] = useState(!!preference?.channel);

  const urlParams = new URLSearchParams(window.location.search);
  const showSelector = urlParams.get('selector');

  if (!showSelector && preference?.channel) {
    window.location.href = `${window.location.origin}/${preference?.channel}`;
  }

  if (loading) {
    return <Status container loading status={'Fetching channels...'}/>;
  }

  return (
    <div className='vh-100 d-flex justify-content-md-center align-items-center'>
      <Container>
        <Row>
          <Col sm={{span: 12}} md={{span: 4, offset: 4}}>
            <h4>Pacific Preferences</h4>
            <Form className={'my-5'}>
              <Form.Group controlId='channelSelector'>
                <Form.Label>Select Channel</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-primary" size={'sm'} id="dropdown-channel">
                    {selectedChannel ? <Icon name={'desktop'} content={selectedChannel}/> :
                      <Icon name={'cog'} content={'Select Channel...'}/>}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {results?.map((channel: Channel, key: number) => {
                      return (
                        <Dropdown.Item
                          key={key}
                          disabled={channel.name === selectedChannel}
                          onClick={() => {
                            setSelectedChannel(channel.name)
                          }}>
                          <Icon name={'desktop'} content={channel.name}/>
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Form.Group controlId='rememberAsDefaultCheckbox'>
                <Form.Check type='checkbox'
                            label='Remember as default'
                            defaultChecked={rememberAsDefault}
                            className={'form-label'}
                            onClick={() => setRememberAsDefault(!rememberAsDefault)}/>
              </Form.Group>
              <Button
                disabled={!selectedChannel}
                variant='primary'
                      onClick={event => {
                event.preventDefault();
                if (selectedChannel) {
                  rememberAsDefault && setPreference({channel: selectedChannel});
                  window.location.href = `${window.location.origin}/${selectedChannel}`;
                }
              }}>
                <Icon name={'arrow-circle-right'} content={'GO'}/>
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
