/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {lazy, Suspense, useContext} from 'react';
import {Document} from '@bloomreach/spa-sdk';
import {BrProps} from '@bloomreach/react-sdk';
import {RenderInlineEditing} from '../utility/component/RenderInlineEditing';
import {Loader, Status} from '../utility';
import {getSegmentWithPrefix, GlobalSearchContext, UserContext} from '../../contexts';
import {getFacetFieldFilters, getFacetFilterFromString, getSearchParam} from '../../utils/SearchUtil';
import {lastSegmentOfUrl} from '../../utils/UrlUtils';

const ProductGridByCategory = lazy(() => import('./ProductGridByCategory')
  .then((module) => ({default: module.ProductGridByCategory}))
);

const config = require('./ProductGridByCategoryComponentConfig.json');

export const ProductGridByCategoryComponent = (props: BrProps) => {
  const {
    page,
    component
  } = props;
  const useSearchContext = () => useContext(GlobalSearchContext);
  const {globalSearchParams} = useSearchContext()!;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  const {document: documentRef} = component!.getModels();
  const content = (documentRef && page!.getContent(documentRef)) || page!.getDocument<Document>();

  let categoryId = content?.getData()?.relatedexdocids;
  // Adds support to picker
  try {
    const selectedCategoryIds = JSON.parse(categoryId);
    if (Array.isArray(selectedCategoryIds)) {
      categoryId = selectedCategoryIds[0];
    }
  } catch (e) {

  }

  const params = component!.getParameters();
  const template = params?.template || 'default';

  if (!categoryId) {
    categoryId = params?.categoryId;
  }

  if (!categoryId) {
    categoryId = lastSegmentOfUrl();
  }

  if (!categoryId) {
    return <Status container error status={'The category page you are looking for has not been configured.'}/>;
  }

  let {
    pageSize,
    pageNumber,
    sort,
    segment,
    viewId,
    widgetId
  } = getSearchParam(params, {
    pageNumber: config.defaultPageNumber,
    pageSize: params.pageSize || config.defaultPageSize,
    sort: ''
  });

  if (!viewId) {
    viewId = globalSearchParams.view_id;
  }

  if (!segment) {
    segment = getSegmentWithPrefix(userSegment?.secondary || userSegment?.primary) || ''; //globalSearchParams.segment;
  }

  let facetFieldFilters = getFacetFieldFilters();
  if (facetFieldFilters.length === 0 && globalSearchParams.filter) {
    facetFieldFilters = getFacetFilterFromString(globalSearchParams.filter);
  }

  // Check associated banners
  const {slotBanner} = (content?.getData() ?? {}) as any;
  const banners = slotBanner?.map((slotBannerItem: any) => slotBannerItem.banner);
  const slots = slotBanner?.map((slotBannerItem: any) => slotBannerItem.slot);

  return (
    <Suspense fallback={<Loader/>}>
      <RenderInlineEditing {...{
        page,
        content
      }}/>
      <ProductGridByCategory
        template={template}
        categoryId={categoryId}
        pageNumber={pageNumber}
        pageSize={pageSize}
        facetFieldFilters={facetFieldFilters}
        segment={segment}
        viewId={viewId}
        widgetId={widgetId}
        sortFields={sort}
        banners={banners}
        slots={slots}
        {...props}
      />
    </Suspense>
  )
}
