/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import AuthorByLine from './templates/AuthorByLine';
import {ContentAuthorByLineProps} from './AuthorByLineComponentTypes';
import {getAuthorByLineProps} from './AuthorByLineComponentUtils';
import {UserContext} from '../../../contexts';

export const AuthorByLineComponent = (contentProps: ContentAuthorByLineProps) => {
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  const props = getAuthorByLineProps(contentProps, userSegment);
  if (!props) {
    return null;
  }

  return (
    <AuthorByLine {...props}/>
  )
};
