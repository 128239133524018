/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Link} from 'react-router-dom';
import {ArticlesByAuthorProps} from '../../../article/ArticleComponentTypes';
import {getMessage} from '../../../../utils/MessageUtils';

const config = require('../../AuthorConfig.json');
const ArticlesByAuthor = ({items}: ArticlesByAuthorProps) => {
  return (
    <div className='py-5 article-byauthor'>
      <h5>{getMessage(config, 'Articles By Author')}</h5>
      <ul className={'list-unstyled'}>
        {items.map((item, key) =>
          <li key={key}>
            <Link to={item.link ? item.link?.url : '#'}>{item.title}</Link>
          </li>
        )}
      </ul>
    </div>
  )
};

export default ArticlesByAuthor;
