/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useMemo} from 'react';
import {BrProps} from '@bloomreach/react-sdk';
import {useSearch} from '../../hooks';
import {useCookies} from 'react-cookie';
import {DEFAULT_UID_COOKIE} from '../DemoBrsmUtils';
import {Status} from '../../components/utility';

// Component output parameters
export interface WidgetSearchProps {
  widgetSearchLoading: boolean;
  widgetSearchResult: any;
}

// Component input parameters
export interface WidgetSearchInputProps {
  family: string;
  viewId?: string;
  segment?: string;
  start?: number;
  limit?: number;
  widgetId: string;
  itemIds: string;
}

function withWidgetSearchBase<P extends BrProps>(Component: React.ComponentType<P & WidgetSearchProps>) {

  return (props: P & WidgetSearchInputProps) => {
    const {
      page,
      family,
      viewId,
      segment,
      start = 0,
      limit = 12,
      widgetId,
      itemIds
    } = props;

    const {
      discoveryAuthKey,
      discoveryDomainKey,
      discoveryAccountId,
      smAuthKey,
      smDomainKey,
      smAccountId
    } = page!.getChannelParameters();

    const authKey = smAuthKey || discoveryAuthKey || process.env.REACT_APP_BRWIDGETS_AUTH_KEY;
    const domainKey = smDomainKey || discoveryDomainKey || process.env.REACT_APP_BRWIDGETS_DOMAIN_KEY;
    const accountId = smAccountId || discoveryAccountId || process.env.REACT_APP_BRWIDGETS_ACCOUNT_ID;

    const endpoint = process.env.REACT_APP_BRWIDGETS_ROOT_URI!;
    const fields = process.env.REACT_APP_BRWIDGETS_FIELDS!;

    const [cookies] = useCookies(['_br_uid_2']);

    const params = useMemo(
      () => ({
        _br_uid_2: cookies._br_uid_2 || DEFAULT_UID_COOKIE,
        item_ids: itemIds,
        account_id: accountId,
        domain_key: domainKey,
        auth_key: authKey,
        request_id: new Date().getTime(),
        rows: limit,
        start: start,
        fields,
        url: window.location.href.replaceAll('seg=', 'segment='),
        ref_url: window.location.origin + "/",
        view_id: viewId || '',
        request_type: '',
        segment
      }),
      [
        cookies._br_uid_2,
        accountId,
        authKey,
        domainKey,
        viewId,
        itemIds,
        limit,
        start,
        fields,
        segment
      ],
    );

    // Perform actual search query
    const [results, loading, error] = useSearch(`${endpoint}/${family}/${widgetId}`, params);
    if (loading) {
      return null;
    }

    if (!loading && error) {
      return <Status status={error.message} container error/>
    }

    return (
      <Component
        widgetSearchLoading={loading}
        widgetSearchResult={results}
        {...props}
      />
    );
  };
}

export function withWidgetSearch<P extends BrProps>(
  Component: React.ComponentType<P & WidgetSearchProps>,
) {
  return withWidgetSearchBase(Component);
}

