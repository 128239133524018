/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Common enums
 */
enum LocationEnum {
  Left = 'left',
  Right = 'right',
}

enum ImageTypeEnum {
  Internal = 'internal',
  External = 'external',
  Bynder = 'bynder',
  Unsplash = 'unsplash',
}

enum ProductProviderEnum {
  BloomReach,
  Apollo
}

enum ServiceStatus {
  SUCCESS,
  ERROR,
  IN_PROGRESS,
  WARNING
}

enum CartStageEnum {
  SHOPPING = 'Shopping',
  SHIPPING = 'Shipping',
  DELIVERY = 'Delivery',
  BILLING = 'Billing',
  PURCHASE = 'Purchase',
  FINISH = 'Finish'
}

enum ImageSizeEnum {
  // From BR ImageSet
  Banner = 'banner',
  Large = 'large',
  LargeSquare = 'largesquare',
  MediumSquare = 'mediumsquare',
  Small = 'small',
  SmallSquare = 'smallsquare',
  Original = 'original',
  Thumbnail = 'thumbnail',
  // From Unsplash
  // full -> large
  // raw -> original
  // small -> small
  // thumb -> thumbnail
}

const countryOptions = [
  { key: 'us', value: 'US', flag: 'us', text: 'United States' },
  { key: 'it', value: 'IT', flag: 'it', text: 'Italy' },
  { key: 'uk', value: 'GB', flag: 'uk', text: 'United Kingdom' },
  { key: 'nl', value: 'NL', flag: 'nl', text: 'Netherlands' },
  { key: 'de', value: 'DE', flag: 'de', text: 'Germany' },
  { key: 'es', value: 'ES', flag: 'es', text: 'Spain' },
  { key: 'be', value: 'BE', flag: 'be', text: 'Belgium' },
  { key: 'ch', value: 'CH', flag: 'ch', text: 'Switzerland' },
  { key: 'fr', value: 'FR', flag: 'fr', text: 'France' },
  { key: 'lu', value: 'LU', flag: 'lu', text: 'Luxembourg' },
];

const stateOptions = [
  {"text": "Alabama", "value":"AL"},
  {"text": "Alaska", "value":"AK"},
  {"text": "Arizona", "value":"AZ"},
  {"text": "Arkansas", "value":"AR"},
  {"text": "California", "value":"CA"},
  {"text": "Colorado", "value":"CO"},
  {"text": "Connecticut", "value":"CT"},
  {"text": "Delaware", "value":"DE"},
  {"text": "District of Columbia", "value":"DC"},
  {"text": "Florida", "value":"FL"},
  {"text": "Georgia", "value":"GA"},
  {"text": "Hawaii", "value":"HI"},
  {"text": "Idaho", "value":"ID"},
  {"text": "Illinois", "value":"IL"},
  {"text": "Indiana", "value":"IN"},
  {"text": "Iowa", "value":"IA"},
  {"text": "Kansas", "value":"KS"},
  {"text": "Kentucky", "value":"KY"},
  {"text": "Louisiana", "value":"LA"},
  {"text": "Maine", "value":"ME"},
  {"text": "Maryland", "value":"MD"},
  {"text": "Massachusetts", "value":"MA"},
  {"text": "Michigan", "value":"MI"},
  {"text": "Minnesota", "value":"MN"},
  {"text": "Mississippi", "value":"MS"},
  {"text": "Missouri", "value":"MO"},
  {"text": "Montana", "value":"MT"},
  {"text": "Nebraska", "value":"NE"},
  {"text": "Nevada", "value":"NV"},
  {"text": "New Hampshire", "value":"NH"},
  {"text": "New Jersey", "value":"NJ"},
  {"text": "New Mexico", "value":"NM"},
  {"text": "New York", "value":"NY"},
  {"text": "North Carolina", "value":"NC"},
  {"text": "North Dakota", "value":"ND"},
  {"text": "Ohio", "value":"OH"},
  {"text": "Oklahoma", "value":"OK"},
  {"text": "Oregon", "value":"OR"},
  {"text": "Pennsylvania", "value":"PA"},
  {"text": "Rhode Island", "value":"RI"},
  {"text": "South Carolina", "value":"SC"},
  {"text": "South Dakota", "value":"SD"},
  {"text": "Tennessee", "value":"TN"},
  {"text": "Texas", "value":"TX"},
  {"text": "Utah", "value":"UT"},
  {"text": "Vermont", "value":"VT"},
  {"text": "Virginia", "value":"VA"},
  {"text": "Washington", "value":"WA"},
  {"text": "West Virginia", "value":"WV"},
  {"text": "Wisconsin", "value":"WI"},
  {"text": "Wyoming", "value":"WY"}
];

enum ErrorLocationEnum {
  Top = 'top',
  Bottom = 'bottom',
  Middle = 'middle'
}

export {
  ErrorLocationEnum,
  countryOptions,
  stateOptions,
  LocationEnum,
  ImageTypeEnum,
  ProductProviderEnum,
  ServiceStatus,
  CartStageEnum,
  ImageSizeEnum
}
