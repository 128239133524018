/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {useHistory} from 'react-router';
import {Button} from 'react-bootstrap';
import {BrPageContext} from '@bloomreach/react-sdk';
import {Icon} from '../../icon/Icon';
import {QUERY_PARAM_CONTENT_FACET, QUERY_PARAM_FACET} from '../../../../utils/SearchUtil';
import {updateSearchUrl} from '../FacetListUtils';
import {getMessage} from '../../../../utils/MessageUtils';
import {FacetField} from '../../../../demo-connector-components/DemoBrsmUtils';

export interface SelectedFacetsProps {
  selectedFilters: Array<FacetField>,
  facetsList?: Array<FacetField | null>,
  handleFacetValueClick: Function,
  isContent: boolean
}

const config = require('./FacetConfig.json');
export const SelectedFacets = (props: SelectedFacetsProps) => {
  const {
    selectedFilters,
    facetsList,
    handleFacetValueClick,
    isContent
  } = props;

  const page = useContext(BrPageContext)!;
  const history = useHistory();

  const handleRemoveAllFacetsClick = () => {
    const facetFilterName = isContent ? QUERY_PARAM_CONTENT_FACET : QUERY_PARAM_FACET;
    const queryParameters = new URLSearchParams(window.location.search);
    queryParameters.delete(facetFilterName);
    updateSearchUrl(queryParameters, page, history);
  };

  return (
    <div>
      {selectedFilters.map((field) => {
        const facetName = field.id;
        return field.values.map((val, key: number) => {
          const facets = facetsList?.find((facetField) => facetField?.id === facetName);
          const facetValue = facets?.values.find((facetValue) => facetValue?.id === val.id);
          const label = facetValue?.name || val.name;
          return (
            <Button
              key={key}
              className='mr-1 my-1 cursor-pointer'
              size='sm'
              variant='outline-dark'
              onClick={(e) => handleFacetValueClick(e, facetName, val.id)}>
              <Icon name='times' content={label}/>
            </Button>
          );
        });
      })}
      {selectedFilters.length > 0 && (
        <div>
          <Button
            className='mr-1 my-1 cursor-pointer'
            size='sm'
            variant='dark'
            onClick={(e) => handleRemoveAllFacetsClick()}>
            {getMessage(config, 'clear-all-filters')}
          </Button>
        </div>
      )}
    </div>
  );
}

