/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useState} from 'react';
import {Button, Col, Form} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {CartContext, UserContext} from '../../../contexts';
import {CartStageEnum} from '../../global/CommonEnums';
import {Status} from '../../utility';
import {SetOrderBillingAddress} from '../../order/action/SetOrderBillingAddress';
import ReturnButton from '../action/ReturnButton';
import PaymentMethod from '../util/PaymentMethod';
import {getDefaultConnector} from '../../../connector-components/utils';
import {GetAddressGroupProps, withGetAddressGroup} from '../../../connector-components';
import {readOrderFromSession} from '../../order/OrderComponentUtils';
import {AddressOptionType, StageInputProps} from '../CartComponentTypes';
import {getProfileTabUrl} from '../../user-profile/UserProfileComponentUtils';

function BillingBase(props: BrProps & GetAddressGroupProps & StageInputProps) {
  const {
    page,
    setStage,
    loading,
    addressGroup
  } = props;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  const {cartDetails}: any = useContext(CartContext);
  const orderDetails = readOrderFromSession();
  const defaultConnector = getDefaultConnector(page!);

  const [billingOptions, setBillingOptions] = useState<any>({
    billingAddressId: cartDetails.billingAddress?.id || orderDetails?.billingAddress?.id,
    paymentMethod: null
  })
  const {billingAddressId, paymentMethod} = billingOptions;

  if (loading) {
    return (
      <Status container loading status={'We are fetching customer address group for you'}/>
    )
  }

  if (!addressGroup) {
    return null;
  }

  const {addresses, defaultBillingAddress} = addressGroup;

  const billingAddressOptions: Array<AddressOptionType> | undefined = addresses?.filter((address) => address?.billingAddress).map((address) => {
    const {id, streetAddress, additionalStreetInfo, city, state, country, postalCode} = address!;
    return {
      key: id,
      value: id,
      text: `${streetAddress} ${additionalStreetInfo}, ${city}, ${state} ${country} ${postalCode}`
    }
  });

  // TODO: looks like for SAP the ID of the shippingAddress of the order is different from the ID of the initial selected address
  // TODO: SAP creates a different ID for it. So for UI, when the back button is hit, we will need to do match by text
  let selectedAddressId = billingAddressId;
  const matchedOption = billingAddressOptions?.find((billingOption) => billingOption.key === selectedAddressId);
  if (!matchedOption) {
    const orderBillingAddress = orderDetails?.billingAddress;
    if (orderBillingAddress) {
      const matchedOption = billingAddressOptions?.find((billingOption) => billingOption.text === `${orderBillingAddress.streetAddress} ${orderBillingAddress.additionalStreetInfo}, ${orderBillingAddress.city}, ${orderBillingAddress.state} ${orderBillingAddress.country} ${orderBillingAddress.postalCode}`)
      selectedAddressId = matchedOption?.key;
    }
  }

  selectedAddressId = selectedAddressId || defaultBillingAddress?.id;

  // Make sure the address exists
  if (!selectedAddressId && addresses && addresses.length > 0) {
    selectedAddressId = addresses[0]?.id;
  }

  const setOrderBillingAddressParams = {
    billingAddressId: selectedAddressId,
    setStage,
    paymentMethod
  }

  const setPaymentMethodData = (value: any) => {
    const {cardNumber, cardHolder, expiration, cvc} = value!;
    const expirationSegments = expiration.split('/');
    const updatedOptions = {
      ...billingOptions, ...{
        paymentMethod: {
          paymentData: {
            accountIssuer: 'visa',
            accountNumber: cardNumber,
            accountHolderName: cardHolder,
            accountExpirationDate: {
              day: 1,
              month: parseInt(expirationSegments[0]),
              year: 2000 + parseInt(expirationSegments[1])
            },
            additionalCode: cvc
          }
        }
      }
    };
    if (JSON.stringify(billingOptions) !== JSON.stringify(updatedOptions)) {
      setBillingOptions(updatedOptions);
    }
  }

  return (
    <React.Fragment>
      <Form noValidate={true}>
        <h4>Billing <Button variant={'primary'} className={'float-right'} as={'a'}
                            href={getProfileTabUrl(page!, 'addresses', userSegment)}>Manage
          Addresses</Button></h4>
        <Form.Group className={'pt-3'}>
          <Form.Label className={'text-uppercase'}>Select Billing Address</Form.Label>
          <Form.Control
            as={'select'}
            onChange={(e) => {
              setBillingOptions({...billingOptions, billingAddressId: e.target.value});
            }}
            defaultValue={selectedAddressId}
            placeholder='Select Shipping Address'
          >
            {billingAddressOptions?.map((option: any) => {
              return <option key={option.key}
                             value={option.value}>{`${option.text}${option.key === defaultBillingAddress?.id ? ' (default)' : ''}`}</option>;
            })}
          </Form.Control>
        </Form.Group>
        <PaymentMethod handler={setPaymentMethodData}/>
        <Form.Row>
          <Form.Group as={Col}>
            <ReturnButton stage={defaultConnector === 'commercetools' ? CartStageEnum.SHIPPING : CartStageEnum.DELIVERY}
                          setStage={setStage}/>
          </Form.Group>
          <Form.Group as={Col}>
            <SetOrderBillingAddress {...setOrderBillingAddressParams} orderId={orderDetails?.id} page={page!}/>
          </Form.Group>
        </Form.Row>
      </Form>
    </React.Fragment>
  )
}

export const Billing = withGetAddressGroup(BillingBase);
