/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {BannerComponentProps} from '../BannerComponentTypes';
import {ResponsiveQueryProps} from '../../../demo-connector-components/responsive/ResponsiveQuery';
import {getImageVariantByResponsiveQuery} from '../../../utils/ImageUtil';

const BannerSplitPanel = ({content, params, responsiveQuery}: BannerComponentProps & ResponsiveQueryProps) => {
  const textColor = params.textColor === 'black' ? 'dark' : 'white';

  const {title, text, images} = content;
  const image = getImageVariantByResponsiveQuery(images, responsiveQuery);

  const divStyle2 = image ? {
    style: {
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }
  } : {};

  return (
    <div className='article-full'>
      <Container fluid>
        <Row>
          <Col className={'d-flex bg-hero'} sm={6}>
            <div className={`my-auto ml-auto mr-0 text-${textColor}`} style={{
              maxWidth: '540px'
            }}>
              {title && <h1 className={`mb-4`}>{title}</h1>}
              {text && <p dangerouslySetInnerHTML={{__html: text}}/>}
            </div>
          </Col>
          <Col className={'height-md-70vh'} sm={6} {...divStyle2}>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BannerSplitPanel;
