/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {BrComponentContext} from '@bloomreach/react-sdk';
import {UserContext} from '../../contexts/UserContext';

export const BRX_PRODUCT_EVENT_NAME = 'brx.page.product';
export const BRX_USER_EVENT_NAME = 'brx.user';
export const BRX_CONTENT_EVENT_NAME = 'brx.page.content';
export const BRX_AUTOSUGGEST_EVENT_NAME = 'brx.event.suggest';
export const BRX_CATEGORY_EVENT_NAME = 'brx.page.category';
export const BRX_SEARCH_EVENT_NAME = 'brx.page.search';
export const BRX_ORDER_EVENT_NAME = 'brx.basket';

export interface GtmEventSearch {
  search_term?: string
}

export interface GtmEventCategory {
  catId?: string,
  catName?: string
}

export interface GtmEventProduct {
  prodId?: string,
  prodName?: string,
  code?: string
}

export interface GtmEventProps {
  setGtmEventProduct: Function,
  setGtmEventUser: Function,
  setGtmEventContent: Function,
  setGtmEventCategory: Function,
  setGtmEventSearch: Function,
  setGtmEventOrder: Function
}

function withGtmEventBase<P>(Component: React.ComponentType<P & GtmEventProps>) {
  return (props: P) => {
    const component = useContext(BrComponentContext)!;
    const {userState: {user}, gtmEventState: {setGtmEvent}}: any = useContext(UserContext);

    const setGtmEventProduct = (product: any) => {
      if (product) {
        console.log('[GTM]', 'Product', product);
        const productEvent: GtmEventProduct = {
          prodId: product?.itemKey || product?.itemId?.id,
          prodName: product?.displayName,
          code: product?.itemId?.code
        };
        const componentId = component.getId();
        const updatedGtmEvent: any = {};
        updatedGtmEvent[componentId] = {}
        updatedGtmEvent[componentId][BRX_PRODUCT_EVENT_NAME] = productEvent;
        setGtmEvent(updatedGtmEvent);
      }
    };

    const setGtmEventUser = (componentId: string, globalSearchParams: any) => {
      if (componentId) {
        const searchParams = JSON.parse(sessionStorage.getItem('searchContext') || '{}');
        let customer_profile = searchParams.segment || '';
        if (customer_profile.startsWith('customer_profile:')) {
          customer_profile = customer_profile.substring('customer_profile:'.length);
        }
        const userEvent: any = {
          view_id: globalSearchParams.view_id,
          customer_profile
        }
        if (user?.id) {
          userEvent.user_id = user?.id;
        }
        console.log('[GTM]', 'USER', userEvent);
        const updatedGtmEvent: any = {};
        updatedGtmEvent[componentId] = {};
        updatedGtmEvent[componentId][BRX_USER_EVENT_NAME] = userEvent;
        setGtmEvent(updatedGtmEvent);
      }
    };

    const setGtmEventContent = (content: any) => {
      if (content) {
        const {id: item_id, name: item_name} = content.getData();
        const componentId = component.getId();
        const contentEvent: any = {
          item_id,
          item_name,
          catalogs: [{name: process.env.REACT_APP_CONTENT_SEARCH_CATALOG_NAME}]
        }
        console.log('[GTM', contentEvent);
        const updatedGtmEvent: any = {};
        updatedGtmEvent[componentId] = {};
        updatedGtmEvent[componentId][BRX_CONTENT_EVENT_NAME] = contentEvent;
        setGtmEvent(updatedGtmEvent);
      }
    };

    const setGtmEventCategory = (category: any) => {
      if (category) {
        const componentId = component.getId();
        //DataLayerUtils.push( BRX_CATEGORY_EVENT_NAME, category );
        console.log("[GTM]", "Add Category", category);
        const updatedGtmEvent: any = {};
        updatedGtmEvent[componentId] = {};
        updatedGtmEvent[componentId][BRX_CATEGORY_EVENT_NAME] = category;
        setGtmEvent(updatedGtmEvent);
      }
    };

    const setGtmEventSearch = (queryKeyword: any) => {
      if (queryKeyword) {
        const componentId = component.getId();
        const searchEvent: GtmEventSearch = {
          "search_term": queryKeyword
        }
        //DataLayerUtils.push(BRX_SEARCH_EVENT_NAME, searchEvent);
        console.log("[GTM]", "Search", searchEvent);
        const updatedGtmEvent: any = {};
        updatedGtmEvent[componentId] = {};
        updatedGtmEvent[componentId][BRX_SEARCH_EVENT_NAME] = searchEvent;
        setGtmEvent(updatedGtmEvent);
      }
    };

    const setGtmEventOrder = (fetchedOrder: any) => {
      if (fetchedOrder) {
        const orderEvent: any = {};
        orderEvent.items = fetchedOrder?.orderItems?.map((orderItem: any) => {
          const {quantity, item} = orderItem!;
          const itemKey = item?.varAttrs?.find((attribute: any) => attribute?.name === "mpn")
            ?.values?.[0];
          return {
            prodId: itemKey || item?.itemId?.id,
            code: item?.itemId?.code,
            prodName: item?.displayName,
            price: item?.purchasePrice?.moneyAmounts?.[0]?.amount,
            quantity
          }
        });
        orderEvent.order_id = fetchedOrder?.id;
        orderEvent.basket_value = fetchedOrder?.totalAmount?.[0]?.amount;

        const componentId = component.getId();
        console.log("[GTM]", "OrderEvent:", orderEvent);
        const updatedGtmEvent: any = {};
        updatedGtmEvent[componentId] = {};
        updatedGtmEvent[componentId][BRX_ORDER_EVENT_NAME] = orderEvent;
        setGtmEvent(updatedGtmEvent);
      }
    };

    return (
      <Component
        setGtmEventProduct={setGtmEventProduct}
        setGtmEventUser={setGtmEventUser}
        setGtmEventContent={setGtmEventContent}
        setGtmEventCategory={setGtmEventCategory}
        setGtmEventSearch={setGtmEventSearch}
        setGtmEventOrder={setGtmEventOrder}
        {...props}
      />
    );
  };
}

export function withGtmEvent<P>(Component: React.ComponentType<P & GtmEventProps>) {
  return withGtmEventBase(Component);
}
