/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup_addresses,
  UPDATE_CURRENT_CUSTOMER_ADDRESS,
  UpdateCurrentCustomerAddress,
  UpdateCurrentCustomerAddress_updateCurrentCustomerAddress,
  UpdateCurrentCustomerAddressVariables,
} from '../../modules/commerce';
import {AddressFormProps} from './index';
import {withApolloClient} from '../ApolloClient';

interface InputProps {
  address: CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup_addresses;
}

export interface UpdateAddressProps extends InputProps {
  addressForm: AddressFormProps;
  updateAddress: () => Promise<FetchResult<UpdateCurrentCustomerAddress>>;
  loading: boolean;
  result?: UpdateCurrentCustomerAddress_updateCurrentCustomerAddress;
}

function withUpdateAddressBase<P>(Component: React.ComponentType<P & UpdateAddressProps>) {
  return (props: P & InputProps) => {
    const {address} = props;
    const [firstName, setFirstName] = useState<string>(address.firstName ?? '');
    const [middleName, setMiddleName] = useState<string>(address.middleName ?? '');
    const [lastName, setLastName] = useState<string>(address.lastName ?? '');
    const [country, setCountry] = useState<string>(address.country ?? '');
    const [city, setCity] = useState<string>(address.city ?? '');
    const [state, setState] = useState<string>(address.state ?? '');
    const [company, setCompany] = useState<string>(address.company ?? '');
    const [postalCode, setPostalCode] = useState<string>(address.postalCode ?? '');
    const [streetAddress, setStreetAddress] = useState<string>(address.streetAddress ?? '');
    const [additionalStreetInfo, setAdditionalStreetInfo] = useState<string>(address.additionalStreetInfo ?? '');
    const [shippingAddress, setShippingAddress] = useState<boolean>(address.shippingAddress ?? false);
    const [billingAddress, setBillingAddress] = useState<boolean>(address.billingAddress ?? false);
    const [readOnly, setReadOnly] = useState<boolean>(address.readOnly ?? false);

    const [updateAddress, {data, loading}] = useMutation<UpdateCurrentCustomerAddress,
      UpdateCurrentCustomerAddressVariables>(UPDATE_CURRENT_CUSTOMER_ADDRESS, {
      variables: {
        addressInput: {
          firstName,
          middleName,
          lastName,
          country,
          city,
          state,
          company,
          postalCode,
          streetAddress,
          additionalStreetInfo,
          shippingAddress,
          billingAddress,
          readOnly,
          id: address.id,
        },
      },
    });

    return (
      <Component
        addressForm={{
          firstName,
          setFirstName,
          middleName,
          setMiddleName,
          lastName,
          setLastName,
          country,
          setCountry,
          city,
          setCity,
          state,
          setState,
          company,
          setCompany,
          postalCode,
          setPostalCode,
          streetAddress,
          setStreetAddress,
          additionalStreetInfo,
          setAdditionalStreetInfo,
          shippingAddress,
          setShippingAddress,
          billingAddress,
          setBillingAddress,
          readOnly,
          setReadOnly,
        }}
        updateAddress={updateAddress}
        loading={loading}
        result={data?.updateCurrentCustomerAddress}
        {...props}
      />
    );
  };
}

export function withUpdateAddress<P>(Component: React.ComponentType<P & UpdateAddressProps>) {
  return withApolloClient(withUpdateAddressBase(Component));
}
