/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  CurrentCustomer_getCurrentCustomer,
  UPDATE_CUSTOMER,
  UpdateCurrentCustomerDetail,
  UpdateCurrentCustomerDetail_updateCurrentCustomerDetail,
  UpdateCurrentCustomerDetailVariables,
} from '../../modules/commerce';
import {withApolloClient} from '../../connector-components';

export interface UpdateCustomerFormProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  middleName: string;
  setMiddleName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
}

interface InputProps {
  customer: CurrentCustomer_getCurrentCustomer;
}

export interface UpdateCustomerProps extends InputProps {
  updateCustomerForm: UpdateCustomerFormProps;
  updateCustomer: () => Promise<FetchResult<UpdateCurrentCustomerDetail>>;
  loading: boolean;
  result?: UpdateCurrentCustomerDetail_updateCurrentCustomerDetail;
}

function withUpdateCustomerBase<P>(Component: React.ComponentType<P & UpdateCustomerProps>) {
  return (props: P & InputProps) => {
    const {customer} = props;
    const [email, setEmail] = useState<string>(customer.email ?? '');
    const [firstName, setFirstName] = useState<string>(customer.firstName ?? '');
    const [middleName, setMiddleName] = useState<string>(customer.middleName ?? '');
    const [lastName, setLastName] = useState<string>(customer.lastName ?? '');

    const [update, {data, loading}] = useMutation<UpdateCurrentCustomerDetail, UpdateCurrentCustomerDetailVariables>(
      UPDATE_CUSTOMER,
      {
        // TODO: This option is needs to make sure result can be passed down to child component
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            email,
            firstName,
            middleName,
            lastName,
          },
        },
      },
    );

    return (
      <Component
        updateCustomerForm={{
          email,
          setEmail,
          firstName,
          setFirstName,
          middleName,
          setMiddleName,
          lastName,
          setLastName,
        }}
        updateCustomer={update}
        loading={loading}
        result={data?.updateCurrentCustomerDetail}
        {...props}
      />
    );
  };
}

export function withDemoUpdateCustomer<P>(Component: React.ComponentType<P & UpdateCustomerProps>) {
  return withApolloClient(withUpdateCustomerBase(Component));
}
