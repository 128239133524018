/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * ContentBlog component
 */
import {BlogContentProps, BlogProps} from './BlogComponentTypes';
import {AuthorByLineProps} from '../author/author-byline/AuthorByLineComponentTypes';
import {getAuthorByLineProps} from '../author/author-byline/AuthorByLineComponentUtils';
import {UserSegmentType} from '../../contexts';

export const GetBlogProps = ({documentRef, page, type}: BlogContentProps, userSegment: UserSegmentType): BlogProps | null => {
  const document = documentRef && page.getContent(documentRef);

  if (!document) {
    return null;
  }

  const {authors: documentRefs, introduction, title, publicationDate: date, content, categories} = document.getData();

  let blogProps: BlogProps = {
    type,
    title,
    introduction: page.rewriteLinks(introduction),
    date,
    body: page.rewriteLinks(content),
    categories,
    link: {
      url: document.getUrl() || ''
    }
  };

  const authorByLineProps: AuthorByLineProps = getAuthorByLineProps({
    componentStyle: '',
    params: undefined,
    page, documentRefs, date}, userSegment);
  blogProps.authors = authorByLineProps && authorByLineProps.authors;

  return blogProps;
};
