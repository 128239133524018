/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const SESSION_CART = 'cartId';
const SM_VIEW_ID_ITEM_NAME = 'smViewId';

class SessionService {
  setCartIdInSession(cartId: string | undefined): void {
    if (cartId) {
      sessionStorage.setItem(SESSION_CART, cartId);
    } else {
      sessionStorage.removeItem(SESSION_CART);
    }
  }

  getCartIdFromSession(): string | undefined {
    return sessionStorage.getItem(SESSION_CART) ?? undefined;
  }

  getPreferredSmViewId(): string {
    return sessionStorage.getItem(SM_VIEW_ID_ITEM_NAME) ?? '';
  }

  setPreferredSmViewId(smViewId: string | undefined): void {
    if (smViewId) {
      sessionStorage.setItem(SM_VIEW_ID_ITEM_NAME, smViewId);
    } else {
      sessionStorage.removeItem(SM_VIEW_ID_ITEM_NAME);
    }
  }
}

export const sessionService = new SessionService();
