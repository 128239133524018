/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  Cart,
  CartFragment_entries,
  CartVariables,
  GET_CART,
  REMOVE_FROM_CART,
  RemoveFromCart,
  RemoveFromCart_removeFromCart,
  RemoveFromCartVariables,
} from '../../modules/commerce';
import {addError, sessionService} from '../../modules/commerce/api';
import {withApolloClient} from '../ApolloClient';

interface InputProps {
  entry: CartFragment_entries;
}

export interface RemoveFromCartProps {
  removeFromCart: () => Promise<FetchResult<RemoveFromCart>>;
  loading: boolean;
  result?: RemoveFromCart_removeFromCart;
}

function withRemoveFromCartBase<P>(Component: React.ComponentType<P & RemoveFromCartProps>) {
  return (props: P & InputProps) => {
    const [mutate, {data, loading}] = useMutation<RemoveFromCart, RemoveFromCartVariables>(REMOVE_FROM_CART);

    const removeFromCart = () => {
      const cartId = sessionService.getCartIdFromSession();
      if (!cartId) {
        addError({
          code: 'VALIDATION_ERROR',
          message: 'Cart does not exist',
          operation: 'removeFromCart',
        });

        return Promise.resolve({});
      }
      return mutate({
        variables: {
          input: {
            cartId,
            entryId: props.entry.id,
          },
        },
        // eslint-disable-next-line no-shadow
        update(cache, {data}) {
          if (data?.removeFromCart.cart) {
            cache.writeQuery<Cart, CartVariables>({
              query: GET_CART,
              variables: {
                id: cartId,
              },
              data: {
                getCart: {
                  __typename: 'Cart',
                  id: data.removeFromCart.cart.id,
                  revision: data.removeFromCart.cart.revision,
                  totalQuantity: data.removeFromCart.cart.totalQuantity,
                  entries: data.removeFromCart.cart.entries,
                  totalListPrice: data.removeFromCart.cart.totalListPrice,
                  totalPurchasePrice: data.removeFromCart.cart.totalPurchasePrice,
                  active: data.removeFromCart.cart.active,
                  state: data.removeFromCart.cart.state,
                  discounts: data.removeFromCart.cart.discounts
                },
              },
            });
          }
        },
      });
    };

    return <Component removeFromCart={removeFromCart} loading={loading} result={data?.removeFromCart} {...props} />;
  };
}

export function withRemoveFromCart<P>(Component: React.ComponentType<P & RemoveFromCartProps>) {
  return withApolloClient(withRemoveFromCartBase(Component));
}
