/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, {useContext, useState} from 'react';
import {Button, ButtonGroup, ButtonToolbar} from 'react-bootstrap';
import {Animated} from 'react-animated-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppInspectorWidget, EngagementWidget, PreferenceWidget} from './widget';
import {BrPageContext} from '@bloomreach/react-sdk';

export function Helper() {
  const page = useContext(BrPageContext)!;
  const [inspectorVisible, setInspectorVisible] = useState<boolean>(false);

  const buttonVariant = 'outline-primary';
  if (page.isPreview()) {
    return null;
  }
  return (
    <div className={'fixed-top'}>
      <ButtonToolbar className={'float-right'}>
        <Animated animationIn="slideInRight" animationOut="slideOutRight" animationInDuration={1000}
                  animateOnMount={false}
                  animationOutDuration={1000} isVisible={inspectorVisible}>
          {inspectorVisible &&
            <Button variant={'link'} size={'sm'} onClick={() => setInspectorVisible(!inspectorVisible)}>
              <FontAwesomeIcon icon={['fas', 'angle-double-right']}/>
            </Button>}
          <ButtonGroup className={'mr-2'}>
            <PreferenceWidget/>
          </ButtonGroup>
          <ButtonGroup className={'mr-2'}>
            <Button variant={buttonVariant} size={'sm'}>
              <EngagementWidget/>
            </Button>
          </ButtonGroup>
          {/*<ButtonGroup>
            <Button variant={'link'} size={'sm'}>
              <DiscoveryWidget/>
            </Button>
          </ButtonGroup>*/}
          <ButtonGroup>
            <Button variant={buttonVariant} size={'sm'}>
              <AppInspectorWidget/>
            </Button>
          </ButtonGroup>
        </Animated>
        {!inspectorVisible &&
          <Button variant={'link'} size={'sm'} onClick={() => setInspectorVisible(!inspectorVisible)}>
            <FontAwesomeIcon icon={['fas', 'angle-double-left']}/>
          </Button>}
      </ButtonToolbar>
    </div>
  )
}
