/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {CHECK_OUT, CheckOut, CheckOut_checkOut, CheckOutVariables} from '../../modules/commerce';
import {addError, sessionService} from '../../modules/commerce/api';
import {withApolloClient} from '../ApolloClient';

export interface CheckOutProps {
  checkOut: () => Promise<FetchResult<CheckOut>>;
  loading: boolean;
  result?: CheckOut_checkOut;
}

function withCheckOutBase<P>(Component: React.ComponentType<P & CheckOutProps>) {
  return (props: P) => {
    const [mutate, {loading, data}] = useMutation<CheckOut, CheckOutVariables>(CHECK_OUT);

    const checkOut = () => {
      const cartId = sessionService.getCartIdFromSession();
      if (!cartId) {
        addError({
          code: 'VALIDATION_ERROR',
          message: 'Cart does not exist',
          operation: 'checkOut',
        });

        return Promise.resolve({});
      }
      return mutate({
        variables: {
          cartId,
        },
      });
    };

    return <Component checkOut={checkOut} loading={loading} result={data?.checkOut} {...props} />;
  };
}

export function withCheckOut<P>(Component: React.ComponentType<P & CheckOutProps>) {
  return withApolloClient(withCheckOutBase(Component));
}
