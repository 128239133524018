/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect} from 'react';
import {BrProps} from '@bloomreach/react-sdk';
import ArticleGrid from './templates/ArticleGrid';
import {DemoBrsmContentSearchProps, withBrsmContentSearch} from '../../demo-connector-components';
import {getContentSearchResults} from './ArticleGridSearchComponentUtils';
import {useParams} from 'react-router-dom';

export interface ArticleGridBySearchProps {
  autoCorrectQuery: string,
  params: Record<string, any>,
  setNumberOfArticles: React.Dispatch<React.SetStateAction<number>>,
}

function ArticleGridBySearchBase(props: BrProps & DemoBrsmContentSearchProps & ArticleGridBySearchProps) {
  const {
    page,
    autoCorrectQuery,
    params,
    setNumberOfArticles,
    contentSearchLoading,
    contentSearchResult,
  } = props;

  const {channelId} = useParams();

  useEffect(() => {
    if (!contentSearchLoading && contentSearchResult) {
      const numberOfArticles = contentSearchResult?.numFound ?? contentSearchResult?.docs?.length;
      setNumberOfArticles(numberOfArticles);
    }
  }, [contentSearchLoading, contentSearchResult]); // eslint-disable-line react-hooks/exhaustive-deps

  if (contentSearchLoading || !contentSearchResult) {
    return null;
  }

  const updatedContentSearchResult = getContentSearchResults(page!, channelId, params, autoCorrectQuery, contentSearchResult);

  return (
    <ArticleGrid {...{...updatedContentSearchResult}}/>
  )
}

export const ArticleGridBySearchComponent = withBrsmContentSearch(ArticleGridBySearchBase);
