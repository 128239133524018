/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * ArticlesByAuthor component
 */
import React from 'react';
import {Page} from '@bloomreach/spa-sdk';
import ArticlesByAuthor from './templates/ArticlesByAuthor';
import {GetArticlesByAuthorProps} from './ArticlesByAuthorComponentUtils';

export interface ArticlesByAuthorComponentProps {
  page: Page,
  pageable: any,
  params: Partial<Record<string, string>>
}

export const ArticlesByAuthorComponent = ({page, pageable, params}: ArticlesByAuthorComponentProps) => {
  const articlesByAuthorProps = GetArticlesByAuthorProps({
    page,
    pageable,
    params
  });
  if (!articlesByAuthorProps) {
    return null;
  }
  return <ArticlesByAuthor {...articlesByAuthorProps}/>
};
