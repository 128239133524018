/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Pagination} from 'react-bootstrap';
import {ListPaginationProps} from '../ListPaginationTypes';
import {useHistory} from 'react-router-dom';

export const ListPaginationTwoButton = (props: ListPaginationProps) => {
  const {
    previous,
    previousPageUrl,
    next,
    nextPageUrl,
  } = props;

  const history = useHistory();

  const gotoPage = (pageUrl: string) => {
    history.push(pageUrl);
  }

  return (
    <Pagination className={'my-5 justify-content-center'}>
      <Pagination.Prev disabled={!previous} onClick={() => gotoPage(previousPageUrl)}>{'< Previous'}</Pagination.Prev>
      <Pagination.Next disabled={!next} onClick={() => gotoPage(nextPageUrl)}>{'Next >'}</Pagination.Next>
    </Pagination>
  )
};
