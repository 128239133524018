/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useState} from 'react';
import {Badge, Col, Container, Form, Image, ListGroup, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {BrPageContext} from '@bloomreach/react-sdk';
import {AddToCartForm} from '../../cart/action/AddToCart';
import {ProductPrice} from './ProductPrice';
import {SecuredComponentWrapper} from '../../utility';
import {ItemFragment_itemId} from '../../../modules/commerce';
import {ProductProps} from '../ProductComponentTypes';
import {getMessage} from '../../../utils/MessageUtils';
import {PromotionComponent} from '../../promotion/PromotionComponent';
import {getPromotionImages, getPromotionVideos} from '../../promotion/PromotionComponentUtils';
import {getImageSetUrl, ItemVariant} from '../../../demo-connector-components/DemoBrsmUtils';
import {getSku} from '../ProductComponentUtils';
import {trackExponeaEvent} from '../../exponea/ExponeaUtils';
import {MoneyAmount} from '../../../demo-connector-components/DemoBrsmUtils';

const config = require('../ProductConfigComponent.json');

interface ProductInputProps {
  productId?: string
  hideSku?: boolean
}

export const Product = (props: ProductProps & ProductInputProps) => {
  const {
    productId,
    hideSku,
    itemId,
    itemKey = '',
    displayName,
    description,
    listPrice,
    purchasePrice,
    imageSet,
    variants
  } = props;

  const page = useContext(BrPageContext)!;
  const attributeMap: any = config.attributes;
  const attributeKeys = Object.keys(attributeMap);
  const paddingClassName = config.padding;
  const [count, setCount] = useState(1);

  let query = productId;
  const originalProduct: ItemVariant = {
    __typename: 'ItemVariant',
    varAttrs: undefined,
    itemId,
    description,
    displayName,
    listPrice,
    purchasePrice,
    imageSet
  };

  let initialVariant = originalProduct;

  if (query) {
    const matches = (/\d+___([\d_]+)?$/).exec(query);
    if (matches && matches.length > 0) {
      const querySegments = query.split('___');
      const queryItemCode = querySegments[1];
      if (queryItemCode && queryItemCode !== '' && variants && variants.length > 0) {
        if (queryItemCode === querySegments[0]) {
          // For the link from widget API call
          initialVariant = variants?.find((item) => item.itemId.code === itemId.code) || originalProduct;
        } else {
          initialVariant = variants?.find((item) => item.itemId.code === queryItemCode) || originalProduct;
        }
      }
    } else {
      query = query.replace('___', '');
      if (itemId.id === query || query.startsWith('var1')) {
        initialVariant = originalProduct;
      } else {
        const matchedVariant = variants?.find((item) => item.itemId.id === query);
        if (matchedVariant !== null) {
          initialVariant = matchedVariant!;
        }
      }
    }
  }

  const [variant, setVariant] = useState(initialVariant);

  const {
    itemId: variantItemId,
    displayName: variantDisplayName,
    description: variantDescription,
    listPrice: variantListPrice,
    //purchasePrice: variantPurchasePrice,
    imageSet: variantImageSet,
    varAttrs: variantAttributes
  } = variant;

  const addToCartParams = {
    // TODO: Temp fix before we re-import the products to CommerceTools
    itemId: {__typename: 'ItemId', id: itemId.id, code: itemId.id} as ItemFragment_itemId, // itemId as ItemFragment_itemId,
    itemKey,
    quantity: count,
    itemPrice: listPrice, // added to params; need to send to Exponea
    itemTitle: displayName, // added to params; need to send to Exponea
    mode: 'button'
  };

  const videos = getPromotionVideos(props.videos);
  const images = getPromotionImages(props.images);
  images.push({
    type: 'IMAGE',
    media: getImageSetUrl(variantImageSet)!
  })

  // TEMP code -- trigger Engagement's view_item event
  let itemMoneyAmount: MoneyAmount | undefined = props.listPrice.moneyAmounts?.[0];
  let priceAmount = itemMoneyAmount?.amount;
  let prodId = productId;
  if (productId && productId.indexOf('___') > 0) {
    prodId = productId.split('___')[0];
  }
  trackExponeaEvent('view_item', {
    product_id: prodId,
    price: priceAmount,
    title: props.displayName
  });


  return (
    <Container className='py-5 product'>
      <Row>
        <Col lg={true}>
          <PromotionComponent {...{images, videos}}/>
          {/*variantImageUrls?.map((imageUrl, key) => (
            <div key={key}>
              <Image fluid rounded src={imageUrl.url} alt={imageUrl.label}/>
            </div>))*/}
        </Col>
        <Col lg={true}>
          <h2>{variantDisplayName}</h2>
          {!hideSku && <div className='text-muted'>SKU: {getSku(variantItemId)}</div>}
          <div>
            {variantAttributes?.filter((variantAttribute) => {
              const {name} = variantAttribute;
              return (attributeKeys.indexOf(name) !== -1 && attributeMap[name].display);
            }).map((variantAttribute, key: number) => {
              const {name, values} = variantAttribute;
              return (
                <OverlayTrigger
                  key={key}
                  placement={'top'}
                  overlay={
                    <Tooltip id={`tooltip-${key}`}>
                      {attributeMap[name]?.label}
                    </Tooltip>
                  }
                >
                  <Badge variant={'info'} className={'mr-1'}>{values}</Badge>
                </OverlayTrigger>
              );
            })}
          </div>
          <br/>
          <h3 className={'text-primary'}><ProductPrice price={variantListPrice!}/></h3>
          <Form className={paddingClassName}>
            <Form.Group>
              <Form.Label>{getMessage(config, 'Select quantity')}</Form.Label>
              <Form.Control type='number' value={count} min={1} size={'sm'} style={{
                width: '6em'
              }} onChange={(e) => setCount(parseInt(e.currentTarget.value))}/>
            </Form.Group>
          </Form>
          <div className={paddingClassName}>
            {<SecuredComponentWrapper showMessage={false}>
              <AddToCartForm {...addToCartParams} page={page}/>
            </SecuredComponentWrapper>}
          </div>
          <div className={paddingClassName}>
            <p dangerouslySetInnerHTML={{__html: variantDescription}}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={true} className={'d-flex justify-content-center'}>
          {variants && variants.length > 1 && <ListGroup horizontal className={paddingClassName}>
            {variants.map((item, key: number) => (
              <ListGroup.Item key={key}
                              active={item.itemId === variantItemId && item.itemId.code === variantItemId.code}
                              onClick={() => setVariant(item)}>
                <Image thumbnail style={{height: '5em'}}
                       src={getImageSetUrl(variantImageSet)}/>
              </ListGroup.Item>
            ))}
          </ListGroup>}
        </Col>
      </Row>
    </Container>
  );
};
