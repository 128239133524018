/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useMemo} from 'react';
import {useCookies} from 'react-cookie';
import {BrProps} from '@bloomreach/react-sdk';
import {SuggestionsProps} from './DemoSuggestions';
import {useSearch} from '../../hooks';
import {Suggestions_findSuggestions, Suggestions_findSuggestions_items} from '../../modules/commerce';
import {BrProduct, DEFAULT_UID_COOKIE, getItem, SuggestionGroupsV2} from '../DemoBrsmUtils';
import {configValue} from '../../utils/ApiUtils';

interface InputProps {
  input: string;
  viewId?: string;
}

function withDemoBrsmSuggestionsBase<P extends BrProps>(Component: React.ComponentType<P & SuggestionsProps>) {
  return (props: P & InputProps) => {
    const [cookies] = useCookies(['_br_uid_2']);
    const {page, input, viewId} = props;

    const {
      discoveryAuthKey,
      discoveryDomainKey,
      discoveryAccountId,
      smAuthKey,
      smDomainKey,
      smAccountId
    } = page!.getChannelParameters();

    const authKey = smAuthKey || discoveryAuthKey;
    const domainKey = smDomainKey || discoveryDomainKey;
    const accountId = smAccountId || discoveryAccountId;

    const endpoint = configValue({envKey: 'REACT_APP_SUGGESTION_ROOT_URI', defaultKey: 'suggestion.endpoint'})

    const params = useMemo(
      () => ({
        _br_uid_2: cookies._br_uid_2 || DEFAULT_UID_COOKIE,
        view_id: viewId,
        account_id: accountId,
        auth_key: authKey,
        domain_key: domainKey,
        catalog_views: domainKey,
        request_type: 'suggest',
        url: window.location.href,
        ref_url: window.location.href,
        request_id: new Date().getTime(),
        q: input,
      }),
      [
        cookies._br_uid_2,
        accountId,
        authKey,
        domainKey,
        viewId,
        input
      ],
    );

    // Perform actual search query
    const [results, loading] = useSearch(endpoint, params);
    if (loading || !results) {
      return null;
    }

    let suggestions: Suggestions_findSuggestions | null;

    const terms: string[] = [];
    const items: BrProduct[] = [];

    ((results?.suggestionGroups ?? []) as SuggestionGroupsV2[]).forEach((group) => {
      terms.push(...(group.querySuggestions?.map((item) => item.query) ?? []));
      items.push(...(group.searchSuggestions ?? []));
    });

    suggestions = {
      __typename: "SuggestionResult",
      terms: terms,
      items: items.map(item => getItem(item) as Suggestions_findSuggestions_items)
    }

    return <Component loading={loading} suggestions={suggestions} {...props} />;
  };
}

export function withDemoBrsmSuggestions<P extends BrProps>(Component: React.ComponentType<P & SuggestionsProps>) {
  return withDemoBrsmSuggestionsBase(Component);
}
