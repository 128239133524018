/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useQuery} from '@apollo/client';
import {
  CurrentCustomerAddressGroup,
  CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup,
  GET_CURRENT_CUSTOMER_ADDRESS_GROUP,
} from '../../modules/commerce';
import {withApolloClient} from '../ApolloClient';

export interface GetAddressGroupProps {
  loading: boolean;
  addressGroup?: CurrentCustomerAddressGroup_getCurrentCustomerAddressGroup;
}

function withGetAddressGroupBase<P>(Component: React.ComponentType<P & GetAddressGroupProps>) {
  return (props: P) => {
    const {loading, data} = useQuery<CurrentCustomerAddressGroup>(GET_CURRENT_CUSTOMER_ADDRESS_GROUP);

    return <Component loading={loading} addressGroup={data?.getCurrentCustomerAddressGroup ?? undefined} {...props} />;
  };
}

export function withGetAddressGroup<P>(Component: React.ComponentType<P & GetAddressGroupProps>) {
  return withApolloClient(withGetAddressGroupBase(Component));
}
