/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {lazy, Suspense} from 'react';
import {ContainerItem} from '@bloomreach/spa-sdk';
import {BrProps} from '@bloomreach/react-sdk';
import {Loader} from '../utility';
import {BrsmProductDetail} from './ProductDetail';

const ProductDetail = lazy(() => import('./ProductDetail')
  .then((module) => ({default: module.ProductDetail}))
);

export const ProductPageComponent = (props: BrProps) => {
  const {page, component} = props;

  const containerItem = (component as ContainerItem)!
  const {connectorid, product}: any = containerItem.getContent(page!);

  const defaultConnector = connectorid?.selectionValues?.[0]?.key || 'brsm';
  const productID = product?.productid && product?.productid.replaceAll(';code=', '___').replaceAll('id=', '');

  if (!productID) {
    return null;
  }

  const RenderProduct = () => {
    switch (defaultConnector) {
      case 'brsm':
        return <BrsmProductDetail productID={productID} page={page!} component={component!} />;
      default:
        return <ProductDetail productID={productID} page={page!} component={component!}/>;
    }
  }

  return (
    <Suspense fallback={<Loader/>}>
      <RenderProduct/>
    </Suspense>
  );
};
