/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  CurrentCustomerAddressGroup,
  GET_CURRENT_CUSTOMER_ADDRESS_GROUP,
  REMOVE_CURRENT_CUSTOMER_ADDRESS,
  RemoveCurrentCustomerAddress,
  RemoveCurrentCustomerAddress_removeCurrentCustomerAddress,
  RemoveCurrentCustomerAddressVariables,
} from '../../modules/commerce';
import {withApolloClient} from '../ApolloClient';

interface InputProps {
  addressId: string;
}

export interface RemoveAddressProps {
  removeAddress: () => Promise<FetchResult<RemoveCurrentCustomerAddress>>;
  loading: boolean;
  result?: RemoveCurrentCustomerAddress_removeCurrentCustomerAddress;
}

function withRemoveAddressBase<P>(Component: React.ComponentType<P & RemoveAddressProps>) {
  return (props: P & InputProps) => {
    const [removeAddress, {data, loading}] = useMutation<RemoveCurrentCustomerAddress,
      RemoveCurrentCustomerAddressVariables>(REMOVE_CURRENT_CUSTOMER_ADDRESS, {
      variables: {
        addressId: props.addressId,
      },
      // eslint-disable-next-line no-shadow
      update(cache, {data}) {
        if (data?.removeCurrentCustomerAddress.address) {
          const results = cache.readQuery<CurrentCustomerAddressGroup>({query: GET_CURRENT_CUSTOMER_ADDRESS_GROUP});
          if (results?.getCurrentCustomerAddressGroup?.addresses) {
            cache.writeQuery<CurrentCustomerAddressGroup>({
              query: GET_CURRENT_CUSTOMER_ADDRESS_GROUP,
              data: {
                getCurrentCustomerAddressGroup: {
                  __typename: 'AddressGroup',
                  id: results.getCurrentCustomerAddressGroup.id,
                  addresses: results.getCurrentCustomerAddressGroup.addresses.filter(
                    (addr) => addr?.id !== data.removeCurrentCustomerAddress.address?.id,
                  ),
                  defaultShippingAddress: results.getCurrentCustomerAddressGroup.defaultShippingAddress,
                  defaultBillingAddress: results.getCurrentCustomerAddressGroup.defaultBillingAddress,
                },
              },
            });
          }
        }
      },
    });

    return (
      <Component
        removeAddress={removeAddress}
        loading={loading}
        result={data?.removeCurrentCustomerAddress}
        {...props}
      />
    );
  };
}

export function withRemoveAddress<P>(Component: React.ComponentType<P & RemoveAddressProps>) {
  return withApolloClient(withRemoveAddressBase(Component));
}
