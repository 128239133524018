/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useMemo, useState} from 'react';
import {BrProps} from '@bloomreach/react-sdk';
import {useSearch} from '../../hooks';
import {FacetFieldFilterInput} from '../../modules/commerce';

export interface BrsmContentSearchParamsType {
  keyword?: string,
  offset?: number,
  limit?: number,
  sort?: string,
  facetFilters?: string | string[] | FacetFieldFilterInput | FacetFieldFilterInput[] | null
}

export interface DemoBrsmContentSearchProps {
  contentSearchLoading: boolean;
  contentSearchResult: any;
  setKeyword: any;
}

function withBrsmContentSearchBase<P extends BrProps>(Component: React.ComponentType<P & DemoBrsmContentSearchProps>) {
  return (props: P & BrsmContentSearchParamsType) => {
    const {
      page,
      keyword: keywordInput,
      offset: offsetInput,
      limit: limitInput,
      sort: sortInput,
      facetFilters: facetFiltersInput
    } = props;

    const {
      contentSearchAccountId,
      contentSearchCatalogName
    } = page?.getChannelParameters() ?? {};

    const endpoint = process.env.REACT_APP_CONTENT_SEARCH_ROOT_URI!;
    const facetFields = process.env.REACT_APP_CONTENT_SEARCH_FACETS!;
    const fields = process.env.REACT_APP_CONTENT_SEARCH_FIELDS!;

    const catalogName = contentSearchCatalogName || process.env.REACT_APP_CONTENT_SEARCH_CATALOG_NAME;
    const accountId = contentSearchAccountId || process.env.REACT_APP_CONTENT_SEARCH_ACCOUNT_ID;

    const [keyword, setKeyword] = useState<string>(keywordInput ?? '');

    const params = useMemo(
      () => ({
        account_id: accountId,
        catalog_name: catalogName,
        request_type: 'search',
        search_type: 'keyword',
        rows: limitInput ?? 12,
        start: offsetInput ?? 0,
        fl: fields.split(','),
        q: keyword ?? keywordInput,
        sort: sortInput,
        'facet.field': facetFields,
        fq: facetFiltersInput,
        url: window.location.href,
      }),
      [
        accountId,
        catalogName,
        limitInput,
        offsetInput,
        fields,
        keywordInput,
        sortInput,
        facetFiltersInput,
        keyword,
        facetFields
      ],
    );


    // Perform actual search query
    const [results, loading] = useSearch(endpoint, params);

    let contentSearchResult;

    if (results) {
      const {
        response: {numFound, docs},
        facet_counts: {facet_fields},
      } = results;

      contentSearchResult = {
        numFound,
        docs,
        facet_fields
      }
    }

    return (
      <Component
        contentSearchLoading={loading}
        contentSearchResult={contentSearchResult}
        setKeyword={setKeyword}
        {...props}
      />
    );
  };
}

export function withBrsmContentSearch<P extends BrProps>(Component: React.ComponentType<P & DemoBrsmContentSearchProps>) {
  return withBrsmContentSearchBase(Component);
}
