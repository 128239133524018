/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect} from 'react';
import {Button, Col, Form} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {Icon, Status} from '../../utility';
import {UserContext} from '../../../contexts';
import {GetErrorProps, UpdateCustomerProps, withGetError} from '../../../connector-components';
import {CurrentCustomer_getCurrentCustomer} from '../../../modules/commerce';
import {withDemoUpdateCustomer} from '../../../demo-connector-components';

export interface UpdateCustomerExtraProps {
  customer?: CurrentCustomer_getCurrentCustomer
}

const config = require('../UserProfileComponentConfig.json');

function UpdateUserDetailBase(props: BrProps & UpdateCustomerProps & UpdateCustomerExtraProps & GetErrorProps) {
  const {
    customer,
    updateCustomerForm,
    updateCustomer,
    loading,
    result,
    errors,
    clearError
  } = props;

  const {
    setEmail,
    setFirstName,
    setMiddleName,
    setLastName,
  } = updateCustomerForm;

  const {userState: {setUser}} = useContext(UserContext)!;

  const {defaultMessage, successMessage} = config.security.updateUserDetail;

  useEffect(() => {
    if (!loading && result?.success) {
      setUser(result?.customer);
    }
  }, [result?.customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage = errors?.find((error) => error.operation === 'updateCustomer')?.message;
  return (
    <Form noValidate={true}>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label className={'text-uppercase'}>First name</Form.Label>
          <Form.Control type='text' name='firstName' defaultValue={customer?.firstName ?? ''} required
                        onChange={(e) => setFirstName(e.currentTarget.value)}/>
        </Form.Group>
        <Form.Group as={Col} xs={1}>
          <Form.Label className={'text-uppercase'}>Middle</Form.Label>
          <Form.Control type='text' name='middleName' defaultValue={customer?.middleName ?? ''}
                        onChange={(e) => setMiddleName(e.currentTarget.value)}/>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label className={'text-uppercase'}>Last name</Form.Label>
          <Form.Control type='text' name='lastName' defaultValue={customer?.lastName ?? ''} required
                        onChange={(e) => setLastName(e.currentTarget.value)}/>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label className={'text-uppercase'}>Email</Form.Label>
          <Form.Control type='text' name='email' defaultValue={customer?.email} required
                        onChange={(e) => setEmail(e.currentTarget.value)}/>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Button variant='primary' onClick={() => {
            clearError();
            updateCustomer();
          }}>
            {loading ? <Icon name={'spinner'} size={'lg'} spin content={defaultMessage}/>
              : <Icon name={'save'} content={defaultMessage}/>
            }
          </Button>
          {errorMessage && <Status error status={errorMessage} customClassName={'mt-3'} dismissible/>}
          {result?.success && <Status status={successMessage} customClassName={'mt-3'} dismissible/>}
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

export const UpdateUserDetail = withGetError(withDemoUpdateCustomer(UpdateUserDetailBase));
