/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Container} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {UpdateUserDetail} from '../security/UpdateUserDetail';
import {ChangeUserPassword} from '../security/ChangeUserPassword';
import {Status} from '../../utility';
import {GetCurrentCustomerProps, withGetCurrentCustomer} from '../../../connector-components';

const config = require('../UserProfileComponentConfig.json');

function ProfileSecurityBase(props: BrProps & GetCurrentCustomerProps) {
  const {
    page,
    component,
    loading,
    currentCustomer
  } = props;

  if (loading) {
    return <Status container loading status={config.security.updateUserDetail.defaultLoadingMessage}/>
  }

  if (!currentCustomer || !currentCustomer.currentCustomer) {
    return null;
  }

  return (
    <Container className={'pt-5'}>
      <ChangeUserPassword page={page!} component={component!} loading={loading}/>
      <UpdateUserDetail customer={currentCustomer.currentCustomer!} page={page!} component={component!}/>
    </Container>
  );
}

export const ProfileSecurity = withGetCurrentCustomer(ProfileSecurityBase);
