/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {SESSION_ORDER} from './OrderComponentTypes';
import {CartStageEnum} from '../global/CommonEnums';

export const saveOrderToSession = (order: any | undefined) => {
  order ? sessionStorage.setItem(SESSION_ORDER, JSON.stringify(order)) : sessionStorage.removeItem(SESSION_ORDER);
}

export const readOrderFromSession = (): any | undefined => {
  const item = sessionStorage.getItem(SESSION_ORDER);
  return item ? JSON.parse(item) : undefined;
}

export const getInitialStage = (): CartStageEnum => {
  const orderDetails = readOrderFromSession();
  let initialStage = CartStageEnum.SHOPPING;
  if (orderDetails) {
    initialStage = CartStageEnum.SHIPPING;
    if (orderDetails.shippingAddress) {
      initialStage = CartStageEnum.DELIVERY;
      if (orderDetails.shipMethod) {
        initialStage = CartStageEnum.BILLING;
        if (orderDetails.billingAddress) {
          initialStage = CartStageEnum.PURCHASE;
          if (orderDetails.draft === false) {
            initialStage = CartStageEnum.FINISH;
          }
        }
      }
    }
  }
  return initialStage;
}
