/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {useApolloClient} from '@apollo/client';
import {auth} from '../../modules/commerce/api';
import {withApolloClient} from '../ApolloClient';

export interface LogoutProps {
  logout: () => Promise<void>;
  loading: boolean;
}

function withLogoutBase<P>(Component: React.ComponentType<P & LogoutProps>) {
  return (props: P) => {
    const [loading, setLoading] = useState(false);
    const client = useApolloClient();

    const logout = async () => {
      setLoading(true);
      auth.logout();
      await client.resetStore();
      setLoading(false);
    };

    return <Component logout={logout} loading={loading} {...props} />;
  };
}

export function withLogout<P>(Component: React.ComponentType<P & LogoutProps>) {
  return withApolloClient(withLogoutBase(Component));
}
