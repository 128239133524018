/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Badge} from 'react-bootstrap';
import {AuthorBioProps} from '../AuthorBioComponentTypes';
import {getMessage} from '../../../utils/MessageUtils';

const config = require('../AuthorConfig.json');
export default function AuthorBioDetails({fullName, role, info}: AuthorBioProps) {
  return (
    <div className='author-details'>
      <div className={'mb-4'}>
        <h3>{`${getMessage(config, 'hey-i-am')} ${fullName}`} <Badge pill variant={'primary'} className={'ml-3'}>
          <small>{role}</small>
        </Badge></h3>
      </div>
      {info && <p dangerouslySetInnerHTML={{__html: info}}/>}
    </div>
  );
}
