/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useState} from 'react';
import {Badge, Button, Card, CardDeck, Col, Container, Jumbotron, Row} from 'react-bootstrap';
import {Document} from '@bloomreach/spa-sdk';
import {BrProps} from '@bloomreach/react-sdk';
import {GlobalSearchContext, UserContext} from '../../contexts';
import {ComponentWrapper, SearchBoxComponent} from '..';
import {GuidedSellingOption, GuidedSellingStepContent} from './GuidedSellingFlowComponentTypes';
import {getProps} from './GuidedSellingFlowComponentUtils';

export const GuidedSellingFlowComponent = (props: BrProps) => {
  const {
    page,
    component
  } = props;
  const {userSegmentState: {userSegment, setUserSegment}} = useContext(UserContext)!;

  const [currStepIndex, setCurrStepIndex] = useState(0);
  const useSearchContext = () => useContext(GlobalSearchContext);
  const {globalSearchParams, setGlobalSearchParams} = useSearchContext()!;
  const documentRef = component!.getModels().document;
  const document = documentRef ? page!.getContent<Document>(documentRef) : undefined;
  if (!document) {
    return null;
  }

  const props2 = getProps(page!, {documentRef});
  const {content} = props2;
  const steps: GuidedSellingStepContent[] = content && content.steps;

  // Fix the issue with paramName
  if (steps) {
    steps.forEach((step : any) => {
      step.paramName = step?.paramName?.selectionValues?.[0]?.key;
    })
  }

  const handleOptionClick = ({paramName, paramValue}: any) => {

    const newParams: any = {};
    newParams[paramName] = paramValue;
    //DataLayerUtils.brxEvent( 'brx.search.update', newParams );
    const updatedParams = {...globalSearchParams, ...newParams};
    setGlobalSearchParams(updatedParams);

    // Set segment in userContext
    if (updatedParams?.segment !== userSegment?.secondary) {
      setUserSegment({secondary: updatedParams?.segment});
    }

    const nextStepIndex = currStepIndex + 1;
    setCurrStepIndex(nextStepIndex);
  };

  const handleReset = () => {
    const resetParams = {view_id: process.env.REACT_APP_DEFAULT_VIEW_ID, segment: '', filter: ''};
    setGlobalSearchParams(resetParams);
    // Reset segment
    setUserSegment({secondary: ''});
    setCurrStepIndex(0);
  };

  const OptionsStep = ({paramName, options, text, title}: GuidedSellingStepContent) => {
    //const { globalSearchParams, setGlobalSearchParams } = useSearchContext()!;
    return (
      <Row className='h-80'>
        <Col className='my-auto'>
          {title &&
          <Card.Title as='div'
                      className='h5 font-weight-extra-light mb-1 text-black-50'>{title}</Card.Title>}
          {text && <Card.Text as='div' className='h3 mb-0 text-black'
                              dangerouslySetInnerHTML={{__html: text}}/>}
        </Col>
        <Col className='d-flex justify-content-around my-auto'>
          <CardDeck>
            {options && options.map((option: GuidedSellingOption, index: number) => (
              option && option.value &&
              <Card as='a'
                    className='guided-selling-option bg-primary h-100 px-4 pb-2 pt-3 shadow btn btn-primary'
                    key={index}
                    onClick={() => handleOptionClick({paramName, paramValue: option.value})}
                    role='button'>
                <Card.Img className='h-80 mb-2' id={`${paramName}|${option.value}`}
                          style={{backgroundImage: `url(${option.iconUrl})`}}/>
                <Card.Body className='h-20 p-0 text-center'>
                  <Card.Text as='div'
                             className='font-weight-light mb-0 text-white'>{option.label}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </CardDeck>
        </Col>
      </Row>
    );
  };

  const SearchStep = ({paramName, options, text, title}: GuidedSellingStepContent) => {
    //const { globalSearchParams, setGlobalSearchParams } = useSearchContext()!;
    return (
      <Row className='h-80'>
        <Col className='my-auto'>
          {title &&
          <Card.Title as='div'
                      className='h5 font-weight-extra-light mb-1 text-black-50'>{title}</Card.Title>}
          {text && <Card.Text as='div' className='h3 mb-3 text-black'
                              dangerouslySetInnerHTML={{__html: text}}/>}
          <Button as='a' className='mb-2 mr-2' onClick={() => handleReset()}
                  role='button' variant='outline-dark'>Reset</Button>
        </Col>
        <Col className='my-auto'>
          <SearchBoxComponent {...props}/>
        </Col>
      </Row>
    );
  };

  return (
    <ComponentWrapper  {...{
      type: 'Guided Selling Flow', inlineEditingProps: {
        page,
        content: document
      }
    }}>
      <Jumbotron className='guided-selling--flow' data-content-name={document?.getName()}
                 style={{backgroundImage: `url(${content?.imageUrl})`}}>
        <Container>
          <Card className='bg-transparent border-0'>
            {content?.header &&
            <Card.Header dangerouslySetInnerHTML={{__html: content.header}}/>}
            <Card.Body className='h-100 p-0'>
              {steps[currStepIndex] ? <OptionsStep {...steps[currStepIndex]} /> :
                <SearchStep {...steps[currStepIndex - 1]}/>}
              <Row className='h-20 mt-4'>
                <Col className='d-flex justify-content-center h-20'>
                  {steps.map((step, key) => {
                    return <Badge key={key} className='mx-2' pill
                                  variant={key === currStepIndex ? 'light' : 'primary'}>{key + 1}</Badge>;
                  })}
                  <Badge className='mx-2' pill
                         variant={currStepIndex === steps.length ? 'light' : 'primary'}>{steps.length + 1}</Badge>
                </Col>
              </Row>
            </Card.Body>
            {content?.footer &&
            <Card.Footer dangerouslySetInnerHTML={{__html: content.footer}}/>}
          </Card>
        </Container>
      </Jumbotron>
    </ComponentWrapper>
  );
};
