/*
 * Copyright 2019 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {ApolloClient} from '@apollo/client';
import {Page} from '@bloomreach/spa-sdk';
import {
  Cart,
  CartFragment,
  CartVariables,
  CurrentCustomer,
  CustomerFragment,
  GET_CART,
  GET_CURRENT_CUSTOMER,
} from '../modules/commerce';
import {loggedInVar, sessionService} from '../modules/commerce/api';

export async function refreshCustomer(client: ApolloClient<unknown>): Promise<CustomerFragment | null> {
  const result = await client.query<CurrentCustomer>({
    query: GET_CURRENT_CUSTOMER,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const currentCustomer = result.data.getCurrentCustomer;
  if (currentCustomer) {
    loggedInVar(true);
  }
  return currentCustomer;
}

export async function refreshCart(client: ApolloClient<unknown>): Promise<CartFragment | null> {
  sessionService.setCartIdInSession(undefined);

  const result = await client.query<Cart>({
    query: GET_CART,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const cart = result.data.getCart;
  if (cart?.id) {
    client.writeQuery<Cart, CartVariables>({
      query: GET_CART,
      variables: {id: cart.id},
      data: result.data,
    });

    sessionService.setCartIdInSession(cart.id);
  }

  return cart;
}

/*
export interface BrProps<T extends Component = Component> {
  component: T;
  page: Page;
}
*/

export type ChannelMetaData = {
  channel?: {
    info: {
      props: Record<string, string>;
    };
  };
};

export function getDefaultConnector(page: Page): string {
  const channelInfoProps = page.getChannelParameters();
  return channelInfoProps?.apolloConnector ?? channelInfoProps?.defaultConnector ?? 'brsm';
}

export function getDefaultTitle(page: Page): string {
  return page.getChannelParameters()?.pacificTitle || process.env.REACT_APP_PACIFIC_TITLE || 'Pacific';

}

export function getSmViewId(page: Page): string | undefined {
  const {
    smViewId,
    discoveryViewId
  } = page.getChannelParameters();
  return sessionService.getPreferredSmViewId() || smViewId || discoveryViewId;
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return !!value;
}
