/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Page} from '@bloomreach/spa-sdk';
import {BannerComponentArgs, BannerContent} from '../banner/BannerComponentTypes';
import {getLinksArrayDynamic, getResourceLink} from '../global/ComponentUtils';
import {UserSegmentType} from '../../contexts';
import {getImageVariants} from '../../utils/ImageUtil';

export const getAllItemsProps = (
  pageable: any,
  params: any,
  template: string
): BannerComponentArgs[] => {
  return Object.keys(pageable)
    .filter(key => !!pageable[key])
    .map(key => {
      const idx = key.replace('banner', 'Banner');
      const itemParams = {
        textAlignment: params[`textAlignment${idx}`] || 'left',
        textColor: params[`textColor${idx}`] || 'black',
        template: template,
      };
      return {
        documentRef: pageable[key],
        params: itemParams,
        template: template,
        fluid: false,
        nowrap: true,
      };
    });
};

export const getSlideContent = (page: Page, data: any, userSegment: UserSegmentType): BannerContent => {
  const {content, images: image, links: linkRefs, resource, title} = data;

  const linksArray = getLinksArrayDynamic(page, linkRefs, userSegment);
  let links = (linksArray && linksArray.filter((linkRef: any) => linkRef.url && linkRef.label)) || [];
  const titleLink = linksArray && linksArray.find((linkRef: any) => linkRef.url && (!linkRef.label || 0 === linkRef.label.length));
  const resourceLink = getResourceLink(resource);
  if (resourceLink) {
    links.push(resourceLink);
  }

  return {
    title,
    titleLink,
    subtitle: '',
    text: page.rewriteLinks(content?.value),
    links,
    images: getImageVariants(page, image)
  };
};

