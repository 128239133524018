/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {ArticleByAuthor, ArticleByAuthorContentProps, ArticlesByAuthorProps} from '../../article/ArticleComponentTypes';

export const GetArticlesByAuthorProps = ({page, pageable, params = {}}: ArticleByAuthorContentProps): ArticlesByAuthorProps | null => {
  if (!pageable) {
    return null;
  }

  const showPosts = params.showPosts !== 'off';
  const pageSize = parseInt(params.pageSize || '12');

  let items: Array<ArticleByAuthor> = [];
  if (showPosts) {
    (pageable?.items ?? []).some((item: any, index: number) => {
      const document = page.getContent(item);
      if (document) {
        const {title} = document.getData();
        items.push({
          title,
          link: {
            url: document.getUrl()!
          }
        })
      }
      return pageSize ? index >= pageSize : true;
    });
  }

  return {
    items
  };
};
