/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {gql} from '@apollo/client';
import {CART_FRAGMENT} from '../queries/cart';

export const ADD_TO_CART = gql`
  mutation AddToCart($input: AddToCartInput!) {
    addToCart(addToCartInput: $input) {
      code
      success
      message
      cart {
        ...CartFragment
      }
    }
  }

  ${CART_FRAGMENT}
`;

export const UPDATE_CART = gql`
  mutation UpdateCart($input: UpdateCartInput!) {
    updateCart(updateCartInput: $input) {
      code
      success
      message
      cart {
        ...CartFragment
      }
    }
  }

  ${CART_FRAGMENT}
`;

export const REMOVE_FROM_CART = gql`
  mutation RemoveFromCart($input: RemoveFromCartInput!) {
    removeFromCart(removeFromCartInput: $input) {
      code
      success
      message
      cart {
        ...CartFragment
      }
    }
  }

  ${CART_FRAGMENT}
`;

export const UPDATE_CART_BY_ORDER = gql`
  mutation UpdateCartByOrder($orderId: String!) {
    updateCartByOrder(orderId: $orderId) {
      code
      success
      message
      cart {
        ...CartFragment
      }
    }
  }

  ${CART_FRAGMENT}
`;
