/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {Media} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {ProductPrice} from '../../product/templates';
import {ProductGridProps} from '../../product/ProductComponentTypes';
import {getImageSetUrl} from '../../../demo-connector-components/DemoBrsmUtils';
import {getProductUrl} from '../../product/ProductComponentUtils';
import {BrPageContext} from '@bloomreach/react-sdk';
import {UserContext} from '../../../contexts';

const ProductGridSidebar = ({products}: ProductGridProps) => {
  const page = useContext(BrPageContext)!;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  return (
    <div className='mt-4 productgrid-sidebar'>
      <h4>Related products</h4>
      {products?.map((product, key) => {
          const {itemId, imageSet, displayName, listPrice} = product!;
          const productUrl = getProductUrl(page, itemId.id, itemId.code, userSegment)
          return (
            <div className={'h6'} key={key}>
              <Link to={productUrl}>
                <Media className={'mb-3'}>
                  {imageSet &&
                  <div className={'image-thumbnail image-background mr-2'} style={{
                    backgroundImage: 'url(' + getImageSetUrl(imageSet) + ')'
                  }}/>}
                  <Media.Body>
                    <strong className={'font-weight-normal'}>
                      {displayName}
                    </strong>
                    <div>
                      <ProductPrice price={listPrice!}/>
                    </div>
                  </Media.Body>
                </Media>
              </Link>
            </div>
          )
        }
      )}
    </div>
  )
};

export default ProductGridSidebar;
