/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useMemo} from 'react';
import {useCookies} from 'react-cookie';
import {
  DemoItemsByCategory_findItemsByCategory,
  DemoItemsByCategory_findItemsByCategory_facetResult_fields,
  DemoItemsByCategory_findItemsByCategory_items,
  QueryHintInput,
} from '../../modules/commerce';
import {BrProps} from '@bloomreach/react-sdk';
import {getSmViewId} from '../../connector-components/utils';
import {addError} from '../../modules/commerce/api';
import {ProductGridCategoryProps, ProductsInputProps} from './DemoProductGridCategory';
import {useSearch} from '../../hooks';
import {useHistory} from 'react-router-dom';
import {
  BrProduct,
  createFacetResult,
  DEFAULT_BRSM_FIELDS,
  DEFAULT_UID_COOKIE,
  getItem,
  sortFieldsMapping,
  statsField
} from '../DemoBrsmUtils';
import {Status} from '../../components/utility';

function withDemoBrsmProductGridCategoryBase<P extends BrProps>(Component: React.ComponentType<P & ProductGridCategoryProps>) {
  return (props: P & ProductsInputProps) => {
    const {
      page,
      categoryId,
      viewId = '',
      widgetId = '',
      segment,
      pageSize = 12,
      pageNumber = 1,
      facetFieldFilters = [],
      sortFields = ''
    } = props;

    const {
      smEndpoint,
      discoveryRealm,
      discoveryAuthKey,
      discoveryDomainKey,
      discoveryAccountId,
      discoveryFields,
      smAuthKey,
      smDomainKey,
      smAccountId
    } = page!.getChannelParameters();

    const endpoint = smEndpoint || `https://${discoveryRealm === 'STAGING' ? 'staging-': ''}core.dxpapi.com/api/v1/core/`;
    const authKey = smAuthKey || discoveryAuthKey;
    const domainKey = smDomainKey || discoveryDomainKey;
    const accountId = smAccountId || discoveryAccountId;
    const fields = discoveryFields || DEFAULT_BRSM_FIELDS;

    const smViewId = viewId || getSmViewId(page!) || '';
    const [cookies] = useCookies(['_br_uid_2']);

    if (!categoryId) {
      addError({
        code: 'VALIDATION_ERROR',
        message: 'Category id not provided',
        operation: 'fetchProductByCategory',
      });
    }

    const queryHint: QueryHintInput = {
      facetFieldFilters,
      viewId: smViewId,
      brUid2: cookies._br_uid_2,
    }

    queryHint.params = [{
      name: 'url',
      values: [window.location.href]
    }];

    if (segment) {
      queryHint.params.push({
        name: 'segment',
        values: [segment]
      })
    }

    queryHint.customAttrFields = ['skuid'];
    queryHint.customVariantAttrFields = ['skuid'];

    const history = useHistory();

    const {query, groupBy} = useMemo(() => {
      const search = new URLSearchParams(history.location.search);
      return {
        query: search.get('q') ?? search.get('cid') ?? categoryId,
        groupBy: search.get('groupby')
      }
    }, [history.location.search, categoryId]);

    const facetFieldFiltersJson = JSON.stringify(facetFieldFilters);

    const params = useMemo(
      () => {
        return {
          view_id: smViewId,
          widget_id: widgetId,
          account_id: accountId,
          auth_key: authKey,
          domain_key: domainKey,
          search_type: 'category',
          request_type: 'search',
          url: window.location.href,
          ref_url: window.location.href,
          request_id: new Date().getTime(),
          //widget_id: widgetId || widget?.[searchType],
          //user_id: userId,
          q: query,
          groupby: groupBy,
          group_limit: groupBy ? Math.min(pageSize, 8) : '',
          fl: fields.split(','),
          fq: JSON.parse(facetFieldFiltersJson),
          rows: pageSize,
          sort: sortFieldsMapping[sortFields]!,
          start: pageSize * (pageNumber - 1),
          _br_uid_2: cookies._br_uid_2 || DEFAULT_UID_COOKIE,
          'stats.field': statsField,
        }
      },
      [
        cookies._br_uid_2,
        accountId,
        authKey,
        domainKey,
        smViewId,
        query,
        groupBy,
        pageNumber,
        pageSize,
        facetFieldFiltersJson,
        sortFields,
        widgetId,
        fields
      ],
    );

    // Perform actual search query
    const [results, loading, error] = useSearch(endpoint, params);
    if (loading) {
      return null;
    }

    if (!loading && error) {
      return <Status status={error.message} container error/>
    }

    const {
      category_map: categoryMap,
      response: {numFound, start, docs},
      facet_counts,
    } = results;

    const categoryName = categoryMap?.[categoryId];

    const itemsPageResult: DemoItemsByCategory_findItemsByCategory = {
      __typename: 'ItemsPageResult',
      count: docs.length,
      facetResult: {
        __typename: "FacetResult",
        fields: createFacetResult(facet_counts) as (DemoItemsByCategory_findItemsByCategory_facetResult_fields | null)[]
      },
      items: (docs as (BrProduct)[]).map(item => {
        return (getItem(item) as DemoItemsByCategory_findItemsByCategory_items);
      }),
      limit: pageSize,
      offset: start ?? 0,
      total: numFound
    }

    return (
      <Component
        pageSize={pageSize}
        pageNumber={pageNumber}
        viewId={viewId}
        segment={segment}
        sortFields={sortFields}
        itemsPageResult={itemsPageResult}
        loading={loading}
        facetFieldFilters={facetFieldFilters}
        categoryName={categoryName}
        {...props}
      />
    );
  };
}

export function withDemoBrsmProductGridCategory<P extends BrProps>(
  Component: React.ComponentType<P & ProductGridCategoryProps>,
) {
  return withDemoBrsmProductGridCategoryBase(Component);
}
