/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Document} from '@bloomreach/spa-sdk';
import {BrProps} from '@bloomreach/react-sdk';
import {ComponentWrapper} from '..';
import AuthorBio from './templates/AuthorBio';
import {ArticlesByAuthorComponent} from './articles-by-author/ArticlesByAuthorComponent';
import {GetAuthorBioProps} from './AuthorBioComponentUtils';

export const AuthorBioComponent = (props: BrProps) => {
  const {
    page,
    component
  } = props;
  const {document: documentRef, pagination} = component!.getModels();

  const {model: pageable} = page!.getContent(pagination) as any;

  const content = documentRef
    ? page!.getContent(documentRef)
    : // if we don't get a documentRef at the top, and there's no document component property,
      // pull the document from the page directly
    page!.getDocument<Document>();

  //const content = documentRef && page.getContent(documentRef);

  if (!content) {
    return null;
  }

  const params = component!.getParameters();

  const propsSidebar = GetAuthorBioProps({
    page: page!,
    documentRef,
    content,
    params: {
      type: 'sidebar'
    }
  });

  const propsDetails = GetAuthorBioProps({
    page: page!,
    documentRef,
    content,
    params: {
      type: 'details'
    }
  });

  return (
    <ComponentWrapper {...{type: 'Author Bio'}}>
      <Container className={'py-5'}>
        <Row>
          <Col xs={3}>
            {propsSidebar && <AuthorBio {...propsSidebar}/>}
          </Col>
          <Col xs={9}>
            {propsDetails && <AuthorBio {...propsDetails}/>}
            <div className='mb-4'>
              <ArticlesByAuthorComponent {...{
                page: page!,
                pageable,
                params
              }}/>
            </div>
          </Col>
        </Row>
      </Container>
    </ComponentWrapper>
  );
};
