/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {CartContext} from '../../../contexts';
import {Icon, Status} from '../../utility';
import {AddToCartProps, GetErrorProps, withGetError} from '../../../connector-components';
import {pushGtmEventAddToCart} from '../../gtm/GTMComponentUtils';
import {withDemoAddToCart} from '../../../demo-connector-components';
import {ItemFragment_itemId} from '../../../modules/commerce';
import {WidgetProps} from '../../product/ProductComponentTypes';
import {MoneyAmount, Price} from '../../../demo-connector-components/DemoBrsmUtils';
import {trackExponeaEvent} from '../../exponea/ExponeaUtils';

export interface AddToCartFormProps {
  itemId: ItemFragment_itemId,
  itemKey: string,
  mode: string,
  quantity: number,
  widgetProps?: WidgetProps,
}

export interface AddToCartFormPropsExponea {
  itemPrice: Price, //added for exponea
  itemTitle: string, // added for exponea
}

function AddToCartFormBase(props: GetErrorProps & AddToCartProps & AddToCartFormProps & AddToCartFormPropsExponea) {
  const {setCartDetails}: any = useContext(CartContext);
  const {
    addToCart,
    itemId,
    itemKey,
    loading,
    mode,
    quantity,
    itemPrice,
    itemTitle,
    result,
    widgetProps,
    errors,
    clearError
  } = props;
  const DEFAULT_MESSAGE = 'Add to cart';
  const SUCCESS_MESSAGE = 'Successfully added the item to the cart!';

  const addToCartWrapper = () => {
    // Trigger Discovery ATC event via GTM
    pushGtmEventAddToCart(widgetProps, itemKey, itemId, quantity);

    // TEMP code - to be refactored/cleaned up later
    // Trigger Engagement cart_update event
    let itemMoneyAmount : MoneyAmount | undefined = itemPrice?.moneyAmounts?.[0];
    let priceAmount = itemMoneyAmount?.amount;
    let totalPrice = priceAmount? priceAmount * quantity : 0.0;
    trackExponeaEvent ('cart_update', {
      action: 'add',
      product_id: itemId.id,
      price: priceAmount,
      total_quantity: quantity,
      total_price: totalPrice,
      title: itemTitle
    });
    return addToCart();
  };

  const ButtonTemplate = ({children, ...rest}: any) => {
    switch (mode) {
      case 'button':
        return <Button {...rest} data-attribute-item={itemId.id}
                       data-attribute-code={itemId.code}
                       data-attribute-quantity={quantity}
                       variant={'primary'} size={'sm'}
                       onClick={() => {
                         clearError();
                         addToCartWrapper();
                       }}>
          {loading ? <Icon name={'spinner'} size={'lg'} spin content={DEFAULT_MESSAGE}/>
            : <Icon name={'cart-plus'} content={DEFAULT_MESSAGE}/>
          }
        </Button>;
      case 'icon':
      default:
        return <Button data-attribute-item={itemId.id} data-attribute-code={itemId.code}
                       data-attribute-quantity={quantity}
                       className={'float-right'} {...rest} variant={'primary'}
                       onClick={() => {
                         clearError();
                         addToCartWrapper();
                       }}>
          {loading ? <Icon name={'spinner'} size={'lg'} spin/>
            : <Icon name={'cart-plus'}/>
          }
        </Button>;
    }
  };

  useEffect(() => {
    if (result?.success) {
      setCartDetails(result.cart);
    }
  }, [result?.cart]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage = errors?.find((error: any) => error?.operation === 'addToCart')?.message;
  return (
    <React.Fragment>
      <ButtonTemplate/>
      {errorMessage && <Status status={errorMessage} customClassName={'mt-3'} error/>}
      {result?.success &&
      <Status status={SUCCESS_MESSAGE} customClassName={'mt-3'} dismissible/>}
    </React.Fragment>
  );
}

export const AddToCartForm = withGetError(withDemoAddToCart(AddToCartFormBase));
