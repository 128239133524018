/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Col, Container, Image, ListGroup, Row} from 'react-bootstrap';
import {CallToActionEntryProps} from '../../ArticleComponentTypes';
import {getImageVariantBySize} from '../../../../utils/ImageUtil';
import {ImageSizeEnum} from '../../../global/CommonEnums';

export const CallToActionEntry = (props: CallToActionEntryProps) => {
  const {title, description, images, internalLink, externalLink} = props;
  const image = getImageVariantBySize(images, ImageSizeEnum.Large);
  return (
    <Container className='article-entry-calltoaction'>
      <Row>
        <Col xs={8}>
          <div>{title}</div>
          <h6 dangerouslySetInnerHTML={{__html: description}}/>
          <h6>Links</h6>
          <ListGroup variant={'flush'} className='list-group-borderless mb-5'>
            {externalLink &&
            <ListGroup.Item as={'a'} href={externalLink.url} target='_blank' className={'External Link'}/>}
            {internalLink &&
            <ListGroup.Item as={'a'} href={internalLink.url} target='_self' className={'Internal Link'}/>}
          </ListGroup>
        </Col>
        <Col xs={4}>
          {image && <Image fluid src={image} alt='Call to Action'/>}
        </Col>
      </Row>
    </Container>
  );
};
