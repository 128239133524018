/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {PromotionMedia} from './PromotionComponentTypes';
import {VideoComponentProps} from '../video/VideoComponentTypes';

export const getPromotionVideos = (videos: Array<VideoComponentProps | null> | undefined): Array<PromotionMedia> => {
  return videos ? videos.filter(video => !!video).map(video => {
    const content = video?.content;
    return {
      type: 'VIDEO',
      media: content?.videoItem.videoLink
    } as PromotionMedia
  }) : [];
}

export const getPromotionImages = (images: Array<string>  | undefined): Array<PromotionMedia> => {
  return images ? images.filter(image => !!image).map(image => {
    return {
      type: 'IMAGE',
      media: image
    } as PromotionMedia
  })  : [];
}
