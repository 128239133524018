/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useContext, useEffect} from 'react';
import {Button, Form, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {CartContext} from '../../../contexts';
import {Icon, Status} from '../../utility';
import {GetErrorProps, UpdateCartItemProps, withGetError, withUpdateCartItem} from '../../../connector-components';
import {pushGtmEventUpdateCart} from '../../gtm/GTMComponentUtils';

export interface UpdateCartInputProps {
  entry: any
}

function UpdateCartFormBase(props: GetErrorProps & UpdateCartItemProps & UpdateCartInputProps) {
  const {
    entry,
    quantity,
    setQuantity,
    updateCart,
    loading,
    result,
    errors,
    clearError
  } = props;

  const DEFAULT_MESSAGE = 'Update cart';
  const SUCCESS_MESSAGE = 'Item updated successfully!';
  const {setCartDetails}: any = useContext(CartContext);
  const item = entry.items[0];

  const updateCartWrapper = () => {
    pushGtmEventUpdateCart(item, quantity);
    clearError();
    return updateCart();
  };

  useEffect(() => {
    if (result?.success) {
      setCartDetails(result.cart);
    }
  }, [result?.cart]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage = errors?.find((error) => error?.operation === 'updateCart')?.message;

  return (
    <Fragment>
      <InputGroup>
        <Form.Control
          type='number'
          min={1}
          autoComplete='none'
          onChange={(e) => setQuantity(parseInt(e.currentTarget.value))}
          defaultValue={quantity}
          required
        />
        <InputGroup.Append>
          <OverlayTrigger
            placement={'top'}
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id={`update-${entry?.id}`}>
                {DEFAULT_MESSAGE}
              </Tooltip>
            }
          >
            <InputGroup.Text as={Button}
                             variant={'outline-primary'}
                             onClick={() => updateCartWrapper()}>
              {loading ? <Icon name={'spinner'} size={'lg'} spin/>
                : <Icon name={'cart-arrow-down'}/>
              }
            </InputGroup.Text>
          </OverlayTrigger>
        </InputGroup.Append>
      </InputGroup>
      {errorMessage && <Status status={errorMessage} customClassName={'mt-3'} error/>}
      {result?.success && <Status status={SUCCESS_MESSAGE} customClassName={'mt-3'} dismissible/>}
    </Fragment>
  );
}

export const UpdateCartForm = withGetError(withUpdateCartItem(UpdateCartFormBase));
