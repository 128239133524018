/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect} from 'react';
import {Badge, Col, Container, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AuthorByLine from '../../author/author-byline/templates/AuthorByLine';
import ProductGridSidebar from '../../product-grid/templates/ProductGridSidebar';
import {CallToActionEntry, ParagraphEntry} from './entry';
import {ArticleProps} from '../ArticleComponentTypes';
import {BannerComponent} from '../..';
import {LocationEnum} from '../../global/CommonEnums';
import {getMessage} from '../../../utils/MessageUtils';
import {getImageVariantByResponsiveQuery} from '../../../utils/ImageUtil';
import {ResponsiveQueryProps, withResponsiveQuery} from '../../../demo-connector-components/responsive/ResponsiveQuery';

const config = require('../ArticleConfig.json');

function ArticleBase(props: ArticleProps & ResponsiveQueryProps) {
  const {authors, tags, entries, sidebars, description, images, title, date, responsiveQuery} = props;

  const mainDivClass = sidebars && sidebars?.length > 0 ? 8 : 12;

  const imageUrl = getImageVariantByResponsiveQuery(images, responsiveQuery);

  const divStyle = (responsiveQuery.isMediumOrGreater) && imageUrl ? {
    style: {
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50% 100%',
      backgroundPosition: 'right top'
    }
  } : {};

  useEffect(() => {
    document.querySelectorAll('.article-entry-paragraph p img').forEach((element) => {
      element.classList.add('m-1');
    });
  }, [document]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='article-full'>
      <div className='bg-hero' {...divStyle}>
        <Container>
          <Row>
            <Col md={6} className={'d-flex'}>
              <div className={'my-auto'}>
                {title && <h1 className={'my-2'}>{title}</h1>}
                <ul className={'mb-2 list-inline'}>
                  {tags?.map((tag: string, key: number) => (
                    <li className={'text-uppercase list-inline-item'} key={key}>
                      <Badge pill variant='primary'>{tag}</Badge>
                    </li>
                  ))}
                </ul>
                {description && <p dangerouslySetInnerHTML={{__html: description}}/>}
                <ul className={'my-2 list-inline'}>
                  <li className={'list-inline-item'}><h5>{getMessage(config, 'Share')}:</h5></li>
                  <li className={'list-inline-item'}><FontAwesomeIcon icon={[`fab`, `facebook-f`]}/></li>
                  <li className={'list-inline-item'}><FontAwesomeIcon icon={[`fab`, `instagram`]}/></li>
                  <li className={'list-inline-item'}><FontAwesomeIcon icon={[`fab`, `pinterest`]}/></li>
                  <li className={'list-inline-item'}><FontAwesomeIcon icon={[`fab`, `twitter`]}/></li>
                  <li className={'list-inline-item'}><FontAwesomeIcon icon={[`fab`, `youtube`]}/></li>
                </ul>
              </div>
            </Col>
            <Col md={6} className='height-md-70vh'>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={'py-5'}>
        <Row>
          <Col md={mainDivClass} className={'clearfix'}>
            {authors &&
            <AuthorByLine authors={authors} componentStyle={'Article'} date={date}/>}
            <div dangerouslySetInnerHTML={{__html: description}}/>
            {entries?.map((entry, key: number) => {
                switch (entry?.type) {
                  case 'paragraph':
                  case 'demoParagraph':
                    return (
                      <ParagraphEntry {...entry} key={key}/>
                    );
                  case 'calltoaction':
                  case 'demoCallToAction':
                    return (
                      <CallToActionEntry {...entry} key={key}/>
                    );
                  default:
                    return null;
                }
              }
            )}
          </Col>
          {sidebars && <Col md={4}>
            {sidebars.filter((sidebar) => sidebar != null).map((sidebar, key: number) => {
                switch (sidebar!.type) {
                  case 'banner':
                    const {banner: documentRef} = sidebar!;
                    if (!documentRef) {
                      return null;
                    }
                    const bannerProps = {
                      documentRef,
                      params: {},
                      template: 'sidebar',
                      fluid: true,
                      nowrap: true
                    };
                    return (
                      <BannerComponent {...bannerProps} key={key}/>
                    );
                  case 'product':
                    if (!sidebar?.products) {
                      return null;
                    }
                    const productGridProps = {
                      products: sidebar.products,
                      showTitle: false,
                      showFacets: false,
                      showPagination: false,
                      facetsLocation: LocationEnum.Left
                    }
                    return (
                      <ProductGridSidebar {...productGridProps} key={key}/>
                    );
                  default:
                    return null;
                }
              }
            )}
          </Col>}
        </Row>
      </Container>
    </div>
  );
}

export const Article = withResponsiveQuery(ArticleBase);

