/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useQuery} from '@apollo/client';
import {Cart, CartFragment, CartVariables, GET_CART} from '../../modules/commerce';
import {sessionService} from '../../modules/commerce/api';
import {withApolloClient} from '../ApolloClient';

export interface CartProps {
  loading: boolean;
  cart?: CartFragment;
}

function withCartBase<P>(Component: React.ComponentType<P & CartProps>) {
  return (props: P) => {
    const cartId = sessionService.getCartIdFromSession();
    const {loading, data} = useQuery<Cart, CartVariables>(GET_CART, {
      variables: {
        id: cartId,
      },
    });

    if (data && cartId !== data?.getCart?.id && data?.getCart?.active) {
      sessionService.setCartIdInSession(data?.getCart?.id);
    }

    if (!data?.getCart?.active) {
      sessionStorage.removeItem('cartId');
    }

    return <Component loading={loading} cart={data?.getCart?.active ? data?.getCart : undefined} {...props} />;
  };
}

export function withCart<P>(Component: React.ComponentType<P & CartProps>) {
  return withApolloClient(withCartBase(Component));
}
