/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useState} from 'react';
import {Button, Col, Form} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {CartContext, UserContext} from '../../../contexts';
import {CartStageEnum} from '../../global/CommonEnums';
import {Status} from '../../utility';
import {SetOrderShippingAddress} from '../../order/action/SetOrderShippingAddress';
import ReturnButton from '../action/ReturnButton';
import {GetAddressGroupProps, withGetAddressGroup} from '../../../connector-components';
import {readOrderFromSession} from '../../order/OrderComponentUtils';
import {AddressOptionType, StageInputProps} from '../CartComponentTypes';
import {getProfileTabUrl} from '../../user-profile/UserProfileComponentUtils';

function ShippingBase(props: BrProps & GetAddressGroupProps & StageInputProps) {
  const {
    page,
    setStage,
    loading,
    addressGroup
  } = props;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;

  const {cartDetails}: any = useContext(CartContext);
  const orderDetails = readOrderFromSession();

  const [shippingOptions, setShippingOptions] = useState({
    shippingAddressId: cartDetails.shippingAddress?.id || orderDetails?.shippingAddress?.id
  });

  const {shippingAddressId} = shippingOptions;

  if (loading) {
    return (
      <Status container loading status={'We are fetching customer address group for you'}/>
    )
  }

  if (!addressGroup) {
    return null;
  }

  const {addresses, defaultShippingAddress} = addressGroup;

  const shippingAddressOptions: Array<AddressOptionType> | undefined = addresses?.filter((address) => address?.shippingAddress).map((address) => {
    const {id, streetAddress, additionalStreetInfo, city, state, country, postalCode} = address!;
    return {
      key: id,
      value: id,
      text: `${streetAddress} ${additionalStreetInfo}, ${city}, ${state} ${country} ${postalCode}`
    }
  });

  let selectedAddressId = shippingAddressId || defaultShippingAddress?.id;

  if (!selectedAddressId && addresses && addresses.length > 0) {
    selectedAddressId = addresses[0]?.id;
  }

  const setOrderShippingAddressParams = {
    shippingAddressId: selectedAddressId,
    setStage
  }

  return (
    <React.Fragment>
      <Form noValidate={true}>
        <h4>Shipping <Button variant={'primary'} className={'float-right'} as={'a'} href={getProfileTabUrl(page!, 'addresses', userSegment)}>Manage
          Addresses</Button></h4>
        <Form.Group className={'pt-3'}>
          <Form.Label className={'text-uppercase'}>Select Shipping Address</Form.Label>
          <Form.Control
            as={'select'}
            onChange={(e) => {
              setShippingOptions({...shippingOptions, shippingAddressId: e.target.value});
            }}
            defaultValue={selectedAddressId}
            placeholder='Select Shipping Address'
          >
            {shippingAddressOptions && shippingAddressOptions.map((option) => {
              return <option key={option.key} value={option.value}>{`${option.text}${option.key === defaultShippingAddress?.id ? ' (default)' : ''}`}</option>;
            })}
          </Form.Control>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <ReturnButton stage={CartStageEnum.SHOPPING} setStage={setStage}/>
          </Form.Group>
          <Form.Group as={Col}>
            <SetOrderShippingAddress {...setOrderShippingAddressParams} orderId={orderDetails?.id} page={page!}/>
          </Form.Group>
        </Form.Row>
      </Form>
    </React.Fragment>
  )
}

export const Shipping = withGetAddressGroup(ShippingBase);
