/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';
import {ITEM_LIKE_FRAGMENT} from './items';
import {ITEM_VARIANT_FRAGMENT} from './demoorder';

export const DISCOUNT_FRAGMENT = gql`
  fragment DiscountFragment on Discount {
    code
    targetIds
    type
  }
`;

export const CART_FRAGMENT = gql`
  fragment CartFragment on Cart {
    id
    totalQuantity
    revision
    entries {
      id
      quantity
      items {
        ...ItemLikeFragment
        ...ItemVariantFragment
      }
      customAttrs {
        name,
        values
      }
    }
    totalListPrice {
      moneyAmounts {
        ...MoneyAmountFragment
      }    }
    totalPurchasePrice {
      moneyAmounts {
        ...MoneyAmountFragment
      }
    }
    active
    state
    discounts {
      ...DiscountFragment
    }
  }

  ${ITEM_LIKE_FRAGMENT}
  ${ITEM_VARIANT_FRAGMENT}
  ${DISCOUNT_FRAGMENT}
`;

export const GET_CART = gql`
  query Cart($id: String) {
    getCart(id: $id) {
      ...CartFragment
    }
  }

  ${CART_FRAGMENT}
`;
