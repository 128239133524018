/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gql } from '@apollo/client';

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    firstName
    middleName
    lastName
    streetAddress
    additionalStreetInfo
    company
    postalCode
    city
    state
    country
    billingAddress
    shippingAddress
    readOnly
  }
`;

export const GET_CURRENT_CUSTOMER_ADDRESS_GROUP = gql`
  query CurrentCustomerAddressGroup {
    getCurrentCustomerAddressGroup {
      id
      addresses {
        ...AddressFragment
      }
      defaultShippingAddress {
        ...AddressFragment
      }
      defaultBillingAddress {
        ...AddressFragment
      }
    }
  }

  ${ADDRESS_FRAGMENT}
`;
