/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {AuthorByLineProps, ContentAuthorByLineProps} from './AuthorByLineComponentTypes'
import {UserSegmentType} from '../../../contexts';
import {getImageVariants} from '../../../utils/ImageUtil';

export const getAuthorByLineProps = (props: ContentAuthorByLineProps, userSegment: UserSegmentType): AuthorByLineProps => {
  const {
    page,
    date,
    componentStyle,
    documentRefs
  } = props;
  const authorDocs = documentRefs?.map(documentRef => page.getContent(documentRef))
  const authors = authorDocs?.filter(authorDoc => !!authorDoc)
    .map(authorDoc => {
      const {displayName, picture} = authorDoc!.getData();
      return {
        name: displayName ?? '',
        link: authorDoc!.getUrl()!,
        images: getImageVariants(page, picture)
      }
    })
  return {
    componentStyle,
    authors,
    date
  }
};
