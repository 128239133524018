import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Widget} from '@typeform/embed-react';
import {ContainerItem, getContainerItemContent} from '@bloomreach/spa-sdk';
import {BrComponentContext, BrProps} from '@bloomreach/react-sdk';
import {ComponentWrapper} from '..';

export const TypeformComponent = ({
  component,
  page,
}: BrProps<ContainerItem>): React.ReactElement | null => {
  const { header } = getContainerItemContent(component!, page!) ?? {};
  const componentCtx = React.useContext(BrComponentContext);

  const params = componentCtx?.getParameters();
  const template = params?.['template'];
  const formid = params?.['formId'];

  const height = params?.['height'] || '500';
  const width = '100';
  const style = {
    height: `${height}px`,
    width: `${width}%`,
  };

  const renderForm = () => {
    return <Widget id={formid} style={style} className='demo-form mt-3' disableAutoFocus/>;
  };

  const renderTitleAndDescription = () => {
    return (
      <div className='mt-3'>
        {header && (
          <div
            className='form-description'
            dangerouslySetInnerHTML={{ __html: header.value }}
          />
        )}
      </div>
    );
  };

  const getLayout = () => {
    switch (template) {
      case 'form-left':
        return (
          <Row>
            <Col>{renderForm()}</Col>
            <Col>{renderTitleAndDescription()}</Col>
          </Row>
        );
      case 'form-right':
        return (
          <Row>
            <Col>{renderTitleAndDescription()}</Col>
            <Col>{renderForm()}</Col>
          </Row>
        );
      default:
        return (
          <Row>
            <Col>
              {renderTitleAndDescription()}
              {renderForm()}
            </Col>
          </Row>
        );
    }
  };

  const getPlaceholder = () => {
    return (
      <Row>
        <Col>
          <div className='edit-text'>
            <FontAwesomeIcon className='ml-2 my-auto' icon={['fas', 'edit']} />
            Click here to edit the Form component.
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <ComponentWrapper {...{ type: 'Typeform' }}>
      <Container>
        {formid && getLayout()}
        {!formid && page!.isPreview() && getPlaceholder()}
      </Container>
    </ComponentWrapper>
  );
};
