/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Card, Form, Row} from 'react-bootstrap';
import {BrProps} from '@bloomreach/react-sdk';
import {SetUserDefaultShippingAddress} from './SetUserDefaultShippingAddress';
import {SetUserDefaultBillingAddress} from './SetUserDefaultBillingAddress';
import {GetAddressGroupProps} from '../../../connector-components';

export default function SetUserDefaultAddress(props: BrProps & GetAddressGroupProps) {
  const {page, component, addressGroup, loading} = props;
  return (
    <Card>
      <Card.Body>
        <Card.Title>Default Addresses</Card.Title>
        <Form>
          <Row>
            <SetUserDefaultShippingAddress page={page!} component={component!} addressGroup={addressGroup} loading={loading} addresses={[]}/>
            <SetUserDefaultBillingAddress page={page!} component={component!} addressGroup={addressGroup} loading={loading} addresses={[]}/>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
