/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {BrPageContext,} from '@bloomreach/react-sdk';
import {ComponentWrapper} from '..';

export const FullPagePersonalisationComponent = ({nowrap = false,}) => {
  const page = React.useContext(BrPageContext)!;

  if (page.isPreview()) {
    return (
      <ComponentWrapper {...{type: 'empty', nowrap: nowrap}}>
        Full page personalisation configuration
      </ComponentWrapper>
    );
  } else {
    return (
      <ComponentWrapper {...{type: 'empty', nowrap: nowrap}}>

      </ComponentWrapper>
    );
  }
};
