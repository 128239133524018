/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Page} from '@bloomreach/spa-sdk';
import {FacetListState} from './FacetListTypes';
import {QUERY_PARAM_CONTENT_FACET, QUERY_PARAM_FACET} from '../../../utils/SearchUtil';
import {FacetField} from '../../../demo-connector-components/DemoBrsmUtils';

export const filterFacets = (facetResult: Array<FacetField | null> | undefined, excluded: Array<string>) => {
  return facetResult?.filter((facet) => facet && facet.values?.length > 0 && excluded.indexOf(facet.name) === -1) || [];
};

export const getFacetsState = (
  filteredFacetFields: Array<FacetField | null>,
  facetFieldFilters: Array<FacetField>,
  numOfDisplayedFacetItems: number,
  numOfOpenedFacets: number,
): FacetListState => {
  const derivedState: FacetListState = {};
  filteredFacetFields?.forEach((facetField, index) => {
    // This is for handling adding/updating component using CMS console.
    if (facetField && !derivedState[facetField.name]) {
      if (facetField.values && facetField.values.length > 0) {
        derivedState[facetField.name] = {
          shown: facetField.values.length > numOfDisplayedFacetItems ? numOfDisplayedFacetItems : facetField.values.length,
          max: facetField.values.length,
          open: index < numOfOpenedFacets,
          values: {},
        };
        facetField.values.forEach((value) => {
          derivedState[facetField.name].values[value.id] = false;
        });
      } else {
        derivedState[facetField.name] = {
          open: false,
          shown: 0,
          max: 0,
          values: {},
        };
      }
    }
  });

  facetFieldFilters?.forEach((facet) => {
    derivedState[facet.id].open = true;
    facet.values.forEach((val) => {
      derivedState[facet.id].values[val.id] = true;
    });
  });
  return derivedState;
};

export const getSelectedFilters = (isContent: boolean): Array<FacetField> => {
  let selectedFilters: Array<FacetField> = [];

  const facetFilterName = isContent ? QUERY_PARAM_CONTENT_FACET : QUERY_PARAM_FACET;
  const searchParams = new URLSearchParams(window.location.search)
  if (searchParams.has(facetFilterName)) {
    searchParams.getAll(facetFilterName).forEach((categoryParameter) => {
      const pair = categoryParameter.split(':');
      if (pair.length === 2) {
        const category = pair[0].replace(/'/g, '');
        const value = pair[1].replace(/'/g, '');
        const filter = selectedFilters.find((selectedFilter) => selectedFilter.id === category);
        const filterValue = {
          id: value,
          name: '',
          count: 0
        }
        if (filter) {
          filter.values.push(filterValue);
        } else {
          selectedFilters.push({
            id: category,
            name: '',
            values: [filterValue]
          })
        }
      }
    })
  }
  return selectedFilters;
}

export const updateSearchUrl = (queryParameters: URLSearchParams, page: Page, history: any) => {
  const updatedLocationSearch = `${queryParameters}`;
  let updatedUrl = window.location.pathname + '?' + updatedLocationSearch!;
  if (!updatedLocationSearch) {
    updatedUrl += '?';
  }
  history.push(updatedUrl);
}
