/*
 * Copyright 2022 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { sessionService } from './session-service';
import { clearError, connectorVar, loggedInVar } from './variables';

export interface Credentials {
  username?: string;
  password?: string;
  scope?: string;
  authHint?: Record<string, unknown>;
}

interface AuthResponse {
  token: string;
}

export const tokenCache = new Map<string, Promise<AxiosResponse>>();

export class AuthService {
  async login(
    credentials: Credentials,
    connector?: string,
    previousToken?: string,
  ): Promise<AuthResponse | AxiosResponse> {
    if (previousToken) {
      const cachedToken = this.getTokenFromCache(previousToken);
      if (cachedToken) {
        return cachedToken;
      }
    }

    const config = {} as AxiosRequestConfig;
    const existingToken = sessionStorage.getItem('token');
    if (existingToken) {
      config.headers = {
        authorization: `Bearer ${existingToken}`,
      };
    }
    config.headers = {
      ...config.headers,
      connector,
    };

    let res;
    try {
      const signInRequest = axios.post(`${process.env.REACT_APP_APOLLO_SERVER_URI}/signin`, credentials, config);
      if (previousToken) {
        tokenCache.set(previousToken, signInRequest);
      }
      res = await signInRequest;
    } catch (err) {
      if (err.response.data?.errors[0]?.extensions?.code === 'TOKEN_INVALID') {
        delete config.headers.authorization;
        const signInRequest = axios.post(`${process.env.REACT_APP_APOLLO_SERVER_URI}/signin`, credentials, config);
        if (previousToken) {
          tokenCache.set(previousToken, signInRequest);
        }
        res = await signInRequest;
      } else {
        throw err;
      }
    }

    if (res.status === 200 && res.data?.authorization) {
      const token = res.data.authorization;
      sessionStorage.setItem('token', token);
      clearError({ operation: 'login' });
      return { token };
    }

    throw new Error(`Something went wrong during login operation: ${res?.data?.errors?.[0]?.message}`);
  }

  async refresh(previousToken: string, connector = connectorVar()): Promise<AuthResponse | AxiosResponse> {
    const cachedToken = this.getTokenFromCache(previousToken);
    if (cachedToken) {
      return cachedToken;
    }
    const data = {
      type: 'refresh',
    };
    const config = {} as AxiosRequestConfig;
    config.headers = {
      connector,
      authorization: previousToken,
    };

    const tokenRefreshRequest = axios.post(`${process.env.REACT_APP_APOLLO_SERVER_URI}/signin`, data, config);
    tokenCache.set(previousToken, tokenRefreshRequest);

    const res = await tokenRefreshRequest;

    if (res.status === 200 && res.data.authorization) {
      const token = res.data.authorization;
      sessionStorage.setItem('token', token);
      clearError({ operation: 'refresh' });
      return { token };
    }
    throw new Error(`Something went wrong during refresh operation: ${res.data?.errors?.[0]?.message}`);
  }

  getTokenFromCache(previousToken: string): Promise<AuthResponse | AxiosResponse> | undefined {
    if (tokenCache.has(previousToken)) {
      const cachedToken = tokenCache.get(previousToken);
      if (cachedToken) {
        return cachedToken;
      }
    } else {
      tokenCache.clear();
    }

    return undefined;
  }

  logout(): void {
    sessionStorage.removeItem('token');
    sessionService.setCartIdInSession(undefined);
    sessionService.setPreferredSmViewId(undefined);
    loggedInVar(false);
    // ToDo execute the logout
  }
}

export const auth = new AuthService();
