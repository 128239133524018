/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment} from 'react';
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';

export interface IconProps {
  name: IconProp | Array<IconProp>,
  color?: string,
  size?: SizeProp,
  content?: string,
  spin?: boolean,
  children?: React.ReactNode
}

export const Icon = (props: IconProps) => {
  const {name, content, size = '', children, color, spin} = props;
  const iconOptions : FontAwesomeIconProps = {
    icon: name as IconProp
  }
  if (content || children) {
    iconOptions.className = 'mr-2';
  }
  if (size) {
    iconOptions.size = size;
  }
  if (color) {
    iconOptions.color = color;
  }
  if (spin) {
    iconOptions.spin = spin;
  }
  return (
    <Fragment>
      <FontAwesomeIcon {...iconOptions}/>
      {content || children || ''}
    </Fragment>
  )
}
