/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {lazy, Suspense, useContext} from 'react';
import {BrProps} from '@bloomreach/react-sdk';
import {getSegmentWithPrefix, GlobalSearchContext, UserContext} from '../../contexts';
import {
  getFacetFieldFilters,
  getFacetFilterFromString,
  getSearchParam,
  QUERY_PARAM_CONTENT_FACET,
  QUERY_PARAM_CONTENT_PAGE_NUM,
  QUERY_PARAM_CONTENT_PAGE_SIZE,
  QUERY_PARAM_CONTENT_SORT
} from '../../utils/SearchUtil';
import {Loader, Status} from '../utility';

const ProductGridBySearch = lazy(() => import('./ProductGridBySearch')
  .then((module) => ({default: module.ProductGridBySearch}))
);

const config = require('../product-grid-by-category/ProductGridByCategoryComponentConfig.json');
export const ProductGridBySearchComponent = (props: BrProps) => {
  const {
    component
  } = props;
  const params = component!.getParameters();
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;
  const useSearchContext = () => useContext(GlobalSearchContext);
  const {globalSearchParams} = useSearchContext()!;

  const {template = 'default', groupBy : groupByParam, groupLimit, showGroupByDropdown, hideSku, showSkuSelect} = params;

  let {
    pageSize,
    pageNumber,
    sort,
    segment,
    viewId,
    query: searchQuery,
    groupBy
  } = getSearchParam(params, {
    pageNumber: config.defaultPageNumber,
    pageSize: params.pageSize || config.defaultPageSize,
    sort: '',
    groupBy: groupByParam
  });

  if (!viewId) {
    viewId = globalSearchParams.view_id;
  }

  if (!segment) {
    segment = getSegmentWithPrefix(userSegment?.secondary || userSegment?.primary) || ''; //globalSearchParams.segment;
  }

  let facetFieldFilters = getFacetFieldFilters();
  if (facetFieldFilters.length === 0 && globalSearchParams.filter) {
    facetFieldFilters = getFacetFilterFromString(globalSearchParams.filter);
  }

  const query = searchQuery || params.query;
  if (!query) {
    return <Status container error status={'Search keyword not provided!'}/>;
  }
  const widgetId = params.widgetId;

  // Content Search Parameters
  const queryParameters = new URLSearchParams(window.location.search);

  const contentPageSizeParam = queryParameters.get(QUERY_PARAM_CONTENT_PAGE_SIZE);
  const contentPageSize = contentPageSizeParam ? parseInt(contentPageSizeParam) : 12;
  const contentCurrentPageParam = queryParameters.get(QUERY_PARAM_CONTENT_PAGE_NUM);
  const contentOffset = contentCurrentPageParam ? (parseInt(contentCurrentPageParam) - 1) * contentPageSize : 0;
  const contentSort = queryParameters.get(QUERY_PARAM_CONTENT_SORT) ?? undefined;
  const contentFacet = getFacetFieldFilters(QUERY_PARAM_CONTENT_FACET);

  return (
    <Suspense fallback={<Loader/>}>
      <ProductGridBySearch
        showGroupByDropdown={showGroupByDropdown}
        hideSku={hideSku}
        showSkuSelect={showSkuSelect}
        template={template}
        query={query}
        segment={segment}
        viewId={viewId}
        widgetId={widgetId}
        pageNumber={pageNumber}
        pageSize={pageSize}
        facetFieldFilters={facetFieldFilters}
        groupBy={groupBy}
        groupLimit={groupLimit}
        sortFields={sort}
        keyword={query}
        offset={contentOffset}
        limit={contentPageSize}
        sort={contentSort}
        facetFilters={contentFacet}
        {...props}
      />
    </Suspense>
  )
}

