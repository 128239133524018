/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Helmet} from 'react-helmet';

interface PageTitleProps {
  siteName?: string,
  contentTitle?: string,
  pageTitle?: string,
  titleSplitter?: string
}

interface SeoProps {
  seo?: any,
  seoTags?: any
}

const PageTitle = ({siteName, contentTitle, pageTitle, titleSplitter, seo, seoTags}: PageTitleProps & SeoProps) => {
  const {seotitle: seoTitle, seodescription: seoDescription} = seo ?? {};

  titleSplitter = titleSplitter || ' | ';
  let title = siteName;

  if (contentTitle) {
    title = title + titleSplitter + contentTitle;
  } else if (pageTitle) {
    title = title + titleSplitter + pageTitle;
  }

  return (
    <Helmet>
      <title>{seoTitle || title}</title>
      {seoDescription && <meta name='description' content={seoDescription}/>}
      {seoTags?.map((seoTag: any, key: number) => {
        const {tag, value} = seoTag;
        return (
          <meta name={tag} content={value} key={key}/>
        )
      })}
    </Helmet>
  )
};

export default PageTitle;
