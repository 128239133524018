/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {useState} from 'react';
import {Page} from '@bloomreach/spa-sdk';
import {initializeSegmentation} from '@bloomreach/segmentation';

export interface SegmentationComponentProps {
  page?: Page;
  perPage?: false;
}

export const SegmentationComponent = ({page, perPage}: SegmentationComponentProps) => {

  const [initialized, setInitialized] = useState(false);
  const channelProps = page ? page.getChannelParameters() : {};

  const locale = page?.getLocale() ?? channelProps.locale ?? 'en_US';

  sessionStorage.setItem('locale', locale);

  const segmentationConfigs = {
    projectToken: channelProps.exponeaProjectToken ?? process.env.REACT_APP_EXPONEA_PROJECT_TOKEN,
    targetURL: channelProps.exponeaApiTarget ?? process.env.REACT_APP_EXPONEA_API_TARGET
  };

  if (perPage) {
    initializeSegmentation(segmentationConfigs).then(() => {
      console.log('[EXPONEA]', 'initializeSegmentation finished', segmentationConfigs);
    });
  } else {
    !initialized && initializeSegmentation(segmentationConfigs).then(() => {
      console.log('[EXPONEA]', 'initializeSegmentation finished', segmentationConfigs);
      setInitialized(true);
    });
  }

  return null;
}
