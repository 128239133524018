/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * ProductShowcase template
 */
import React, {Fragment} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {BannerComponent} from '../..';
import ProductShowcaseGrid from './ProductShowcaseGrid';
import {ProductShowcaseProps} from '../ProductShowcaseComponentTypes';

const ProductShowcase = ({documentRef, params, products, bannerLocation}: ProductShowcaseProps) => {
  const bannerProps = {
    documentRef,
    params,
    template: 'product-showcase',
    fluid: true,
    nowrap: true
  };

  const RenderProductShowcaseGrid = () => (
    <Col xs={6} className={'d-flex'}>
      <ProductShowcaseGrid {...{products}}/>
    </Col>
  );

  const RenderProductShowcaseBanner = () => (
    <Col xs={6} className={'p-0 bg-banner'}>
      <BannerComponent {...bannerProps}/>
    </Col>
  );

  return (
    <Container fluid>
      <Row className='bg-showcase product-showcase'>
        {bannerLocation === 'right' ?
          <Fragment>
            <RenderProductShowcaseGrid/>
            <RenderProductShowcaseBanner/>
          </Fragment>
        :
          <Fragment>
            <RenderProductShowcaseBanner/>
            <RenderProductShowcaseGrid/>
          </Fragment>
        }
      </Row>
    </Container>
  )
};

export default ProductShowcase;
