/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {lazy, Suspense} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {Configuration} from '@bloomreach/spa-sdk';
import {BrComponent, BrPage, BrPageContext} from '@bloomreach/react-sdk';

import {
  ArticleComponent,
  ArticleListComponent,
  AuthorBioComponent,
  BannerCollectionComponent,
  BannerComponent,
  BannerCTAComponent,
  BlogCollectionComponent,
  BlogComponent,
  BlogsByAuthorComponent,
  CampaignP18NComponent,
  CarouselComponent,
  CartComponent,
  CategoryShowcaseComponent,
  ContentComponent,
  ContentListComponent,
  FullPagePersonalisationComponent,
  GuidedSellingFlowComponent,
  HeaderToolbarComponent,
  JustForYouComponent,
  MenuComponent,
  OrderComponent,
  OrderHistoryComponent,
  PageNotFoundComponent,
  ProductComponent,
  ProductPageComponent,
  ProductGridByCategoryComponent,
  ProductGridByCategoryPageComponent,
  ProductGridBySearchComponent,
  ProductRecommendationsComponent,
  ProductShowcaseComponent,
  SearchBoxComponent,
  TypeformComponent,
  UserProfileComponent,
  VideoComponent
} from '../components';
import {Loader} from '../components/utility';
import {brxmEndpoint, CartProvider, GlobalSearchProvider, UserProvider} from '../contexts';
import {Helper} from '../components/header-toolbar/Helper';
import {useParams} from 'react-router-dom';

library.add(fab, fas);

const InspectorComponent = lazy(() => import('../components/utility')
  .then((module) => ({default: module.InspectorComponent}))
);

const PageFooterComponent = lazy(() => import('../layouts')
  .then((module) => ({default: module.PageFooterComponent}))
);

const PageHeaderComponent = lazy(() => import('../layouts')
  .then((module) => ({default: module.PageHeaderComponent}))
);

const ScrollToTopComponent = lazy(() => import('../components')
  .then((module) => ({default: module.ScrollToTopComponent}))
);

const PageTitleComponent = lazy(() => import('../components')
  .then((module) => ({default: module.PageTitleComponent}))
);

const CommerceCookieComponent = lazy(() => import('../components')
  .then((module) => ({default: module.CommerceCookieComponent}))
);

const GTMComponent = lazy(() => import('../components')
  .then((module) => ({default: module.GTMComponent}))
);

const SegmentationComponent = lazy(() => import('../components')
  .then((module) => ({default: module.SegmentationComponent}))
);

export const ChannelPage = () => {

  const {channelId} = useParams();

  const configuration: Configuration = {
    httpClient: axios.request,
    endpoint: brxmEndpoint(channelId),
    baseUrl: `/${channelId}`,
    request: {
      path: `${window.location.pathname}${window.location.search}`
    }
  };

  const mapping: any = {
    'Article Detail': ArticleComponent,
    'Article List': ArticleListComponent,
    'Author Bio': AuthorBioComponent,
    'Banner': BannerComponent,
    'Banner Collection': BannerCollectionComponent,
    'Carousel': CarouselComponent,
    'Cart': CartComponent,
    'Category': ProductGridByCategoryPageComponent,
    'Category Showcase': CategoryShowcaseComponent,
    'Content': ContentComponent,
    'Content list': ContentListComponent,
    'Blog Detail': BlogComponent,
    'Blog List': BlogCollectionComponent,
    'Blog Posts by Author': BlogsByAuthorComponent,
    'Header Toolbar': HeaderToolbarComponent,
    'Guided Selling Flow': GuidedSellingFlowComponent,
    'Just For You': JustForYouComponent,
    'Main Menu': MenuComponent,
    'Menu': MenuComponent,
    'Order Detail': OrderComponent,
    'Order History': OrderHistoryComponent,
    'Campaign Personalization': CampaignP18NComponent,
    'Product': ProductPageComponent,
    'Product Detail': ProductComponent,
    'Product Grid (Search)': ProductGridBySearchComponent,
    'Product Grid (Category)': ProductGridByCategoryComponent,
    'Product Media Item': ProductComponent,
    'Product Recommendations': ProductRecommendationsComponent,
    'Product Showcase': ProductShowcaseComponent,
    'Search Box': SearchBoxComponent,
    'Simple Content': ContentComponent,
    'User Profile': UserProfileComponent,
    'Video': VideoComponent,
    'Full page personalisation': FullPagePersonalisationComponent,
    'Page Not Found': PageNotFoundComponent,
    'BannerCTA': BannerCTAComponent,
    'Form': TypeformComponent,
    ArticleComponent,
    ArticleListComponent,
    AuthorBioComponent,
    BannerComponent,
    BannerCollectionComponent,
    BannerCTAComponent,
    CarouselComponent,
    CartComponent,
    CategoryShowcaseComponent,
    GuidedSellingFlowComponent,
    HeaderToolbarComponent,
    MenuComponent,
    OrderComponent,
    OrderHistoryComponent,
    ContentComponent,
    PageNotFoundComponent,
    CampaignP18NComponent,
    ProductComponent,
    ProductGridByCategoryComponent,
    ProductGridBySearchComponent,
    ProductRecommendationsComponent,
    ProductShowcaseComponent,
    UserProfileComponent,
    SearchBoxComponent,
    VideoComponent
  };

  return (
    <Suspense fallback={<Loader/>}>
      <BrPage configuration={configuration} mapping={mapping}>
        <BrPageContext.Consumer>
          {(page) => <SegmentationComponent page={page}/>}
        </BrPageContext.Consumer>
        <PageTitleComponent/>
        <ScrollToTopComponent/>
        <CommerceCookieComponent/>
        <UserProvider>
          <GlobalSearchProvider>
            <CartProvider>
              <Helper/>
              <PageHeaderComponent/>
              <BrPageContext.Consumer>
                {(page) => {
                  const layout = page?.getComponent()?.getName();
                  const twoColumnLayout = layout?.indexOf('two-column') !== -1;
                  return (
                    <Container fluid={!twoColumnLayout} id='content' role='main'>
                      <Row className='flex-lg-nowrap'>
                        <BrComponent path='left'>
                          <Col lg='3' className='flex-fill px-0'>
                            <BrComponent/>
                          </Col>
                        </BrComponent>
                        <BrComponent path='main'>
                          <Col xs='auto' className='flex-fill px-0'>
                            <BrComponent/>
                          </Col>
                        </BrComponent>
                        <BrComponent path='right'>
                          <Col lg='3' className='flex-fill px-0'>
                            <BrComponent/>
                          </Col>
                        </BrComponent>
                      </Row>
                    </Container>
                  )
                }}
              </BrPageContext.Consumer>
              <PageFooterComponent/>
            </CartProvider>
            <GTMComponent/>
            <InspectorComponent/>
          </GlobalSearchProvider>
        </UserProvider>
      </BrPage>
    </Suspense>
  );
}

