/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext} from 'react';
import {Badge, Card} from 'react-bootstrap';
import {CartContext} from '../../contexts';
import {ProductPrice} from '../product/templates';
import Address from './util/Address';
import {readOrderFromSession} from '../order/OrderComponentUtils';

export default function CartMenu() {
  const {cartDetails}: any = useContext(CartContext);
  const {totalQuantity, totalListPrice, totalPurchasePrice} = cartDetails;

  const orderDetails = readOrderFromSession();
  const {totalAmount, taxAmount, shippingAddress, billingAddress, shipMethod, paymentMethodId} = orderDetails || {};

  const ItemInfo = ({children}: any) => (
    <Badge pill variant='primary' className={'float-right'}>
      {children}
    </Badge>
  );

  return (
    <Card>
      <Card.Header><h4>Order Summary</h4></Card.Header>
      <Card.Body>
        <Card.Text>
          <strong>
            Item subtotal ({totalQuantity || 0})
            {totalAmount ? <ItemInfo><ProductPrice moneyAmount={totalAmount?.[0]}/></ItemInfo>
              : <ItemInfo><ProductPrice price={totalPurchasePrice} listPrice={totalListPrice}/></ItemInfo>}
          </strong>
        </Card.Text>
        {shippingAddress && <Card.Text>
          <Address address={shippingAddress} header={'Shipping Address'}/>
        </Card.Text>}
        {shipMethod && <Card.Text>
          <strong>
            Delivery <ItemInfo>{shipMethod}</ItemInfo>
          </strong>
        </Card.Text>}
        {billingAddress && <Card.Text>
          <Address address={billingAddress} header={'Billing Address'}/>
        </Card.Text>}
        {paymentMethodId && <Card.Text>
          <strong>
            Payment <ItemInfo>{paymentMethodId}</ItemInfo>
          </strong>
        </Card.Text>}
        {taxAmount && <Card.Text>
          <strong>
            Tax <ItemInfo><ProductPrice moneyAmount={taxAmount?.[0]}/></ItemInfo>
          </strong>
        </Card.Text>}
      </Card.Body>
      {totalAmount && <Card.Footer>
        <strong>
          Total <ItemInfo><ProductPrice moneyAmount={totalAmount?.[0]}/></ItemInfo>
        </strong>
      </Card.Footer>}
    </Card>
  )
}
