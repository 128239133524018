/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {
  ADD_CURRENT_CUSTOMER_ADDRESS,
  AddCurrentCustomerAddress,
  AddCurrentCustomerAddress_addCurrentCustomerAddress,
  AddCurrentCustomerAddressVariables,
  CurrentCustomerAddressGroup,
  GET_CURRENT_CUSTOMER_ADDRESS_GROUP,
} from '../../modules/commerce';
import {AddressFormProps} from './index';
import {withApolloClient} from '../ApolloClient';

export interface AddAddressProps {
  addressForm: AddressFormProps;
  addAddress: () => Promise<FetchResult<AddCurrentCustomerAddress>>;
  loading: boolean;
  result?: AddCurrentCustomerAddress_addCurrentCustomerAddress;
}

function withAddAddressBase<P>(Component: React.ComponentType<P & AddAddressProps>) {
  return (props: P) => {
    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [streetAddress, setStreetAddress] = useState<string>('');
    const [additionalStreetInfo, setAdditionalStreetInfo] = useState<string>('');
    const [shippingAddress, setShippingAddress] = useState<boolean>(false);
    const [billingAddress, setBillingAddress] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);

    const [addAddress, {data, loading}] = useMutation<AddCurrentCustomerAddress, AddCurrentCustomerAddressVariables>(
      ADD_CURRENT_CUSTOMER_ADDRESS,
      {
        variables: {
          addressInput: {
            firstName,
            middleName,
            lastName,
            country,
            city,
            state,
            company,
            postalCode,
            streetAddress,
            additionalStreetInfo,
            shippingAddress,
            billingAddress,
            readOnly,
          },
        },
        // eslint-disable-next-line no-shadow
        update(cache, {data}) {
          if (data?.addCurrentCustomerAddress.address) {
            const results = cache.readQuery<CurrentCustomerAddressGroup>({query: GET_CURRENT_CUSTOMER_ADDRESS_GROUP});
            if (results?.getCurrentCustomerAddressGroup) {
              cache.writeQuery<CurrentCustomerAddressGroup>({
                query: GET_CURRENT_CUSTOMER_ADDRESS_GROUP,
                data: {
                  getCurrentCustomerAddressGroup: {
                    __typename: 'AddressGroup',
                    id: results.getCurrentCustomerAddressGroup.id,
                    addresses: (results.getCurrentCustomerAddressGroup.addresses ?? []).concat(
                      data.addCurrentCustomerAddress.address,
                    ),
                    defaultShippingAddress: results.getCurrentCustomerAddressGroup.defaultShippingAddress,
                    defaultBillingAddress: results.getCurrentCustomerAddressGroup.defaultBillingAddress,
                  },
                },
              });
            }
          }
        },
      },
    );

    return (
      <Component
        addressForm={{
          firstName,
          setFirstName,
          middleName,
          setMiddleName,
          lastName,
          setLastName,
          country,
          setCountry,
          city,
          setCity,
          state,
          setState,
          company,
          setCompany,
          postalCode,
          setPostalCode,
          streetAddress,
          setStreetAddress,
          additionalStreetInfo,
          setAdditionalStreetInfo,
          shippingAddress,
          setShippingAddress,
          billingAddress,
          setBillingAddress,
          readOnly,
          setReadOnly,
        }}
        addAddress={addAddress}
        loading={loading}
        result={data?.addCurrentCustomerAddress}
        {...props}
      />
    );
  };
}

export function withAddAddress<P>(Component: React.ComponentType<P & AddAddressProps>) {
  return withApolloClient(withAddAddressBase(Component));
}
