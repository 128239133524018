/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {ChangeEvent, Fragment, useEffect, useReducer} from 'react';
import {Badge, Col, Form} from 'react-bootstrap';
import {FieldStateType} from '../../header-toolbar/HeaderToolbarComponentTypes';

export interface PaymentMethodStateType {
  cardNumber?: FieldStateType,
  cardHolder?: FieldStateType,
  cvc?: FieldStateType,
  expiration?: FieldStateType,
}

export default function PaymentMethod({handler}: any) {
  const initialState : PaymentMethodStateType = {
    cardNumber: {
      value: ''
    },
    cardHolder: {
      value: ''
    },
    cvc: {
      value: ''
    },
    expiration: {
      value: ''
    }
  };

  let reducer = (state: PaymentMethodStateType, newState: PaymentMethodStateType | null) => {
    if (newState === null) {
      return initialState;
    }
    return {...state, ...newState};
  }

  const [paymentMethod, setPaymentMethod] = useReducer(reducer, initialState);
  const {cardNumber, cardHolder, cvc, expiration} = paymentMethod;

  const validateCardNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const validationResult = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(value);
    if (validationResult) {
      setPaymentMethod({
        cardNumber: {
          value,
          status: true,
          message: null
        }
      });
    } else {
      setPaymentMethod({
        cardNumber: {
          value: '',
          status: false,
          message: 'Invalid credit card number'
        }
      });
    }
  }

  const validateCardHolder = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const validationResult = value.length > 0;
    if (validationResult) {
      setPaymentMethod({
        cardHolder: {
          value,
          status: true,
          message: null
        }
      });
    } else {
      setPaymentMethod({
        cardHolder: {
          value: '',
          status: false,
          message: 'Invalid credit card holder name'
        }
      });
    }
  }

  const validateExpiration = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const validationResult = /^((0[1-9])|11|12)\/[0-9]{2}$/.test(value);
    if (validationResult) {
      setPaymentMethod({
        expiration: {
          value,
          status: true,
          message: null
        }
      });
    } else {
      setPaymentMethod({
        expiration: {
          value: '',
          status: false,
          message: 'Invalid expiration date'
        }
      });
    }
  }

  const validateCVC = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const validationResult = /^[0-9]{3,4}$/.test(value);
    if (validationResult) {
      setPaymentMethod({
        cvc: {
          value,
          status: true,
          message: null
        }
      });
    } else {
      setPaymentMethod({
        cvc: {
          value: '',
          status: false,
          message: 'Invalid card verification code'
        }
      });
    }
  }

  const validateCVCKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    if (value.length >=3 ) {
      const validationResult = /^[0-9]{3,4}$/.test(value);
      if (validationResult) {
        setPaymentMethod({
          cvc: {
            value,
            status: true,
            message: null
          }
        });
      } else {
        setPaymentMethod({
          cvc: {
            value: '',
            status: false,
            message: 'Invalid card verification code'
          }
        });
      }
    }
  }

  useEffect(() => {
    if (handler) {
      if (cardNumber?.status === true && cardHolder?.status === true && expiration?.status === true && cvc?.status === true) {
        handler({
          cardNumber: cardNumber.value,
          cardHolder: cardHolder.value,
          expiration: expiration.value,
          cvc: cvc.value
        })
      }
    }
  }, [cardNumber, cardHolder, expiration, cvc, handler]);

  return (
    <Fragment>
      <h4>Payment Method</h4>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label className={'text-uppercase'}>CARD NUMBER
            <Badge variant={'info'} className={'ml-2'} onClick={() => {
              setPaymentMethod({
                cardHolder: {
                  value: 'Jane Pacific',
                  status: true,
                  message: null
                },
                cardNumber: {
                  value: '4916674319158256',
                  status: true,
                  message: null
                },
                expiration: {
                  value: '12/30',
                  status: true,
                  message: null
                },
                cvc: {
                  value: '123',
                  status: true,
                  message: null
                }
              });
            }}>Autofill</Badge>
          </Form.Label>
          <Form.Control type='text' name='cardNumber' autoComplete='none' required
                        placeholder={'Card Number'} defaultValue={cardNumber?.value}
                        isInvalid={cardNumber?.status === false}
                        onBlur={validateCardNumber}
          />
          {cardNumber?.message && <Form.Control.Feedback type='invalid'>
            {cardNumber?.message}
          </Form.Control.Feedback>}
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={6}>
          <Form.Label className={'text-uppercase'}>CARD HOLDER</Form.Label>
          <Form.Control type='text' name='cardHolder' autoComplete='none' required
                        placeholder={'Card Holder'} defaultValue={cardHolder?.value}
                        isInvalid={cardHolder?.status === false}
                        onBlur={validateCardHolder}
          />
          {cardHolder?.message && <Form.Control.Feedback type='invalid'>
            {cardHolder.message}
          </Form.Control.Feedback>}
        </Form.Group>
        <Form.Group as={Col} xs={3}>
          <Form.Label className={'text-uppercase'}>EXPIRATION</Form.Label>
          <Form.Control type='text' name='expiration' autoComplete='none' required
                        placeholder={'MM/YY'} defaultValue={expiration?.value}
                        isInvalid={expiration?.status === false}
                        onBlur={validateExpiration}/>
          {expiration?.message && <Form.Control.Feedback type='invalid'>
            {expiration.message}
          </Form.Control.Feedback>}
        </Form.Group>
        <Form.Group as={Col} xs={3}>
          <Form.Label className={'text-uppercase'}>CVC</Form.Label>
          <Form.Control type='text' name='cvc' autoComplete='none' required
                        placeholder={'***'} defaultValue={cvc?.value}
                        isInvalid={cvc?.status === false}
                        onKeyUp={validateCVCKeyUp}
                        onBlur={validateCVC}/>
          {cvc?.message && <Form.Control.Feedback type='invalid'>
            {cvc.message}
          </Form.Control.Feedback>}
        </Form.Group>
      </Form.Row>
    </Fragment>
  )
}
