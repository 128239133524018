/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useEffect, useState} from 'react';
import {Card, Collapse, Dropdown, NavLink, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {BrPageContext} from '@bloomreach/react-sdk';
import {AddToCartForm} from '../../cart/action/AddToCart';
import {PreferenceContext, UserContext} from '../../../contexts';
import {ProductPrice} from './ProductPrice';
import {pushGtmEventWidgetClick} from '../../gtm/GTMComponentUtils';
import {SecuredComponentWrapper} from '../../utility';
import {ItemFragment_itemId} from '../../../modules/commerce';
import {ProductProps} from '../ProductComponentTypes';
import {PromotionProps} from '../../promotion/PromotionComponentTypes';
import {PromotionComponent} from '../../promotion/PromotionComponent';
import {getProductUrl} from '../ProductComponentUtils';
import {Attribute, getImageSetUrl, ItemVariant} from '../../../demo-connector-components/DemoBrsmUtils';
import {ProductCardProps} from './ProductCard';

export const COLORS_ATTRIBUTE = 'sku_color';
export const COLORS_DEFAULT_VALUE = 'opaque';
export const SIZES_ATTRIBUTE = 'sku_size';
export const SIZES_DEFAULT_VALUE = 'standard';

export const ProductCardSkuSelect = (props: ProductProps & ProductCardProps) => {
  const page = useContext(BrPageContext)!;
  const {userSegmentState: {userSegment}} = useContext(UserContext)!;
  const {preference} = useContext(PreferenceContext)!;

  const {
    itemId,
    displayName,
    listPrice,
    purchasePrice,
    imageSet,
    variants,
    widgetProps
  } = props;

  const initVariant = () => variants?.[0] ?? {
    __typename: 'ItemVariant',
    customAttrs: [],
    imageSet,
    master: false,
    varAttrs: undefined,
    itemId,
    displayName,
    listPrice,
    purchasePrice,
    description: ''
  };

  const [visible, setVisible] = useState(false);
  const [variant, setVariant] = useState<ItemVariant>(initVariant());

  const [promotionProps,] = useState<PromotionProps | null>(null);

  const history = useHistory();

  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);

  useEffect(() => {
    setVariant(initVariant());
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    itemId: variantItemId,
    displayName: variantDisplayName,
    imageSet: variantImageSet,
  } = variant;

  const addToCartParams = {
    itemName: variantDisplayName,
    itemId: {
      id: variantItemId.id,
      code: variantItemId.code ?? variantItemId.id
    } as ItemFragment_itemId,
    itemKey: '',
    quantity: 1,
    itemPrice: listPrice, // added to params; need to send to Exponea
    itemTitle: displayName, // added to params; need to send to Exponea
    mode: 'button',
    widgetProps
  };

  let hasPromotion: boolean = false;
  if (promotionProps) {
    const {images, videos} = promotionProps;
    if (images?.length && videos?.length) {
      hasPromotion = true;
      promotionProps.template = 'CARD';
      const imageUrl = getImageSetUrl(variantImageSet);
      if (imageUrl) {
        if (!images.some(image => image.media === imageUrl)) {
          images.push({
            type: 'IMAGE',
            media: imageUrl
          });
        }
      }
    }
  }

  const productUrl = getProductUrl(page, variantItemId.id, variantItemId.code, userSegment)
  const linkOption = preference.enableHeatmap ? {href: `https://pacifichome.bloomreach.com${productUrl}`} : {};

  // SKU select
  const getAttributeValue = (name: string, array: Attribute[] | null = []): string => {
    const attr = array?.find(e => e.name === name);
    return attr?.values ? Array.isArray(attr?.values) ? attr?.values?.[0] : attr?.values : name === COLORS_ATTRIBUTE ? COLORS_DEFAULT_VALUE : SIZES_DEFAULT_VALUE;
  }

  const hasAttributeValue = (name: string, array: Attribute[] = []): boolean => {
    return array.find(e => e.name === name) !== undefined;
  }

  const getWebColor = (name: string): string => {
    const colors = [
      {name: 'black', value: '#000'},
      {name: 'blue', value: '#4682B4'},
      {name: 'brown', value: '#8B4513'},
      {name: 'cream', value: '#FFFACD'},
      {name: 'gray', value: '#C0C0C0'},
      {name: 'gold', value: '#FFD700'},
      {name: 'green', value: '#3CB371'},
      {name: 'multi', value: '#FF1493'},
      {name: 'natural', value: '#F5DEB3'},
      {name: 'opaque', value: '#F5F5F5'},
      {name: 'orange', value: '#FF6347'},
      {name: 'pink', value: '#FFC0CB'},
      {name: 'red', value: '#DC143C'},
      {name: 'white', value: 'white'},
      {name: 'yellow', value: '#FFFF00'},
    ]
    const color = colors.find(color => color.name.toLowerCase() === name.toLowerCase());
    return color ? color.value : '#ffffff';
  }

  const getVariantsByAttribute = (attributeName: string, variants: ItemVariant[]): ItemVariant[] => {
    return variants.filter(e => getAttributeValue(attributeName, e.varAttrs ?? []) === getAttributeValue(attributeName, variant.varAttrs ?? []));
  }

  const isCurrentVariant = (item: ItemVariant): boolean => {
    return item?.itemId?.id === variant?.itemId?.id && item.itemId.code === variant.itemId.code;
  }

  const updateVariant = (item: ItemVariant) => {
    if (isCurrentVariant(item)) {
      return;
    }
    const newVariant = {
      __typename: "ItemVariant",
      customAttrs: [],
      imageSet: variant.imageSet,
      master: false,
      varAttrs: item.varAttrs,
      itemId: item.itemId,
      displayName: variant.displayName,
      listPrice: item.listPrice,
      purchasePrice: item.purchasePrice,
      description: "",
      variants,
    };
    setVariant(newVariant);
  }

  return (
    <Card className='shadow h-100 product-card' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {hasPromotion ?
        <div className={'card-img-top'}>
          <PromotionComponent {...{...promotionProps}}/>
        </div>
        :
        <div className={'card-img-top img-fluid d-flex justify-content-end'} style={{
          backgroundImage: `url(${getImageSetUrl(variantImageSet)})`,
        }}>
          {getAttributeValue(COLORS_ATTRIBUTE, variant.varAttrs) &&
            (getVariantsByAttribute(SIZES_ATTRIBUTE, variants).length > 1 || getAttributeValue(COLORS_ATTRIBUTE, variant.varAttrs) !== COLORS_DEFAULT_VALUE) &&
            <div className='product-color-swatch-wrapper'>
              <OverlayTrigger
                placement={'left'}
                overlay={
                  <Tooltip id={`color-tooltip-${variant.itemId.id}`}>
                    {getAttributeValue(COLORS_ATTRIBUTE, variant.varAttrs)}
                  </Tooltip>
                }
              >
                <div className="product-color-swatch" style={{
                  backgroundColor: `${getWebColor(getAttributeValue(COLORS_ATTRIBUTE, variant.varAttrs).toLowerCase())}`,
                }}/>
              </OverlayTrigger>
            </div>
          }
        </div>
      }
      <Card.Body className={`text-center d-flex flex-column`}>
        <Card.Text>
          <NavLink onClick={() => {
            pushGtmEventWidgetClick(widgetProps, variantItemId.id);
            history.push(productUrl);
          }} className={'product-pdp-link'} {...linkOption}>
            {variantDisplayName}
          </NavLink>
        </Card.Text>
        <Card.Subtitle className={"mt-auto"}>

          {getVariantsByAttribute(COLORS_ATTRIBUTE, variants).length === 1 &&
            hasAttributeValue(SIZES_ATTRIBUTE, variant?.varAttrs ?? []) &&
            getAttributeValue(SIZES_ATTRIBUTE, variant.varAttrs) !== SIZES_DEFAULT_VALUE &&
            <div className='mb-2'>
              <small>{getAttributeValue(SIZES_ATTRIBUTE, variant.varAttrs)}</small>
            </div>
          }

          {variants.length > 1 && getVariantsByAttribute(SIZES_ATTRIBUTE, variants).length > 1 && <div>
            {getVariantsByAttribute(SIZES_ATTRIBUTE, variants)?.map((item, key: number) => (
              <OverlayTrigger
                key={key}
                placement={'bottom'}
                overlay={
                  <Tooltip id={`color-tooltip-${item.itemId.id}-${key}`}>
                    {getAttributeValue(COLORS_ATTRIBUTE, item.varAttrs)}
                  </Tooltip>
                }
              >
                <span
                  key={key}
                  className={`product-color-swatch d-inline-block ${isCurrentVariant(item) ? 'active' : ''}`}
                  style={{
                    backgroundColor: `${getWebColor(getAttributeValue(COLORS_ATTRIBUTE, item.varAttrs).toLowerCase())}`,
                  }}
                  onClick={() => {
                    updateVariant(item);
                  }}
                />
              </OverlayTrigger>
            ))}</div>
          }

          {variants && getVariantsByAttribute(COLORS_ATTRIBUTE, variants).length > 1 &&
            <Dropdown>
              <Dropdown.Toggle variant='link'>
                <small>{getAttributeValue(SIZES_ATTRIBUTE, variant.varAttrs)}</small>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {getVariantsByAttribute(COLORS_ATTRIBUTE, variants).map((item, key: number) => (
                  <Dropdown.Item
                    key={key}
                    active={getAttributeValue(SIZES_ATTRIBUTE, item.varAttrs).toLowerCase() === getAttributeValue(SIZES_ATTRIBUTE, variant.varAttrs).toLowerCase()}
                    onClick={() => {
                      updateVariant(item);
                    }}
                  >
                    {getAttributeValue(SIZES_ATTRIBUTE, item.varAttrs)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>}

          {variant.listPrice &&
            <ProductPrice
              price={variant.listPrice}
            />
          }
        </Card.Subtitle>
      </Card.Body>
      <SecuredComponentWrapper showMessage={false}>
        <Collapse in={visible}>
          <Card.Footer className={'text-center'}>
            <AddToCartForm {...addToCartParams} page={page}/>
          </Card.Footer>
        </Collapse>
      </SecuredComponentWrapper>
    </Card>
  );
};
