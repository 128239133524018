/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {Fragment, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {CartStageEnum} from '../../global/CommonEnums';
import {Icon, Status} from '../../utility';
import {GetErrorProps, SetOrderDetailsProps, withGetError, withSetOrderDetails} from '../../../connector-components';
import {saveOrderToSession} from '../OrderComponentUtils';
import {SetOrderDeliveryMethodProps} from '../OrderComponentTypes';

function SetOrderDeliveryBase(props: SetOrderDeliveryMethodProps & GetErrorProps & SetOrderDetailsProps) {
  const {
    deliveryMethod,
    setStage,
    orderDetailsForm,
    loading,
    setOrderDetails,
    result,
    errors,
    clearError,
  } = props;

  const {
    setShipmentMethodId,
  } = orderDetailsForm;

  const DEFAULT_MESSAGE = `Next to ${CartStageEnum.BILLING}`;

  useEffect(() => {
    if (deliveryMethod) {
      setShipmentMethodId(deliveryMethod);
    }
  }, [deliveryMethod]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (result) {
      saveOrderToSession(result?.order);
      setStage(CartStageEnum.BILLING);
    }
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {errors?.map((error, key: number) => <Status key={key} onClose={clearError} container status={error?.message}
                                                        dismissible customClassName={'mt-3'} error/>)}
      <Button variant={'primary'} className={'float-right'} onClick={() => {
        clearError();
        setOrderDetails();
      }} disabled={!deliveryMethod}>
        {loading ? <Icon name={'spinner'} size={'lg'} spin content={DEFAULT_MESSAGE}/>
          : <Icon name={'arrow-right'} content={DEFAULT_MESSAGE}/>}
      </Button>
    </Fragment>
  )
}

export const SetOrderDelivery = withGetError(withSetOrderDetails(SetOrderDeliveryBase));
