/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useContext, useState} from 'react';
import {Button, Popover, Table} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {BrPageContext} from '@bloomreach/react-sdk';
import {brxmEndpoint} from '../../../contexts';
import {Icon} from '../icon/Icon';
import {Status} from '../status/Status';
import {version} from '../../../../package.json';
import {updateUrlParameter} from '../../../utils/UrlUtils';

export const INSPECTOR_PARAM_NAME = 'inspector';

export const InspectorComponent = () => {
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const isInspector = query.get('inspector') === 'true';

  const [loadingBrxm, setLoadingBrxm] = useState(false);
  const [statusBrxm, setStatusBrxm] = useState('');
  const [errorBrxm, setErrorBrxm] = useState('');

  const [loadingApollo, setLoadingApollo] = useState(false);
  const [statusApollo, setStatusApollo] = useState('');
  const [errorApollo, setErrorApollo] = useState('');

  const [loadingContentSearch, setLoadingContentSearch] = useState(false);
  const [statusContentSearch, setStatusContentSearch] = useState('');
  const [errorContentSearch, setErrorContentSearch] = useState('');

  const page = useContext(BrPageContext)!;

  if (!isInspector || !process) {
    return null;
  }

  const checkBrxmServerStatus = () => {
    setLoadingBrxm(true);
    setErrorBrxm('')
    setStatusBrxm('');
    if (brxmEndpoint()) {
      fetch(brxmEndpoint()).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setStatusBrxm('OK');
        } else {
          setErrorBrxm(response.statusText || 'Status code ' + response.status);
        }
      }).catch((error) => {
        setErrorBrxm(error);
      }).finally(() => {
        setLoadingBrxm(false);
      })
    } else {
      setLoadingBrxm(false);
      setErrorBrxm('Endpoint configuration is missing!');
    }
  }

  const checkApolloServerStatus = () => {
    setLoadingApollo(true);
    setErrorApollo('')
    setStatusApollo('');
    if (process.env.REACT_APP_APOLLO_SERVER_URI) {
      fetch(process.env.REACT_APP_APOLLO_SERVER_URI + '/graphql', {
        method: 'POST',
        headers: {'connector': 'brsm', 'content-type': 'application/json'},
        body: JSON.stringify({
            query: "{ findCategories { id parentId displayName path } } "
          }
        ),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setStatusApollo('OK');
        } else {
          setErrorApollo(response.statusText || 'Status code ' + response.status);
        }
      }).catch((error) => {
        setErrorApollo(error);
      }).finally(() => {
        setLoadingApollo(false);
      })
    } else {
      setLoadingApollo(false);
      setErrorApollo('Endpoint configuration is missing!');
    }
  }

  const checkContentSearchServerStatus = () => {
    setLoadingContentSearch(true);
    setErrorContentSearch('')
    setStatusContentSearch('');
    if (process.env.REACT_APP_CONTENT_SEARCH_ROOT_URI &&
      process.env.REACT_APP_CONTENT_SEARCH_ACCOUNT_ID &&
      process.env.REACT_APP_CONTENT_SEARCH_CATALOG_NAME &&
      process.env.REACT_APP_CONTENT_SEARCH_FIELDS
    ) {
      const searchParams: Record<string, string> = {
        account_id: process.env.REACT_APP_CONTENT_SEARCH_ACCOUNT_ID,
        catalog_name: process.env.REACT_APP_CONTENT_SEARCH_CATALOG_NAME,
        request_type: 'search',
        search_type: 'keyword',
        rows: `10`,
        start: `0`,
        fl: process.env.REACT_APP_CONTENT_SEARCH_FIELDS,
        q: `tool`,
        url: window.location.href
      }

      const urlParams = new URLSearchParams(searchParams);
      fetch(`${process.env.REACT_APP_CONTENT_SEARCH_ROOT_URI}?${urlParams}`, {}).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setStatusContentSearch('OK');
        } else {
          setErrorContentSearch(response.statusText || 'Status code ' + response.status);
        }
      }).catch((error) => {
        setErrorContentSearch(error);
      }).finally(() => {
        setLoadingContentSearch(false);
      })
    } else {
      setLoadingContentSearch(false);
      setErrorContentSearch('Endpoint configuration is missing!');
    }
  }

  const closeInspector = () => {
    history.push(updateUrlParameter('true', INSPECTOR_PARAM_NAME, true));
  }

  const channelParameters = page.getChannelParameters();
  return (
    <Popover id='inspector-info' className='no-arrow my-6 mx-4 p-2' show={true}>
      <div>
        <div className={'h4 my-2'}>Pacific Inspector
          <Button size={'sm'} variant={'light'} className='close ml-auto' onClick={closeInspector}>
            <Icon name={'times'}/>
          </Button>
        </div>
        <Table striped bordered hover size={'sm'}>
          <thead>
          <tr>
            <th colSpan={2}>System Properties</th>
          </tr>
          </thead>
          <tbody className={'font-size-1'}>
          <tr>
            <td>React Version</td>
            <td>{React.version}</td>
          </tr>
          <tr>
            <td>Application Version</td>
            <td>{version}</td>
          </tr>
          {process.env && Object.keys(process.env).sort().map((variable: any, key) => <tr key={key}>
            <td>{variable}</td>
            <td
              className={'text-break'}>{process?.env?.[variable]?.startsWith && process?.env?.[variable]?.startsWith('http') ?
              <a href={process?.env[variable]} target={'_blank'}
                 rel='noopener noreferrer'>{process?.env[variable]}</a> : process?.env?.[variable]}
            </td>
          </tr>)}
          </tbody>
        </Table>
        <Table striped bordered hover size={'sm'}>
          <thead>
          <tr>
            <th colSpan={2}>Channel</th>
          </tr>
          </thead>
          <tbody className={'font-size-1'}>
          <tr>
            <td>Locale</td>
            <td className={'font-size-1 text-break'}>{page.getLocale()}</td>
          </tr>
          {Object.keys(channelParameters).sort().map((prop: any, key) => <tr key={key}>
            <td>{prop}</td>
            <td className={'font-size-1 text-break'}>{channelParameters[prop]}</td>
          </tr>)}
          </tbody>
        </Table>
        <Table striped bordered hover size={'sm'}>
          <thead>
          <tr>
            <th colSpan={2}>Brx GraphQL Service</th>
          </tr>
          </thead>
          <tbody className={'font-size-1'}>
          <tr>
            <td className={'font-size-1 align-middle'}>GraphQL Playground</td>
            <td>
              <a href={`${process.env.REACT_APP_APOLLO_SERVER_URI}/graphql`} target={'_blank'}
                 rel='noopener noreferrer'>{`${process.env.REACT_APP_APOLLO_SERVER_URI}/graphql`}</a>
            </td>
          </tr>
          </tbody>
        </Table>
        <Table striped bordered hover size={'sm'}>
          <thead>
          <tr>
            <th colSpan={2}>Service Status</th>
          </tr>
          </thead>
          <tbody className={'font-size-1'}>
          <tr>
            <td>
              <Button variant={'primary'} size={'sm'} onClick={checkBrxmServerStatus}>
                {loadingBrxm ? <Icon name={'spinner'} size={'lg'} spin content={`Page Model API`}/>
                  : <Icon name={'tools'} content={`Page Model API`}/>
                }
              </Button>
            </td>
            <td>
              {statusBrxm && <Status status={statusBrxm} dismissible customClassName={'my-1'}/>}
              {errorBrxm && <Status error status={errorBrxm} dismissible customClassName={'my-1'}/>}
            </td>
          </tr>
          <tr>
            <td>
              <Button variant={'primary'} size={'sm'} onClick={checkContentSearchServerStatus}>
                {loadingContentSearch ? <Icon name={'spinner'} size={'lg'} spin content={`Content Search`}/>
                  : <Icon name={'tools'} content={`Content Search`}/>
                }
              </Button>
            </td>
            <td>
              {statusContentSearch && <Status status={statusContentSearch} dismissible customClassName={'my-1'}/>}
              {errorContentSearch && <Status error status={errorContentSearch} dismissible customClassName={'my-1'}/>}
            </td>
          </tr>
          <tr>
            <td>
              <Button variant={'primary'} size={'sm'} onClick={checkApolloServerStatus}>
                {loadingApollo ? <Icon name={'spinner'} size={'lg'} spin content={`Apollo Server`}/>
                  : <Icon name={'tools'} content={`Apollo Server`}/>
                }
              </Button>
            </td>
            <td>
              {statusApollo && <Status status={statusApollo} dismissible customClassName={'my-1'}/>}
              {errorApollo && <Status error status={errorApollo} dismissible customClassName={'my-1'}/>}
            </td>
          </tr>
          </tbody>
        </Table>
      </div>
    </Popover>
  )
}
