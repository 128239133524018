/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {ChangeEvent, Fragment, useEffect, useState} from 'react';
import {Button, Col, Form, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BrProps} from '@bloomreach/react-sdk';
import {AddAddressProps, GetErrorProps, withAddAddress, withGetError} from '../../../connector-components';
import {Icon, Status} from '../../utility';
import {countryOptions, stateOptions} from '../../global/CommonEnums';

const config = require('../UserProfileComponentConfig.json');

function AddUserAddressBase(props: BrProps & AddAddressProps & GetErrorProps) {
  const {
    addressForm,
    addAddress,
    loading,
    result,
    errors,
    clearError
  } = props;

  const {
    firstName, setFirstName,
    middleName, setMiddleName,
    lastName, setLastName,
    country, setCountry,
    city, setCity,
    state, setState,
    //company, setCompany,
    postalCode, setPostalCode,
    streetAddress, setStreetAddress,
    additionalStreetInfo, setAdditionalStreetInfo,
    shippingAddress, setShippingAddress,
    billingAddress, setBillingAddress,
  } = addressForm;

  useEffect(() => {
    setCountry(config.address.addUserAddress.defaultCountryCode);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setState(config.address.addUserAddress.defaultState[country]);
  }, [country]); // eslint-disable-line react-hooks/exhaustive-deps

  const [show, setShow] = useState(false);
  const DEFAULT_MESSAGE = config.address.addUserAddress.defaultMessage;

  useEffect(() => {
    if (result?.success) {
      show && setShow(false);
    }
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage = errors?.find((error) => error.operation === 'addAddress')?.message;
  return (
    <Fragment>
      <Button variant={'success'} className={'float-right'} onClick={(event) => {
        setShow(true);
      }}>
        <Icon name={'plus'} content={config.address.addUserAddress.defaultDescription}/>
      </Button>
      <Modal show={show} onHide={() => setShow(false)} size='lg'>
        <Modal.Header closeButton>
          <span className='align-middle'><FontAwesomeIcon icon='plus'/> Add New Address</span>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete='off'>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className={'text-uppercase'}>First name</Form.Label>
                <Form.Control
                  type='text' name='firstName' autoComplete='none' required
                  placeholder={'First Name'} defaultValue={firstName}
                  onChange={(e) => setFirstName(e.currentTarget.value)}/>
              </Form.Group>
              <Form.Group as={Col} xs={2}>
                <Form.Label className={'text-uppercase'}>Middle</Form.Label>
                <Form.Control type='text' name='middleName' autoComplete='none'
                              placeholder={'Middle'} defaultValue={middleName}
                              onChange={(e) => setMiddleName(e.currentTarget.value)}/>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={'text-uppercase'}>Last name</Form.Label>
                <Form.Control type='text' name='lastName' autoComplete='none'
                              placeholder={'Last Name'} defaultValue={lastName}
                              onChange={(e) => setLastName(e.currentTarget.value)}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className={'text-uppercase'}>Street Address</Form.Label>
                <Form.Control type='text' name='streetAddress' autoComplete='none' required
                              placeholder={'Street Address'} defaultValue={streetAddress}
                              onChange={(e) => setStreetAddress(e.currentTarget.value)}/>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={'text-uppercase'}>APT, SUITE, ETC.</Form.Label>
                <Form.Control type='text' name='additionalStreetInfo' autoComplete='none'
                              placeholder={'Street Address Line 2'}
                              defaultValue={additionalStreetInfo}
                              onChange={(e) => setAdditionalStreetInfo(e.currentTarget.value)}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className={'text-uppercase'}>City</Form.Label>
                <Form.Control type='text' name='city' autoComplete='none' required
                              placeholder={'City'} defaultValue={city}
                              onChange={(e) => setCity(e.currentTarget.value)}/>
              </Form.Group>
              {country === 'US' && <Form.Group as={Col}>
                <Form.Label className={'text-uppercase'}>State</Form.Label>
                {/*<Form.Control type='text' name='state' autoComplete='none'
                              placeholder={'State'} defaultValue={state}
                              onChange={(e) => setState(e.currentTarget.value)}/>*/}
                <Form.Control
                  as={'select'}
                  onChange={(e) => {
                    setState(e.currentTarget.value);
                  }}
                  defaultValue={state}
                  placeholder='State'
                >
                  {stateOptions?.map((option: any, key) => {
                    return <option key={key} value={option.value}>{option.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>}
              <Form.Group as={Col}>
                <Form.Label className={'text-uppercase'}>Country</Form.Label>
                <Form.Control as={'select'}
                              placeholder='Select Country'
                              name='country'
                              defaultValue={country}
                              onChange={(e) => setCountry(e.currentTarget.value)}>
                  {countryOptions?.map((option) => {
                    return <option key={option.key} value={option.value}>{option.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={'text-uppercase'}>Postal Code</Form.Label>
                <Form.Control type='text' name='postalCode' autoComplete='none'
                              placeholder={'Postal Code'} defaultValue={postalCode}
                              onChange={(e) => setPostalCode(e.currentTarget.value)}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Check label='Shipping Address'
                            defaultChecked={shippingAddress}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setShippingAddress(e.target.checked)
                            }}/>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Check label='Billing Address'
                            defaultChecked={billingAddress}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setBillingAddress(e.target.checked)
                            }}/>
              </Form.Group>
            </Form.Row>
            <Col>
              {errorMessage &&
              <Status error status={errorMessage} onClose={clearError} customClassName={'mt-3'} dismissible={true}/>}
            </Col>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'success'} onClick={() => {
            clearError();
            addAddress();
          }}>
            {loading ? <Icon name={'spinner'} size={'lg'} spin content={DEFAULT_MESSAGE}/>
              : <Icon name={'plus'} content={DEFAULT_MESSAGE}/>}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export const AddUserAddress = withGetError(withAddAddress(AddUserAddressBase));
