/*
 * Copyright 2022 Bloomreach (http://www.bloomreach.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {ReactElement, useContext} from 'react';
import {UserContext} from '../../../contexts';
import {Status} from './Status';

export interface LoginStatusProps {
  showMessage?: boolean,
  children: ReactElement<any>
}

export const LoginStatus = ({showMessage = true, children}: LoginStatusProps) => {
  const {userState: {user}} = useContext(UserContext)!;

  if (user?.username) {
    return children;
  }

  if (showMessage) {
    return <Status customClassName={'my-5'} container error status={'You must login first!'}/>
  } else {
    return null;
  }
}
