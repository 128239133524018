import {gql} from 'apollo-boost'
import {ITEM_LIKE_FRAGMENT} from "./items";

export const FIND_SUGGESTIONS = gql`
  query Suggestions($text: String!, $queryHint: QueryHintInput) {
    findSuggestions(text: $text, queryHint: $queryHint) {
      terms
      items {
        ...ItemLikeFragment
      }
    }
  }

  ${ITEM_LIKE_FRAGMENT}
`;
